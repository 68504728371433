import React, { useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Link, SxProps, Typography } from '@mui/material'
import { logInfo } from '../../../log'
import { EventBase, SalesforceApp } from '../../../client'
import { salesforceAppIconUrl } from '../../../service/media'
import { Path } from '../../../routes'
import { useNavigate } from 'react-router-dom'
import useIsBlockedByPlan from 'hooks/useIsBlockedByPlan'
import { MasterTask } from '../SelectTasks'
import SalesforceNotesCard from './SalesforceNotesCard'
import SalesforceTasksCard from './SalesforceTasksCard'
// import SalesforceFlowsCard from './SalesforceFlowsCard'
import { gSx } from 'styles/Theme'
import SalesforceFlowsCard from './SalesforceFlowsCard'
import CrmContactCard from '../CrmContactCard'
import { addSalesforceIds } from 'service/integrations/salesforceApi'

interface Props {
  open: boolean
  onClose: () => void
  app: SalesforceApp
  event: EventBase
  notesHtml: string
}

export default function SalesforceDialog({ open, onClose, app, event, notesHtml }: Props) {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const [contacts, setContacts] = useState(event.contacts ?? [])
  const { isBlocked } = useIsBlockedByPlan(true)
  const [masterTasks, setMasterTasks] = useState<MasterTask[]>([])
  const [loading, setLoading] = useState(false)
  const [err, setErr] = useState<string | undefined>()

  useEffect(() => {
    if (open) {
      logInfo(`${app} NoteSender Dialog Open`)
      queryClient.invalidateQueries({ queryKey: ['getEvent', event?.id] })
    }
  }, [open])

  const disabled = isBlocked || !!err || loading

  useEffect(() => {
    run()
    async function run() {
      setLoading(true)
      setErr(undefined)
      const updated = await addSalesforceIds(event.id)
      if (updated instanceof Error) {
        setErr(updated.message)
      } else {
        if (updated)
          setContacts(updated)
      }
      setLoading(false)
    }
  }, [event.contacts])

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}
      slotProps={{ paper: { style: { height: '98vh', margin: 0, maxHeight: '98vh' } } }}
    >
      <DialogTitle sx={gSx.Row}>
        <Box
          component="img"
          sx={icon}
          src={salesforceAppIconUrl(app)}
          alt={app}
        />
        <Typography variant='h3'>
          Send to {app}
        </Typography>
      </DialogTitle>

      <DialogContent>
        {loading && <LinearProgress />}
        {err && <Typography color='red' textAlign={'center'}>{err}</Typography>}

        {isBlocked &&
          <Typography sx={sxText}>{`Please `}
            <Link onClick={() => navigate(Path.Billing)}>Upgrade your plan</Link>
          </Typography>
        }
        <Box sx={{ padding: 1 }} />

        <CrmContactCard event={event} contacts={contacts} disabled={disabled} />

        <SalesforceNotesCard
          event={event} app={app} contacts={contacts} disabled={disabled}
          notesHtml={notesHtml}
        />

        <SalesforceTasksCard
          event={event} app={app} contacts={contacts} disabled={disabled}
          masterTasks={masterTasks} setMasterTasks={setMasterTasks}
        />

        <SalesforceFlowsCard
          event={event} app={app} contacts={contacts} disabled={disabled}
          tasks={masterTasks}
        />

      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>

    </Dialog >
  )
}

const icon: SxProps = {
  width: 40,
  height: 40,
  marginRight: 2
}

const sxText: SxProps = {
  paddingY: '5px',
  cursor: 'pointer'
}
