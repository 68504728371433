import { ClientIndex } from 'client'
import Fuse from 'fuse.js'
import { getContactName } from 'service/clientApi'

export function createFuzzySearch(contacts?: ClientIndex[]) {
  if (!contacts)
    return

  // Combine first and last name into a fullName field
  const peopleWithFullName = contacts.map(c => ({
    ...c,
    fullName: getContactName(c),
  }))

  const options = {
    keys: ['firstName', 'lastName', 'fullName', 'email'],
    threshold: 0.3,
  }

  const fuse = new Fuse(peopleWithFullName, options)

  return (query: string) => {
    if (!query) return contacts
    return fuse.search(query).map(result => result.item)
  }
}