import React, { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import CheckIcon from '@mui/icons-material/Check'
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Divider, FormControl, Grid2, InputLabel, MenuItem, Select, Snackbar, SxProps, Typography } from '@mui/material'
import { ClientDetail, EventBase, RedtailActivity, RedtailTeam, RedtailUser } from '../../../client'
import { gColor, gSx } from '../../../styles/Theme'
import { LoadingButton } from '@mui/lab'
import { appendRedtailActivity, getRedtailEvents, getRedtailTeams, getRedtailUsers, sendRedtailNotes } from 'service/integrations/redtailApi'
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { getContactName } from 'service/clientApi'
import dayjs from 'dayjs'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'

interface Props {
  event: EventBase
  contacts?: ClientDetail[]
  eventSummaryHtml: string
  disabled: boolean
}

export default function RedtailNotesCard({ event, eventSummaryHtml, contacts, disabled: disabledByParent }: Props) {
  const queryClient = useQueryClient()
  const eventId = event?.id
  const [contact, setContact] = useState<ClientDetail | undefined>(undefined)
  const [notifyUser, setNotifyUser] = useState<RedtailUser | undefined>(undefined)
  const [notifyTeam, setNotifyTeam] = useState<RedtailTeam | undefined>(undefined)
  const isSent = !!event?.redtail_sent
  const [isSnackSuccess, setSnackSuccess] = useState(false)
  const [busyNote, setBusyNote] = useState(false)
  const [busyAppend, setBusyAppend] = useState(false)
  const [err, setErr] = useState<string | undefined>()
  const [expanded, setExpanded] = useState(!isSent)
  const [selectedEvent, setSelectedEvent] = useState<RedtailActivity | undefined>()
  const disabled = !contact || busyNote || busyAppend || disabledByParent

  useEffect(() => { // initialize
    if (contacts && contacts?.length > 0)
      setContact(contacts[0])
    else {
      setContact(undefined)
    }
  }, [contacts])

  const { data: teams } = useQuery({
    queryKey: ['getRedtailTeams'], queryFn: getRedtailTeams,
    enabled: queryClient.getQueryData(['getRedtailTeams']) === undefined,
  })

  const { data: users } = useQuery({
    queryKey: ['getRedtailUsers'], queryFn: getRedtailUsers,
    enabled: queryClient.getQueryData(['getRedtailUsers']) === undefined,
  })

  const date15DaysAgo = new Date()
  date15DaysAgo.setDate(date15DaysAgo.getDate() - 15)
  const date1DayFromNow = new Date()
  date1DayFromNow.setDate(date1DayFromNow.getDate() + 1)
  const { data: events, isLoading: isEventsLoading } = useQuery({
    queryKey: ['getRedtailEvents'],
    queryFn: async () => await getRedtailEvents(
      date15DaysAgo.toISOString(),
      date1DayFromNow.toISOString(),
    )
  })

  async function onAppendEvent() {
    setBusyAppend(true)
    setErr(undefined)
    if (!selectedEvent || !selectedEvent.id) {
      setErr('Please select an event to append to')
      setBusyAppend(false)
      return
    }

    const res = await appendRedtailActivity(event.id, eventSummaryHtml, selectedEvent.id)
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      setSnackSuccess(true)
      await queryClient.invalidateQueries(['getEvent', eventId])
      setExpanded(false)
    }
    setBusyAppend(false)
  }

  async function onSendNote() {
    setBusyNote(true)
    setErr(undefined)
    const res = await sendRedtailNotes(event.id, eventSummaryHtml, contact, notifyUser, notifyTeam)
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      setSnackSuccess(true)
      await queryClient.invalidateQueries(['getEvent', eventId])
      setExpanded(false)
    }
    setBusyNote(false)
  }

  return (
    <>
      <Accordion
        sx={gSx.Crm.Accordian}
        defaultExpanded={!isSent}
        expanded={expanded}
        onChange={() => setExpanded(state => !state)}
        disableGutters
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={gSx.Crm.AccordianSummary}
        >
          <Box sx={gSx.Row}>
            {isSent
              ? <CheckCircleIcon sx={{ ...gSx.Crm.LeftIcon, color: gColor.green500 }} />
              : <TextSnippetOutlinedIcon sx={gSx.Crm.LeftIcon} />
            }
            <Typography variant='h5'>Meeting Summary</Typography>
          </Box>
          {isSent &&
            <Typography color={gColor.green500} sx={{ paddingRight: 2 }}>Sent</Typography>
          }
        </AccordionSummary>

        <AccordionDetails>
          <Typography>Send the Meeting Summary as a Redtail Note.</Typography>

          {err && <Typography color={'tomato'}>{err}</Typography>}

          <Box sx={{ padding: 1 }} />

          <FormControl>
            <InputLabel id="contact" size='small'>Contact</InputLabel>
            <Select id="contact" size='small' disabled={disabled}
              label='Contact' value={contact?.id || ''}
              onChange={e => {
                const selectedContact = contacts?.find(c => c.id === e.target.value)
                setContact(selectedContact)
              }}
              sx={{ width: 250 }}
            >
              {contacts?.map(c => (
                <MenuItem key={c.id} value={c.id}>
                  {getContactName(c)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box sx={{ padding: 1 }} />

          <Grid2 container direction='column' spacing={2}>
            <Box sx={gSx.RowBetween}>
              <Box sx={gSx.Row}>
                <FormControl size='small'>
                  <InputLabel id="notify-user" size='small'>Notify User (Optional)</InputLabel>
                  <Select id="notify-user" size='small' disabled={disabled}
                    label='Notify User (Optional)' value={notifyUser?.id}
                    onChange={e => {
                      const selectedUser = users?.find(c => c.id === e.target.value)
                      setNotifyUser(selectedUser)
                    }}
                    sx={{ width: 250 }}
                  >
                    <MenuItem value="">
                      None
                    </MenuItem>
                    {users?.map(u => (
                      <MenuItem key={u.id} value={u.id}>
                        {`${u.first_name} ${u.last_name}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Box sx={{ padding: 2 }} />

                <FormControl size='small'>
                  <InputLabel id="notify-user" size='small'>Notify Team (Optional)</InputLabel>
                  <Select id="notify-team" size='small' disabled={disabled}
                    label='Notify Team (Optional)' value={notifyTeam?.id}
                    onChange={e => {
                      const selectedTeam = teams?.find(c => c.id === e.target.value)
                      setNotifyTeam(selectedTeam)
                    }}
                    sx={{ width: 250 }}
                  >
                    <MenuItem value="">
                      None
                    </MenuItem>
                    {teams?.map(t => (
                      <MenuItem key={t.id} value={t.id}>
                        {`${t.name}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <LoadingButton
                variant={'contained'}
                onClick={onSendNote}
                disabled={disabled}
                loading={busyNote}
                sx={{ marginLeft: 3 }}
              >
                Send Notes
              </LoadingButton>
            </Box>
            <Divider />
            <Typography variant='caption'><span style={{ fontWeight: 500, fontStyle: 'italic' }}>Append summary to</span> will only display activities that began within the last 15 days. Recurring activities will have the summary appended to every occurrence.</Typography>
            <Box sx={gSx.RowBetween}>
              <Box sx={gSx.Row}>
                <FormControl>
                  <InputLabel id="append-summary-label" size='small'>Append summary to</InputLabel>
                  <Select id="append-event" size='small' disabled={disabled}
                    label='Append summary to' value={notifyUser?.id}
                    onChange={e => {
                      const selectedEvent = events?.find(event => event.id === e.target.value)
                      setSelectedEvent(selectedEvent)
                    }}
                    sx={{ width: 250 }}
                  >
                    {events ? events.map((event, index) => {
                      const eventDate = dayjs(event.start_date)
                      let timeDisplay

                      if (event.end_date) {
                        const startTime = eventDate.format('h:mma')
                        const endTime = dayjs(event.end_date).format('h:mma')
                        timeDisplay = `${startTime} - ${endTime}`
                      } else {
                        timeDisplay = eventDate.format('h:mma')
                      }
                      return (
                        <MenuItem
                          key={event.id}
                          value={event.id}
                          sx={{ ...sxEventItem, background: index % 2 === 0 ? "#fbfbfb" : "white" }}
                        >
                          <Typography variant='body1' sx={sxEventSubject}>{event.subject}</Typography>
                          <Grid2 container wrap='nowrap' sx={{ alignItems: 'center', justifyContent: 'space-between', width: '100%', gap: '0.5em' }}>
                            {/* Format as "[CalendarTodayIcon] Mar 6, 2025" */}
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                color: "text.secondary",
                              }}
                            >
                              <CalendarTodayIcon sx={{ fontSize: "0.8rem", mr: 0.5 }} />
                              <Typography variant="caption" sx={{ fontSize: "0.7rem" }}>
                                {dayjs(eventDate).format("MMM D")}
                              </Typography>
                            </Box>
                            {/* Format as "[AccessTimeIcon] 11:30am - 12:30pm" */}
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                color: "text.secondary",
                              }}
                            >
                              <AccessTimeIcon sx={{ fontSize: "0.8rem", mr: 0.5 }} />
                              <Typography variant="caption" sx={{ fontSize: "0.7rem" }}>
                                {timeDisplay.toLowerCase()}
                              </Typography>
                            </Box>
                          </Grid2>
                        </MenuItem>)
                    }) :
                      isEventsLoading ?
                        <MenuItem disabled>Loading Redtail Activities</MenuItem>
                        :
                        <MenuItem disabled>No Redtail Activities</MenuItem>
                    }
                  </Select>
                </FormControl>
              </Box>

              <LoadingButton
                variant={'contained'}
                onClick={onAppendEvent}
                disabled={disabled}
                loading={busyAppend}
                sx={{ marginLeft: 3 }}
              >
                Append activity
              </LoadingButton>
            </Box>
          </Grid2>

        </AccordionDetails>
      </Accordion>

      <Snackbar
        open={isSnackSuccess}
        autoHideDuration={3000}
        onClose={() => setSnackSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          variant="filled"
          icon={<CheckIcon fontSize="inherit" />}
          onClose={() => setSnackSuccess(false)}
          severity='success'
          sx={{ width: '100%' }}
        >
          Meeting Summary Sent
        </Alert>
      </Snackbar>
    </>
  )
}

const sxEventSubject: SxProps = {
  width: '100%',
  fontWeight: 500,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
}

const sxEventItem: SxProps = { display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '4px 16px' }