import React, { useRef, useEffect, useState } from 'react'
import { Api, auth, config } from '../../service/api'
import { Box, SxProps, Card, Typography, LinearProgress } from '@mui/material'
import { observer } from 'mobx-react-lite'
import ReactPlayer from 'react-player'
import { useQuery } from '@tanstack/react-query'
import { useStore } from '../../hooks/useStore'
import { retryDelayExpBackOffUptoSevenSecs, retryStopOnlyExpired } from '../../hooks/hookHelpers'
import { gSx } from 'styles/Theme'

interface QueryError {
  message: string;
  description: string;
  statusCode: string | number;
  status: string | number;
}

interface Props {
  eventId: string
}

export default observer(VideoCard)
function VideoCard({ eventId }: Props) {
  const playerRef = useRef(null)
  const store = useStore()
  const seekSeconds = store.User.getSeekSeconds()
  const [videoUrl, setVideoUrl] = useState('')
  const [isAudio, setIsAudio] = useState(false)

  const { data, error, isLoading } = useQuery({
    queryKey: ['getEventVideo' + eventId],
    refetchOnWindowFocus: false,
    queryFn: runQuery,
    retry: retryStopOnlyExpired,
    retryDelay: retryDelayExpBackOffUptoSevenSecs, // retry max 7 secs
  })

  async function runQuery() {

    const api = await Api()
    const res = await api.events.getEventVideo(eventId)
    return res
  }

  useEffect(() => {
    if (data && data.bucket_filename && data.bucket_filename != videoUrl) {
      setVideoUrl(data.bucket_filename)
    }
  }, [data?.bucket_filename])

  const sxCard: SxProps = {
    padding: '28.125% 0 28.125% 0', /* 16:9 Aspect Ratio */
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }

  if (error) {
    const queryError = error as QueryError
    if (queryError.status === 410) {
      return (
        <Card sx={sxCard}>
          <Box sx={gSx.CenterVertical}>
            <Typography variant="h6" color="grey">Media Expired</Typography>
          </Box>
        </Card>
      )
    }
  }

  if (playerRef.current) {
    // @ts-expect-error seekto never
    playerRef.current.seekTo(seekSeconds, 'seconds')
  }

  if (isLoading || !data) {
    return (
      <Card sx={sxCard}>
        <Box sx={gSx.CenterVertical}>
          <Typography>Media Processing</Typography>
          <Box sx={{ width: '100%', paddingY: '1vh' }}>
            <LinearProgress />
          </Box>
        </Box>
      </Card>
    )
  }

  const handleReady = (player: ReactPlayer) => {
    if (player.getInternalPlayer() instanceof HTMLAudioElement) {
      setIsAudio(true)
    } else {
      setIsAudio(false)
    }
  }

  function onVideoProgress(event: any) {
    store.User.setTranscriptSeekSeconds(event.playedSeconds)
  }
  return (
    <Card sx={{
      borderRadius: '5px',
      padding: `${isAudio ? '50px' : '56.25%'} 0 0 0`, /* 56.25% = Player ratio: 100 / (1280 / 720) */
      position: 'relative'
    }}>
      <ReactPlayer
        ref={playerRef}
        url={videoUrl}
        controls
        light={data.thumbnail_filename}
        style={{ position: 'absolute', top: 0, left: 0 }}
        width={'100%'}
        height={'100%'}
        volume={0.8}
        onProgress={onVideoProgress}
        onReady={handleReady}
      />
    </Card >
  )
}
