import { useState } from 'react'
import { Box, Button, Popover, Typography, SxProps, IconButton, Tooltip } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../hooks/useStore'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs, { Dayjs } from 'dayjs'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { gColor, gSx } from '../../styles/Theme'
import { logInfo } from '../../log'
import Grid from '@mui/material/Grid2'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
interface Props {
  isShowDate: boolean
}
export default observer(SearchEventDates)
function SearchEventDates({ isShowDate }: Props) {
  const store = useStore()
  const params = store.User.getEventSearchParams()
  const earliest = params.earliest ? dayjs(params.earliest) : null
  const latest = params.latest ? dayjs(params.latest) : null

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const id = open ? 'popover' : undefined

  async function onEarliest(d: Dayjs | null) {
    logInfo('SearchEventDates onEarliest', { earliest: d?.toISOString() })
    store.User.setEventSearchParams({ ...params, earliest: d?.toISOString() })
  }

  async function onLatest(d: Dayjs | null) {
    logInfo('SearchEventDates onLatest', { latest: d?.toISOString() })
    store.User.setEventSearchParams({ ...params, latest: d?.toISOString() })
  }

  async function onClear() {
    logInfo('SearchEventDates onClear')
    store.User.setEventSearchParams({})
  }

  if (isShowDate == false)
    return null

  return (
    <Box>
      <Tooltip title="Date Range">
        <IconButton
          onClick={(event: any) => setAnchorEl(event.currentTarget)}
          sx={{ height: 40 }}
        >
          <CalendarMonthIcon color={earliest || latest ? "secondary" : "primary"} />
        </IconButton>
      </Tooltip>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={{ padding: '1vh', backgroundColor: 'white' }}>

          <Grid container spacing={6}>
            <Grid size={{ xs: 6 }} >
              <Typography variant='h4' sx={{ fontWeight: 'bold' }}>Earliest</Typography>

              <DatePicker value={earliest} onChange={onEarliest} />
              {
                earliest === null && <Typography>Since the dawn of time</Typography>
              }

            </Grid>
            <Grid size={{ xs: 6 }} >
              <Typography variant='h4' sx={{ fontWeight: 'bold' }}>Latest</Typography>

              <DatePicker value={latest} onChange={onLatest} />
              {
                latest === null && <Typography>Today</Typography>
              }

            </Grid>
          </Grid>

          <Box sx={{ paddingY: '1vh' }} />

          <Box sx={gSx.RowBetween}>
            {
              (earliest !== null || latest !== null) && (
                <Button
                  variant='outlined'
                  color="error"
                  onClick={onClear}
                >
                  Clear
                </Button>
              )
            }
            <Box sx={{ paddingX: '2vh' }} />
            <Button
              variant='outlined'
              onClick={() => setAnchorEl(null)}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Popover>
    </Box>
  )
}

const sxTitleDate: SxProps = {
  color: gColor.green500,
  fontSize: '13px',
  fontWeight: 'bold',
  paddingLeft: '10px',
  whiteSpace: 'nowrap'
}