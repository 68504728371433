import React, { useEffect, useState } from 'react'
import { Box, SxProps, LinearProgress, Typography, ButtonBase } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { Path } from 'routes'
import { Media } from 'service/media'
import { logInfo } from 'log'
import { getCreateUser } from 'service'
import { requestNotificationPermission } from 'service/msgApi'
import mixpanel from 'mixpanel-browser'
import { auth } from 'service/api'
import { useQuery } from '@tanstack/react-query'
import { GetCalendarUser } from 'service/calendarApi'
import { getOrgInvitesAsRecipient } from 'service/orgsApi'
import { Region } from 'client'
import { sleep } from 'utils'
import { signOut } from 'firebase/auth'
// import GoogleConsentButton from './GoogleConsentButton'

export default function LoadingScreen() {
  const navigate = useNavigate()
  const fbUser = auth.currentUser
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search) // may contain affiliate and region
  urlParams.delete('apiKey') // from email link sign in 
  urlParams.delete('oobCode') // from email link sign in 
  const homePathWithParams = `${Path.Home}?${urlParams.toString()}`

  const [errCountDown, setErrCountDown] = useState(100)
  const [err, setErr] = useState(false)

  useEffect(() => { // countdown
    if (err) {
      const interval = setInterval(() => {
        setErrCountDown(prev => {
          if (prev > 0) {
            return prev - 2
          } else {
            clearInterval(interval)
            return 0
          }
        })
      }, 100)
      return () => clearInterval(interval)
    }
  }, [err])

  useEffect(() => {
    logInfo(`Loading Screen`, { fbUser })
    if (fbUser == null)
      navigate(`${Path.SignIn}?${urlParams.toString()}`)
  }, [fbUser])

  useQuery({ // preload cal
    queryKey: ['GetCalendarUser'],
    queryFn: GetCalendarUser,
  })

  useEffect(() => {
    run()
  }, [])

  async function run() {
    mixpanel.identify(fbUser?.uid)
    mixpanel.people.set({
      name: fbUser?.displayName,
      "$email": fbUser?.email
    })

    const region = localStorage.getItem('region') as Region ?? undefined
    const affiliateId = localStorage.getItem('affiliateId') ?? undefined
    const user = await getCreateUser(affiliateId, region)
    if (user instanceof Error) {
      setErr(true)
      await sleep(5000)
      await signOut(auth)
      return
    }

    if (user?.blocked_pending_demo) {
      navigate(Path.BlockedPendingDemo)
      return
    }

    const invites = await getOrgInvitesAsRecipient()
    if (invites && invites.length > 0) {
      navigate(Path.ReceivedInvite)
      return
    }

    requestNotificationPermission() // do not allow await. for mobile web app users.

    navigate(homePathWithParams)
  }

  return (
    <Box>
      <ButtonBase onClick={() => signOut(auth)}>
        <Typography color={'lightgray'}>{`[Signout]`}</Typography>
      </ButtonBase>

      <Box sx={sxPage}>
        <Box component='img' sx={sxLogo} src={Media.logo100} />

        <Box sx={{ width: '30%', maxWidth: '350px', paddingTop: 3 }}>
          {err
            ? <LinearProgress variant="determinate" value={errCountDown} />
            : <LinearProgress />
          }
        </Box>

        {err &&
          <Typography color={'red'} variant='h5'>
            Something went wrong. Please Sign In Again.
          </Typography>
        }

      </Box >

    </Box>
  )
}

const sxPage: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '90vh',
  width: '100%',
}

const sxLogo: SxProps = {
  height: 30,
  width: 35,
  resizeMode: 'contain',
  alignContent: 'center',
  paddingLeft: '5px',
  paddingRight: '10px',
}