import React, { useEffect } from 'react'
import { config } from 'service/api'
import { getRegionFromSubDomain } from 'service/host'

// See web/README.md > Email Link Auth for Web
// subdomains have different local storage, and auth.
// auth email link is sensitive to subdomain and will not work if on another subdomain.
// Region subdomain: url with subdomain must move to region as url param.

export default function useRedirectSubDomainRegion(affiliateId?: string) {
  const urlParams = new URLSearchParams(location.search) // may contain affiliate

  const region = getRegionFromSubDomain()
  useEffect(() => {
    if (region) {
      const url = affiliateId ? `${config.web}/hi/${affiliateId}` : `${config.web}`
      window.location.href = `${url}?${urlParams.toString()}&region=${region}`
      return
    }
  }, [region])
}