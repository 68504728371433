import React from 'react'
import { Box } from '@mui/system'
import { SxProps, Card, CardHeader, Container, Typography, Chip, Avatar, Tooltip } from '@mui/material'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { gSx } from 'styles/Theme'
import { getEnterprise, numStandardToOrgFromEnterprise, numStarterToOrgFromEnterprise } from 'service/enterpriseApi'
import { useNavigate, useParams } from 'react-router-dom'
import { SkeletonRows } from 'components/Skeletons'
import { getOrgById } from 'service/orgsApi'
import EnterpriseBackButton from 'components/enterprise/EnterpriseBackButton'
import Grid from '@mui/material/Grid2'
import EnterpriseOrgEditSeatsDialog from 'components/enterprise/EnterpriseOrgEditSeatsDialog'
import ContainerMedium from 'components/ContainerMedium'

export default function EnterpriseOrgScreen() {
  const { id } = useParams()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { data: ent } = useQuery({
    queryKey: ['getEnterprise'], queryFn: getEnterprise,
  })

  const { data: org, isLoading: isLoadingOrg } = useQuery({
    queryKey: ['getOrgById', id], queryFn: async () => await getOrgById(id),
  })
  const standardInitToOrgByEnt = numStandardToOrgFromEnterprise(ent, org)
  const starterInitToOrgByEnt = numStarterToOrgFromEnterprise(ent, org)

  if (isLoadingOrg)
    return (
      <Container sx={{ paddingY: 3 }}>
        <EnterpriseBackButton />
        <Typography variant='h1' sx={{ paddingY: 2 }} >Organization</Typography>
        <SkeletonRows count={3} height={100} />
      </Container >
    )

  return (
    <ContainerMedium>
      <EnterpriseBackButton />
      <Typography variant='h1' sx={{ paddingY: 2 }} >{org?.org_name} Organization</Typography>

      <Card>
        <CardHeader title='Plan Allocation'
          action={(org && <EnterpriseOrgEditSeatsDialog org={org} />)}
        />

        <Grid container>
          <Grid size={11}>
            <Typography>Standard Seats</Typography>
            <Typography>Starter Seats</Typography>
          </Grid>

          <Grid size={1} textAlign={'right'}>
            <Typography>{standardInitToOrgByEnt}</Typography>
            <Typography>{starterInitToOrgByEnt}</Typography>
          </Grid>
        </Grid>

      </Card>

      <Card>
        <CardHeader title='Users' />

        {org?.org_user_list.map(u =>
          <Box key={u.uid} sx={sxLine}>

            <Grid container>
              <Grid size={6}>

                <Tooltip title={`${u.name} ${u.email}`}>
                  <Chip
                    avatar={<Avatar alt={u.email} src={u.photo_url} />}
                    label={`${u.name ? u.name : u.email}`}
                    variant="outlined"
                  />
                </Tooltip>
              </Grid>

              <Grid size={3}>
                <Typography>{u.org_user_role}</Typography>
              </Grid>
              <Grid size={3}>
                <Typography>{u.org_user_plan}</Typography>
              </Grid>

            </Grid>

          </Box>
        )
        }
      </Card>

    </ContainerMedium >
  )
}

const sxLine = {
  paddingY: 1,

}
