import React, { useEffect, useState } from 'react'
import { Link, Card, Box, SxProps, Button, Typography, useMediaQuery } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { useLocation, useNavigate } from 'react-router-dom'
import { Path } from 'routes'
import { Media } from 'service/media'
import { logInfo } from 'log'
import { auth, config } from 'service/api'
import SignupCarousel from 'components/help/SignupCarousel'
import { gSx, theme } from 'styles/Theme'
import SignInActionSection from 'components/auth/SignInActionSection'
import useRedirectSubDomainRegion from 'hooks/useRedirectSubDomainRegion'

export default function SignInScreen() {
  useRedirectSubDomainRegion()
  const navigate = useNavigate()
  const location = useLocation()
  const fbUser = auth.currentUser
  const urlParams = new URLSearchParams(location.search) // may contain affiliate
  const loadingParamsScreen = `${Path.Loading}?${urlParams.toString()}`
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    logInfo(`Signin Screen`)
    localStorage.removeItem('affiliateId')
    localStorage.removeItem('affiliateOfferParams')
  }, [])

  useEffect(() => {
    if (fbUser)
      navigate(loadingParamsScreen)
  }, [fbUser])

  const section: SxProps = {
    ...(isSmallScreen ? gSx.CenterVertical : gSx.CenterPage),
    zIndex: 2, position: 'relative', color: 'white'
  }

  return (
    <Grid container>
      <Grid size={{ xs: 12, md: 6 }}>
        <SignInActionSection />
      </Grid>

      <Grid size={{ xs: 12, md: 6 }} sx={{ backgroundColor: '#1C4662', position: 'relative', overflow: 'hidden' }}>
        <Box sx={section}>
          <Box sx={{ padding: 3 }} />
          <Typography variant='h3' sx={{ paddingX: 3 }}>
            Join the 1000+ advisors already using FinMate AI
          </Typography>

          <SignupCarousel />
        </Box>
        <Box component="img" src={Media.mountainsBg} sx={sxBackground} />
        <BgAnimation />
      </Grid>
    </Grid>
  )
}

function BgAnimation() {
  return (
    <style>
      {`
    @keyframes moveBackground {
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
    }
  `}
    </style>
  )
}

const sxBackground: SxProps = {
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '200%',
  height: 'auto',
  animation: 'moveBackground 100s linear infinite',
  zIndex: 1,
}