import React, { useRef, useEffect } from 'react'
import { Path } from '../../routes'
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'
import { SxProps, useMediaQuery } from '@mui/material'
import { getAuth } from 'firebase/auth'
import { logErr } from '../../log'
import { useLocation } from 'react-router-dom'

export default function TawkToHelp() {
  const tawkMessengerRef = useRef(null)
  const isSmall = useMediaQuery('(max-width:600px)')
  const location = useLocation()
  const p = location.pathname

  const isShow = (p.includes(Path.Home)
    || p.includes(Path.Organization)
    || p.includes(Path.Settings)
    || p.includes(Path.Integrations)
    || p.includes(Path.Billing)
    || p.includes(Path.ZoomSettings)
    || p.includes(Path.CalendarAuth)
  ) && !isSmall

  const updateWidgetStatus = () => {
    if (!tawkMessengerRef) return
    if (!tawkMessengerRef.current) return
    try {
      if (isShow) {
        // @ts-expect-error minimize never 
        tawkMessengerRef.current.showWidget()
      }
      else {
        // @ts-expect-error maximize never
        tawkMessengerRef.current.hideWidget()
      }
    } catch (error) {
      console.log("tawkMessengerRef error", error)
    }
  }

  useEffect(() => {
    updateWidgetStatus()
  }, [isShow, tawkMessengerRef?.current])

  getAuth().onAuthStateChanged(async fbUser => {
    if (tawkMessengerRef.current) {
      try {
        if (fbUser !== null) {
          // @ts-expect-error setAttributes never
          tawkMessengerRef.current.setAttributes({
            id: fbUser?.uid,
            email: fbUser?.email,
            name: fbUser?.displayName
          }, function (e: any) {
            // logErr('Tawkto attributes', { e })
          })
        } else {
          // @ts-expect-error setAttributes never
          tawkMessengerRef.current.setAttributes({
            id: '',
            email: '',
            name: ''
          }, function (e: any) {
            // logErr('Tawkto attributes clear', { e })
          })
        }
      } catch (e) {
        // do nothing
      }

    }
  })

  return (
    <TawkMessengerReact
      propertyId="62fd6ac537898912e9639ee1"
      widgetId="1h66rgikh"
      ref={tawkMessengerRef}
      onLoad={updateWidgetStatus}
    />
  )
}
