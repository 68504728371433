import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Typography, FormControl, Select, MenuItem, SelectChangeEvent, FormGroup, Divider, Skeleton } from '@mui/material'
import 'firebase/auth'
import { FlowInput, FlowInputConfig, SalesforceUser } from 'client'
import Grid from '@mui/material/Grid2'
import { auth } from 'service/api'

interface Props {
  input: FlowInput
  setFlowInputs: Dispatch<SetStateAction<FlowInputConfig[]>>;
  allUsers: SalesforceUser[]
}

export default function SalesforceFlowUser({ input, allUsers, setFlowInputs }: Props) {
  const [selectedUser, setSelectedUser] = useState<SalesforceUser>()

  useEffect(() => {
    const me = allUsers?.find(u => u.email == auth.currentUser?.email)
    if (me) {
      setSelectedUser(me)
      const newFlowInput: FlowInputConfig = { input, user: me }
      setFlowInputs(prev => [...prev.filter(o => o.input !== input), newFlowInput])
    }
  }, [])

  const onUserChange = (event: SelectChangeEvent<any>) => {
    if (!allUsers)
      return
    const user: SalesforceUser = JSON.parse(event.target.value)
    setSelectedUser(user)
    const newFlowInput: FlowInputConfig = { input, user }
    setFlowInputs(prev => [...prev.filter(o => o.input !== input), newFlowInput])
  }

  if (!selectedUser)
    return <Skeleton variant="rectangular" width={'90%'} height={20} />

  return (
    <Grid container>
      <Grid size={4}>
        <Typography>{input.name}</Typography>
      </Grid>
      <Grid size={8}>

        <FormGroup>
          <FormControl fullWidth>
            <Select size='small'
              value={selectedUser ? JSON.stringify(selectedUser) : ''}
              onChange={onUserChange}
            >
              {allUsers.map(u =>
                <MenuItem key={u.id} value={JSON.stringify(u)}>{u.name}, {u.email}</MenuItem>
              )}
            </Select>
          </FormControl>
        </FormGroup>

      </Grid>
      <Grid size={12}>
        <Divider sx={{ marginY: 1 }} />
      </Grid>
    </Grid>
  )
}
