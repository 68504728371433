import React, { useState } from 'react'
import { Typography, Snackbar, Alert, Tooltip, Box } from '@mui/material'
import 'firebase/auth'
import { LoadingButton } from '@mui/lab'
import { importSalesforceWhos } from 'service/integrations/salesforceApi'
import { SalesforceApp } from 'client'
import { useQueryClient } from '@tanstack/react-query'

interface Props {
  app: SalesforceApp
  includeTeamContacts?: boolean
}
export default function SalesforceImportContactsButton({ app, includeTeamContacts }: Props) {
  const queryClient = useQueryClient()
  const [busy, setBusy] = useState(false)
  const [err, setErr] = useState<string | undefined>()
  const [isSnackSuccess, setSnackSuccess] = useState(false)

  async function onImportContacts() {
    setErr(undefined)
    setBusy(true)
    const res = await importSalesforceWhos(includeTeamContacts)
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      setSnackSuccess(true)
      await queryClient.invalidateQueries({ queryKey: ['getClientsIndex'] })
    }
    setBusy(false)
  }

  return (
    <Box>
      <Tooltip title={<span>Imports {app} contacts<br />into FinMate AI Clients</span>}>
        <LoadingButton
          onClick={onImportContacts}
          loading={busy}
          variant='outlined'
          sx={{ whiteSpace: 'nowrap' }}
        >
          Import {app} Contacts
        </LoadingButton>
      </Tooltip>

      {err && <Typography sx={{ color: 'tomato' }}>
        {err}
      </Typography>
      }

      <Snackbar
        open={isSnackSuccess}
        autoHideDuration={3000}
        onClose={() => setSnackSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          variant="filled"
          onClose={() => setSnackSuccess(false)}
          severity='success'
          sx={{ width: '100%' }}
        >
          Importing {app} contacts, this may take a while. To see new imports please refresh the page in a few minutes.
        </Alert>
      </Snackbar>
    </Box>
  )
}