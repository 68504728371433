import { logErr } from "log"
import { Api } from "./api"
import { ClientDetail, ClientIndex, RedtailType, WealthboxType } from "client"
import { getErrorMessage, isEmailValid } from "utils"

export async function getClientDetail(client_id?: string) {
  if (!client_id)
    return
  try {
    const api = await Api()
    return await api.clients.getClientDetail(client_id)
  } catch (e) {
    logErr('getClientDetail', { e })
  }
}

export async function getClientsIndex() {
  try {
    const api = await Api()
    return await api.clients.getClientsIndex()
  } catch (e) {
    logErr('getClientsIndex', { e })
  }
}

export function getRecentClients(limit: number, clients?: ClientIndex[]) {
  if (!clients)
    return []

  // sort last_event_datetime descending
  clients.sort((a, b) => {
    const dateA = a.last_event_datetime ? new Date(a.last_event_datetime).getTime() : -Infinity
    const dateB = b.last_event_datetime ? new Date(b.last_event_datetime).getTime() : -Infinity
    if (dateA !== -Infinity && dateB !== -Infinity) {
      return dateB - dateA
    }
    if (dateA !== -Infinity) return -1
    if (dateB !== -Infinity) return 1
    return 0
  })

  return clients.slice(0, limit)
}

export async function upsertClient(client: ClientDetail, eventId?: string) {
  try {
    if (!isValidContactByEmailOrCrmId(client))
      throw new Error('Invalid Contact, missing Email or Crm Id')
    const api = await Api()
    return await api.clients.upsertClient(client, eventId)
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('upsertClient', { err })
    return err
  }
}

export async function deleteClient(clientId?: string) {
  if (!clientId)
    return new Error('missing client Id')
  try {
    const api = await Api()
    return await api.clients.deleteClient(clientId)
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('deleteClient', { err })
    return err
  }
}

export function getContactName(contact?: ClientIndex | ClientDetail, noEmail?: boolean) {
  if (!contact)
    return ''

  const isEntity = isContactEntity(contact)
  if (isEntity)
    return contact.full_name ?? ''

  if ((contact?.last_name && contact.last_name.length > 0) || (contact?.first_name && contact.first_name.length > 0))
    return `${contact.first_name ?? ''} ${contact.last_name ?? ''}`

  if (noEmail)
    return ''
  return contact?.email ?? ''
}

export function isContactEntity(c: ClientDetail) {
  // only wealthbox/redtail implements non person, entities.

  // used for fullName or FirstName/lastName
  if (c.wealthbox_type && c.wealthbox_type != WealthboxType.PERSON)
    return true
  if (c.redtail_type && c.redtail_type != RedtailType.INDIVIDUAL)
    return true

  return false
}

export function isValidContactByEmailOrCrmId(c: ClientDetail) {
  if (c.redtail_id || c.wealthbox_id || (c.salesforce_id && c.salesforce_id.length > 0))
    return true
  if (c.email && isEmailValid(c.email))
    return true
  return false
}
