import React from 'react'
import { Chip } from '@mui/material'
import { ModuleIdentifier, ModuleOptionLevelOfDetailType } from '../../client'

interface DetailLevelChipProps {
  module?: ModuleIdentifier;
}

const TemplateDetailLevelChip: React.FC<DetailLevelChipProps> = ({
  module,
}) => {
  const detailLevel: ModuleOptionLevelOfDetailType = module?.options?.level_of_detail || ModuleOptionLevelOfDetailType.DETAILED
  const isDetailed = detailLevel === ModuleOptionLevelOfDetailType.DETAILED
  if (!module?.subprompt_affix_list || module.subprompt_affix_list.length == 0)
    return null

  return (
    <Chip
      size='small'
      label={detailLevel}
      color={isDetailed ? "primary" : "default"}
      variant="outlined"
      sx={{
        height: 20,
        '& .MuiChip-label': {
          px: 1,
          fontSize: '0.7rem',
          fontWeight: 500
        }
      }}
    />
  )
}

export default TemplateDetailLevelChip