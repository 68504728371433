import React, { useEffect, useState } from 'react'
import { Box, ButtonBase, Card, IconButton, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { EventBase } from 'client'
import { gColor, gSx } from 'styles/Theme'
import ClientEventDialog from 'components/client/ClientCardForEventDialog'
import EditIcon from '@mui/icons-material/Edit'
import { CrmClientBadge } from 'components/client/CrmClientBadge'
import { ContactChip } from 'components/client/ContactChip'

interface Props {
  event: EventBase
  disabled?: boolean
}

export default function PrecisefpContactCard({ event, disabled }: Props) {
  const contacts = event?.contacts ?? []
  const hasContacts = !!(event?.contacts?.[0])
  const [showAddClientDialog, setShowAddClientDialog] = useState(false)

  return (
    <Card>
      <Grid container >
        <Grid size={2}>
          <Typography variant='h5'>Contact</Typography>
        </Grid>

        <Grid size={9}>
          {hasContacts && <ContactChip contact={contacts[0]} />}

          {!hasContacts &&
            <>
              <ButtonBase onClick={() => setShowAddClientDialog(true)}>
                <Typography sx={{ color: 'red', cursor: 'pointer', fontWeight: 'bold' }}
                >
                  Add a contact
                </Typography>
              </ButtonBase>

              <Typography >
                The Email should match your existing PreciseFP profile, or Finmate will automatically create a new PreciseFP profile for you.
              </Typography>
            </>
          }

        </Grid>

        <Grid size={1}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton
              aria-label='edit'
              onClick={() => setShowAddClientDialog(true)}
              disabled={disabled}
            >
              <EditIcon fontSize='small' />
            </IconButton>
          </Box>
        </Grid>
      </Grid>

      <ClientEventDialog
        event={event}
        open={showAddClientDialog}
        onClose={() => setShowAddClientDialog(false)}
      // TODO only 1 contact or multi contact @tyler
      />
    </Card>
  )
}
