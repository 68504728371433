import { useEffect } from 'react'
import { checkRedtailAuthed } from 'service/integrations/redtailApi'
import { useQueryClient } from '@tanstack/react-query'

/**
 * if redtail auth is bad cred, 
 * then backend disconnects redtail. 
 *     and user is notified by fcm(firebase cloud messaging).
 * 
 * @returns {void}
 */
export default function useRedtailValidateAuth() {
  const queryClient = useQueryClient()

  useEffect(() => {
    async function run() {
      const res = await checkRedtailAuthed()
      if (res instanceof Error) {
        if (res.message.includes('Redtail Invalid Credentials'))
          await queryClient.invalidateQueries({ queryKey: ['getRedtailSettings'] })
      }
    }
    run()
  }, [])

  return
}
