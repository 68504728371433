import React, { useEffect } from 'react'
import { ipInfo } from 'service'
import { useStore } from 'hooks/useStore'
import Grid2 from '@mui/material/Grid2'
import ZoomSetupAtHome from 'components/settings/ZoomSetupAtHome'
import { logInfo } from '../log'
import Guide from '../components/help/DashboardGuide'
import EventsList from '../components/event/EventsList'
import { requestNotificationPermission } from '../service/msgApi'
import ListCalendarEventsShort from '../components/calendar/ListCalendarEventsShort'
import ReceivedInvitesForHome from 'components/settings/ReceivedInvitesForHome'
import ExpiredPlanBanner from 'components/pay/ExpiredPlanBanner'
import WebBanner from 'components/WebBanner'
import useRedtailValidateAuth from 'hooks/useRedtailValidateAuth'
import BotJoinUploadRecorderCard from 'components/BotJoinUploadRecorderCard'
import { Region } from 'client'

export default function Home() {
  const store = useStore()
  useRedtailValidateAuth()

  useEffect(() => {
    logInfo('Home Screen')
    ipInfo()
    store.User.setEventSearchParams(undefined)
  }, [])

  useEffect(() => {
    // Refresh the token every week
    requestNotificationPermission()
    const interval = setInterval(requestNotificationPermission, 604800000)
    return () => clearInterval(interval)
  }, [])

  return (
    <Grid2 container spacing={2} sx={{ padding: 3 }}>
      <Grid2 size={{ xs: 12, lg: 8 }} >
        <Guide />
        <ReceivedInvitesForHome />
        <ExpiredPlanBanner />
        <BotJoinUploadRecorderCard />
        <EventsList />
      </Grid2>

      <Grid2 size={{ xs: 12, lg: 4 }} >
        <WebBanner />
        <ZoomSetupAtHome />
        <ListCalendarEventsShort />
      </Grid2>
    </Grid2 >
  )
}
