import React, { useState, useEffect, useMemo, useRef } from 'react'
import {
  Box, IconButton, Tooltip,
  Button,
} from '@mui/material'
import {
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_Row,
  type MRT_TableOptions,
} from 'material-react-table'
import { Child } from '../../client'
import DeleteIcon from '@mui/icons-material/Delete'
import TableAccordion from './TableAccordion'
import { getNumberCell, getLabelCell } from './CustomCells'
import { foldTable, hideTable, FinanceTableFamilyMemberProps, getEditOptions, getUniqueMemberId } from './financesUtils'
import { useCustomTable } from './useCustomTable'

export default function ChildrenTable({ eventId, title, type, subType, dataInput, datasetGroup, dynamicValues, hideEmptyTable, foldEmptyTable, readOnly, onUpdate, onDeleteClient }: FinanceTableFamilyMemberProps<'Family Members', 'Children'>) {

  const [expanded, setExpanded] = useState(true)
  const [dataList, setDataList] = useState<Child[]>([])
  const [newData, setNewData] = useState<Child>()
  const [dsElements, setDsElements] = useState<any>()

  useEffect(() => {
    if (dataInput) {
      setDataList(dataInput)
    }
  }, [dataInput])

  useEffect(() => {
    if (datasetGroup && datasetGroup['elements']) {
      setDsElements(datasetGroup['elements'])
    }
  }, [datasetGroup])

  useEffect(() => {
    setExpanded(foldTable(foldEmptyTable, dataList))
  }, [foldEmptyTable, dataList])

  const handleCreate: MRT_TableOptions<Child>['onCreatingRowSave'] = async ({
    values,
    table,
  }) => {
    const updated = [...dataList]
    if (newData) {
      const newPerson = { ...newData }
      const newMemberId = getUniqueMemberId(dynamicValues)
      newPerson.member_id = newMemberId
      updated.push(newPerson)
      onUpdate(type, subType, updated)
    }
    table.setCreatingRow(null) // exit creating mode
  }

  function handleDelete(row: MRT_Row<Child>) {
    const updated = [...dataList]
    if (row.index >= 0) {
      const memberId = updated[row.index].member_id
      if (memberId)
        onDeleteClient(memberId)
    }
  }

  const columns = useMemo<MRT_ColumnDef<Child>[]>(
    () => [
      {
        accessorKey: 'First Name',
        header: 'First Name',
        muiEditTextFieldProps: ({ row }) => ({
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevnewData) => ({
                ...prevnewData,
                'First Name': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...dataList]
            updated[rowIdx]['First Name'] = event.target.value
            onUpdate(type, subType, updated)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Last Name',
        header: 'Last Name',
        muiEditTextFieldProps: ({ row }) => ({
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevnewData) => ({
                ...prevnewData,
                'Last Name': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...dataList]
            updated[rowIdx]['Last Name'] = event.target.value
            onUpdate(type, subType, updated)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Gender',
        header: 'Gender',
        editSelectOptions: getEditOptions(dsElements, 'Gender', dynamicValues),
        Cell: getLabelCell(() => getEditOptions(dsElements, 'Gender', dynamicValues)),
        muiEditTextFieldProps: ({ row }) => ({
          select: true,
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevnewData) => ({
                ...prevnewData,
                Gender: event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...dataList]
            updated[rowIdx]['Gender'] = event.target.value
            onUpdate(type, subType, updated)
          },
        }),
        size: 50
      },
      {
        accessorKey: 'Birthdate',
        header: 'Birthdate', // TODO: format check
        muiEditTextFieldProps: ({ row }) => ({
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevnewData) => ({
                ...prevnewData,
                Birthdate: event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...dataList]
            updated[rowIdx]['Birthdate'] = event.target.value
            onUpdate(type, subType, updated)
          },
        }),
        size: 100
      },
    ],
    [dataList, newData, dsElements, dynamicValues],
  )

  const table = useCustomTable({
    columns,
    data: dataList,
    readOnly: readOnly,
    tableTitle: title,
    onDelete: handleDelete,
    onCreate: handleCreate,
    // onCancel: handleCancel,
  })

  if (hideTable(hideEmptyTable, dataList)) {
    return (
      <></>
    )
  }

  return (
    <TableAccordion title={title} table={table} readOnly={readOnly} expanded={expanded} setExpanded={setExpanded} />
  )
}
