import React, { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import CheckIcon from '@mui/icons-material/Check'
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Divider, FormControl, Grid2, InputLabel, MenuItem, Select, Snackbar, Typography } from '@mui/material'
import { ClientDetail, EventBase } from '../../../client'
import { gColor, gSx } from '../../../styles/Theme'
import { LoadingButton } from '@mui/lab'
import { getWealthboxEvents, sendNotesToWealthbox } from 'service/integrations/wealthboxApi'
import useIsBlockedByPlan from 'hooks/useIsBlockedByPlan'
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { getContactName } from 'service/clientApi'
import dayjs from 'dayjs'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'

interface Props {
  event: EventBase
  contacts?: ClientDetail[]
  eventSummaryHtml: string
  disabled?: boolean
}

export default function WealthboxNotesCard({ event, contacts, eventSummaryHtml, disabled }: Props) {

  const queryClient = useQueryClient()
  const { isBlocked } = useIsBlockedByPlan(true)
  const isSent = !!event.wealthbox_sent
  const [busyNote, setBusyNote] = useState(false)
  const [busyAppend, setBusyAppend] = useState(false)
  const [isSnackSuccess, setSnackSuccess] = useState(false)
  const [err, setErr] = useState<string | undefined>()
  const [expanded, setExpanded] = useState(!isSent)
  const [contact, setContact] = useState<ClientDetail | undefined>(undefined)
  const [selectedEvent, setSelectedEvent] = useState(-1)
  const disableSendNote = isBlocked || !contact || busyNote || busyAppend || disabled
  const disableSendAppend = isBlocked || !contact || busyNote || busyAppend || disabled

  useEffect(() => { // initialize
    if (contacts && contacts.length > 0)
      setContact(contacts[0])
  }, [contacts])

  const date15DaysAgo = new Date()
  date15DaysAgo.setDate(date15DaysAgo.getDate() - 15)
  const date1DayFromNow = new Date()
  date1DayFromNow.setDate(date1DayFromNow.getDate() + 1)
  const { data: events, isLoading: isEventLoading } = useQuery({
    queryKey: ['getWealthboxEvents'],
    queryFn: async () => await getWealthboxEvents(
      date15DaysAgo.toISOString(),
      date1DayFromNow.toISOString(),
    )
  })

  async function onSend() {
    setErr(undefined)

    const eventId = selectedEvent == -1 ? undefined : selectedEvent

    const res = await sendNotesToWealthbox(event.id, eventSummaryHtml, contact, eventId)
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      queryClient.invalidateQueries(['getEvent', event.id])
      setSnackSuccess(true)
      setExpanded(false)
    }
    setBusyNote(false)
    setBusyAppend(false)
  }

  return (
    <>
      <Accordion expanded={expanded} sx={gSx.Crm.Accordian}
        defaultExpanded={!isSent} disableGutters
        onChange={() => setExpanded(state => !state)}
      >
        <AccordionSummary sx={gSx.Crm.AccordianSummary}
          expandIcon={<ExpandMoreIcon />}
        >
          <Box sx={gSx.Row}>
            {isSent
              ? <CheckCircleIcon sx={{ ...gSx.Crm.LeftIcon, color: gColor.green500 }} />
              : <TextSnippetOutlinedIcon sx={gSx.Crm.LeftIcon} />
            }
            <Typography variant='h5'>Meeting Summary</Typography>
          </Box>
          {isSent &&
            <Typography color={gColor.green500} sx={{ paddingRight: 2 }}>Sent</Typography>
          }
        </AccordionSummary>

        <AccordionDetails>
          <Typography sx={{ marginBottom: '1em' }}>Send the Meeting Summary as a Wealthbox Note or append it to an event description.</Typography>

          {err && <Typography color={'tomato'}>{err}</Typography>}

          <Grid2 container spacing={2} direction={'column'}>
            <Grid2 container sx={{ justifyContent: 'space-between', width: '100%' }}>
              <FormControl sx={{ minWidth: '10em' }} size='small'>
                <InputLabel id="contact-label">Contact</InputLabel>
                <Select id="contact" size='small' label='contact' disabled={busyNote || !contact}
                  sx={{ minWidth: '10em' }}
                  value={contact?.id || ''}
                  onChange={e => {
                    const selectedContact = contacts?.find(c => c.id === e.target.value)
                    setContact(selectedContact)
                  }}
                >
                  {contacts?.map(c => (
                    <MenuItem key={c.id} value={c.id}>
                      {getContactName(c)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <LoadingButton variant={'contained'} sx={{ height: 'fit-content' }}
                onClick={() => {
                  setBusyNote(true)
                  onSend()
                }} disabled={disableSendNote} loading={busyNote}
              >
                Send Notes
              </LoadingButton>
            </Grid2>
            <Divider />
            <Typography variant='caption'><span style={{ fontWeight: 500, fontStyle: 'italic' }}>Append summary to</span> will only display events that began within the last 15 days. Recurring events appear only if their first occurrence falls within this timeframe.</Typography>
            <Grid2 container sx={{ justifyContent: 'space-between', width: '100%' }}>
              <FormControl sx={{ minWidth: '10em', maxWidth: '100%' }}>

                <InputLabel id="note-to-label">Append summary to</InputLabel>
                <Select id="wbEvents" size='small' disabled={busyAppend}
                  label="Append summary to"
                  sx={{ minWidth: '10em', maxWidth: '100%' }}
                  value={selectedEvent}
                  onChange={e => {
                    setSelectedEvent(+e.target.value)

                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        width: '33em',
                        maxWidth: 'calc(100% - 5em)' // Make sure the dropdown items don't overflow the screen
                      }
                    }
                  }}
                >
                  <MenuItem value={-1} disabled sx={{ paddingTop: 0, paddingBottom: 0, minHeight: 'fit-content' }}>Select an event</MenuItem>
                  {events ?
                    events.map((event, index) => {
                      const eventDate = dayjs(event.starts_at)
                      let timeDisplay

                      if (event.ends_at) {
                        const startTime = eventDate.format("h:mma")
                        const endTime = dayjs(event.ends_at).format("h:mma")
                        timeDisplay = `${startTime} - ${endTime}`
                      } else {
                        timeDisplay = eventDate.format("h:mma")
                      }
                      return (
                        <MenuItem
                          key={event.id}
                          value={event.id}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            padding: "4px 16px",
                            background: index % 2 === 0 ? "#fbfbfb" : "white",
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{
                              width: "100%",
                              fontWeight: 500,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {event.title}
                          </Typography>
                          <Grid2 container wrap='nowrap' sx={{ alignItems: 'center', justifyContent: 'space-between', width: '100%', gap: '0.5em' }}>
                            {/* Format as "[CalendarTodayIcon] Mar 6, 2025" */}
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                color: "text.secondary",
                              }}
                            >
                              <CalendarTodayIcon sx={{ fontSize: "0.8rem", mr: 0.5 }} />
                              <Typography variant="caption" sx={{ fontSize: "0.7rem" }}>
                                {dayjs(eventDate).format("MMM D")}
                              </Typography>
                            </Box>
                            {/* Format as "[AccessTimeIcon] 11:30am - 12:30pm" */}
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                color: "text.secondary",
                              }}
                            >
                              <AccessTimeIcon sx={{ fontSize: "0.8rem", mr: 0.5 }} />
                              <Typography variant="caption" sx={{ fontSize: "0.7rem" }}>
                                {timeDisplay.toLowerCase()}
                              </Typography>
                            </Box>
                          </Grid2>
                        </MenuItem>
                      )
                    })
                    :
                    isEventLoading ?
                      < MenuItem disabled>Loading events...</MenuItem>
                      :
                      <MenuItem disabled>No events</MenuItem>
                  })
                </Select>
              </FormControl>
              <LoadingButton variant={'contained'} sx={{ height: 'fit-content' }}
                onClick={() => {
                  setBusyAppend(true)
                  onSend()
                }} disabled={disableSendAppend || selectedEvent == -1} loading={busyAppend}
              >
                Append Event
              </LoadingButton>
            </Grid2>
          </Grid2>
        </AccordionDetails>
      </Accordion >

      <Snackbar open={isSnackSuccess} autoHideDuration={3000}
        onClose={() => setSnackSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert variant="filled" severity='success' sx={{ width: '100%' }}
          icon={<CheckIcon fontSize="inherit" />}
          onClose={() => setSnackSuccess(false)}
        >
          Meeting Summary Sent
        </Alert>
      </Snackbar>
    </>
  )
}
