import React, { useState } from 'react'
import {
  Typography,
  Box,
  Button,
  TextField,
  SxProps,
  Dialog,
  LinearProgress,
  Skeleton,
  Tooltip,
  Link,
} from '@mui/material'
import { gSx } from '../../styles/Theme'
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates'
import { useQuery } from '@tanstack/react-query'
import AddToZoomButton from 'components/settings/AddToZoomButton'
import { Media } from 'service/media'
import useIsBlockedByPlan from '../../hooks/useIsBlockedByPlan'
import { createBot } from 'service/eventApi'
import { getZoomSettings } from 'service/integrations/zoomApi'

import { useTranslation } from 'react-i18next'

export default function BotJoinByUrl() {
  const { t } = useTranslation()
  const [showModal, setModal] = useState(false)
  const [url, setUrl] = useState('')
  const [success, setSuccess] = useState<string | undefined>()
  const [err, setErr] = useState<string | undefined>()
  const { isBlocked, isLoading } = useIsBlockedByPlan()

  const { data: zm } = useQuery({
    queryKey: ['getZoomSettings'], queryFn: getZoomSettings,
  })
  const isZoomUrlAndDisconnected = url.includes('zoom.us') && !zm?.isVideoReady

  const handleKeyPress = async (event: any) => {
    if (event.key === 'Enter') {
      await onRecord()
    }
  }

  async function onRecord() {
    setModal(true)
    setSuccess(undefined)
    setErr(undefined)

    if (url.includes('zoom') && !zm?.isVideoReady) {
      setErr(`Connect Zoom with Finmate to continue`)
      setModal(false)
      return
    }

    const res = await createBot(url)
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      setSuccess(`Recorder created for ${res}. A bot will join soon.`)
    }
    setUrl('')
    setModal(false)
  }

  return (
    <>
      <Box sx={gSx.Row}>
        <Typography variant='h6'>{t('Have FinMate Join a Video Meeting?')}</Typography>
        <Tooltip title='Zoom'>
          <Box component="img" sx={sxIcon} src={Media.zoomLogo} alt="Zoom" />
        </Tooltip>

        <Tooltip title='Google Meet'>
          <Box component="img" sx={sxIcon} src={Media.meetLogo} alt="Google Meet" />
        </Tooltip>

        <Tooltip title='Microsoft Teams'>
          <Box component="img" sx={sxIcon} src={Media.teamsLogo} alt="Teams Logo" />
        </Tooltip>

        <Tooltip title='Webex'>
          <Box component="img" sx={sxIcon} src={Media.webexLogo} alt="Webex Logo" />
        </Tooltip>
      </Box>
      <Box sx={{ padding: 0.5 }} />
      {
        isLoading ? (
          <Skeleton variant="rectangular" width={'100%'} height={50} />
        ) : (
          <Box sx={gSx.Row}>
            <TextField
              multiline
              id='outlined-basic'
              label='Paste the Meeting Link Here'
              variant='outlined'
              value={url}
              onChange={(t) => setUrl(t.target.value)}
              sx={sxText}
              disabled={isBlocked}
              onKeyDown={handleKeyPress}
            />
            <Tooltip title={isBlocked ? 'Requires Starter or Standard Plan' : ""}>
              <Box sx={{ display: 'flex', alignItems: 'center', padding: 1 }}>
                <Button
                  onClick={async () => await onRecord()}
                  variant='contained'
                  size='large'
                  disabled={isBlocked || url.length == 0}
                  sx={{ paddingX: 5 }}
                >
                  Join
                </Button>
              </Box>
            </Tooltip>
          </Box>
        )
      }

      {success && <>
        <Typography sx={{ color: 'success.main' }}>{success}</Typography>
        {success.includes('teams.microsoft.com') &&
          <Typography sx={{ color: 'success.main' }}>If the notetaker does not join, See <Link rel='noopener' target='_blank' href="https://docs.finmate.ai/docs/MicrosoftTeams/Setup">Teams Setup Guide</Link>.
          </Typography>
        }
      </>
      }

      {err &&
        <Box>
          <Typography color='error.main'>{err}</Typography>
          {!(isZoomUrlAndDisconnected) &&
            <Typography color='error.main'>The url must have domains from one of the following: zoom.us, meet.google.com, teams.live.com, teams.microsoft.com, or webex.com.</Typography>
          }

          {err.includes('teams.microsoft.com') &&
            <Typography sx={{ color: 'error.main' }}>If the notetaker does not join, See <Link rel='noopener' target='_blank' href="https://docs.finmate.ai/docs/MicrosoftTeams/Setup">Teams Setup Guide</Link>.
            </Typography>
          }
        </Box>
      }

      {isZoomUrlAndDisconnected && <AddToZoomButton />}

      <Dialog open={showModal} onClose={() => setModal(false)}>
        <Box sx={sxDialog}>
          <Typography id='modal-modal-title' variant='h3'>
            Recorder Joining the call
          </Typography>

          <Box sx={{ width: '100%', paddingY: '2vh' }}>
            <LinearProgress />
          </Box>

          <Box sx={gSx.Row}>
            <TipsAndUpdatesIcon />
            <Box sx={{ padding: '1vh' }} />
            <Box>
              <Typography variant='h6'>{'Pro Tip'}</Typography>
              <Typography>{'Its good practice to confirm your client\'s answers, by restating it. It also helps AI with validation.'}</Typography>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  )
}

const sxText: SxProps = {
  minWidth: '150px',
  width: '100%',
}

const sxDialog: SxProps = {
  padding: '3vh',
}

const sxIcon: SxProps = {
  marginLeft: '5px',
  width: '20px'
}
