import React, { Dispatch, SetStateAction, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import CheckIcon from '@mui/icons-material/Check'
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Snackbar, Typography } from '@mui/material'
import { ActionItem, ClientDetail, CRM, EventBase, SalesforceApp } from '../../../client'
import { LoadingButton } from '@mui/lab'
import useIsBlockedByPlan from 'hooks/useIsBlockedByPlan'
import SelectTasks, { MasterTask } from '../SelectTasks'
import { sendTasksToSalesforce } from 'service/integrations/salesforceApi'
import { gColor, gSx } from 'styles/Theme'
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

interface Props {
  app: SalesforceApp
  event: EventBase
  contacts?: ClientDetail[]
  masterTasks: MasterTask[]
  setMasterTasks: Dispatch<SetStateAction<MasterTask[]>>
  disabled: boolean
}

export default function SalesforceTasksCard({ app, event, contacts, masterTasks, setMasterTasks, disabled }: Props) {
  const queryClient = useQueryClient()
  const eventId = event?.id
  const hasContacts = !!(event?.contacts?.[0])
  const { isBlocked } = useIsBlockedByPlan(true)
  const isSent = !!event?.salesforce_tasks_sent
  const [isSnackSuccess, setSnackSuccess] = useState(false)
  const [busy, setBusy] = useState(false)
  const [err, setErr] = useState<string | undefined>()
  const [expanded, setExpanded] = useState(!isSent)
  const isAnySelected = masterTasks.some(m => m.isSelected)
  const disableSend = isBlocked || !hasContacts || busy || !isAnySelected || disabled

  async function onSend() {
    setBusy(true)
    setErr(undefined)
    const tasks: ActionItem[] = masterTasks.filter(m => m.isSelected)
    const res = await sendTasksToSalesforce(event.id, app, tasks)
    if (res instanceof Error) {
      if (res.message.includes('INVALID_SESSION_ID'))
        setErr('Unauthorized. Please sign in again.')
      else {
        const add = "\n Please try again. If error persists, sign out and back in. If issue remains, contact support."
        setErr(res.message + add)
      }

    } else {
      queryClient.invalidateQueries(['getEvent', eventId])
      setSnackSuccess(true)
      setExpanded(false)
    }
    setBusy(false)
  }

  return (
    <>
      <Accordion
        sx={gSx.Crm.Accordian}
        expanded={expanded}
        onChange={() => setExpanded(state => !state)}
        disableGutters
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={gSx.Crm.AccordianSummary}
        >
          <Box sx={gSx.Row}>
            {isSent
              ? <CheckCircleIcon sx={{ ...gSx.Crm.LeftIcon, color: gColor.green500 }} />
              : <TaskOutlinedIcon sx={gSx.Crm.LeftIcon} />
            }
            <Typography variant='h5'>Tasks</Typography>
          </Box>
          {isSent &&
            <Typography color={gColor.green500} sx={{ paddingRight: 2 }}>Sent</Typography>
          }
        </AccordionSummary>

        <AccordionDetails>

          <Typography>All selected items will be saved as {app} Tasks.</Typography>
          <Typography variant='caption'>Please note that all Tasks created will initially be assigned to you. To assign them to other team members, edit the Task details within your {app} CRM.</Typography>

          <SelectTasks
            eventId={event.id}
            masterTasks={masterTasks}
            setMasterTasks={setMasterTasks}
            disabled={busy}
            crm={CRM.SALESFORCE}
            contacts={contacts}
          />

          {err && <Typography color={'tomato'}>{err}</Typography>}

          <Box sx={gSx.RowBetween}>
            <Box />
            <Box sx={gSx.Row}>
              <LoadingButton
                variant={'contained'}
                onClick={onSend}
                disabled={disableSend}
                loading={busy}
              >
                Send Tasks
              </LoadingButton>
            </Box>
          </Box>

        </AccordionDetails>
      </Accordion>

      <Snackbar
        open={isSnackSuccess}
        autoHideDuration={3000}
        onClose={() => setSnackSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          variant="filled"
          icon={<CheckIcon fontSize="inherit" />}
          onClose={() => setSnackSuccess(false)}
          severity='success'
          sx={{ width: '100%' }}
        >
          {app} Tasks Sent
        </Alert>
      </Snackbar>
    </>
  )
}
