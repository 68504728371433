import React from 'react'
import { Container, Typography } from '@mui/material'
import EnterpriseRolesCard from 'components/enterprise/EnterpriseRolesCard'
import EnterpriseOrgList from 'components/enterprise/EnterpriseOrgList'
import EnterprisePlanCard from 'components/enterprise/EnterprisePlanCard'
import Grid from "@mui/material/Grid2"
import EnterpriseGeneralCard from 'components/enterprise/EnterpriseGeneralCard'

export default function EnterpriseScreen() {

  return (
    <Container sx={{ paddingY: 3 }}>
      <Typography variant='h1' sx={{ paddingBottom: 2 }}>Enterprise</Typography>

      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 6 }} sx={{ display: 'flex' }}>
          <EnterpriseGeneralCard />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }} sx={{ display: 'flex' }}>
          <EnterprisePlanCard />
        </Grid>

        <Grid size={12}>
          <EnterpriseOrgList />
        </Grid>

        <Grid size={12}>
          <EnterpriseRolesCard />
        </Grid>

      </Grid>

    </Container >
  )
}
