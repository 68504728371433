import React, { useEffect } from 'react'
import { Typography, Box, Card, SxProps, ButtonBase, Link, Button } from '@mui/material'
import 'firebase/auth'
import { Media } from '../../service/media'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { gSx } from 'styles/Theme'
import RedtailIntegrationLine from 'components/integrations/redtail/RedtailIntegrationLine'
import ContainerSmall from 'components/ContainerSmall'
import WealthboxIntegrationLine from 'components/integrations/wealthbox/WealthboxIntegrationLine'
import ZoomIntegrationLine from 'components/integrations/ZoomIntegrationLine'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import GoogleCalendarIntegrationLine from 'components/integrations/GoogleCalendarIntegrationLine'
import MicrosoftCalendarIntegrationLine from 'components/integrations/MicrosoftCalendarIntegrationLine'
import { logInfo } from 'log'
import SalesforceIntegrationLine from 'components/integrations/salesforce/SalesforceIntegrationLine'
import PractifiIntegrationLine from 'components/integrations/salesforce/PractifiIntegrationLine'
import Xlr8IntegrationLine from 'components/integrations/salesforce/Xlr8IntegrationLine'
import RingCentralIntegrationLine from 'components/integrations/ringcentral/RingCentralIntegrationLine'
import ZoomPhoneIntegrationLine from 'components/integrations/ringcentral/ZoomPhoneIntegrationLine'
import PrecisefpIntegrationLine from 'components/integrations/precisefp/PrecisefpIntegrationLine'
import { getOrg } from 'service/orgsApi'
import { useQuery, useQueryClient } from '@tanstack/react-query'

export default function IntegrationsScreen() {
  const queryClient = useQueryClient()
  useEffect(() => {
    logInfo('Integrations Screen')
  }, [])

  const { data: org } = useQuery({
    queryKey: ['getOrg'], queryFn: getOrg,
    enabled: queryClient.getQueryData(['getOrg']) === undefined,
  })

  return (
    <ContainerSmall>
      <Typography variant='h1' sx={{ paddingBottom: '2vh' }} >Integrations</Typography>

      <Typography variant='h4' sx={{ paddingY: '2vh' }} >CRM</Typography>
      <Typography>{'Easily Send your Meeting notes to your favorite CRMs.\nWe are fully proud partners and are fully integrated with:'}</Typography>

      <RedtailIntegrationLine />
      <WealthboxIntegrationLine />
      <SalesforceIntegrationLine />
      <PractifiIntegrationLine />
      <Xlr8IntegrationLine />
      {
        org?.enable_precisefp && (
          <>
            <Typography variant='h4' sx={{ paddingY: '2vh' }} >Financial Planning</Typography>
            <PrecisefpIntegrationLine />
          </>
        )
      }

      <Typography variant='h4' sx={{ paddingY: '2vh' }} >Phone Calling</Typography>
      <RingCentralIntegrationLine />
      <ZoomPhoneIntegrationLine />

      <Typography variant='h4' sx={{ paddingY: '2vh' }} >Video Conferencing</Typography>

      <ZoomIntegrationLine />

      <Card>
        <Box sx={gSx.RowBetween}>
          <Box
            component="img"
            sx={sxLogo}
            src={Media.meetLogo}
          />
          <Typography>Google Meet Ready</Typography>
          <CheckCircleIcon color={'primary'} />
        </Box>
      </Card>

      <Card>
        <Box sx={gSx.RowBetween}>
          <Box
            component="img"
            sx={sxLogo}
            src={Media.teamsLogo}
          />
          <Typography>Microsoft Teams Ready</Typography>
          <CheckCircleIcon color={'primary'} />
        </Box>
      </Card>

      <Card>
        <Box sx={gSx.RowBetween}>
          <Box
            component="img"
            sx={sxLogo}
            src={Media.webexLogo}
          />
          <Typography>WebEx Ready</Typography>
          <CheckCircleIcon color={'primary'} />
        </Box>
      </Card>

      <Typography variant='h4' sx={{ paddingY: '2vh' }} >Calendar</Typography>
      <Typography>For our notetaker to auto join your meetings</Typography>
      <GoogleCalendarIntegrationLine />
      <MicrosoftCalendarIntegrationLine />

      <Typography variant='h4' sx={{ paddingY: '2vh' }} >Mobile</Typography>

      <Card>

        <ButtonBase
          sx={sxCardButton}
          href='https://apps.apple.com/us/app/finmate-ai/id6469643088'
          target="_blank"
          rel="noopener"
        >
          <Box
            component="img"
            sx={{ width: 135, height: 43 }}
            src={Media.appleAppStore}
          />
          <Button>iOS FinMate AI Recorder</Button>
          <ChevronRightIcon />
        </ButtonBase>
      </Card>

      <Card>

        <ButtonBase
          sx={sxCardButton}
          href='https://play.google.com/store/apps/details?id=com.finmate.ai'
          target="_blank"
          rel="noopener"
        >
          <Box
            component="img"
            sx={{ width: 135, height: 43 }}
            src={Media.googlePlayStore}
          />

          <Button>Android FinMate AI Recorder</Button>

          <ChevronRightIcon />
        </ButtonBase>
      </Card>

    </ContainerSmall >
  )
}

const sxLogo: SxProps = {
  width: 40,
}

const sxCardButton: SxProps = {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignContent: 'center',
}