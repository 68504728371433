import { ActionItem, ClientDetail, FlowInputConfig, SalesforceApp, SalesforceBase, SalesforceEvent, SalesforceUser } from "client"
import { logErr, logInfo } from "log"
import { Api, config } from "../api"
import { getErrorMessage } from "utils"
import queryString from 'query-string'
import { Media } from "service/media"

// pop up window
export async function salesforceOAuth(app: SalesforceApp) {

  localStorage.setItem('SalesforceApp', app)

  const windowName = 'PopupWindow'
  const windowFeatures = 'width=500,height=700'

  const queryParams = config.env == 'local' ? {
    // localhost http
    client_id: config.salesforceClientId,
    redirect_uri: `${config.salesforceRedirect}/localhost`,
    response_type: "token",
    prompt: "login"

    // ngrok https. see readme.
    // client_id: config.salesforceClientId,
    // redirect_uri: `https://c054-24-5-149-147.ngrok-free.app/auth/salesforce/callback`,
    // response_type: "code",
    // prompt: "login"
  } : {
    client_id: config.salesforceClientId,
    redirect_uri: config.salesforceRedirect,
    response_type: "code",
    prompt: "login",
    scope: "refresh_token offline_access web api id profile email openid"
  }

  try {
    // https://practifi.cloudforce.com/ does not provide 0auth2 credentials
    const baseUrl = `https://login.salesforce.com/services/oauth2/authorize`
    const url = `${baseUrl}?${queryString.stringify(queryParams)}`

    logInfo('Salesforce OAuth Sign In', { url, queryParams })
    window.open(url, windowName, windowFeatures)
  } catch (e) {
    logErr('Salesforce OAuth Sign In')
  }
}

export async function exchangeAuthCodeForTokens(code?: string) {
  if (!code)
    return new Error('no auth code')

  let salesforceApp = SalesforceApp.SALESFORCE
  const storageItem = localStorage.getItem('SalesforceApp')
  if (storageItem != null)
    salesforceApp = storageItem as SalesforceApp

  try {
    const api = await Api()
    await api.salesforce.exchangeAuthCodeForTokens(code, salesforceApp)
    return salesforceApp
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('exchangeAuthCodeForTokens', { err })
    return err
  }
}

export async function saveSalesforceAuthLocalhost(data: SalesforceBase) {
  try {
    data.app = SalesforceApp.SALESFORCE
    const storageItem = localStorage.getItem('SalesforceApp')
    if (storageItem != null)
      data.app = storageItem as SalesforceApp

    const api = await Api()
    await api.salesforce.saveSalesforceAuthLocalhost(data)
    return true
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('saveSalesforceAuthLocalhost', { err })
    return err
  }
}

export async function getSalesforceSettings(): Promise<SalesforceBase | undefined> {
  try {
    const api = await Api()
    return await api.salesforce.getSalesforceSettings()
  } catch (e) {
    logErr('getSalesforceSettings', { e })
  }
}

export async function getSalesforceUsers(): Promise<SalesforceUser[] | undefined> {
  try {
    const api = await Api()
    return await api.salesforce.getSalesforceUsers()
  } catch (e) {
    logErr('getSalesforceUsers', { e })
  }
}

export async function importSalesforceWhos(includeTeamContacts?: boolean): Promise<number | Error> {
  try {
    logInfo('salesforce importContacts', { includeTeamContacts })
    const api = await Api()
    return await api.salesforce.importContacts(includeTeamContacts)
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('salesforce importContacts', { err, includeTeamContacts })
    return err
  }
}

export async function getSalesforceEvents(startDate: string | undefined, endDate: string | undefined): Promise<SalesforceEvent[] | undefined> {
  try {
    const api = await Api()
    return await api.salesforce.getSalesforceEvents(startDate, endDate)
  } catch (e) {
    logErr('getSalesforceEvents', { e })
  }
}

export async function importSalesforceContacts(includeTeamContacts?: boolean): Promise<number | Error> {
  try {
    logInfo('salesforce importContacts', { includeTeamContacts })
    const api = await Api()
    return await api.salesforce.importContacts(includeTeamContacts)
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('salesforce importContacts', { err, includeTeamContacts })
    return err
  }
}

export async function addSalesforceIds(eventId: string) {
  try {
    const api = await Api()
    return await api.salesforce.addSalesforceIds(eventId)
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('addSalesforceIds', { err })
    return err
  }
}

export async function sendNoteToSalesforce(
  client: ClientDetail,
  eventId: string,
  app: SalesforceApp,
  notes: string,
  salesforceEventId?: string
): Promise<Error | undefined> {

  try {
    logInfo('salesforce sendNoteToSalesforce', { client, eventId, app })
    const api = await Api()
    await api.salesforce.sendNotesToSalesforce({
      event_id: eventId,
      app,
      client,
      summary_plain_text: notes,
      sf_event_id: salesforceEventId
    })
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('salesforce sendNoteToSalesforce', { err })
    return err
  }
}

export async function sendTasksToSalesforce(
  eventId: string,
  app: SalesforceApp,
  tasks: ActionItem[],
): Promise<Error | undefined> {

  try {
    logInfo('salesforce sendTasksToSalesforce', { eventId, app })
    const api = await Api()
    await api.salesforce.sendTasksToSalesforce({
      event_id: eventId,
      app,
      tasks,
    })
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('salesforce sendTasksToSalesforce', { err })
    return err
  }
}

export async function getSalesforceFlows() {
  try {
    const api = await Api()
    return await api.salesforce.getSalesforceFlows()
  } catch (e) {
    logErr('getSalesforceFlows', { e })
  }
}

export async function startSalesforceFlow(
  eventId: string,
  app: SalesforceApp,
  apiName: string,
  inputsConfig: FlowInputConfig[],
): Promise<Error | undefined> {

  try {
    logInfo('salesforce startSalesforceFlow', { eventId, apiName, app })
    const api = await Api()
    await api.salesforce.startSalesforceFlow({
      event_id: eventId,
      app,
      api_name: apiName,
      inputs_config: inputsConfig
    })
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('salesforce startSalesforceFlow', { err })
    return err
  }
}

export async function disconnect(): Promise<undefined | Error> {
  try {
    logInfo('salesforce Disconnect')
    const api = await Api()
    await api.salesforce.disconnectSalesforce()
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('salesforce disconnect', { err })
    return err
  }
}

export function isSalesforceApp(app: any): app is SalesforceApp {
  return Object.values(SalesforceApp).includes(app)
}

export function getSalesforceIcon(app?: SalesforceApp) {
  if (app == SalesforceApp.PRACTIFI)
    return Media.practifiIcon
  if (app == SalesforceApp.XLR8)
    return Media.xlr8Icon
  return Media.salesforceIcon
}
