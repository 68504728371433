import React, { useState } from 'react'
import { config } from '../../service/api'
import { Alert, Box, Button, ButtonBase, Card, Snackbar, TextField, Typography } from '@mui/material'
import { manualJoinAuth, manualSignInAuth } from '../../service/authApi'
import { useNavigate } from 'react-router-dom'
import { Path } from '../../routes'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'

interface Props {
  affiliateParams?: string
}

export default function ManualSignInDevOnly({ affiliateParams }: Props) {
  const navigate = useNavigate()
  const auth = getAuth()
  const [expand, setExpand] = useState(false)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [openSuccessSnack, setOpenSuccessSnack] = useState(false)
  const [err, setErr] = useState<string | undefined>()
  const loadingPath = `${Path.Loading}?${affiliateParams ?? ''}`

  async function onJoin() {
    setErr(undefined)
    const res = await manualJoinAuth(email, password)
    res instanceof Error ? setErr(res.message) : navigate(loadingPath)
  }

  async function onSignIn() {
    setErr(undefined)
    const res = await manualSignInAuth(email, password)
    res instanceof Error ? setErr(res.message) : navigate(loadingPath)
  }

  async function onForgotPassword() {
    await sendPasswordResetEmail(auth, email)
    setOpenSuccessSnack(true)
  }

  if (config.env == 'prod')
    return null

  return (
    <Card sx={{ backgroundColor: 'mistyrose', paddingY: 0 }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column'
      }}>
        <ButtonBase onMouseOver={() => setExpand(true)}>
          <Typography>Dev only - Manual Sign In</Typography>
        </ButtonBase>

        {
          expand &&
          <>
            <TextField
              onChange={(t) => setEmail(t.target.value)}
              id="email" label="Email" variant="filled"
              value={email}
            />
            <TextField
              onChange={(t) => setPassword(t.target.value)}
              id="password" label="Password"
              variant="filled" value={password}
            />

            <Box>
              <Button onClick={onJoin} variant='outlined'>Signup</Button>
              <Button onClick={onSignIn} variant='outlined'>SignIn</Button>
            </Box>

            {err && <Typography color={'red'}>{err}</Typography>}

            <Button onClick={onForgotPassword}>Forgot Password</Button>
          </>
        }

      </Box>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSuccessSnack}
        autoHideDuration={3000}
        onClose={() => setOpenSuccessSnack(false)}
      >
        <Alert
          variant="filled"
          severity="success"
        >
          {`Password reset email sent to ${email}`}
        </Alert>

      </Snackbar>
    </Card>
  )
}