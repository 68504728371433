import React, { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Divider, FormControl, Grid2, InputLabel, MenuItem, Select, Snackbar, Typography } from '@mui/material'
import { ClientDetail, EventBase, SalesforceApp } from '../../../client'
import { gColor, gSx } from '../../../styles/Theme'
import { LoadingButton } from '@mui/lab'
import { getSalesforceEvents, sendNoteToSalesforce } from 'service/integrations/salesforceApi'
import useIsBlockedByPlan from 'hooks/useIsBlockedByPlan'
import { htmlToPlainText } from 'utils'
import CheckIcon from '@mui/icons-material/Check'
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { getContactName } from 'service/clientApi'
import dayjs from 'dayjs'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'

interface Props {
  app: SalesforceApp
  event: EventBase
  contacts?: ClientDetail[]
  notesHtml: string
  disabled: boolean
}

export default function SalesforceNotesCard({ app, event, contacts, notesHtml, disabled: disabledByParent }: Props) {
  const queryClient = useQueryClient()
  const eventId = event?.id
  const [contact, setContact] = useState<ClientDetail | undefined>(undefined)
  const { isBlocked } = useIsBlockedByPlan(true)
  const isSent = !!event?.salesforce_sent
  const [isSnackSuccess, setSnackSuccess] = useState(false)
  const [busyNote, setBusyNote] = useState(false)
  const [busyAppend, setBusyAppend] = useState(false)
  const [err, setErr] = useState<string | undefined>()
  const [expanded, setExpanded] = useState(!isSent)
  const disabled = !contact || busyNote || busyAppend || disabledByParent

  useEffect(() => { // initialize
    if (contacts && contacts?.length > 0)
      setContact(contacts[0])
    else {
      setContact(undefined)
    }
  }, [contacts])
  const [selectedEvent, setSelectedEvent] = useState<string | undefined>()
  const disableSendNote = isBlocked || !contact || busyAppend
  const disableSendAppend = isBlocked || !contact || busyNote || !selectedEvent

  async function onSend() {
    setErr(undefined)
    if (contact && notesHtml && event?.id) {
      const notes = htmlToPlainText(notesHtml ?? '')
      const res = await sendNoteToSalesforce(contact, event.id, app, notes, selectedEvent)
      if (res instanceof Error) {
        if (res.message.includes('INVALID_SESSION_ID'))
          setErr('Unauthorized. Please sign in again.')
        else {
          const add = "\n Please try again. If error persists, sign out and back in. If issue remains, contact support."
          setErr(res.message + add)
        }
      } else {
        queryClient.invalidateQueries(['getEvent', eventId])
        setSnackSuccess(true)
        setExpanded(false)
      }
    }
    setBusyNote(false)
    setBusyAppend(false)
  }

  const date15DaysAgo = new Date()
  date15DaysAgo.setDate(date15DaysAgo.getDate() - 15)
  const date1DayFromNow = new Date()
  date1DayFromNow.setDate(date1DayFromNow.getDate() + 1)
  const { data: events, isLoading: isEventsLoading } = useQuery({
    queryKey: ['getSalesforceEvents'],
    queryFn: async () => await getSalesforceEvents(
      date15DaysAgo.toISOString(),
      date1DayFromNow.toISOString(),
    )
  })

  return (
    <>
      <Accordion
        sx={gSx.Crm.Accordian}
        defaultExpanded={!isSent}
        expanded={expanded}
        onChange={() => setExpanded(state => !state)}
        disableGutters
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={gSx.Crm.AccordianSummary}
        >
          <Box sx={gSx.Row}>
            {isSent
              ? <CheckCircleIcon sx={{ ...gSx.Crm.LeftIcon, color: gColor.green500 }} />
              : <TextSnippetOutlinedIcon sx={gSx.Crm.LeftIcon} />
            }
            <Typography variant='h5'>Meeting Summary</Typography>
          </Box>
          {isSent &&
            <Typography color={gColor.green500} sx={{ paddingRight: 2 }}>Sent</Typography>
          }
        </AccordionSummary>

        <AccordionDetails>
          <Typography sx={{ marginBottom: 2 }}>Send the Meeting Summary as a {app} Note.</Typography>

          {err && <Typography color={'tomato'}>{err}</Typography>}

          <Grid2 container spacing={2} direction={'column'} sx={{ width: '100%' }}>
            <Grid2 container sx={{ justifyContent: 'space-between', width: '100%' }}>
              <FormControl>
                <InputLabel id="contact" size='small'>Contact</InputLabel>
                <Select id="contact" size='small' disabled={disabled}
                  label='Contact' value={contact?.id || ''}
                  onChange={e => {
                    const selectedContact = contacts?.find(c => c.id === e.target.value)
                    setContact(selectedContact)
                  }}
                  sx={{ width: 250, maxWidth: '100%' }}
                >
                  {contacts?.map(c => (
                    <MenuItem key={c.id} value={c.id}>
                      {getContactName(c)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <LoadingButton
                variant={'contained'}
                onClick={() => {
                  setBusyNote(true)
                  onSend()
                }}
                disabled={disabled || disableSendNote}
                loading={busyNote}
                sx={{ marginLeft: 3, height: 'fit-content' }}
              >
                Send Notes
              </LoadingButton>
            </Grid2>

            <Divider />

            <Grid2 container sx={{ justifyContent: 'space-between', width: '100%' }}>
              <FormControl sx={{ minWidth: '10em', maxWidth: '100%' }}>
                <InputLabel id="note-to-label">Append to</InputLabel>
                <Select id="note-to" label="Append to"
                  sx={{ minWidth: '10em', maxWidth: '100%' }}
                  value={selectedEvent}
                  onChange={e => {
                    setSelectedEvent(e.target.value)
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        width: '33em',
                        maxWidth: 'calc(100% - 5em)' // Make sure the dropdown items don't overflow the screen
                      }
                    }
                  }}
                >
                  {events ? events.map((event, index) => {
                    const eventDate = dayjs(event.StartDateTime)
                    let timeDisplay

                    if (event.EndDateTime) {
                      const startTime = eventDate.format('h:mma')
                      const endTime = dayjs(event.EndDateTime).format('h:mma')
                      timeDisplay = `${startTime} - ${endTime}`
                    } else {
                      timeDisplay = eventDate.format('h:mma')
                    }

                    const sxMenuItem = {
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      padding: '4px 16px',
                      background: index % 2 === 0 ? "#fbfbfb" : "white"
                    }
                    return (
                      <MenuItem
                        key={event.Id}
                        value={event.Id}
                        sx={sxMenuItem}
                      >
                        <Typography variant='body1' sx={sxText}>{event.Subject}</Typography>
                        <Grid2 container wrap='nowrap' sx={{ alignItems: 'center', justifyContent: 'space-between', width: '100%', gap: '0.5em' }}>
                          {/* Format as "[CalendarTodayIcon] Mar 6, 2025" */}
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              color: "text.secondary",
                            }}
                          >
                            <CalendarTodayIcon sx={{ fontSize: "0.8rem", mr: 0.5 }} />
                            <Typography variant="caption" sx={{ fontSize: "0.7rem" }}>
                              {dayjs(eventDate).format("MMM D")}
                            </Typography>
                          </Box>
                          {/* Format as "[AccessTimeIcon] 11:30am - 12:30pm" */}
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              color: "text.secondary",
                            }}
                          >
                            <AccessTimeIcon sx={{ fontSize: "0.8rem", mr: 0.5 }} />
                            <Typography variant="caption" sx={{ fontSize: "0.7rem" }}>
                              {timeDisplay.toLowerCase()}
                            </Typography>
                          </Box>
                        </Grid2>
                      </MenuItem>)
                  }) :
                    isEventsLoading ?
                      <MenuItem disabled>Loading Salesforce events</MenuItem>
                      :
                      <MenuItem disabled>No Salesforce Events</MenuItem>
                  }
                </Select>
              </FormControl>
              <LoadingButton
                variant={'contained'}
                onClick={() => {
                  setBusyAppend(true)
                  onSend()
                }}
                disabled={disabled || disableSendAppend}
                loading={busyAppend}
                sx={{ marginLeft: 3, height: 'fit-content' }}
              >
                Append Notes
              </LoadingButton>
            </Grid2>
          </Grid2>
        </AccordionDetails>
      </Accordion>

      <Snackbar
        open={isSnackSuccess}
        autoHideDuration={3000}
        onClose={() => setSnackSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          variant="filled"
          icon={<CheckIcon fontSize="inherit" />}
          onClose={() => setSnackSuccess(false)}
          severity='success'
          sx={{ width: '100%' }}
        >
          {app} Meeting Summary Sent
        </Alert>
      </Snackbar>
    </>
  )
}

const sxText = {
  width: '100%',
  fontWeight: 500,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
}
