import { ClientData } from '../../client'
import { logErr } from '../../log'
import { getErrorMessage } from '../../utils'

export interface FinanceTableProps<T extends keyof ClientData, S extends keyof NonNullable<ClientData[T]>> {
  eventId?: string
  title: string
  type: T
  subType: S
  dataInput?: NonNullable<ClientData[T]>[S]
  datasetGroup?: any
  dynamicValues?: any
  foldEmptyTable: boolean
  hideEmptyTable: boolean
  readOnly: boolean
  onUpdate: (type: T, subType: S, updated: NonNullable<ClientData[T]>[S]) => void
}

export type FinanceTableWithMemberIdProps<T extends keyof ClientData, S extends keyof NonNullable<ClientData[T]>> = Omit<
  FinanceTableProps<T, S>,
  "onUpdate"
> & {
  memberId: string,
  onUpdate: (type: T, subType: S, updated: NonNullable<ClientData[T]>[S], memberId: string) => void;
}

export type FinanceTableFamilyMemberProps<T extends keyof ClientData, S extends keyof NonNullable<ClientData[T]>> = FinanceTableProps<T, S> & {
  onDeleteClient: (memberId: string) => void
}

export interface TableRef {
  createRowFunction: () => void;
}

export interface UpdateClientDataInterface {
  <P extends keyof ClientData, S extends keyof NonNullable<ClientData[P]>>(
    type: P,
    subType: S,
    updated: NonNullable<ClientData[P]>[S]
  ): Promise<void>;
}

export function hideTable(hideEmptyTable: boolean, data: any[] | undefined) {
  if (hideEmptyTable && (!data || data.length == 0))
    return true
  return false
}

export function hideTitle(hideEmptyTables: boolean, data: any | undefined) {
  if (hideEmptyTables) {
    if (!data) return true
    for (const [key, value] of Object.entries(data)) {
      console.log(key, (value && Array.isArray(value) && value.length > 0), value)
      if (value && Array.isArray(value) && value.length > 0) return false
    }
    return true
  }
  return false
}

export function foldTable(foldEmptyTable: boolean, data: any[] | undefined) {
  if (foldEmptyTable && (!data || data.length == 0))
    return false
  return true
}

export function getEditOptions(datasetElements: any, elementKey: string, dynamicValues: any) {
  const edit_options: any[] = []
  try {
    if (!datasetElements) return edit_options
    const element = datasetElements[elementKey]
    if (!element)
      return edit_options
    const options = element.options
    const allowed_values = options.allowed_values
    if (allowed_values) {
      for (const av of allowed_values) {
        if (av)
          edit_options.push(av)
      }
    }

    const dynamicValueIds = options.dynamic_values
    if (dynamicValueIds) {
      for (const dvi of dynamicValueIds) {
        const id = dvi["dataset_id"]
        if (dynamicValues && id in dynamicValues && dynamicValues[id].values && dynamicValues[id].values.length > 0) {
          for (const v of dynamicValues[id].values) {
            edit_options.push(v)
          }
        }
      }
    }
    return edit_options
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('getEditOptions', { err })
  }
  return edit_options
}

export function updateDynamicValues(clientData: ClientData, dynamicValueKeyMap: any) {
  try {
    for (const dvKey in dynamicValueKeyMap) {
      dynamicValueKeyMap[dvKey].values = []
      const dv: any = dynamicValueKeyMap[dvKey]
      if (!dv) continue
      const targetPanel = clientData[dv.panel_title as keyof ClientData] as any
      if (!targetPanel) continue
      const targetGroup = targetPanel[dv.group_title]
      if (!targetGroup || targetGroup.length == 0)
        continue
      for (const elements of targetGroup) {
        if (!elements || !elements["member_id"]) continue
        const fullName = `${elements['First Name'] ?? ""} ${elements['Last Name'] ?? ""}`.trim()
        if (fullName && elements["member_id"]) {
          dynamicValueKeyMap[dvKey].values.push({
            label: fullName,
            value: elements.member_id
          })
        }
      }
    }
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('updateDynamicValues', { err })
  }
  return dynamicValueKeyMap
}

export function isMemberIdInData(clientData: ClientData, memberId: string) {
  try {
    const ClientDataPanelExcludeList = ["Clients", "Client Details", "Employment & Income", "Family Members"]
    const ElementWithMemberIdList = ["Owner", "Beneficiary", "Primary Beneficiary", "Secondary Beneficiary", "For"]
    const res = []
    for (const panelKey in clientData) {
      // Skip panels without any assets a client
      if (ClientDataPanelExcludeList.includes(panelKey))
        continue
      const panel = clientData[panelKey as keyof ClientData]
      if (!panel)
        continue
      for (const groupKey in panel) {
        const group = panel[groupKey as keyof typeof panel] as Record<string, any>
        if (!group)
          continue
        for (const elementIdx in group) {
          const elements = group[elementIdx]
          if (!elements)
            continue
          for (const elementKey in elements) {
            const element = elements[elementKey as keyof typeof elements]
            if (!element)
              continue
            // check elements that contain member_id
            if (ElementWithMemberIdList.includes(elementKey)) {
              if (String(element) == memberId) {
                res.push(groupKey)
              }
            }
          }
        }
      }
    }
    return res
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('isMemberIdInData', { err })
  }
  return []
}

export function deleteClientInData(clientData: ClientData, memberId: string): ClientData {
  try {
    const ClientDataPanelIncludeList = ["Client Details", "Employment & Income", "Family Members"]
    // duplicated clientData 
    const duplicatedClientData = JSON.parse(JSON.stringify(clientData))

    for (const panelKey in duplicatedClientData) {
      // loop only the following panel, Panel only for client
      if (!ClientDataPanelIncludeList.includes(panelKey))
        continue
      const panel = duplicatedClientData[panelKey as keyof ClientData]
      if (!panel)
        continue
      for (const groupKey in panel) {
        const group = panel[groupKey as keyof typeof panel]

        if (!group || !Array.isArray(group))
          continue

        const newGroup = (group as any[]).filter(element =>
          !("member_id" in element && element["member_id"] === memberId)
        )
        // Update the panel with the filtered array
        panel[groupKey as keyof typeof panel] = newGroup as typeof group
      }
    }
    return duplicatedClientData
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('deleteClientInData', { err })
  }
  return clientData
}

function getRandomDigitsString() {
  const randomNumber = Math.floor(100 + Math.random() * 900) // random 3 digit member id
  const newMemberId = randomNumber.toString()
  return newMemberId
}

export function getUniqueMemberId(dynamicValueKeyMap: any): string {
  // memberId is internal use only, converted to full name on precisefp sync
  try {
    const memberIdSet = new Set()
    for (const dvKey in dynamicValueKeyMap) {
      const values = dynamicValueKeyMap[dvKey].values
      if (!values || !Array.isArray(values))
        continue
      for (const v of values) {
        memberIdSet.add(v)
      }
    }
    const i = 0
    while (i < 10) {
      const newMemberId = getRandomDigitsString()
      if (!memberIdSet.has(newMemberId))
        return newMemberId
    }
    throw new Error("unexpected getUniqueMemberId error")
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('getUniqueMemberId', { err })
  }
  return ""
}