import React, { useEffect, useState } from 'react'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { Box, Button, Dialog, LinearProgress, TextField, Typography, DialogActions, Input, Snackbar, Alert, DialogTitle, DialogContent } from '@mui/material'
import { logInfo } from '../../log'
import { UploadFile } from '../../service/eventApi'
import { useQueryClient } from '@tanstack/react-query'
import { Origin } from 'client'
import CheckIcon from '@mui/icons-material/Check'
import { useWakeLock } from 'react-screen-wake-lock'
import { LoadingButton } from '@mui/lab'

interface Props {
  open: boolean
  onClose: () => void
}

export default function UploadDialog({ open, onClose }: Props) {
  const queryClient = useQueryClient()
  const { isSupported, request: wakeOn, release: wakeOff } = useWakeLock()

  const [file, setFile] = useState<File | undefined>(undefined)
  const [title, setTitle] = useState('')
  const [busy, setBusy] = useState(false)
  const [err, setErr] = useState<string | undefined>()
  const [progress, setProgress] = useState(0)
  const [isSnackSuccess, setSnackSuccess] = useState(false)

  useEffect(() => {
    if (open) {
      logInfo('UploadDialog Open')
      setTitle('')
      setFile(undefined)
      setProgress(0)
    }
  }, [open])

  useEffect(() => {
    const beforeUnloadHandler = (ev: any) => {
      if (busy) {
        ev.preventDefault()
        ev.returnValue = 'Are you sure you want to close?'
      }
    }
    if (busy)
      window.addEventListener('beforeunload', beforeUnloadHandler)

    return () => { window.removeEventListener('beforeunload', beforeUnloadHandler) }
  }, [busy])

  async function onUpload() {
    if (!file) {
      setErr('No file selected.')
      return
    }
    setErr(undefined)
    setBusy(true)
    logInfo('UploadDialog start', { title, "filename": file.name, "filetype": file.type, "filesize": file.size })
    isSupported && await wakeOn()

    const now = (new Date()).toLocaleString()
    let newTitle = title
    if (newTitle.length == 0) {
      newTitle = `Uploaded Meeting ${now}`
    }
    const res = await UploadFile(file, newTitle, Origin.WEB_UPLOAD, setProgress)
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      await queryClient.invalidateQueries({ queryKey: ['getEvents'] })
      setSnackSuccess(true)
      onClose()
    }
    setBusy(false)
    isSupported && await wakeOff()
  }

  function onCloseDialog(event: object, reason: string) {
    if (reason != "escapeKeyDown" && reason != "backdropClick") {
      logInfo('UploadDialog Close')
      onClose()
    }
  }

  return (
    <>
      <Dialog open={open} onClose={onCloseDialog}>

        <DialogTitle>Upload Meeting</DialogTitle>

        <DialogContent>
          <Typography variant='caption' textAlign={'center'}>Supported files: mp3, mp4, wav, and m4a.</Typography>
          <Box sx={{ padding: 1 }} />

          <TextField
            label='Meeting Title'
            aria-label='Meeting Title'
            onChange={(t) => setTitle(t.target.value)}
            variant="outlined"
            value={title}
            disabled={busy}
            sx={{ width: 500, maxWidth: '100%' }}
          />

          <Box sx={{ padding: 1 }} />

          <Input
            type="file"
            inputProps={{ accept: '.m4a,audio/m4a,audio/mp4,audio/x-m4a,audio/*' }}
            onChange={(e: any) => setFile(e.target.files?.[0])}
            disabled={busy}
            style={{ display: 'none' }} // Hide the default input appearance
            id="file-input" // Add an ID to associate with the label
            aria-label="choose file"
          />
          <label htmlFor="file-input">
            <Button variant="contained" component="span">
              <Typography>Choose File</Typography>
            </Button>
          </label>

          <Typography>{file?.name}</Typography>

          {err && <Typography color={'tomato'} sx={{ paddingTop: 1 }}>{err}</Typography>}

        </DialogContent>

        {busy &&
          <LinearProgress variant="determinate" value={progress} sx={{ margin: 1 }} />
        }

        <DialogActions>
          <Button
            onClick={onClose}
            disabled={busy}
          >
            Cancel
          </Button>

          <LoadingButton
            variant="contained"
            color="primary"
            startIcon={<CloudUploadIcon />}
            onClick={onUpload}
            aria-label="upload meeting"
            disabled={busy || !file}
            sx={{ paddingY: 1.5, paddingX: 6 }}
            loading={busy}
          >
            Upload
          </LoadingButton>
        </DialogActions>
      </Dialog >
      <Snackbar
        open={isSnackSuccess}
        autoHideDuration={3000}
        onClose={() => setSnackSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          variant="filled"
          icon={<CheckIcon fontSize="inherit" />}
          onClose={() => setSnackSuccess(false)}
          severity='success'
          sx={{ width: '100%' }}
        >
          Meeting Successfully Uploaded
        </Alert>
      </Snackbar>
    </>
  )
}
