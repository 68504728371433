import React, { useEffect, useState } from 'react'
import {
  Box,
  Card,
  Skeleton,
  Button,
} from '@mui/material'
import { EventBase } from '../../client'
import { gSx } from '../../styles/Theme'
import ClientEventDialog from './ClientCardForEventDialog'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1'
import { ContactChip } from './ContactChip'

interface Props {
  event?: EventBase
  disabled?: boolean
}

export default function ContactCardForEvent({ event, disabled }: Props) {
  const contacts = event?.contacts ?? []
  const [showDialog, setDialog] = useState(false)
  const isProcessing = (!event || !event.owner_id)

  return (
    <Card>
      {
        isProcessing ? (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Skeleton variant="rectangular" width={'80%'} height={20} />
            <Skeleton variant="rectangular" width={'80%'} height={20} />
          </Box>
        ) : (
          <>
            <Box sx={gSx.RowBetween}>
              {contacts.length > 0 &&
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {contacts.map(c =>
                    <ContactChip key={c.id} contact={c} eventId={event.id} showRemove={!disabled} />
                  )}
                </Box>
              }

              <Box>
                <Button variant='outlined' aria-label='add'
                  onClick={() => setDialog(true)} disabled={disabled}
                >
                  <PersonAddAlt1Icon fontSize='small' />
                </Button>
              </Box>
            </Box>
          </>
        )
      }

      <ClientEventDialog
        event={event}
        open={showDialog}
        onClose={() => setDialog(false)}
      />

    </Card>
  )
}
