// This is a modified version of react-audio-visualize's LiveAudioVisualizer.tsx
import {
  type ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import { calculateBarData, draw } from "./utils"

export interface Props {
  /**
   * Media recorder who's stream needs to visualized
   */
  mediaRecorder: MediaRecorder
  /**
   * Function to set noAudioInput state
   */
  setNoAudioInput: (noAudioInput: boolean) => void
  /**
   * Audio warning timer
  */
  audioWarningTimer?: number
  /**
   * Width of the visualization. Default" "100%"
   */
  width?: number | string
  /**
   * Height of the visualization. Default" "100%"
   */
  height?: number | string
  /**
   * Width of each individual bar in the visualization. Default: `2`
   */
  barWidth?: number
  /**
   * Gap between each bar in the visualization. Default `1`
   */
  gap?: number
  /**
   * BackgroundColor for the visualization: Default `transparent`
   */
  backgroundColor?: string
  /**
   *  Color of the bars drawn in the visualization. Default: `"rgb(160, 198, 255)"`
   */
  barColor?: string
  /**
   * An unsigned integer, representing the window size of the FFT, given in number of samples.
   * A higher value will result in more details in the frequency domain but fewer details in the amplitude domain.
   * For more details {@link https://developer.mozilla.org/en-US/docs/Web/API/AnalyserNode/fftSize MDN AnalyserNode: fftSize property}
   * Default: `1024`
   */
  fftSize?:
  | 32
  | 64
  | 128
  | 256
  | 512
  | 1024
  | 2048
  | 4096
  | 8192
  | 16384
  | 32768
  /**
   * A double, representing the maximum decibel value for scaling the FFT analysis data
   * For more details {@link https://developer.mozilla.org/en-US/docs/Web/API/AnalyserNode/maxDecibels MDN AnalyserNode: maxDecibels property}
   * Default: `-10`
   */
  maxDecibels?: number
  /**
   * A double, representing the minimum decibel value for scaling the FFT analysis data, where 0 dB is the loudest possible sound
   * For more details {@link https://developer.mozilla.org/en-US/docs/Web/API/AnalyserNode/minDecibels MDN AnalyserNode: minDecibels property}
   * Default: `-90`
   */
  minDecibels?: number
  /**
   * A double within the range 0 to 1 (0 meaning no time averaging). The default value is 0.8.
   * If 0 is set, there is no averaging done, whereas a value of 1 means "overlap the previous and current buffer quite a lot while computing the value",
   * which essentially smooths the changes across
   * For more details {@link https://developer.mozilla.org/en-US/docs/Web/API/AnalyserNode/smoothingTimeConstant MDN AnalyserNode: smoothingTimeConstant property}
   * Default: `0.4`
   */
  smoothingTimeConstant?: number
}

const LiveAudioVisualizer: (props: Props) => ReactElement = ({
  mediaRecorder,
  setNoAudioInput,
  audioWarningTimer = 30000,
  width = "100%",
  height = "100%",
  barWidth = 2,
  gap = 1,
  backgroundColor = "transparent",
  barColor = "rgb(160, 198, 255)",
  fftSize = 1024,
  maxDecibels = -10,
  minDecibels = -90,
  smoothingTimeConstant = 0.4,
}: Props) => {
  const [context, setContext] = useState<AudioContext>()
  const [audioSource, setAudioSource] = useState<MediaStreamAudioSourceNode>()
  const [analyser, setAnalyser] = useState<AnalyserNode>()
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const lastAudioInputTime = useRef<Date | undefined>()

  useEffect(() => {
    if (!mediaRecorder.stream) return

    const ctx = new AudioContext()
    const analyserNode = ctx.createAnalyser()
    setAnalyser(analyserNode)
    analyserNode.fftSize = fftSize
    analyserNode.minDecibels = minDecibels
    analyserNode.maxDecibels = maxDecibels
    analyserNode.smoothingTimeConstant = smoothingTimeConstant
    const source = ctx.createMediaStreamSource(mediaRecorder.stream)
    source.connect(analyserNode)
    setContext(ctx)
    setAudioSource(source)

    return () => {
      source.disconnect()
      analyserNode.disconnect()
      ctx.state !== "closed" && ctx.close()
    }
  }, [mediaRecorder.stream])

  useEffect(() => {
    if (analyser && mediaRecorder.state === "recording") {
      if (!lastAudioInputTime.current) {
        lastAudioInputTime.current = new Date()
      }
      report()
    } else {
      lastAudioInputTime.current = undefined
    }
  }, [analyser, mediaRecorder.state])

  const report = useCallback(() => {
    if (!analyser || !context) return

    const data = new Uint8Array(analyser?.frequencyBinCount)

    if (mediaRecorder.state === "recording") {
      analyser?.getByteFrequencyData(data)
      processFrequencyData(data)
      requestAnimationFrame(report)
    } else if (mediaRecorder.state === "paused") {
      setNoAudioInput(false)
      processFrequencyData(data)
    } else if (
      mediaRecorder.state === "inactive" &&
      context.state !== "closed"
    ) {
      setNoAudioInput(false)
      context.close()
    } else {
      setNoAudioInput(false)
    }
  }, [analyser, context?.state])

  useEffect(() => {
    return () => {
      if (context && context.state !== "closed") {
        context.close()
      }
      audioSource?.disconnect()
      analyser?.disconnect()
    }
  }, [])

  const processFrequencyData = (data: Uint8Array): void => {
    if (!canvasRef.current) return

    if (data.some((d) => d > 0)) {
      lastAudioInputTime.current = new Date()
      setNoAudioInput(false)
    } else if (lastAudioInputTime.current &&
      new Date().getTime() - lastAudioInputTime.current.getTime() > audioWarningTimer) {
      setNoAudioInput(true)
    }

    const dataPoints = calculateBarData(
      data,
      canvasRef.current.width,
      barWidth,
      gap
    )
    draw(
      dataPoints,
      canvasRef.current,
      barWidth,
      gap,
      backgroundColor,
      barColor
    )
  }

  return (
    <canvas
      ref={canvasRef}
      width={width}
      height={height}
      style={{
        aspectRatio: "unset",
      }}
    />
  )
}

export { LiveAudioVisualizer }
