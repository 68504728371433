import React, { useState } from 'react'
import { EventBase } from '../../../client'
import { Box, Button, SxProps, Tooltip, Typography } from '@mui/material'
import { Media } from '../../../service/media'
import RedTailDialog from './RedtailDialog'
import CheckIcon from '@mui/icons-material/Check'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getRedtailSettings } from 'service/integrations/redtailApi'
import useRedtailValidateAuth from '../../../hooks/useRedtailValidateAuth'

interface Props {
  event?: EventBase
  eventSummaryHtml: string
  disabled: boolean
}
export default function RedtailEventButton({ event, eventSummaryHtml, disabled }: Props) {
  const queryClient = useQueryClient()
  useRedtailValidateAuth()
  const isSent = !!(event?.redtail_sent || event?.redtail_tasks_sent || event?.redtail_workflows_sent)
  const [showDialog, setShowDialog] = useState(false)

  const { data: settings } = useQuery({
    queryKey: ['getRedtailSettings'], queryFn: getRedtailSettings,
    enabled: queryClient.getQueryData(['getRedtailSettings']) === undefined,
  })
  const isSignedIn = settings?.user_id ?? false

  if (!isSignedIn || !event)
    return null
  return (
    <Box sx={{ paddingX: 1 }}>
      <Tooltip title={isSent ? "Sent to Redtail" : 'Send to Redtail'}>
        <Button
          variant='outlined'
          onClick={() => setShowDialog(true)}
          aria-label={'redtail'}
          disabled={disabled}
          startIcon={
            <Box
              component="img"
              sx={icon}
              src={Media.redtailIconWhite}
              alt="Redtail"
            />
          }
          endIcon={
            isSent &&
            <CheckIcon color='success' fontSize='small' />

          }
        >
          <Typography sx={{ fontSize: 14 }}>Redtail</Typography>
        </Button>
      </Tooltip>

      {showDialog && // prevent rt_id generation until dialog opens
        <RedTailDialog
          open={showDialog}
          onClose={() => setShowDialog(false)}
          event={event}
          eventSummaryHtml={eventSummaryHtml}
        />
      }
    </Box>
  )
}

const icon: SxProps = {
  width: 23,
  height: 23,
  marginRight: '5px'
}