import React, { useState } from "react"
import { Box, Button, Card, Typography, Link, SxProps, CardContent, CardActions, CardHeader, Skeleton } from "@mui/material"
import { ProductName } from "../../client"
import { useTheme } from '@mui/material/styles'
import CheckoutDialog from "./CheckoutDialog"
import { gColor, gSx } from "styles/Theme"
import Grid from "@mui/material/Grid2"
import CheckIcon from '@mui/icons-material/Check'
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { getOrg } from "service/orgsApi"
import { getSubscriptionPlans, getSubscription } from "service/payApi"
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'

interface PlanActionSectionProps {
  isLoading: boolean
  isAdmin: boolean
  isYearSub: boolean
  handleButtonClick: () => void
}

const PlanActionSection: React.FC<PlanActionSectionProps> = ({ isLoading, isAdmin, isYearSub, handleButtonClick }) => {
  if (isLoading)
    return (
      <Skeleton width={'90%'} height={50}></Skeleton>
    )

  return (
    <>
      {isAdmin ? (
        <>
          {isYearSub ? (
            <Typography color={gColor.green500} >
              Contact Support to manage seats for your annual subscription
            </Typography>
          ) :
            <Button variant={'outlined'} fullWidth
              onClick={handleButtonClick}>
              Select Plan
            </Button>
          }
        </>
      ) : (
        <Typography color={gColor.green500}>
          Contact your administrator to purchase seats
        </Typography>
      )}
    </>
  )
}

interface Props {
  isAdmin: boolean
}

export default function PricingPlansNew({ isAdmin }: Props) {
  const theme = useTheme()
  const queryClient = useQueryClient()

  const { data: org } = useQuery({
    queryKey: ['getOrg'], queryFn: getOrg,
    enabled: queryClient.getQueryData(['getOrg']) === undefined,
  })

  const { data: sub, isLoading: isSubLoading } = useQuery({
    queryKey: ['getSubscription'], queryFn: getSubscription,
    enabled: queryClient.getQueryData(['getSubscription']) === undefined,
  })

  const { data: plans, isLoading: isPlanLoading } = useQuery({
    queryKey: ['getSubscriptionPlans'], queryFn: getSubscriptionPlans,
    enabled: queryClient.getQueryData(['getSubscriptionPlans']) === undefined,
  })

  const [openCart, setOpenCart] = useState(false)

  function handleButtonClick() {
    setOpenCart(true)
  }

  const isYearSub = sub?.interval == "year"

  const litePrice = plans?.month?.lite?.price ? `$${(plans?.month?.lite?.price?.unit_amount ?? 0) / 100}` : undefined

  const starterDescription = [
    "20 hours / seat / month", // TODO: overage $4 / hour 
    "AI Meeting Summary",
    "Custom Modular Templates",
    "Meeting Analytics",
    "Automated Recording Rules via Calendar",
  ]
  const starterPrice = plans?.month?.starter?.price?.unit_amount ? `$${plans?.month?.starter?.price?.unit_amount / 100}` : undefined

  const standardDescription = [
    "Unlimited hours",
    "AI Meeting Summary",
    "Custom Modular Templates",
    "Meeting Analytics",
    "Automated Recording Rules via Calendar",
  ]
  const standardPrice = plans?.month?.standard?.price?.unit_amount ? `$${plans?.month?.standard?.price?.unit_amount / 100}` : undefined

  const enterpriseDescription = [
    "Everything in Standard", // TODO: overage $3 / hour 
    "Customized Data Retention Policies",
    "Fully Customized Templates",
    "Unlimited Hours",
    "Premium support",
  ]

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, paddingY: '2vh' }}>
        <Typography variant='h1'>Plans</Typography>
        {isYearSub &&
          <Box
            sx={sxBillingBanner}
          >
            <CalendarTodayIcon sx={{
              fontSize: 14,
            }} />
            Annual
          </Box>}
      </Box>
      <Grid container spacing={2}>

        <Grid size={{ xs: 12, md: 6, lg: 3 }} sx={sxGrid}>
          <Card sx={sxCard}>
            <Header
              title='Starter' price={starterPrice}
              backgroundColor={gColor.blue400}
            />

            <CardContent>
              {starterDescription.map((line: string) => (
                <Box key={line} display="flex" alignItems="center" my={1}>
                  <CheckIcon sx={{ color: '#2bc379' }} />
                  <Typography variant="body2" sx={{ ml: 1 }}>{line}</Typography>
                </Box>
              ))}
            </CardContent>

            <CardActions sx={{ paddingX: 2 }}>
              <PlanActionSection isLoading={isSubLoading || isPlanLoading} isAdmin={isAdmin}
                isYearSub={isYearSub}
                handleButtonClick={handleButtonClick} />
            </CardActions>

            <Box sx={{ paddingX: 2, paddingBottom: 2 }}>
              <Typography variant="caption">
                For starter users, surcharge rates over 20 hours a month may apply.
              </Typography>
            </Box>
          </Card>
        </Grid>

        <Grid size={{ xs: 12, md: 6, lg: 3 }} sx={sxGrid}>
          <Card sx={sxCard}>
            <Header
              title='Standard' price={standardPrice} isMostPopular
              backgroundColor={gColor.blue600}
            />

            <CardContent>
              {standardDescription.map((line: string) => (
                <Box key={line} display="flex" alignItems="center" my={1}>
                  <CheckIcon sx={{ color: '#2bc379' }} />
                  <Typography variant="body2" sx={{ ml: 1 }}>{line}</Typography>
                </Box>
              ))}
            </CardContent>

            <CardActions sx={{ paddingX: 2 }}>
              <PlanActionSection isLoading={isSubLoading || isPlanLoading} isAdmin={isAdmin}
                isYearSub={isYearSub}
                handleButtonClick={handleButtonClick} />
            </CardActions>

          </Card>
        </Grid>

        <Grid size={{ xs: 12, md: 6, lg: 3 }} sx={sxGrid}>
          <Card sx={sxCard}>
            <Header title='Enterprise' backgroundColor={gColor.blue800} />

            <CardContent>
              {enterpriseDescription.map((line: string) => (
                <Box key={line} display="flex" alignItems="center" my={1}>
                  <CheckIcon sx={{ color: '#2bc379' }} />
                  <Typography variant="body2" sx={{ ml: 1 }}>{line}</Typography>
                </Box>
              ))}
            </CardContent>

            <CardActions sx={{ paddingX: 2 }}>
              {isAdmin ? (
                <Button variant={'outlined'} fullWidth
                  onClick={() => window.location.href = 'mailto:sales@finmate.ai'}>
                  Contact Sales
                </Button>
              ) : (
                <Typography color={gColor.green500}>
                  Contact your administrator to purchase seats
                </Typography>
              )}
            </CardActions>

          </Card>
        </Grid>

        <Grid size={{ xs: 12, md: 6, lg: 3 }} sx={sxGrid}>
          <Card sx={sxCard} >
            <Header title='Lite' backgroundColor={gColor.blue300} price={litePrice} />

            <CardContent>
              <Typography variant='body1' sx={{ paddingY: 1 }}>
                For assistants.
              </Typography>
              <Typography variant='body1'>
                Perfect for those who want to manage shared meetings. Lite users can edit CRMs, operate analytics, send followup emails, etc.
              </Typography>
            </CardContent>

            <CardActions sx={{ paddingX: 2, paddingTop: 5 }}>
              <PlanActionSection isLoading={isSubLoading || isPlanLoading} isAdmin={isAdmin}
                isYearSub={isYearSub}
                handleButtonClick={handleButtonClick} />
            </CardActions>

          </Card>

        </Grid>

        <Grid size={{ xs: 12, md: 12 }} >

          <Card>
            <CardHeader title="Small Business Discounts Available" titleTypographyProps={{ align: "center" }} />

            <CardContent>
              <Typography variant='body1' textAlign="center">
                We&apos;re committed to making our tool accessible to startups, small RIAs, and Advisors.
              </Typography>
              <Typography variant='body1' textAlign="center">
                Contact us, and we&apos;d be happy to explore how we can make our tool available to all firms.
              </Typography>
              <Box sx={{ padding: '1vh' }} />
              <Box sx={sxButtonWrapper}>
                <Button variant="outlined" aria-label='Contact Sales' onClick={() => window.location.href = 'mailto:sales@finmate.ai'}>
                  {`Contact Sales`}
                </Button>
              </Box>
            </CardContent>

          </Card>

        </Grid>
      </Grid >

      {
        org && !isYearSub &&
        <CheckoutDialog
          org={org}
          open={openCart}
          setOpen={setOpenCart}
        />
      }

    </>
  )
}

interface HeaderProps {
  title: string
  price?: string
  backgroundColor: string
  isMostPopular?: boolean
}

function Header({ title, price, backgroundColor, isMostPopular }: HeaderProps) {
  return (
    <Box sx={{
      backgroundColor: backgroundColor,
      paddingX: 4,
      paddingTop: 0.5,
      height: '80px',
    }}>

      {isMostPopular
        ? <Box sx={{ backgroundColor: 'red', borderRadius: 10, display: 'inline-block', paddingX: 0.5 }}>
          <Typography sx={{ color: 'white', fontWeight: 'bold', fontSize: 9 }}>Most Popular</Typography>
        </Box>
        : <Box sx={{ padding: 1.35 }} />
      }

      <Box sx={gSx.RowBetween}>
        <Typography variant="h5" color={'white'} sx={{ fontWeight: 'bold' }}>{title}</Typography>
        <Typography variant="h5" color={'white'} sx={{ fontWeight: 'bold' }}>{price}</Typography>
      </Box>

      {price && <Box sx={gSx.RowEnd}>
        <Typography variant='caption' color='white'> seat / month</Typography>
      </Box>
      }

    </Box>
  )
}

const sxGrid: SxProps = { display: 'flex' }

const sxCard: SxProps = { margin: 0, padding: 0, flexGrow: 1 }

const sxButtonWrapper: SxProps = {
  display: 'flex',
  justifyContent: 'center',
}

const sxBillingBanner: SxProps = {
  display: 'flex',
  alignItems: 'center',
  gap: 0.5,
  bgcolor: 'primary.main',
  color: 'white',
  px: 1.5,
  py: 0.5,
  borderRadius: 20,
  fontSize: '0.75rem',
  transform: 'translateY(2px)'
}
