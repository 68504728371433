import React, { useEffect, useState } from 'react'
import { Typography, Box, Card, SxProps, Link, Tooltip, Divider, Button } from '@mui/material'
import 'firebase/auth'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { gSx } from '../../styles/Theme'
import { Media } from 'service/media'
import ContainerSmall from 'components/ContainerSmall'
import { logInfo } from 'log'
import IntegrationsBackButton from 'components/IntegrationsBackButton'
import { LoadingButton } from '@mui/lab'
import { disconnectRingCentral, getRingCentralProfile, ringCentralOAuth } from 'service/integrations/ringCentralApi'
import { SkeletonRows } from 'components/Skeletons'
import CloseIcon from '@mui/icons-material/Close'
import PhoneIcon from '@mui/icons-material/Phone'
import Grid from '@mui/material/Grid2'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'

export default function RingCentralSettingsScreen() {
  const queryClient = useQueryClient()
  const [busy, setBusy] = useState(false)
  const [err, setErr] = useState<string | undefined>()

  useEffect(() => {
    logInfo('Ring Central Settings Screen')
  }, [])

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['getRingCentralProfile'],
    queryFn: getRingCentralProfile,
  })

  async function onSignIn() {
    setBusy(true)
    logInfo('RingCentral Sign In Pressed')
    await ringCentralOAuth()
    setBusy(false)
  }

  async function signout() {
    logInfo('RingCentral Signout Pressed')
    setErr(undefined)
    setBusy(true)
    const res = await disconnectRingCentral()
    if (res instanceof Error)
      setErr(res.message)
    else {
      queryClient.invalidateQueries({ queryKey: ['getRingCentralCallLogs'] })
      await refetch()
    }
    setBusy(false)
  }

  return (
    <ContainerSmall>
      <IntegrationsBackButton />
      <Typography variant='h3'>Ring Central Integration</Typography>

      <Divider sx={{ marginY: '2vh ' }} />
      <Box sx={gSx.Row}>
        <Link href="https://www.ringcentral.com/" rel='noopener' target='_blank'>
          <Box
            component="img"
            sx={icon}
            src={Media.ringCentralLogo}
            alt="RingCentral"
          />
        </Link>
        <Box sx={{ padding: 1 }} />
        <Typography variant='h1'>Ring Central</Typography>
      </Box>
      <Box sx={{ padding: '1vh ' }} />

      <Typography>Connect FinMate to Ring Central, capture recorded calls and create notes.</Typography>

      <Box sx={{ padding: '1vh ' }} />

      <Card>

        {
          isLoading
            ? <SkeletonRows count={3} height={100} />
            : data
              ? (
                <Box sx={gSx.RowBetween}>
                  <Typography>
                    {`Signed in as ${data.first_name ?? ''} ${data.last_name}, ${data.email}`}
                  </Typography>

                  <Tooltip title="Disconnect Ring Central">
                    <LoadingButton
                      aria-label='Disconnect Ring Central'
                      onClick={signout}
                      disabled={busy}
                      loading={busy}
                    >
                      <CloseIcon />
                    </LoadingButton>
                  </Tooltip>
                </Box>
              ) : (
                <Box sx={sxConnect}>
                  <LoadingButton
                    variant='contained' onClick={onSignIn}
                    loading={busy}
                    disabled={busy}
                    sx={{ width: 150 }}
                  >
                    Connect
                  </LoadingButton>
                </Box>
              )
        }
      </Card >

      {!data && <Typography variant='caption' sx={{ paddingTop: 2 }}>
        Note: Signing into 1 RingCentral account across multiple FinMate accounts not allowed.
      </Typography>}

      {data &&
        <Card>
          <Grid container>
            <Grid size={1} sx={{ paddingTop: 1 }}>
              <PhoneIcon />
            </Grid>
            <Grid size={11}>
              <Typography>
                Your recorded RingCentral phone calls are automatically saved to FinMate AI. In RingCentral, you can choose to record calls either automatically or on-demand.
              </Typography>

              <Box sx={{ padding: 2 }} />
              <Typography variant='h5'>
                Automatic Recording
              </Typography>

              <Typography>
                <Link href="https://support.ringcentral.com/article-v2/Turning-on-on-demand-or-automatic-call-recording-.html?brand=RingCentral&product=RingEX&language=en_US&pills-nav=automatic_call_recording" rel='noopener' target='_blank'>Setup Instructions
                </Link>
              </Typography>

              <Box sx={{ padding: 2 }} />
              <Typography variant='h5'>
                On-Demand Recording
              </Typography>

              <Typography>
                <Link href="https://support.ringcentral.com/article-v2/Turning-on-on-demand-or-automatic-call-recording-.html?brand=RingCentral&product=RingEX&language=en_US&pills-nav=on-demand_call_recording" rel='noopener' target='_blank'>Setup Instructions
                </Link>
              </Typography>

              <Box sx={{ padding: 1 }} />
              <Typography>
                During your call, press record
              </Typography>

              <Box
                component="img"
                src={Media.ringCentralRecord}
                sx={{ width: 200 }}
              />

            </Grid>
          </Grid>
        </Card>
      }

      <Button target="_blank" rel="noopener"
        href="https://docs.finmate.ai/docs/category/ringcentral"
        startIcon={<TextSnippetIcon />}
      >
        RingCentral Docs
      </Button>
      {/* Dev Only <RingCentralCallLog /> */}

    </ContainerSmall>
  )
}

const icon: SxProps = {
  width: 50,
  marginRight: 2
}

const sxConnect: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}
