/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum RedtailType {
    INDIVIDUAL = 'Individual',
    BUSINESS = 'Business',
    ASSOCIATION = 'Association',
    UNION = 'Union',
    TRUST = 'Trust',
}
