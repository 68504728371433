import React, { useState } from 'react'
import {
  Typography,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
  SxProps,
  Box,
  Switch,
} from '@mui/material'
import { Access, SharedUser } from '../../../client'
import Grid from '@mui/material/Grid2'
import ProfilePhoto from 'components/settings/ProfilePhoto'
import { gSx } from 'styles/Theme'

interface Props {
  permission: SharedUser
  disabled?: boolean
  allPermissions: SharedUser[]
  onSave: (target: SharedUser[]) => Promise<void>
}

export default function UserAccessLineItemEvent({ permission, disabled, allPermissions, onSave }: Props) {
  const [access, setAccess] = useState(permission.access)

  const onAccess = async (event: SelectChangeEvent) => {
    setAccess(event.target.value as Access)

    const updated_p = {
      ...permission,
      access: event.target.value as Access
    }

    const updated_all_p = []
    for (const pp of allPermissions) {
      (pp.uid == permission.uid)
        ? updated_all_p.push(updated_p)
        : updated_all_p.push(pp)
    }
    await onSave(updated_all_p)
  }

  return (
    <>
      <Grid size={8}>
        <Box sx={gSx.Row}>
          <ProfilePhoto photoUrl={permission.photo_url} width={40} />
          <Box sx={{ paddingLeft: 1 }}>
            <Typography>{permission?.name}</Typography>
            <Typography variant='caption'>{permission?.email}</Typography>
          </Box>
        </Box>
      </Grid>

      <Grid size={4} sx={sxAccessGrid}>

        <FormControl fullWidth>
          <Select
            value={access}
            onChange={onAccess}
            disabled={disabled}
          >
            <MenuItem value={Access.EDITOR}>Editor</MenuItem>
            <MenuItem value={Access.VIEWER}>Viewer</MenuItem>
            <MenuItem value={Access.REMOVE}>Remove</MenuItem>
          </Select>
        </FormControl>

      </Grid>

    </>
  )
}

const sxAccessGrid: SxProps = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignContent: 'center',
}
