import React, { useState } from 'react'
import { Card, CardHeader, FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material'
import 'firebase/auth'
import { SalesforceApp } from 'client'
import SalesforceImportContacts from './SalesforceImportContactsButton'
import { gSx } from 'styles/Theme'

interface Props {
  app: SalesforceApp
}

export default function SalesforceImportCard({ app }: Props) {
  const [includeTeamContacts, setIncludeTeamContacts] = useState(false)
  // see README_salesforce.md for more info
  return (
    <Card>
      <CardHeader title='Import Contacts' />

      <Box sx={gSx.RowBetween}>

        <FormControl>
          <InputLabel id="source" size='small'>Source</InputLabel>
          <Select
            labelId="source" id="source" label="Source"
            value={includeTeamContacts} size='small'
            onChange={(e) => (setIncludeTeamContacts(e.target.value === 'true'))}
          >
            <MenuItem value="false">Your Contacts</MenuItem>
            <MenuItem value="true">All Contacts(Yours & Team)</MenuItem>
          </Select>
        </FormControl>

        <SalesforceImportContacts app={app} includeTeamContacts={includeTeamContacts} />
      </Box>
    </Card>
  )
}