import React, { useEffect } from 'react'
import { Card, Typography, SxProps, Button, Box, Tooltip, Skeleton, CardHeader, CardContent, CardActions, IconButton } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Media } from 'service/media'
import { gSx } from 'styles/Theme'

import { GetCalendarUser } from 'service/calendarApi'
import { useNavigate } from 'react-router-dom'
import { Path } from 'routes'
import { useQuery } from '@tanstack/react-query'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'

export default observer(CalendarConnected)
function CalendarConnected() {
  const navigate = useNavigate()

  const { data, isLoading } = useQuery({
    queryKey: ['GetCalendarUser'], queryFn: GetCalendarUser,
  })

  const google = data?.connections?.filter(conn => conn.platform === 'google')[0]
  const microsoft = data?.connections?.filter(conn => conn.platform === 'microsoft')[0]
  const isGoogleConnected = !!google?.connected
  const isMicrosoftConnected = !!microsoft?.connected
  const isNoneConnected = !isGoogleConnected && !isMicrosoftConnected

  return (
    <Card sx={{ flexGrow: 1, margin: 0 }}>
      <CardHeader title="Calendar" />

      {
        isLoading
          ? <Skeleton variant="rectangular" width={'100%'} height={20} />
          : (
            <>
              {isNoneConnected && (
                <>
                  <Typography sx={{ color: 'darkred', paddingBottom: 1 }}>
                    No Google or Outlook Calendar is Connected
                  </Typography>
                  <Box sx={gSx.RowEnd}>
                    <Button
                      onClick={() => navigate(Path.CalendarAuth)}
                      variant='contained'
                    >
                      Connect Calendar
                    </Button>
                  </Box>
                </>
              )}

              {isMicrosoftConnected && (
                <Box sx={gSx.CenterVertical}>
                  <Box sx={gSx.Row}>
                    <Box
                      component="img"
                      sx={icon}
                      src={Media.microsoft100}
                      alt="Microsoft Logo"
                    />
                    <Typography>{`Signed in as ${microsoft.email}`}</Typography>
                  </Box>
                </Box>
              )}

              {isGoogleConnected && (
                <Box sx={gSx.CenterVertical}>
                  <Box sx={gSx.Row}>
                    <Box
                      component="img"
                      sx={icon}
                      src={Media.google100}
                      alt="Google Logo"
                    />
                    <Typography>{`Signed in as ${google.email}`}</Typography>
                  </Box>
                </Box>
              )}
            </>
          )
      }

    </Card>
  )
}

const icon: SxProps = {
  width: 20,
  height: 20,
  marginRight: 2
}
