import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { Box, Card, Typography, Link, List, ListItem, Pagination, Button, Divider } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { getEvents } from '../../service/eventApi'
import { useQuery } from '@tanstack/react-query'
import Grid from '@mui/material/Grid2'
import { useStore } from '../../hooks/useStore'
import { EventSimpleBase, ListEvents } from 'client'
import { gColor, gSx } from 'styles/Theme'

import CheckboxTriState, { CheckboxTriType } from 'components/CheckBoxTriState'
import { grey } from '@mui/material/colors'

interface Props {
  data?: ListEvents
  checkedEventIds?: string[]
  setCheckedEventIds?: Dispatch<SetStateAction<string[]>>
  isMyMeetings?: boolean
}
export default function EventsListTopRow({ data, checkedEventIds, setCheckedEventIds, isMyMeetings }: Props) {

  const res = useRef<ListEvents | undefined>(undefined)
  const [checkBoxAll, setCheckedBoxAll] = useState<CheckboxTriType>('clear')

  useEffect(() => {
    res.current = data
  }, [data])

  useEffect(() => {
    if (!checkedEventIds || !setCheckedBoxAll)
      return
    const total = data?.results?.length ?? 1
    if (checkedEventIds.length == 0)
      setCheckedBoxAll('clear')
    else if (checkedEventIds.length == total) {
      setCheckedBoxAll('filled')
    } else {
      setCheckedBoxAll('partial')
    }
  }, [checkedEventIds])

  function onCheckBoxAll() {
    if (!data?.results || !setCheckedEventIds)
      return

    if (checkBoxAll == 'clear') {
      const all: string[] = []
      for (const a of data.results) {
        all.push(a.id)
        setCheckedEventIds(all)
        setCheckedBoxAll('filled')
      }
    } else {
      setCheckedEventIds([])
      setCheckedBoxAll('clear')
    }
  }

  return (
    <Box sx={{ display: { xs: 'none', md: 'block' }, marginTop: 1 }}>
      <Box sx={{ ...gSx.Row, backgroundColor: grey[200], paddingRight: 1.5 }}>

        <Grid container width='100%' sx={{ backgroundColor: grey[200], alignItems: 'center', marginBottom: 0, marginLeft: 2, height: '50px' }}>

          <Grid size={7} sx={{ display: { xs: 'none', md: 'block' } }}>
            <Box sx={gSx.Row}>

              {isMyMeetings
                ? <CheckboxTriState state={checkBoxAll} onClick={onCheckBoxAll} />
                : <Box sx={{ paddingX: 2.6 }} />
              }

              <Typography variant='h6' sx={{ paddingLeft: 11 }} >Meeting Title</Typography>
            </Box>
          </Grid>

          <Grid size={3} sx={{ display: { xs: 'none', md: 'block' } }}>
            <Typography variant='h6' >Contact</Typography>
          </Grid>

          <Grid size={2} sx={{ display: { xs: 'none', md: 'block' } }}>
            <Typography variant='h6' textAlign={'start'}>Date</Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
