import React from 'react'
import { Typography, Box, Card, SxProps, Button, Skeleton, ButtonBase, Tooltip } from '@mui/material'
import 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import { Path } from 'routes'
import { useQuery } from '@tanstack/react-query'
import { Media } from 'service/media'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { GetCalendarUser } from 'service/calendarApi'
import { isGoogleCalConnected, isMicrosoftCalConnected } from 'utils'

export default function MicrosoftCalendarIntegrationLine() {
  const navigate = useNavigate()
  const { data, isLoading } = useQuery({
    queryKey: ['GetCalendarUser'], queryFn: GetCalendarUser
  })

  const isGoogleCal = isGoogleCalConnected(data)
  const isMSCal = isMicrosoftCalConnected(data)
  const email = data?.connections?.filter(conn => conn.platform === 'microsoft')[0].email

  function Content() {
    if (isMSCal)
      return (
        <Box><Button>Outlook Calendar Connected</Button>
          {email && <Typography>{email}</Typography>}</Box>
      )
    if (isGoogleCal)
      return (
        <Box><Typography color='textDisabled'>Outlook Calendar Sign In</Typography>
          <Typography color='lightgray' variant='caption'>Disabled. Signed into Google Calendar</Typography></Box>
      )
    return (
      <Typography>Outlook Calendar Sign In</Typography>
    )
  }

  return (
    <Card>
      <ButtonBase sx={sxContainer} disabled={isGoogleCal}
        onClick={() => navigate(Path.CalendarAuth + '?platform=microsoft')}
      >
        <Box component="img" src={Media.microsoft100} sx={sxLogo} />
        {isLoading
          ? <Skeleton variant="rectangular" width={'100%'} height={50} />
          : <Content />
        }
        <ChevronRightIcon sx={{ color: isGoogleCal ? 'lightgray' : 'black' }} />
      </ButtonBase>
    </Card>
  )
}

const sxLogo: SxProps = {
  width: 38,
}

const sxContainer: SxProps = {
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
}