import React, { useState } from 'react'
import { IconButton } from "@mui/material"
import ShareIcon from '@mui/icons-material/Share'
import { EventBase } from 'client'
import ShareEventDialog from './ShareEventDialog'
import { logInfo } from 'log'
import AddShareSentSnack from './AddShareSentSnack'

interface Props {
  data?: EventBase
  disabled?: boolean
}

export function ShareEventButton({ data, disabled }: Props) {
  const [openUploadDialog, setOpenDialog] = useState(false)
  const [openSuccessSnack, setOpenSuccessSnack] = useState(false)

  return (
    <>
      <IconButton
        aria-label='share'
        onClick={() => {
          logInfo('Share Event, Open Share Dialog')
          setOpenDialog(true)
        }}
        disabled={disabled}
      >
        <ShareIcon fontSize='small' />
      </IconButton >

      <ShareEventDialog
        open={openUploadDialog}
        onClose={() => {
          logInfo('Share Event, Close Share Dialog')
          setOpenDialog(false)
        }}
        disabled={disabled}
        data={data}
        setOpenSuccessSnack={setOpenSuccessSnack}
      />

      <AddShareSentSnack
        open={openSuccessSnack}
        setOpen={setOpenSuccessSnack}
      />
    </>
  )
}