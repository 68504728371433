import React, { useEffect, useState } from 'react'
import {
  Box,
  SxProps,
  Typography,
  IconButton,
  TextField,
  DialogTitle,
  DialogContent,
  Autocomplete,
  DialogActions,
  Button,
  Divider,
  LinearProgress,
} from '@mui/material'
import { FirebaseUser, OrgUser, OrgUserRole, SharedUser } from '../../../client'
import { gSx } from '../../../styles/Theme'
import CloseIcon from '@mui/icons-material/Close'
import Grid from '@mui/material/Grid2'
import ProfilePhoto from 'components/settings/ProfilePhoto'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getOrg } from 'service/orgsApi'
import { getEvent, updateEventPermissions } from 'service/eventApi'
import UserAccessLineItem from './UserAccessLineItemEvent'
import { logInfo } from 'log'
import { useNavigate } from 'react-router-dom'
import { Path } from 'routes'
import { getFirebaseUser } from 'service'

interface Props {
  setInput: (orgUser: OrgUser) => void
  onAdd: (isAdd: boolean) => void
  onClose: () => void
  eventId?: string
  disabled?: boolean
  setOpenSuccessSnack: (isOpen: boolean) => void
}

export default function ShareEventMain({ eventId, disabled, setInput, onAdd, onClose, setOpenSuccessSnack }: Props) {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [err, setErr] = useState<string | undefined>()
  const [busy, setBusy] = useState(false)
  const [owner, setOwner] = useState<FirebaseUser | undefined>()

  const { data: event } = useQuery({
    queryKey: ['getEvent', eventId],
    queryFn: async () => await getEvent(eventId),
  })
  const permissions = event?.permissions ?? []

  const { data: org } = useQuery({
    queryKey: ['getOrg'], queryFn: getOrg,
    enabled: queryClient.getQueryData(['getOrg']) === undefined,
  })

  useEffect(() => {
    if (event && event.owner_id) {
      getFirebaseUser(event.owner_id)
        .then((res) => setOwner(res))
    }
  }, [event])

  function validUsersToShare() {
    if (!org?.org_user_list)
      return []
    return org.org_user_list
      .filter(u => u.org_user_role != OrgUserRole.INACTIVE)
      .filter(u => u.uid != owner?.uid)
  }

  function onAddPeople(event: any, newValue: OrgUser | null) {
    if (newValue) {
      setInput(newValue)
      onAdd(true)
    }
  }

  async function onSave(target: SharedUser[]) {
    logInfo('Share Event, modify user permissions. Save button pressed.', permissions)
    setErr(undefined)
    setBusy(true)
    const res = await updateEventPermissions(target, event?.id)
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      await queryClient.invalidateQueries({ queryKey: ['getEvent', eventId] })
      setOpenSuccessSnack(true)
    }
    setBusy(false)
  }

  return (
    <>
      <Box sx={gSx.RowBetween}>
        <DialogTitle>Share Event</DialogTitle>
        <IconButton sx={sxClose} aria-label='close' onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent>

        <Autocomplete
          id="add-people"
          options={validUsersToShare()}
          getOptionLabel={(option: OrgUser) => option.name ? `${option.name}, ${option.email}` : `${option.email}`}
          onChange={onAddPeople}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Share with a Person"
              variant="outlined"
            />
          )}
          renderOption={(props, option) => (
            <Box component="li" sx={sxSearchLineItem} {...props}>
              <Box sx={{ width: 50 }}>
                <ProfilePhoto photoUrl={option.photo_url} width={40} />
              </Box>
              {option.name ? `${option.name},  ${option.email}` : `${option.email}`}

            </Box>
          )}
          disabled={disabled}
        />

        <Typography variant='caption'>Restricted to users in your organization</Typography>
        <Box sx={{ padding: '1vh' }} />

        <Typography variant='h6'>People with access</Typography>
        <Box sx={{ padding: 1 }} />

        <Grid container spacing={2}>

          <Grid size={9}>
            <Box sx={gSx.Row}>
              <ProfilePhoto photoUrl={owner?.photo_url} width={40} />
              <Box sx={{ paddingLeft: 1 }}>
                <Typography>{owner?.display_name}</Typography>
                <Typography variant='caption'>{owner?.email}</Typography>
              </Box>
            </Box>
          </Grid>

          <Grid size={3} sx={sxAccessGrid}>
            <Typography sx={{ padding: 1.5 }}>owner</Typography>
          </Grid>

          {permissions?.map(p =>
            <UserAccessLineItem
              key={p.uid}
              permission={p}
              disabled={disabled}
              allPermissions={permissions}
              onSave={onSave}
            />
          )}

        </Grid>

        <Box sx={{ padding: '1vh' }}></Box>

        <Typography variant='caption'>
          Users must have at least Lite Plan, for Editor Permissions.
        </Typography>

      </DialogContent>

      {busy && <LinearProgress />}
      <DialogActions>
        {err && <Typography color={'tomato'} >{err}</Typography>}

      </DialogActions>

      <Divider />

      <DialogContent>
        <Button onClick={() => navigate(Path.RecorderSettings)} >
          Want to auto share meetings?
        </Button>
      </DialogContent>
    </>
  )
}

const sxClose: SxProps = {
  alignSelf: 'flex-start',
}

const sxAccessGrid: SxProps = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignContent: 'center',
  marginBottom: 1
}

const sxSearchLineItem: SxProps = {
  '& > img': { mr: 2, flexShrink: 0 }
}
