/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum WealthboxType {
    PERSON = 'Person',
    HOUSEHOLD = 'Household',
    ORGANIZATION = 'Organization',
    TRUST = 'Trust',
}
