import React, { useState, useEffect, useMemo, forwardRef, useImperativeHandle } from 'react'
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  type MRT_TableOptions,
  type MRT_Row,
} from 'material-react-table'
import { ClientData, EmploymentIncome, Employment } from '../../client'
import { getNumberCell } from './CustomCells'
import { foldTable, hideTable, FinanceTableWithMemberIdProps, TableRef } from './financesUtils'
import { useCustomTable } from './useCustomTable'

const EmploymentTable = forwardRef<TableRef, FinanceTableWithMemberIdProps<'Employment & Income', 'Employment'>>(
  ({ eventId, title, type, subType, dataInput, datasetGroup, dynamicValues, hideEmptyTable, foldEmptyTable, readOnly, memberId, onUpdate }, ref) => {

    const [dataList, setDataList] = useState<Employment[]>([])
    const [newData, setNewData] = useState<Employment>()
    const [dsElements, setDsElements] = useState<any>()

    useEffect(() => {
      if (dataInput) {
        setDataList(dataInput)
      }
    }, [dataInput])

    useEffect(() => {
      if (datasetGroup && datasetGroup['elements']) {
        setDsElements(datasetGroup['elements'])
      }
    }, [datasetGroup])

    useImperativeHandle(ref, () => ({
      createRowFunction: () => {
        table.setCreatingRow(true)
      },
    }))

    const handleCreate: MRT_TableOptions<Employment>['onCreatingRowSave'] = async ({
      values,
      table,
    }) => {
      const updated = [...dataList]
      if (newData) {
        newData.member_id = memberId
        onUpdate(type, subType, [newData], memberId)
      }
      table.setCreatingRow(null) // exit creating mode
    }

    function handleDelete(row: MRT_Row<Employment>) {
      if (row.index >= 0) {
        onUpdate(type, subType, undefined, memberId)
      }
    }

    const columns = useMemo<MRT_ColumnDef<Employment>[]>(
      () => [
        {
          accessorKey: 'Employer',
          header: 'Employer',
          muiEditTextFieldProps: ({ row }) => ({
            onBlur: (event) => {
              if (row.index < 0) {
                setNewData((prevNew) => ({
                  ...prevNew,
                  'Employer': event.target.value,
                }))
                return
              }
              const rowIdx = Number(row.id)
              const updated = [...dataList]
              updated[rowIdx]['Employer'] = event.target.value
              onUpdate(type, subType, updated, memberId)
            },
          }),
          size: 140
        },
        {
          accessorKey: 'Title / Job Position',
          header: 'Title / Job Position',
          muiEditTextFieldProps: ({ row }) => ({
            onBlur: (event) => {
              if (row.index < 0) {
                setNewData((prevNew) => ({
                  ...prevNew,
                  'Title / Job Position': event.target.value,
                }))
                return
              }
              const rowIdx = Number(row.id)
              const updated = [...dataList]
              updated[rowIdx]['Title / Job Position'] = event.target.value
              onUpdate(type, subType, updated, memberId)
            },
          }),
          size: 100
        },
        {
          accessorKey: 'No. of Years Working Here',
          header: 'No. of Years Working Here',
          muiEditTextFieldProps: ({ row }) => ({
            type: 'number',
            onBlur: (event) => {
              if (row.index < 0) {
                setNewData((prevNew) => ({
                  ...prevNew,
                  'No. of Years Working Here': Number(event.target.value),
                }))
                return
              }
              const rowIdx = Number(row.id)
              const updated = [...dataList]
              updated[rowIdx]['No. of Years Working Here'] = Number(event.target.value)
              onUpdate(type, subType, updated, memberId)
            },
          }),
          Cell: getNumberCell({ style: 'decimal' }),
          size: 100
        },
        {
          accessorKey: 'Salary & Tips',
          header: 'Salary & Tips',
          muiEditTextFieldProps: ({ row }) => ({
            type: 'number',
            onBlur: (event) => {
              if (row.index < 0) {
                setNewData((prevNew) => ({
                  ...prevNew,
                  'Salary & Tips': Number(event.target.value),
                }))
                return
              }
              const rowIdx = Number(row.id)
              const updated = [...dataList]
              updated[rowIdx]['Salary & Tips'] = Number(event.target.value)
              onUpdate(type, subType, updated, memberId)
            },
          }),
          Cell: getNumberCell(),
          size: 100
        },
        {
          accessorKey: 'Bonus & Commissions',
          header: 'Bonus & Commissions',
          muiEditTextFieldProps: ({ row }) => ({
            type: 'number',
            onBlur: (event) => {
              if (row.index < 0) {
                setNewData((prevNew) => ({
                  ...prevNew,
                  'Bonus & Commissions': Number(event.target.value),
                }))
                return
              }
              const rowIdx = Number(row.id)
              const updated = [...dataList]
              updated[rowIdx]['Bonus & Commissions'] = Number(event.target.value)
              onUpdate(type, subType, updated, memberId)
            },
          }),
          Cell: getNumberCell(),
          size: 100
        },
      ],
      [dataList, newData, dsElements, dynamicValues],
    )

    const table = useCustomTable({
      columns,
      data: dataList,
      readOnly: readOnly,
      onCreate: handleCreate,
      onDelete: handleDelete,
    })

    return (
      <MaterialReactTable table={table} />
    )
  }
)

EmploymentTable.displayName = 'EmploymentTable'

export default EmploymentTable