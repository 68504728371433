import React from 'react'
import { Box, Paper, SxProps } from '@mui/material'
import { gSx } from 'styles/Theme'

interface CustomContainerProps {
  children: React.ReactNode;
}

const ContainerSmall: React.FC<CustomContainerProps> = ({ children }) => {
  return (
    <Box sx={gSx.CenterVertical}>
      <Box sx={sxContainerSmall}>
        {children}
      </Box>
    </Box>
  )
}

export default ContainerSmall

export const sxContainerSmall: SxProps = {
  margin: 'auto',
  width: '100%',
  maxWidth: 500,
  paddingY: 3,
}
