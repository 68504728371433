import React from 'react'
import Grid2 from '@mui/material/Grid2'
import { UploadButton } from 'components/recorder/UploadButton'
import { OpenWebRecorderButton } from 'components/recorder/WebRecorderButton'
import { Card, Divider } from '@mui/material'

import BotJoinByUrl from '../components/calendar/BotJoinByUrl'

export default function BotJoinUploadRecorderCard() {

  return (
    <Card sx={{ width: '100%', marginBottom: 0 }}>

      <Grid2 container spacing={2}
        direction={{ xs: 'column', md: 'row' }}
        sx={{ width: '100%', justifyContent: 'space-between' }}
      >
        <Grid2 size='grow' sx={{ minWidth: '300px' }}>
          <BotJoinByUrl />
        </Grid2>

        <Divider flexItem />

        <Divider orientation='vertical' flexItem />

        <Grid2 container spacing={2}
          direction={{ sm: 'row', md: 'column' }}
          sx={{ justifyContent: 'center', alignContent: 'center' }}
        >
          <Grid2>
            <UploadButton />
          </Grid2>
          <Grid2>
            <OpenWebRecorderButton />
          </Grid2>
        </Grid2>

      </Grid2>

    </Card>
  )
}
