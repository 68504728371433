import { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import { Dialog, DialogTitle, DialogActions, Box, SxProps, Typography, Grid2 } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { getWebVersion } from 'service/systemApi'
import { Media } from 'service/media'

export default function UpdatePopup() {
  const [open, setOpen] = useState(false)

  const handleVersionCheck = () => {
    const currentVersion = parseInt(process.env.REACT_APP_VERSION ?? '0')
    if (version && currentVersion < +version) {
      return true
    }
    return false
  }

  const handleClose = () => {
    setOpen(false)

    if (handleVersionCheck()) {
      setTimeout(() => {
        setOpen(true)
      }, 1000 * 60 * 10) // 10 minutes
    }
  }

  const { data: version } = useQuery({
    queryKey: ['getWebVersion'], queryFn: getWebVersion, staleTime: 1000 * 60 * 5 // 5 minutes
  })

  useEffect(() => {
    if (open) return
    if (handleVersionCheck()) {
      setOpen(true)
    }
  }, [version])

  return (
    <Dialog
      open={open}
      onClose={handleClose}>
      <DialogTitle sx={{ mx: 'auto', my: 1 }}>
        <Grid2 container sx={{ alignItems: 'center' }}>
          <Box
            component='img'
            aria-label='Finmate AI logo'
            alt='Finmate AI logo'
            sx={sxLogo}
            src={Media.logo100}
          />
          <Typography variant='h3'>
            FinMate AI is ready to update!
          </Typography>
        </Grid2>
      </DialogTitle>
      <DialogActions>
        <Button sx={{ m: 1, py: 1, px: 2 }} variant='outlined' onClick={handleClose}>
          Remind me later
        </Button>
        <Button
          sx={{ m: 1, py: 1, px: 2 }}
          variant='contained'
          onClick={() => {
            window.location.reload()
          }}
        >
          Refresh
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const sxLogo: SxProps = {
  height: 30,
  width: 22,
  resizeMode: 'contain',
  marginRight: '8px',
}