import React, { useEffect, useState } from 'react'
import { Typography, Box, Card, SxProps, Link, Tooltip, Skeleton, Divider, Button } from '@mui/material'
import 'firebase/auth'
import { Media } from '../../service/media'
import { useQuery } from '@tanstack/react-query'
import CloseIcon from '@mui/icons-material/Close'
import { gSx } from '../../styles/Theme'
import { logInfo } from 'log'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { LoadingButton } from '@mui/lab'
import { disconnect, getSalesforceSettings, salesforceOAuth } from 'service/integrations/salesforceApi'
import { sleep } from 'utils'
import { SalesforceApp } from 'client'
import { useNavigate } from 'react-router-dom'
import { Path } from 'routes'
import ContainerMedium from 'components/ContainerMedium'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import SalesforceImportCard from 'components/integrations/salesforce/SalesforceImportCard'

export default function SalesforceSettingsScreen() {
  const navigate = useNavigate()
  const [busy, setBusy] = useState(false)
  const [err, setErr] = useState<string | undefined>()

  useEffect(() => {
    logInfo('Salesforce Settings Screen')
  }, [])

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['getSalesforceSettings'],
    queryFn: getSalesforceSettings,
  })
  const isSignedIn = !!data?.scope

  async function onSignIn() {
    setBusy(true)
    await salesforceOAuth(SalesforceApp.SALESFORCE) // opens pop up
    await sleep(10000) // keep busy for 10 seconds while user signs in at pop up
    setBusy(false)
  }

  async function signout() {
    logInfo('Salesforce Sign out Pressed')
    setBusy(true)
    const res = await disconnect()
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      await refetch()
    }
    setBusy(false)
  }

  return (
    <ContainerMedium>
      <Button
        onClick={() => navigate(Path.Integrations)}
        startIcon={<ArrowBackIcon />}
      >
        Integrations
      </Button>
      <Box sx={{ padding: 1 }} />
      <Typography variant='h3'>Salesforce Integration</Typography>
      <Divider sx={{ marginY: '2vh ' }} />

      <Link href="https://Salesforce.com" rel='noopener' target='_blank'>
        <Box
          component="img"
          sx={sxLogo}
          src={Media.salesforce}
        />
      </Link>

      <Typography>Connect FinMate AI to Salesforce, to easily keep your records up to date. </Typography>
      <Box sx={{ padding: '1vh ' }} />
      <Typography>Effortlessly sync AI-generated Meeting Summaries and Tasks with Salesforce.</Typography>

      <Box sx={{ padding: '1vh ' }} />

      <Typography variant='h6'>Contacts and Leads</Typography>
      <Typography>FinMate AI will search for an existing Salesforce Contact or Lead. If no existing Salesforce Contact or Lead is found, Finmate AI will create a new Salesforce Contact.</Typography>
      <Box sx={{ padding: '1vh ' }} />

      <Typography variant='h6'>Events</Typography>
      <Typography>FinMate AI Meetings Summaries are saved as Salesforce Events.</Typography>
      <Box sx={{ padding: '1vh ' }} />
      <Typography variant='h6'>Tasks</Typography>
      <Typography>FinMate AI Tasks are saved as Salesforce Tasks.</Typography>

      <Box sx={{ paddingY: 1 }} />

      <Card>
        {isLoading ? (
          <Skeleton variant="rectangular" width={'100%'} height={100} />
        ) : (
          isSignedIn ? (
            <Box sx={gSx.RowBetween}>
              <Typography>{`Signed in as `}<b>{`${data.username}`}</b></Typography>
              <Tooltip title="Disconnect Salesforce">
                <LoadingButton
                  aria-label='Disconnect Calendar'
                  onClick={signout}
                  disabled={busy}
                  loading={busy}
                >
                  <CloseIcon />
                </LoadingButton>
              </Tooltip>
            </Box>
          ) : (
            <Box sx={sxConnect}>
              <LoadingButton
                variant='contained' onClick={onSignIn}
                loading={busy}
                disabled={busy}
                sx={{ width: 150 }}
              >
                Connect
              </LoadingButton>
            </Box>
          )
        )}

        {
          err && <Typography sx={{ color: 'tomato' }}>
            {err}
          </Typography>
        }

      </Card >

      {isSignedIn &&
        <SalesforceImportCard app={SalesforceApp.SALESFORCE} />
      }

      <Button target="_blank" rel="noopener"
        href="https://docs.finmate.ai/docs/category/salesforce"
        startIcon={<TextSnippetIcon />}
      >
        Salesforce Docs
      </Button>

    </ContainerMedium>
  )
}

const sxLogo: SxProps = {
  width: 150,
}

const sxConnect: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}
