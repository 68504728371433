import React, { useEffect, useState } from 'react'
import { TextField } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../hooks/useStore'
import { logInfo } from '../../log'

interface Props {
  title?: string
  setTitle: (title: string) => void
}

export default observer(SearchEventBar)
function SearchEventBar({ title, setTitle }: Props) {
  const store = useStore()
  const params = store.User.getEventSearchParams()
  const [lastTitle, setLastTitle] = useState('')

  useEffect(() => { // debouncer code for 1 second delay
    let timer: NodeJS.Timeout
    if (title !== lastTitle) {
      timer = setTimeout(() => {
        if (title && title.length >= 2) {
          store.User.setEventSearchParams({ ...params, title })
        } else if (!title || (title && title.length == 0)) {
          store.User.setEventSearchParams({})
        }
        title && setLastTitle(title)
      }, 1000)
    }
    return () => clearTimeout(timer)
  }, [title, lastTitle])

  async function onChange(t: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setTitle(t.target.value)
  }

  return (
    <TextField
      onChange={onChange}
      label="Filter by Title"
      aria-label="Filter by Title"
      variant="outlined"
      value={title}
      size="small"
      sx={{ minWidth: 200 }}
    />
  )
}
