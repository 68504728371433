import { useEffect } from 'react'
import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth'
import { logErr, logInfo } from 'log'
import { useLocation } from 'react-router-dom'

export default function AuthDeepLinkListener() {
  const auth = getAuth()
  const location = useLocation()

  useEffect(() => {
    // See web/README.md > Email Link Auth for Web
    // isSignInWithEmailLink conflicts with affiliate url params and region param.
    // moved affiliate url params and region param to localstorage
    if (isSignInWithEmailLink(auth, window.location.href)) {
      const email = window.localStorage.getItem('emailForSignIn')
      if (!email)
        return

      signInWithEmailLink(auth, email, window.location.href)
        .then((result) => {
          logInfo('signInWithEmailLink success', result)
        })
        .catch((error) => {
          logErr('signInWithEmailLink failed', error)
          const urlParams = new URLSearchParams(location.search)
          urlParams.delete('apiKey') // from email link sign in 
          urlParams.delete('oobCode') // from email link sign in 
          urlParams.set('emailLinkAuthStatus', 'failed')
          window.location.href = `${location.pathname}?${urlParams.toString()}`
        })
    }
  }, [])

  return null
}