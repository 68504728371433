import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { checkAddPayingSeat, checkSwapPayingSeat, getOrg, updateOrgUser, validateOrgUserEdit } from '../../service/orgsApi'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Alert, Avatar, Box, Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, Snackbar, SxProps, Typography } from '@mui/material'
import { OrgUser, OrgUserRole, ProductName } from '../../client'
import { logInfo } from 'log'
import { addToSubscription, createCheckoutSession, getCustomer, getSubscription, swapSubscription } from 'service/payApi'
import SwapPayingSeatInfo from './SwapPayingSeatInfo'
import AddPayingSeatInfo from './AddPayingSeatInfo'
import Grid from '@mui/material/Grid2'
import CheckIcon from '@mui/icons-material/Check'
import { LoadingButton } from '@mui/lab'
import { getEnterpriseParent } from 'service/orgsApi'
import { gSx } from 'styles/Theme'
import DefinitionsRoleIconDialog from './DefinitionsRoleIconDialog'
import DefinitionsPlanIconDialog from './DefinitionsPlanIconDialog'

interface Props {
  open: boolean
  setOpenDialog: Dispatch<SetStateAction<boolean>>
  user: OrgUser
  setUser: Dispatch<SetStateAction<OrgUser>>
}

export default function OrgUserEditDialog({ open, setOpenDialog, user, setUser }: Props) {
  const queryClient = useQueryClient()
  const [updatedUser, setUpdatedUser] = useState<OrgUser>(user)
  const [isAddPaySeat, setAddSeat] = useState(false)
  const [isSwapPaySeat, setSwapPaySeat] = useState(false)

  const [busy, setBusy] = useState(false)
  const [isSnackSuccess, setSnackSuccess] = useState(false)
  const [validationErrors, setValidationErrors] = useState<string[]>([]) // front end error
  const [err, setErr] = useState<string | undefined>()

  const { data: customer } = useQuery({
    queryKey: ['getCustomer'], queryFn: getCustomer,
    enabled: queryClient.getQueryData(['getCustomer']) === undefined,
  })
  const { data: org } = useQuery({
    queryKey: ['getOrg'], queryFn: getOrg,
    enabled: queryClient.getQueryData(['getOrg']) === undefined,
  })
  const { data: ent } = useQuery({
    queryKey: ['getEnterpriseParent'], queryFn: getEnterpriseParent,
    enabled: queryClient.getQueryData(['getEnterpriseParent']) === undefined,
  })
  const { data: sub } = useQuery({
    queryKey: ['getSubscription'], queryFn: getSubscription,
    enabled: queryClient.getQueryData(['getSubscription']) === undefined,
  })
  const isSubscribed = !!sub?.subscription
  const isYearSub = sub?.interval == "year"

  useEffect(() => {
    const swap = checkSwapPayingSeat(user, updatedUser, org, sub, ent)
    const add = checkAddPayingSeat(user, updatedUser, org, sub, ent)
    setSwapPaySeat(swap)
    setAddSeat(add)

    logInfo('Org User Edit, Change', {
      // user, updatedUser, isSubscribed,
      add, swap
    })

    const validateErrors = validateOrgUserEdit(user, updatedUser, org, sub, customer?.email, ent)
    if (validateErrors.length > 0) {
      setValidationErrors(validateErrors)
      setBusy(false)
      return
    }
    setValidationErrors([])
  }, [updatedUser])

  const onSave = async () => {
    logInfo('Org User Edit, Pressed Save', { user, updatedUser })
    if (validationErrors.length > 0)
      return

    setErr(undefined)
    setBusy(true)

    const standard = updatedUser.org_user_plan == ProductName.STANDARD ? 1 : 0
    const starter = updatedUser.org_user_plan == ProductName.STARTER ? 1 : 0

    let done = false
    if (!isSubscribed && isAddPaySeat) {
      const res = await createCheckoutSession(standard, starter, 0, updatedUser)
      res instanceof Error ? setErr(res.message) : window.location.href = res

    } else if (isSubscribed && isSwapPaySeat) { // switch starter <-> standard
      const res = await swapSubscription(updatedUser)
      res instanceof Error ? setErr(res.message) : done = true

    } else if (isSubscribed && isAddPaySeat) {
      const res = await addToSubscription(standard, starter, 0, updatedUser)
      res instanceof Error ? setErr(res.message) : done = true

    } else {
      const res = await updateOrgUser(updatedUser)
      res instanceof Error ? setErr(res.message) : done = true
    }

    if (done) {
      setSnackSuccess(true)
      await queryClient.invalidateQueries({ queryKey: ['getSubscription'] })
      await queryClient.invalidateQueries({ queryKey: ['getOrg'] })
      await queryClient.invalidateQueries({ queryKey: ['getUser'] })
      setOpenDialog(false)
      setUser({})
    }
    setBusy(false)
  }

  const onClose = () => {
    if (busy)
      return
    setOpenDialog(false)
    setValidationErrors([])
    setErr(undefined)
    setUser({})
  }

  return (
    <>
      <Dialog open={open} onClose={onClose} sx={sxDialog}>
        <DialogTitle>Edit User Role and Plan</DialogTitle>

        <DialogContent>
          <Box sx={gSx.Row}>
            <Avatar alt={user.email} src={user.photo_url} />
            <Box sx={{ padding: 1 }} />
            <Box>
              <Typography variant='h5'>{user.name}</Typography>
              <Typography variant='h5'>{user.email}</Typography>
            </Box>
          </Box>

          <Box sx={{ padding: 1 }} />

          <Grid container sx={{ marginTop: 1 }}>

            <Grid size={6}>
              <Box sx={gSx.Row}>
                <FormControl sx={{ width: 150 }}>
                  <InputLabel id="role">Role</InputLabel>
                  <Select id="role" labelId="role" label="Role"
                    value={updatedUser.org_user_role} disabled={busy}
                    onChange={(e) => setUpdatedUser({
                      ...updatedUser,
                      org_user_role: e.target.value as OrgUserRole
                    })}
                  >
                    <MenuItem value={OrgUserRole.ADMIN}>{OrgUserRole.ADMIN}</MenuItem>
                    <MenuItem value={OrgUserRole.MEMBER}>{OrgUserRole.MEMBER}</MenuItem>
                    <MenuItem value={OrgUserRole.INACTIVE}>{OrgUserRole.INACTIVE}</MenuItem>
                  </Select>
                </FormControl>
                <DefinitionsRoleIconDialog />
              </Box>
            </Grid>
            <Grid size={6}>
              <Box sx={gSx.Row}>
                <FormControl sx={{ width: 150 }}>
                  <InputLabel id="plan">Plan</InputLabel>
                  <Select id="plan" labelId="plan" label="Plan"
                    value={updatedUser.org_user_plan} disabled={busy}
                    onChange={(e) => setUpdatedUser({
                      ...updatedUser,
                      org_user_plan: e.target.value as ProductName
                    })}
                  >
                    <MenuItem value={ProductName.STANDARD}>{ProductName.STANDARD}</MenuItem>
                    <MenuItem value={ProductName.STARTER}>{ProductName.STARTER}</MenuItem>
                    <MenuItem value={ProductName.LITE}>{ProductName.LITE}</MenuItem>
                    <MenuItem value={ProductName.EXPIRED}>{ProductName.EXPIRED}</MenuItem>
                    {user.org_user_plan == ProductName.FREE_TRIAL &&
                      <MenuItem value={ProductName.FREE_TRIAL}>{ProductName.FREE_TRIAL}</MenuItem>
                    }
                  </Select>
                </FormControl>

                <DefinitionsPlanIconDialog />
              </Box>

            </Grid>

          </Grid>

          <Box sx={{ padding: 1 }} />

          {isSwapPaySeat && !isYearSub &&
            <SwapPayingSeatInfo
              org={org}
              sub={sub}
              target={updatedUser}
              customer={customer}
            />
          }
          {isAddPaySeat && !isYearSub &&
            <AddPayingSeatInfo
              sub={sub}
              updatedUser={updatedUser}
              customer={customer}
            />
          }

          {validationErrors.map(e =>
            <Typography key={e} color={'tomato'} >{e}</Typography>
          )}

        </DialogContent>

        {err && <DialogActions>
          <Typography color={'tomato'} >{err}</Typography>
        </DialogActions>
        }

        <DialogActions>
          <Button sx={{ paddingX: 3 }} onClick={onClose} disabled={busy}>
            Cancel
          </Button>

          <LoadingButton variant='contained' sx={{ paddingX: 5 }}
            onClick={onSave} loading={busy}
            disabled={busy || validationErrors.length > 0}
          >
            Save
          </LoadingButton>
        </DialogActions>

      </Dialog >

      <Snackbar open={isSnackSuccess} autoHideDuration={3000}
        onClose={() => setSnackSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert variant="filled" severity='success' sx={{ width: '100%' }}
          icon={<CheckIcon fontSize="inherit" />}
          onClose={() => setSnackSuccess(false)}
        >
          User has been successfully updated!
        </Alert>
      </Snackbar>
    </>
  )
}

const sxDialog: SxProps = {
  '& .MuiDialog-paper': {
    width: '550px', // Set custom width
    maxWidth: '500px', // Ensure it doesn't exceed 800px
  },
}
