import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Typography, FormControl, Select, MenuItem, FormGroup, Divider, Box, Tooltip } from '@mui/material'
import 'firebase/auth'
import { ClientDetail, FlowInput, FlowInputConfig } from 'client'
import Grid from '@mui/material/Grid2'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { gSx } from 'styles/Theme'
import { getContactName } from 'service/clientApi'

interface Props {
  input: FlowInput
  setFlowInputs: Dispatch<SetStateAction<FlowInputConfig[]>>;
  contacts?: ClientDetail[]
}

export default function SalesforceFlowContactOpp({ input, contacts, setFlowInputs }: Props) {
  const [contact, setContact] = useState<ClientDetail | undefined>(undefined)

  useEffect(() => { // initialize
    if (contacts && contacts?.length > 0)
      setContact(contacts[0])
    else {
      setContact(undefined)
    }
  }, [contacts])

  useEffect(() => {
    if (input.sObjectType === "Contact") {
      const newFlowInput: FlowInputConfig = { input, contact }
      setFlowInputs(prev => [...prev.filter(o => o.input !== input), newFlowInput])
    } else if (input.sObjectType === "Opportunity") {
      const newFlowInput: FlowInputConfig = { input, opportunity: contact }
      setFlowInputs(prev => [...prev.filter(o => o.input !== input), newFlowInput])
    }

  }, [contact])

  return (
    <Grid container>
      <Grid size={4}>
        <Box sx={gSx.Row}>
          <Typography>{input.name}</Typography>
          <Box sx={{ paddingX: 0.5 }} />
          <Tooltip title={`Defined by Contacts at the top of this page`}>
            <InfoOutlinedIcon fontSize='small' />
          </Tooltip>
        </Box>

      </Grid>
      <Grid size={8}>

        {contact ? (
          <FormGroup>
            <FormControl fullWidth>
              <Select id="contact" size='small'
                value={contact?.id || ''}
                onChange={e => {
                  const selectedContact = contacts?.find(c => c.id === e.target.value)
                  setContact(selectedContact)
                }}
              >
                {contacts?.map(c =>
                  <MenuItem key={c.id} value={c.id}>
                    {getContactName(c)}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </FormGroup>

        ) : <Typography>Please add a contact at the top</Typography>
        }

      </Grid>
      <Grid size={12}>
        <Divider sx={{ marginY: 1 }} />
      </Grid>
    </Grid>
  )
}
