import React, { useEffect, useState } from "react"
import { FinmateSubscription, OrgBase, ProductName } from "../../client"
import { Card, CardHeader, Skeleton, Typography } from "@mui/material"
import Grid from "@mui/material/Grid2/Grid2"
import { liteAvailableForOrg, standardAvailableForOrg, starterAvailableForOrg } from "../../service/orgsApi"
import { logInfo } from "log"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { getEnterpriseParent } from "service/orgsApi"

interface Props {
  org?: OrgBase
  sub?: FinmateSubscription
  isLoading: boolean
}

export default function PlansAvailable({ org, sub, isLoading }: Props) {

  const { data: ent } = useQuery({
    queryKey: ['getEnterpriseParent'], queryFn: getEnterpriseParent,
  })

  const standardAvail = standardAvailableForOrg(org, sub, ent)
  const starterAvail = starterAvailableForOrg(org, sub, ent)
  const liteAvail = liteAvailableForOrg(org, sub)

  useEffect(() => {
    logInfo('PlansAvailable', { standardAvail, starterAvail, liteAvail })
  }, [ent])

  return (
    <Card sx={{ flexGrow: 1 }}>
      <CardHeader title='Seats Available' />
      <Grid container alignItems="center">
        <Grid size={8}>
          <Typography variant='body2'>
            Standard Plan
          </Typography>
          <Typography variant='body2'>
            Starter Plan
          </Typography>
          <Typography variant='body2'>
            Lite Plan
          </Typography>
        </Grid>

        <Grid size={4}>
          {isLoading
            ? <Skeleton variant="rectangular" width={'100%'} height={20} />
            : (
              <>
                <Typography variant='body2' align="right" sx={{ paddingRight: 2 }}>
                  {`${standardAvail}`}
                </Typography>
                <Typography variant='body2' align="right" sx={{ paddingRight: 2 }}>
                  {`${starterAvail}`}
                </Typography>
                <Typography variant='body2' align="right" sx={{ paddingRight: 2 }}>
                  {`${liteAvail}`}
                </Typography>
              </>
            )
          }
        </Grid>
      </Grid>
    </Card>
  )
}
