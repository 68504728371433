import { ActionItem, ClientDetail, WealthboxContactType, WealthboxProfile, WealthboxWorkflow } from "client"
import { logErr, logInfo } from "log"
import { Api, config } from "service/api"
import { getErrorMessage } from "utils"
import queryString from 'query-string'

// openapi cannot generate below correctly. create own.
export type WealthboxVisibility = "Private" | "Everyone" | string;

export async function wealthboxOAuth() {
  const windowName = 'PopupWindow'
  const windowFeatures = 'width=500,height=700'

  const queryParams = {
    client_id: config.wealthboxClientId,
    redirect_uri: config.wealthboxRedirect,
    response_type: "code",
    scope: "login data",
  }

  try {
    const baseUrl = `https://app.crmworkspace.com/oauth/authorize`
    const url = `${baseUrl}?${queryString.stringify(queryParams)}`
    window.open(url, windowName, windowFeatures)
  } catch (e) {
    logErr('wealthboxOAuth sign in')
  }
}

export async function saveWealthboxTokens(code: string): Promise<boolean | Error> {
  try {
    logInfo('wealthbox authenticate')
    const api = await Api()
    await api.wealthbox.authWealthBox(code)
    return true
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('wealthbox authenticate', { err })
    return err
  }
}

export async function getWealthboxProfile(): Promise<WealthboxProfile | null> {
  try {
    const api = await Api()
    const res = await api.wealthbox.profile()
    if (res == undefined)
      return null
    return res
  } catch (e) {
    logErr('getWealthboxProfile', { e })
  }
  return null
}

export async function sendNotesToWealthbox(
  eventId: string,
  notes: string,
  contact?: ClientDetail,
  wb_event_id?: number,
): Promise<undefined | Error> {
  if (!contact)
    return new Error('missing contact')
  try {
    logInfo('Wealthbox sendNotes', { contact, eventId })
    const api = await Api()
    await api.wealthbox.sendWealthboxNotes({
      event_id: eventId,
      notes,
      contact,
      wb_event_id
    })
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('Wealthbox sendNotes', { err })
    return err
  }
}

export async function sendTasksToWealthbox(
  eventId?: string,
  tasks?: ActionItem[],
): Promise<undefined | Error> {
  if (!eventId)
    return new Error('missing event Id')

  try {
    logInfo('Wealthbox sendTasks', { eventId })
    const api = await Api()
    await api.wealthbox.sendWealthboxTasks({
      event_id: eventId,
      tasks
    })
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('Wealthbox sendTasks', { err })
    return err
  }
}

export async function startWealthboxWorkflows(
  eventId?: string,
  contact?: ClientDetail,
  workflowTemplatesStart?: WealthboxWorkflow[],
): Promise<undefined | Error> {

  if (!contact)
    return new Error('missing contact')
  if (!eventId)
    return new Error('missing event Id')

  try {
    logInfo('Wealthbox startWorkflows', { contact, eventId })
    const api = await Api()
    await api.wealthbox.startWealthboxWorkflows({
      event_id: eventId,
      contact,
      workflow_templates_start: workflowTemplatesStart,
    })
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('Wealthbox startWorkflows', { err })
    return err
  }
}

export async function completeStepWealthboxWorkflows(
  eventId?: string,
  workflowStepsToComplete?: WealthboxWorkflow[],
): Promise<undefined | Error> {

  if (!eventId)
    return new Error('missing event Id')

  const validateSteps = validateWorkflowStepsToComplete(workflowStepsToComplete)
  if (validateSteps instanceof Error) {
    return validateSteps
  }

  try {
    logInfo('Wealthbox complete workflow step', { workflowStepsToComplete, eventId })
    const api = await Api()
    await api.wealthbox.completeWealthboxStepWorkflows({
      event_id: eventId,
      workflow_steps_to_complete: workflowStepsToComplete,
    })
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('Wealthbox complete workflow step', { err })
    return err
  }
}

export function validateWorkflowStepsToComplete(steps?: WealthboxWorkflow[]) {
  if (!steps || steps.length == 0)
    return

  for (const step of steps) {
    const outcomes = step.active_step?.workflow_outcomes
    const hasAvailableOutcomes = outcomes && outcomes.length > 0
    const isSelected = !!step.active_step?.workflow_outcome_selected

    if (hasAvailableOutcomes && !isSelected) {
      return new Error(`Missing outcome for ${step.active_step?.name}`)
    }
  }
}

export async function importWealthboxContacts(contactType: WealthboxContactType): Promise<number | Error> {
  try {
    logInfo('Wealthbox importContacts', { contactType })
    const api = await Api()
    return await api.wealthbox.importWealthboxContacts(contactType)
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('Wealthbox importContacts', { err })
    return err
  }
}

export async function disconnectWealthbox(): Promise<undefined | Error> {
  try {
    const api = await Api()
    await api.wealthbox.disconnectWealthBox()
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('wealthbox disconnect', { err })
    return err
  }
}

export async function getWealthboxSettings() {
  try {
    const api = await Api()
    return await api.wealthbox.getWealthboxSettings()
  } catch (e) {
    logErr('getWealthboxSettings', { e })
  }
}

export async function setWealthboxVisibility(visibility: WealthboxVisibility) {
  try {
    const api = await Api()
    return await api.wealthbox.setVisibility(visibility)
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('wealthbox set visibility', { err })
    return err
  }
}

export async function setWealthboxShowRecurringEvents(show: boolean) {
  try {
    const api = await Api()
    return await api.wealthbox.setShowRecurringCalEvents(show)
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('setWealthboxShowRecurringEvents', { err })
    return err
  }
}

export async function getWealthboxWorkflowTemplates() {
  try {
    const api = await Api()
    return await api.wealthbox.getWealthboxWorkflowTemplates()
  } catch (e) {
    logErr('getWealthboxWorkflowTemplates', { e })
  }
}

export async function getWealthboxWorkflows() {
  try {
    const api = await Api()
    return await api.wealthbox.getWealthboxWorkflows()
  } catch (e) {
    logErr('getWealthboxWorkflows', { e })
  }
}

export async function sortWealthboxWorkflows(allWorkflows?: WealthboxWorkflow[], client?: ClientDetail, wealthbox_id?: number) {
  // sort with "related to" and "assigned to", on top

  if (!allWorkflows) {
    return allWorkflows || []
  }

  const sortedWorkflows = allWorkflows.sort((a, b) => {

    if (client) {
      const isClientWealthboxMatchA = a.linked_to?.id === client.wealthbox_id
      const isClientWealthboxMatchB = b.linked_to?.id === client.wealthbox_id
      if (isClientWealthboxMatchA && !isClientWealthboxMatchB) {
        return -1
      } else if (!isClientWealthboxMatchA && isClientWealthboxMatchB) {
        return 1
      }
    }

    if (wealthbox_id) {
      const isUserAssignedA = a.active_step?.assigned_to === wealthbox_id
      const isUserAssignedB = b.active_step?.assigned_to === wealthbox_id
      if (isUserAssignedA && !isUserAssignedB) {
        return -1
      } else if (!isUserAssignedA && isUserAssignedB) {
        return 1
      }
    }

    return 0
  })

  return sortedWorkflows
}

export async function getWealthboxContact(wealthboxId?: number) {
  if (!wealthboxId)
    return
  try {
    const api = await Api()
    return await api.wealthbox.getWealthboxContact(wealthboxId)
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('getWealthboxContact', { err })
  }
}

export async function getWealthboxEvents(startDate: string | undefined, endDate: string | undefined) {
  try {
    const api = await Api()
    return await api.wealthbox.getWealthboxEvents(startDate, endDate)
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('getWealthboxEvents', { err })
  }
}

export async function addWealthboxIdsHouseholds(event_id: string) {
  try {
    const api = await Api()
    return await api.wealthbox.addWealthboxIdsAndHouseholds(event_id)
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('addWealthboxIdsHouseholds', { err })
    return err
  }
}