import { EmailBase } from "client"
import { Api } from "./api"
import { logErr } from "log"
import { getErrorMessage } from "utils"

export async function emailMeetingDetails(email: EmailBase) {
  try {
    const api = await Api()
    return await api.communications.emailMeetingDetails(email)
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('emailMeetingDetails', { err })
    return err
  }
}