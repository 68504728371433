import React from 'react'
import { Box, SxProps, Card, Typography, Skeleton } from '@mui/material'
import { EventBase } from '../../client'
import { getLongestMonologues, secondsToMinSec } from '../../utils/eventAnalysis'
import { gColor } from '../../styles/Theme'
import { isObjectEmpty } from 'utils'
import Grid from '@mui/material/Grid2'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getEventTranscript } from 'service/eventApi'

interface Props {
  event: EventBase
}

export default function LongestMonologue({ event }: Props) {
  const queryClient = useQueryClient()

  const { data: transcript } = useQuery({
    queryKey: ['getEventTranscript', event?.id],
    queryFn: async () => await getEventTranscript(event.id),
    enabled: queryClient.getQueryData(['getEventTranscript', event.id]) === undefined,
  })
  const monologues = getLongestMonologues(transcript?.entries)

  return (
    <Card sx={sxCard}>
      <Typography variant='h4'>Longest Monologues</Typography>
      <Box sx={{ paddingY: '1vh' }} />

      {
        isObjectEmpty(monologues) ? (
          <Skeleton variant="rectangular" width={'100%'} height={150} />
        ) : (
          Object.entries(monologues).map(([speaker, seconds]) => {

            const timeStr = seconds ? secondsToMinSec(seconds) : undefined
            return (
              <Grid container key={speaker}>
                <Grid size={6}>
                  <Typography variant='h5' sx={sxTime}>{speaker}</Typography>
                  <Typography>{timeStr}</Typography>
                </Grid>
                <Grid size={6}>
                  {
                    seconds < 10 * 60 ? (
                      <Typography>Great job on keeping an engaging conversation!</Typography>
                    ) : (
                      <Typography>
                        That is a long monologue. Consider checking for understanding with your client
                      </Typography>
                    )
                  }
                </Grid>
                <Grid size={12} sx={{ marginBottom: 3 }}>

                </Grid>
              </Grid>
            )
          })
        )
      }
    </Card>
  )
}

const sxCard: SxProps = {
}

const sxTime: SxProps = {
  color: gColor.green500
}
