import React, { useState } from 'react'
import { Box, Button, ButtonBase, Card, Typography } from '@mui/material'
import { ClientDetail, EventBase } from 'client'
import { gSx } from 'styles/Theme'
import ClientEventDialog from 'components/client/ClientCardForEventDialog'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import { ContactChip } from 'components/client/ContactChip'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1'
import { checkEventEditor, isContactInList } from 'service/eventApi'

interface Props {
  event: EventBase
  contacts?: ClientDetail[]
  disabled: boolean
}

export default function CrmContactCard({ event, contacts, disabled }: Props) {
  const hasContacts = contacts && contacts?.length > 0
  const [showAddClientDialog, setShowAddClientDialog] = useState(false)

  const isEditor = checkEventEditor(event)

  interface ChipProps {
    contact: ClientDetail
  }
  function ContactChipWrapper({ contact }: ChipProps) {
    const isRemovable = isContactInList(event.contacts, contact)
    return (
      <Box sx={{ paddingX: 0.5 }}>
        <ContactChip contact={contact} eventId={event?.id} showRemove={isEditor && isRemovable} />
      </Box>
    )
  }

  return (
    <Card sx={{ padding: 1.8 }}>
      <Box sx={gSx.RowBetween}>
        <Box sx={gSx.Row}>
          <PersonOutlineOutlinedIcon sx={{ marginRight: 0.7 }} />
          <Typography variant='h5'>Contacts</Typography>
        </Box>
        <Button variant='outlined' aria-label='add'
          onClick={() => setShowAddClientDialog(true)}
        >
          <PersonAddAlt1Icon fontSize='small' />
        </Button>
      </Box>

      <Box sx={{ padding: 1 }} />

      <Box sx={gSx.Row}>
        {contacts?.map(c => <ContactChipWrapper key={c.id} contact={c} />)}
      </Box>

      {!disabled && !hasContacts &&
        <>
          <ButtonBase onClick={() => setShowAddClientDialog(true)}>
            <Typography sx={{ color: 'red', cursor: 'pointer', fontWeight: 'bold' }}
            >
              Add a contact
            </Typography>
          </ButtonBase>
          <Typography >
            The Email should match your existing CRM contact, or Finmate will automatically create a new CRM contact for you.
          </Typography>
        </>
      }

      <ClientEventDialog
        event={event}
        open={showAddClientDialog}
        onClose={() => setShowAddClientDialog(false)}
      />

    </Card >
  )
}
