import React, { useEffect, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Box, Button, Card, Checkbox, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, List, ListItem, ListItemButton, SxProps, Tooltip, Typography } from '@mui/material'

import { Api } from '../../service/api'
import { ModuleSubPrompt, SubPromptTemplate } from '../../client'
import { SampleOutputDialog, SampleOutputHighlightOptions } from './SampleOutputDialog'
import { logInfo } from 'log'

interface Props {
  open: boolean
  onClose: () => void;
  onUpdateClose: (updatedSections: ModuleSubPrompt[]) => void;
  currentSections: ModuleSubPrompt[]
}

enum AddedStatus {
  Excluded = 0,
  Added,
  ToBeAdded,
  ToBeExcluded,
}

type SubPromptTemplateSelection = SubPromptTemplate & {
  added: AddedStatus;
};

const MeetingTypeSectionsDialog: React.FC<Props> = ({ open, onClose, onUpdateClose, currentSections }) => {
  const [sectionList, setSectionList] = useState<SubPromptTemplateSelection[]>([])
  const [openAddCondition, setOpenAddCondition] = useState(false)

  const [openSampleOutputDialog, setOpenSampleOutputDialog] = useState(false)
  const [sampleHighlightOptions, SetSampleHighlightOptions] = useState<SampleOutputHighlightOptions>({})
  const [subpromptTemplateId, setSubpromptTemplateId] = useState("")
  const [sectionTitle, setSectionTitle] = useState("")

  const { data: sections, isLoading, isError } = useQuery({
    queryKey: ['getAvailableMeetingTypeSections'],
    queryFn: runQuery,
  })

  async function runQuery() {
    const api = await Api()
    return await api.customizations.getAvailableMeetingTypeSections()
  }

  function initSectionList() {
    if (sections && currentSections) {
      const newSectionList = [] as SubPromptTemplateSelection[]
      sections.forEach((section) => {
        const newSection = section as SubPromptTemplateSelection
        if (currentSections.find(prompt => prompt.template_id === section.id)) {
          newSection.added = AddedStatus.Added
        } else {
          newSection.added = AddedStatus.Excluded
        }
        newSectionList.push(newSection)
      })
      newSectionList.sort((a, b) => a.added - b.added)
      setSectionList(newSectionList)
    }
  }

  useEffect(() => {
    initSectionList()
  }, [sections, currentSections, open])

  const handleUpdateConfirm = () => {
    let updatedSections = Array.from(currentSections)
    const templateIdsToExcludeSet = new Set(sectionList.map(item => {
      if (item.added == AddedStatus.ToBeExcluded) return item.id
    }))
    updatedSections = updatedSections.filter(item => !templateIdsToExcludeSet.has(item.template_id))

    sectionList.forEach((section) => {
      if (section.added == AddedStatus.ToBeAdded && section.id) {
        const newSection: ModuleSubPrompt = {
          template_id: section.id,
          template_ui_title: section.ui_title,
          block_option_change: section.block_option_change, // TODO: confirm in BE
          options: {
            ...section.default_options
          }
        }
        updatedSections.push(newSection)
      }
    }
    )
    if (updatedSections.length == 0) {
      setOpenAddCondition(true)
      return
    }
    logInfo("Module Section Updated", { updatedSectionsLength: updatedSections.length })
    onUpdateClose(updatedSections)
  }

  function handleAddConditionClose() {
    setOpenAddCondition(false)
  }

  function handleAddChange(sectionId: string, currentAddedState: AddedStatus) {

    let newState = AddedStatus.ToBeAdded
    if (currentAddedState == AddedStatus.ToBeAdded) {
      newState = AddedStatus.Excluded
    } else if (currentAddedState == AddedStatus.Excluded) {
      newState = AddedStatus.ToBeAdded
    } else if (currentAddedState == AddedStatus.ToBeExcluded) {
      newState = AddedStatus.Added
    } else if (currentAddedState == AddedStatus.Added) {
      newState = AddedStatus.ToBeExcluded
    }
    setSectionList(prevSectionList =>
      prevSectionList.map(item =>
        item.id === sectionId ? { ...item, added: newState } : item
      )
    )
  }

  function handleClickItem(e: React.MouseEvent<HTMLDivElement, MouseEvent>, sectionId: string, currentAddedState: AddedStatus) {
    if ((e.target as HTMLElement).closest('.icon-button-area')) return
    handleAddChange(sectionId, currentAddedState)
  }

  const openSampleOutput = (submodule_template_id: string, sectionTitle: string, highlightOptions: SampleOutputHighlightOptions) => {
    setSubpromptTemplateId(submodule_template_id)
    setSectionTitle(sectionTitle)
    SetSampleHighlightOptions(highlightOptions)
    setOpenSampleOutputDialog(true)
  }
  const closeSampleOutput = () => {
    setOpenSampleOutputDialog(false)
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="meeting-type-sections-dialog"
        maxWidth='md'
        fullWidth={true}
      >
        <DialogTitle id="meeting-type-sections-dialog-title">
          Add Meeting Type Sections
        </DialogTitle>
        <DialogContent>
          <Card>
            {isLoading ? (
              <CircularProgress />
            ) : isError ? (
              <div>Error loading sections</div>
            ) : (
              <List disablePadding={true}>
                {sectionList?.map((section) => (
                  <ListItemButton key={section.id} selected={section.added == AddedStatus.Added || section.added == AddedStatus.ToBeAdded} sx={sxListItem}
                    onClick={(e) => handleClickItem(e, section.id ?? "", section.added)}>
                    <Checkbox
                      checked={section.added == AddedStatus.Added || section.added == AddedStatus.ToBeAdded}
                      onChange={(e) => handleAddChange(section.id ?? "", section.added)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <Box sx={{
                      padding: 1,
                      width: '100%', // Ensure the box takes full width
                      boxSizing: 'border-box', // Include padding in width calculation  
                    }}
                    >
                      <Typography variant={section.added ? "h6" : "h6"} >
                        {section.ui_title}
                      </Typography>
                      <Typography variant="subtitle1" sx={{ marginY: 1, fontSize: '0.875rem', color: 'text.secondary' }}>
                        Heading in Summary: <b>{section.heading}</b>
                      </Typography>
                      <Typography variant="body1" sx={{ marginY: 1 }}>
                        {section.ui_description}
                      </Typography>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: 1 }}>
                        {section.tags?.map((tag) => (
                          <Chip key={tag} label={tag} size="small" />
                        ))}
                      </Box>
                    </Box>
                    <Box
                      className="sample-icon-button-area"
                      sx={sxSampleButton}
                    >
                      <Tooltip title={`View Sample Results`}>
                        <IconButton aria-label='info'
                          onClick={() => {
                            if (section && section.id && section.ui_title) {
                              openSampleOutput(section.id, "Section: " + section.ui_title, { Format: true })
                            }
                          }
                          }>
                          <InfoOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </ListItemButton>
                ))}
              </List>
            )}
          </Card>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleUpdateConfirm} disabled={isLoading || isError}>
            Add
          </Button>
        </DialogActions>
        <Dialog
          open={openAddCondition}
          keepMounted
          onClose={handleAddConditionClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"Section Selection Required"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Please select at least one section for your meeting template.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAddConditionClose}>OK</Button>
          </DialogActions>
        </Dialog>
        {
          subpromptTemplateId && subpromptTemplateId.length > 0 && (
            <SampleOutputDialog sectionTitle={sectionTitle} open={openSampleOutputDialog} onClose={closeSampleOutput} subpromptTemplateId={subpromptTemplateId} highlightOptions={sampleHighlightOptions} />
          )
        }

      </Dialog>
    </div >
  )
}

const sxListItem: SxProps = { border: '1px solid #e0e0e0', borderRadius: 2, marginBottom: 1, padding: 1.5 }

const sxSampleButton: SxProps = {
  position: 'absolute',
  bottom: 0,
  right: 0,
  padding: 1,
}

export default MeetingTypeSectionsDialog
