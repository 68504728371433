import React, { useState } from 'react'
import { Box, SxProps, Typography, Avatar, Tooltip, Divider, Menu, MenuList, MenuItem, ListItemIcon, ListItemText, LinearProgress, Snackbar, Alert } from '@mui/material'
import { ClientDetail, ClientIndex, SalesforceApp, WealthboxType } from 'client'
import { CrmClientBadge } from './CrmClientBadge'
import { grey } from '@mui/material/colors'
import { getInitial } from 'utils'
import { gSx } from 'styles/Theme'
import { getContactName } from 'service/clientApi'
import { removeContactFromEvent } from 'service/eventApi'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getWealthboxContact, getWealthboxProfile } from 'service/integrations/wealthboxApi'
import { getSalesforceIcon, getSalesforceSettings } from 'service/integrations/salesforceApi'
import { getRedtailSettings } from 'service/integrations/redtailApi'
import { Media } from 'service/media'

interface Props {
  eventId?: string
  contact?: ClientDetail
  showRemove?: boolean
}

export function ContactChip({ eventId, contact, showRemove }: Props) {
  const queryClient = useQueryClient()

  const { data: sf } = useQuery({
    queryKey: ['getSalesforceSettings'], queryFn: getSalesforceSettings,
    enabled: queryClient.getQueryData(['getSalesforceSettings']) === undefined,
  })
  const salesforceApp = sf?.app ?? SalesforceApp.SALESFORCE

  const isWealthboxHousehold = !!(contact && contact.wealthbox_type == WealthboxType.HOUSEHOLD
  )
  const { data: wbContact } = useQuery({
    queryKey: ['getWealthboxContact', contact?.wealthbox_id],
    queryFn: async () => await getWealthboxContact(contact?.wealthbox_id),
    enabled: queryClient.getQueryData(['getWealthboxContact', contact?.wealthbox_id]) === undefined && isWealthboxHousehold,
  })

  const [busy, setBusy] = useState(false)
  const [err, setErr] = useState<string | undefined>()
  const [successSnack, setSuccessSnack] = useState(false)

  async function onRemove() {
    if (!eventId || !contact?.id)
      return
    setBusy(true)
    setErr(undefined)

    const res = await removeContactFromEvent(eventId, contact.id)
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: ['getEvent', eventId] }),
      ])
      setSuccessSnack(true)
    }
    setBusy(false)
  }

  if (!contact)
    return

  const name = getContactName(contact)

  return (
    <>
      <Tooltip
        arrow
        slotProps={{ tooltip: { sx: sxTooltip } }}
        title={
          <Box sx={sxRowTop}>

            <Box sx={{ paddingLeft: 1 }}>
              <Typography><b>{getContactName(contact)}</b></Typography>
              {contact?.email && <Typography>{contact?.email}</Typography>}

              {contact.redtail_id &&
                <Box sx={{ ...gSx.Row, marginTop: 1 }}>
                  <Tooltip title={`Redtail Contact (ID: ${contact.redtail_id})`}>
                    <Box
                      component="img"
                      sx={sxIcon}
                      src={Media.redtailIconWhite}
                      alt="Redtail"
                    />
                  </Tooltip>
                  {contact.redtail_type && <Typography>{contact?.redtail_type}</Typography>}
                </Box>
              }

              {contact.wealthbox_id &&
                <Box sx={{ ...gSx.Row, marginTop: 1 }}>
                  <Tooltip title={`Wealthbox Contact (ID: ${contact.wealthbox_id})`}>
                    <Box
                      component="img"
                      sx={sxIcon}
                      src={Media.wealthboxIcon}
                      alt="Wealthbox"
                    />
                  </Tooltip>
                  {contact.wealthbox_type && <Typography>{contact?.wealthbox_type}</Typography>}
                </Box>
              }

              {contact.salesforce_id &&
                <Box sx={{ ...gSx.Row, marginTop: 1 }}>
                  <Tooltip title={`${salesforceApp} Contact (ID: ${contact.salesforce_id})`}>
                    <Box
                      component="img"
                      sx={sxIcon}
                      src={getSalesforceIcon(salesforceApp)}
                      alt="Salesforce"
                    />
                  </Tooltip>
                  {contact.salesforce_type && <Typography>{contact?.salesforce_type}</Typography>}
                </Box>
              }

              {wbContact?.members &&
                <>
                  <Typography sx={{ marginTop: 1 }}><b>Members</b></Typography>
                  {wbContact?.members?.map(m =>
                    <Typography key={m.id}>{
                      `${m.first_name} ${m.last_name} (${m.title})`
                    }
                    </Typography>
                  )}
                </>
              }
            </Box>

            {showRemove &&
              <>
                <Divider sx={{ marginY: 1 }} />
                <MenuList>
                  <MenuItem onClick={onRemove} disabled={busy}>
                    <ListItemIcon>
                      <DeleteOutlineIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Remove</ListItemText>
                  </MenuItem>
                </MenuList>
              </>
            }
            {busy && <LinearProgress />}
          </Box >
        }
      >
        <Box sx={gSx.Crm.Chip()}>
          {contact.photo_url
            ? <Avatar alt={name} src={contact.photo_url} sx={sxAvatar} />
            : <Avatar sx={sxAvatar}>{getInitial(name)}</Avatar>
          }
          <Typography sx={{ paddingX: 0.5, whiteSpace: 'nowrap' }}>{name}</Typography>
        </Box>
      </Tooltip>

      <Snackbar open={successSnack} autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => setSuccessSnack(false)}
      >
        <Alert variant="filled" severity="success">
          Contact Removed from Event
        </Alert>

      </Snackbar>

      <Snackbar open={!!err} autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => setErr(undefined)}
      >
        <Alert variant="filled" severity="error" >{err}</Alert>
      </Snackbar>
    </>
  )
}

const sxAvatar: SxProps = {
  width: 25, height: 25,
  fontSize: 12, marginRight: 0.5,
  color: grey[700],
  fontWeight: 'bold',
}

const sxTooltip: SxProps = {
  color: 'black',
  backgroundColor: 'white',
  padding: 1,
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  border: `1px solid ${grey[300]}`
}

const sxRowTop: SxProps = {
  display: 'flex',
  flexDirection: 'column'
}

const sxIcon: SxProps = {
  marginRight: 1,
  width: 25,
  height: 25,
}