import React, { ChangeEvent, useEffect, useState } from 'react'
import { Typography, Box, Container, Button, SxProps, Divider } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import 'firebase/auth'
import { Path } from '../routes'
import { logInfo } from '../log'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { SkeletonRows } from 'components/Skeletons'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { CalendarEvent } from 'client'
import CalendarEventSimple from 'components/calendar/CalendarEventSimple'
import { getCalendarEvents, GetCalendarUser } from 'service/calendarApi'
import ShowWealthboxRecurringToggle from 'components/calendar/ShowWealthboxRecurringToggle'
import CalendarConnectOptions from 'components/calendar/CalendarConnectOptions'
import CalendarMenu from 'components/calendar/CalendarMenu'

export default function Calendar() {
  const navigate = useNavigate()

  useEffect(() => {
    logInfo('Calendar Screen')
  }, [])

  const { data: calUser, isLoading: isLoadingCalUser } = useQuery({
    queryKey: ['GetCalendarUser'], queryFn: GetCalendarUser,
  })
  const google = calUser?.connections?.filter(conn => conn.platform === 'google')[0]
  const microsoft = calUser?.connections?.filter(conn => conn.platform === 'microsoft')[0]
  const isGoogleConnected = !!google?.connected
  const isMicrosoftConnected = !!microsoft?.connected

  const { data: calEvents, isLoading: isLoadingCalEvents } = useQuery({
    queryKey: ['getCalendarEvents'], queryFn: getCalendarEvents,
  })
  const anyCalConnected =
    (isGoogleConnected || isMicrosoftConnected || calEvents?.is_redtail_signed_in) ?? false
  const isLoading = isLoadingCalUser || isLoadingCalEvents

  useEffect(() => {
    if (!isLoading && !anyCalConnected) {
      navigate(Path.CalendarAuth)
    }
  }, [isLoading, anyCalConnected])

  return (
    <Container sx={{ paddingY: 3 }}>
      <CalendarMenu
        calEvents={calEvents}
        title={<Typography variant='h1'>Calendar Events</Typography>}
      />

      <ShowWealthboxRecurringToggle cal={calEvents} />

      {calEvents?.is_wealthbox_signed_in && (
        <Typography variant='caption'>
          Wealthbox recurring events may have trouble showing up in this calendar
        </Typography>
      )}

      <Divider sx={{ marginY: 1 }} />

      {isLoading ? (
        <SkeletonRows count={10} height={100} />
      ) : anyCalConnected ? (
        calEvents?.events?.length === 0 ? (
          <Typography sx={{ mt: 5 }}>No upcoming events</Typography>
        ) : (
          calEvents?.events?.map((event) => {
            const date = new Date(event.start_time ?? '')
            const readable = date.toLocaleDateString('en-US', {
              weekday: 'short',
              month: 'short',
              day: 'numeric',
              year: 'numeric',
            })
            return (
              <Box key={event.id}>
                {ShowDateIfEarliest(date, calEvents.events) && (
                  <Box sx={sxDate}>
                    <CalendarTodayIcon />
                    <Box sx={{ paddingX: '1vh' }} />
                    <Typography>{readable}</Typography>
                  </Box>
                )}
                <CalendarEventSimple calEvent={event} />
              </Box>
            )
          })
        )
      ) : (
        <CalendarConnectOptions />
      )}
    </Container>
  )
}

function ShowDateIfEarliest(date: Date, all: CalendarEvent[]) {
  const meetingsOftheDay = all.filter(
    (m) => new Date(m.start_time ?? '').toLocaleDateString() === date.toLocaleDateString()
  )
  for (const m of meetingsOftheDay) {
    if (new Date(m.start_time ?? '') < date) return false
  }
  return true
}

const sxDate: SxProps = {
  paddingX: 2,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingBottom: 1
}
