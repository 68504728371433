import { logErr } from "log"
import { Api } from "./api"

export async function getAffiliateUrlShortener(id?: string) {
  if (!id)
    return
  try {
    const api = await Api()
    const res = await api.public.getAffiliateUrlShortener(id)
    if (res)
      return res
    return
  } catch (e) {
    logErr('getAffiliateUrlShortener', { e })
  }
}

export async function checkDisposableEmail(email: string) {
  try {
    const api = await Api()
    const res = await api.public.checkDisposableEmail(email)
    if (res)
      return res
    return
  } catch (e) {
    logErr('checkDisposableEmail', { e })
  }
}