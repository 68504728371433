import React from 'react'
import { Box, Button } from '@mui/material'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getSalesforceSettings } from 'service/integrations/salesforceApi'
import { getWealthboxSettings } from 'service/integrations/wealthboxApi'
import { getRedtailSettings } from 'service/integrations/redtailApi'
import RedtailImportContacts from './redtail/RedtailImportContacts'
import { useNavigate } from 'react-router-dom'
import { Path } from 'routes'
import { SalesforceApp } from 'client'

export default function ImportContactsListButtons() {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { data: salesforce } = useQuery({
    queryKey: ['getSalesforceSettings'], queryFn: getSalesforceSettings,
    enabled: queryClient.getQueryData(['getSalesforceSettings']) === undefined,
  })
  const isSalesforceSignedIn = !!salesforce?.scope

  const { data: wealthboxProfile } = useQuery({
    queryKey: ['getWealthboxSettings'], queryFn: getWealthboxSettings,
    enabled: queryClient.getQueryData(['getWealthboxSettings']) === undefined,
  })
  const isWealthboxSignedIn = !!wealthboxProfile?.user_id

  const { data: redtailSettings } = useQuery({
    queryKey: ['getRedtailSettings'], queryFn: getRedtailSettings,
    enabled: queryClient.getQueryData(['getRedtailSettings']) === undefined,
  })
  const isRedtailSignedIn = !!redtailSettings?.user_id

  function onClickSalesforce() {
    if (salesforce?.app == SalesforceApp.SALESFORCE)
      navigate(Path.SalesforceSettings)
    if (salesforce?.app == SalesforceApp.PRACTIFI)
      navigate(Path.PractifiSettings)
    if (salesforce?.app == SalesforceApp.XLR8)
      navigate(Path.Xlr8Settings)
  }

  return (
    <>
      {isSalesforceSignedIn && salesforce.app &&
        <>
          <Box sx={{ padding: 1 }} />
          <Button onClick={onClickSalesforce} variant='outlined'>
            Import {salesforce.app} Contacts
          </Button>
        </>
      }
      {isWealthboxSignedIn &&
        <>
          <Box sx={{ padding: 1 }} />
          <Button onClick={() => navigate(Path.WealthboxSettings)} variant='outlined'>
            Import Wealthbox Contacts
          </Button>
        </>
      }

      {isRedtailSignedIn &&
        <>
          <Box sx={{ padding: 1 }} />
          <RedtailImportContacts />
        </>
      }
    </>
  )
}
