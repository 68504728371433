import React from 'react'
import { Box, SxProps, Typography, ButtonBase } from '@mui/material'
import { Media } from '../../service/media'

interface Props {
  disable: boolean
  text: string
  onClick: () => Promise<void>
}

export default function GoogleSignIn({ disable, text, onClick }: Props) {

  const sxButton: SxProps = {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    alignSelf: 'center',
    minWidth: 250,
    border: 1,
    borderRadius: 100,
    padding: 1.5,
    borderColor: 'black',
    bgcolor: 'white',
    opacity: disable ? '25%' : '100%',
  }

  return (
    <ButtonBase sx={sxButton} aria-label={text} onClick={onClick}>
      <Box sx={{ padding: 1 }} />
      <Box
        component="img"
        sx={disable ? disableIcon : icon}
        src={Media.google100}
        alt="Google Logo"
      />
      <Typography sx={disable ? disableText : Text}>
        {text}
      </Typography>
    </ButtonBase>
  )
}

const icon: SxProps = {
  width: 20,
  height: 20,
  marginRight: 2
}
const disableIcon: SxProps = {
  width: 20,
  height: 20,
  opacity: 0.2,
  marginRight: 2
}
const disableText: SxProps = {
  color: 'lightgray'
}
const Text: SxProps = {

}
