import React, { useEffect } from 'react'
import { Typography, SxProps, Container, CardHeader, Card } from '@mui/material'
import AutoRecord from '../../components/settings/AutoRecord'
import RecorderAppearance from '../../components/settings/RecorderAppearance'
import ConnectedCalendar from '../../components/settings/ConnectedCalendar'
import { logInfo } from '../../log'
import ZoomSettings from '../../components/settings/ZoomCardForRecorder'
import AutoShareEventsCard from 'components/event/shareEvent/AutoShareCard'
import Grid from '@mui/material/Grid2'
import WebRecorderSettings from 'components/settings/WebRecorderSettings'

export default function RecorderSettings() {

  useEffect(() => {
    logInfo('Recorder Settings Screen')
  }, [])

  return (
    <Container sx={{ paddingY: 3 }}>
      <Typography variant='h1' sx={{ paddingBottom: 2 }} >Recorder</Typography>

      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 6 }} sx={{ display: 'flex' }}>
          <ConnectedCalendar />
        </Grid>

        <Grid size={{ xs: 12, md: 6 }} sx={{ display: 'flex' }}>
          <ZoomSettings />
        </Grid>

        <Grid size={{ xs: 12, md: 6 }} sx={{ display: 'flex' }}>
          <RecorderAppearance />
        </Grid>

        <Grid size={{ xs: 12, md: 6 }} sx={{ display: 'flex' }}>
          <Card sx={{ flexGrow: 1, margin: 0 }}>
            <CardHeader title={'Web Recorder Settings'} />
            <WebRecorderSettings />
          </Card>
        </Grid>

        <Grid size={{ xs: 12, md: 6 }} sx={{ display: 'flex' }}>
          <AutoRecord />
        </Grid>

        <Grid size={{ xs: 12, md: 6 }} sx={{ display: 'flex' }}>
          <AutoShareEventsCard />
        </Grid>
      </Grid>

    </Container>
  )
}

const sxCard: SxProps = {
  display: 'flex',
  flexDirection: 'column',
}

const sxMid: SxProps = {
  display: 'flex',
  justifyContent: 'center',
}
