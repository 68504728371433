import React, { useEffect, useState } from 'react'
import { Card, Box, SxProps, Button, Typography } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { Path } from 'routes'
import GoogleSignInButton from 'components/auth/GoogleSignInButton'
import {
  firebaseGoogleAuth,
  firebaseMicrosoftAuth,
} from 'service/authApi'
import SignInEmailLink from 'components/auth/SignInEmailLink'
// import GoogleConsentButton from './GoogleConsentButton'
import EmailIcon from '@mui/icons-material/Email'
import MicrosoftSignInButton from 'components/auth/MicrosoftSignInButton'

interface Props {
  isSignUp: boolean
}
export default function SignInCard({ isSignUp }: Props) {
  const navigate = useNavigate()
  const location = useLocation()
  const currentParams = new URLSearchParams(location.search) // may contain affiliate
  const loadingParamsScreen = `${Path.Loading}?${currentParams.toString()}`

  const [showEmailLink, setShowEmailLink] = useState(false)
  const [busy, setBusy] = useState(false)
  const [err, setErr] = useState<string | undefined>()

  async function signInGoogle() {
    setBusy(true)
    setErr(undefined)
    const res = await firebaseGoogleAuth()
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      navigate(loadingParamsScreen)
    }
    setBusy(false)
  }

  async function signInMicrosoft() {
    setBusy(true)
    setErr(undefined)
    const res = await firebaseMicrosoftAuth()
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      navigate(loadingParamsScreen)
    }
    setBusy(false)
  }

  return (

    <Card>
      <Typography variant='h6'>
        {isSignUp ? `Sign Up with FinMate AI` : `Sign In to FinMate AI`}
      </Typography>
      <Box sx={{ padding: 1 }} />

      {showEmailLink
        ? (<>
          <Box sx={{ padding: 3 }} />
          <SignInEmailLink isSignUp={isSignUp} />
          <Button onClick={() => setShowEmailLink(false)} sx={{ marginTop: 5.5 }}>
            {isSignUp ? `Other Sign Up Options` : `Other Sign In Options`}
          </Button>
        </>
        ) : (
          <>
            <Box sx={sxButtonWrapper}>
              <GoogleSignInButton
                text={isSignUp ? 'Sign Up With Google' : `Sign In With Google`}
                onClick={signInGoogle} disable={busy}
              />
              {/* 
                keep this consent dialog if we need to do google oauth verification again. requires abide by AI.
                <GoogleConsentButton text={`${signUpIn} With Google`} 
                disable={busy} setDisable={setBusy} 
                  /> */}
            </Box>

            <Box sx={sxButtonWrapper}>
              <MicrosoftSignInButton
                text={isSignUp ? 'Sign Up With Microsoft' : `Sign In With Microsoft`}
                onClick={signInMicrosoft} disable={busy}
              />
            </Box>

            <Box sx={sxButtonWrapper}>
              <Button variant='outlined'
                startIcon={<EmailIcon sx={{ color: 'gray', marginX: 1 }} />}
                sx={sxEmailLinkButton}
                onClick={() => setShowEmailLink(true)}
              >
                {isSignUp ? 'Sign Up With Email Link' : `Sign In With Email Link`}
              </Button>
            </Box>
          </>
        )
      }

      {err && <Typography sx={{ marginTop: 2 }} color={'tomato'}>
        {err} </Typography>
      }
    </Card>
  )
}

const sxEmailLinkButton: SxProps = {
  paddingY: 1.5,
  width: 250,
  borderColor: 'black',
  textTransform: 'none',
  color: 'black',
  fontSize: 15,
  fontWeight: 400
}

const sxButtonWrapper: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  margin: 2,
}
