import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Link, SxProps, Typography } from '@mui/material'
import { logInfo } from '../../../log'
import { EventBase } from '../../../client'
import { Media } from '../../../service/media'
import { Path } from '../../../routes'
import { useNavigate } from 'react-router-dom'
import { addWealthboxIdsHouseholds, getWealthboxSettings } from 'service/integrations/wealthboxApi'
import useIsBlockedByPlan from 'hooks/useIsBlockedByPlan'
import WealthboxNotesCard from './WealthboxNotesCard'
import WealthboxTasksCard from './WealthboxTasksCard'
import WealthboxWorkflowsCard from './WealthboxWorkflowsCard'
import { gSx } from 'styles/Theme'
import { useEffect, useState } from 'react'
import CrmContactCard from '../CrmContactCard'

interface Props {
  open: boolean
  onClose: () => void
  event: EventBase
  eventSummaryHtml: string
}

export default function WealthboxDialog({ open, onClose, event, eventSummaryHtml }: Props) {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { isBlocked } = useIsBlockedByPlan(true)
  const [contacts, setContacts] = useState(event.contacts ?? [])
  const [loading, setLoading] = useState(false)
  const [err, setErr] = useState<string | undefined>()

  const { data: wbSettings, isLoading: wbSettingsLoading } = useQuery({
    queryKey: ['getWealthboxSettings'], queryFn: getWealthboxSettings,
    enabled: queryClient.getQueryData(['getWealthboxSettings']) === undefined,
  })

  const disabled = isBlocked || !!err || wbSettingsLoading || loading

  useEffect(() => {
    run()
    async function run() {
      setLoading(true)
      const withHouseholds = await addWealthboxIdsHouseholds(event.id)
      if (withHouseholds instanceof Error) {
        setErr(withHouseholds.message)
      } else {
        setContacts(withHouseholds)
      }
      setLoading(false)
    }
  }, [event.contacts])

  useEffect(() => {
    if (open) {
      logInfo('Wealthbox NoteSender Dialog Open')
      queryClient.invalidateQueries({ queryKey: ['getEvent', event?.id] })
    }
  }, [open])

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={() => onClose()}
      slotProps={{ paper: { style: { height: '98vh', margin: 0, maxHeight: '98vh' } } }}
    >
      <DialogTitle sx={gSx.Row}>
        <Box component="img" sx={icon} src={Media.wealthboxIcon} alt="Wealthbox" />
        <Typography variant='h3'>Send to Wealthbox</Typography>
      </DialogTitle>

      <DialogContent>
        {loading && <LinearProgress />}
        {err && <Typography color='red' textAlign={'center'}>{err}</Typography>}

        {isBlocked &&
          <Typography sx={sxText}>{`Please `}
            <Link onClick={() => navigate(Path.Billing)}>Upgrade your plan</Link>
          </Typography>
        }
        <Box sx={{ padding: 1 }} />

        <CrmContactCard
          event={event} contacts={contacts} disabled={disabled}
        />

        <WealthboxNotesCard
          event={event} contacts={contacts}
          eventSummaryHtml={eventSummaryHtml} disabled={disabled}
        />

        <WealthboxTasksCard
          event={event} contacts={contacts} disabled={disabled}
        />

        <WealthboxWorkflowsCard
          event={event} contacts={contacts}
          wbConfig={wbSettings} disabled={disabled}
        />
      </ DialogContent >

      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog >
  )
}

const icon: SxProps = {
  width: 40,
  height: 40,
  marginRight: 2
}

const sxText: SxProps = {
  paddingY: '5px',
  cursor: 'pointer'
}
