import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { Box, Card, Typography, Link, List, ListItem, Pagination, Button, Dialog, DialogTitle, DialogContent, LinearProgress, DialogActions, Snackbar, Alert } from '@mui/material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { LoadingButton } from '@mui/lab'
import { gSx } from 'styles/Theme'
import { deleteEvents } from 'service/eventApi'
import { useQueryClient } from '@tanstack/react-query'

interface Props {
  checkedEventIds: string[]
  setCheckedEventIds: Dispatch<SetStateAction<string[]>>
  resetParams: () => Promise<void>
}

export default function DeleteMultipleEvents({ checkedEventIds, setCheckedEventIds, resetParams }: Props) {
  const queryClient = useQueryClient()
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [busy, setBusy] = useState(false)
  const [openSnackError, setOpenSnackError] = useState<undefined | string>()
  const [openSnackSuccess, setOpenSnackSuccess] = useState(false)

  const numSelected = checkedEventIds.length

  function onClose(event: object, reason: string) {
    if (busy)
      return // if busy, prevent dialog close
    setOpenDeleteDialog(false)
  }

  async function onDelete() {
    setBusy(true)
    setOpenSnackError(undefined)

    const res = await deleteEvents(checkedEventIds)
    if (res instanceof Error) {
      setOpenSnackError(res.message)
    } else {
      setOpenSnackSuccess(true)
    }
    // some events can be deleted and error out, thus reset the params.
    await resetParams()
    await queryClient.invalidateQueries({ queryKey: ['getEvents'] })
    setCheckedEventIds([])
    setOpenDeleteDialog(false)
    setBusy(false)
  }

  return (
    <Box sx={{ ...gSx.Row }}>

      {
        checkedEventIds.length > 0 &&
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <Box sx={gSx.Row}>
            <Button
              onClick={() => setOpenDeleteDialog(true)}
              startIcon={<DeleteOutlineIcon />}
              variant='outlined' sx={{ marginX: 1 }}
            >
              Delete
            </Button>

            <Typography sx={{ paddingX: 2, whiteSpace: 'nowrap' }}>{`${numSelected} selected`}</Typography>
          </Box>
        </Box>

      }

      <Dialog
        open={openDeleteDialog}
        onClose={onClose}
      >
        <DialogTitle>Delete Selected Meetings</DialogTitle>

        <DialogContent>

          <Box sx={{ padding: 1 }} />
          <Typography>{`${numSelected} selected meeting(s)`}</Typography>

          <Typography>By Deleting Meetings, Pre-meeting Note preparations will be affected</Typography>

          <Typography color='tomato'>Warning: This action is irreversible.</Typography>

        </DialogContent>

        {busy &&
          <LinearProgress sx={{ margin: 1 }} />
        }

        <DialogActions>
          <Button disabled={busy}
            onClick={() => setOpenDeleteDialog(false)}
            sx={{ marginX: 3 }}
          >
            Cancel
          </Button>

          <LoadingButton
            variant="contained" color="error"
            startIcon={<DeleteOutlineIcon />}
            onClick={onDelete}
            aria-label="delete selected"
            disabled={busy}
            loading={busy}
          >
            Delete Selected
          </LoadingButton>
        </DialogActions>
      </Dialog >

      <Snackbar
        open={openSnackSuccess}
        autoHideDuration={3000}
        onClose={() => setOpenSnackSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          variant="filled"
          onClose={() => setOpenSnackSuccess(false)}
          severity={'success'}
          sx={{ width: '100%' }}
        >
          {`Deleted Meetings Successfully`}
        </Alert>
      </Snackbar>

      <Snackbar
        open={!!openSnackError}
        autoHideDuration={3000}
        onClose={() => setOpenSnackError(undefined)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          variant="filled"
          onClose={() => setOpenSnackError(undefined)}
          severity={'error'}
          sx={{ width: '100%' }}
        >
          {openSnackError}
        </Alert>
      </Snackbar>

    </Box>
  )
}
