import React, { useState } from 'react'
import { EventBase, SalesforceApp } from '../../../client'
import { Box, Button, SxProps, Tooltip, Typography } from '@mui/material'
import { Media, salesforceAppIconUrl } from '../../../service/media'
import CheckIcon from '@mui/icons-material/Check'
import { useQuery } from '@tanstack/react-query'
import SalesforceDialog from './SalesforceDialog'
import { getSalesforceSettings } from 'service/integrations/salesforceApi'
import { grey } from '@mui/material/colors'
import { gSx } from 'styles/Theme'

interface Props {
  event?: EventBase
  notesHtml: string
  disabled: boolean
}
export default function SalesforceEventButton({ event, notesHtml, disabled }: Props) {
  const [showDialog, setShowDialog] = useState(false)

  const { data } = useQuery({
    queryKey: ['getSalesforceSettings'],
    queryFn: getSalesforceSettings,
  })
  const app = data?.app ?? SalesforceApp.SALESFORCE
  const isSent = !!(event?.salesforce_flows_sent || event?.salesforce_sent || event?.salesforce_tasks_sent)

  const isSignedIn = !!data?.scope

  if (!isSignedIn || !event)
    return null

  return (
    <Box sx={{ paddingX: 1 }}>

      <Tooltip title={(isSent) ? `Sent to ${app}` : `Send to ${app}`}>
        <Button
          variant='outlined'
          onClick={() => setShowDialog(true)}
          aria-label={app}
          disabled={disabled}
          startIcon={
            <Box
              component="img"
              sx={icon}
              src={salesforceAppIconUrl(app)}
              alt={app}
            />
          }
          endIcon={
            isSent &&
            <CheckIcon color='success' fontSize='small' />

          }
        >
          <Typography sx={{ fontSize: 14 }} >{app}</Typography>
        </Button>
      </Tooltip>

      {showDialog && // prevent sf_id generation until dialog opens
        <SalesforceDialog
          open={showDialog}
          onClose={() => setShowDialog(false)}
          event={event}
          notesHtml={notesHtml}
          app={app}
        />
      }

    </Box>
  )
}

const icon: SxProps = {
  width: 23,
  height: 23,
  marginRight: '5px'
}