/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AffiliateUrlShortener } from '../models/AffiliateUrlShortener';
import type { SummaryBase } from '../models/SummaryBase';
import type { SystemPublicBase } from '../models/SystemPublicBase';
import type { TranscriptBase } from '../models/TranscriptBase';
import type { Video } from '../models/Video';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PublicService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Public Event Summary Base
     * @param eventId
     * @returns SummaryBase Successful Response
     * @throws ApiError
     */
    public getPublicEventSummaryBase(
        eventId: string,
    ): CancelablePromise<SummaryBase> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/{event_id}/summary/base',
            path: {
                'event_id': eventId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Public Event Followup Email
     * @param eventId
     * @returns string Successful Response
     * @throws ApiError
     */
    public getPublicEventFollowupEmail(
        eventId: string,
    ): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/{event_id}/followup/email',
            path: {
                'event_id': eventId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Public Event Transcript Sentences
     * @param eventId
     * @returns TranscriptBase Successful Response
     * @throws ApiError
     */
    public getPublicEventTranscript(
        eventId: string,
    ): CancelablePromise<TranscriptBase> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/{event_id}/transcript',
            path: {
                'event_id': eventId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Public Event Video
     * @param eventId
     * @returns Video Successful Response
     * @throws ApiError
     */
    public getPublicEventVideo(
        eventId: string,
    ): CancelablePromise<Video> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/{event_id}/video',
            path: {
                'event_id': eventId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Custom Token Microsoft
     * @param microsoftAccessToken
     * @returns string Successful Response
     * @throws ApiError
     */
    public createCustomTokenMicrosoft(
        microsoftAccessToken: string,
    ): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/public/custom_token/microsoft',
            query: {
                'microsoft_access_token': microsoftAccessToken,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Affiliate Url Shortener
     * @param id
     * @returns AffiliateUrlShortener Successful Response
     * @throws ApiError
     */
    public getAffiliateUrlShortener(
        id: string,
    ): CancelablePromise<AffiliateUrlShortener> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/affiliate/url_shortener',
            query: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get System
     * @returns SystemPublicBase Successful Response
     * @throws ApiError
     */
    public getSystemPublic(): CancelablePromise<SystemPublicBase> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/',
        });
    }

    /**
     * Check Disposable Email
     * @param email
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public checkDisposableEmail(
        email: string,
    ): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/disposable_email',
            query: {
                'email': email,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
