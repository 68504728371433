import React, { useState } from 'react'
import { Box, SxProps, Typography, ButtonBase } from '@mui/material'
import { Media } from '../../service/media'
import { useNavigate } from 'react-router-dom'
import { recallMicrosoftAuth } from 'service/authApi'
import { logErr } from 'log'
import { GetCalendarUser, isConnected, recordExternalMeetings, UpdateRecordingPrefs } from 'service/calendarApi'
import { Path } from 'routes'

export default function MicrosoftCalendarButton() {
  const navigate = useNavigate()
  const [busy, setBusy] = useState(false)

  async function onClick() {
    setBusy(true)
    try {
      await recallMicrosoftAuth()

      await checkIfSignedIn()
    } catch (err) {
      logErr('MicrosoftCalendarButton', { err })
    }
    setBusy(false)
  }

  async function checkIfSignedIn() {
    const intervalId = setInterval(async () => {
      const calUser = await GetCalendarUser()
      if (calUser && isConnected(calUser)) {
        clearInterval(intervalId)

        // set default recording prefs, when 1st connecting
        const prefs = recordExternalMeetings(true)
        await UpdateRecordingPrefs(prefs)
        navigate(Path.RecorderSettings)
      }
    }, 2000)
  }

  const sxButton: SxProps = {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    minWidth: 250,
    border: 1,
    borderRadius: 100,
    padding: 1.5,
    borderColor: 'black',
    bgcolor: 'white',
    opacity: busy ? '25%' : '100%',
  }

  const sxText: SxProps = {
    color: busy ? 'lightgray' : 'black',
    width: 200
  }

  return (
    <ButtonBase sx={sxButton} aria-label={'Connect Outlook Calendar'} onClick={onClick}>
      <Box
        component="img"
        sx={busy ? disableIcon : icon}
        src={Media.microsoft100}
        alt="Microsoft Logo"
      />
      <Typography sx={sxText}>
        Connect Outlook Calendar
      </Typography>
    </ButtonBase>
  )
}

const icon: SxProps = {
  width: 20,
  height: 20,
  marginRight: 2
}
const disableIcon: SxProps = {
  width: 20,
  height: 20,
  opacity: 0.2,
  marginRight: 2
}
