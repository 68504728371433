import React, { useEffect } from 'react'
import { SxProps, Box, Container, Button } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { gSx } from '../../styles/Theme'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { Path } from '../../routes'
import ClientNotes from '../../components/client/ClientNotes'
import { logInfo } from '../../log'
import ClientDropdownMenu from 'components/client/ClientDropdownMenuDelete'
import { getClientDetail } from 'service/clientApi'
import { SkeletonRows } from 'components/Skeletons'
import { useStore } from 'hooks/useStore'
import EventsList from 'components/event/EventsList'
import MyEventsList from 'components/event/MyEventsList'
import ContactEdit from '../../components/client/ContactEdit'

export default function ClientDetailScreen() {
  const store = useStore()
  const navigate = useNavigate()
  const { id } = useParams()

  useEffect(() => {
    logInfo('Client Detail Screen', { id })
  }, [])

  const { data: contact, isLoading } = useQuery({
    queryKey: ['getClientDetail', id],
    queryFn: async () => await getClientDetail(id)
  })

  useEffect(() => {
    if (contact)
      store.User.setEventSearchParams({ contact })
  }, [contact])

  return (
    <Container sx={{ paddingTop: '2vh' }}>
      <Box sx={gSx.RowBetween}>
        <Button
          sx={sxBack}
          onClick={() => navigate(Path.Clients)}
          startIcon={<ArrowBackIosIcon fontSize='small' />}
        >
          Back
        </Button>

        <ClientDropdownMenu
          data={contact}
          disabled={isLoading}
        />
      </Box>
      <Box sx={{ padding: 1 }} />

      {
        (isLoading || !contact)
          ? <SkeletonRows count={3} height={200} />
          : (
            <>
              <ContactEdit contact={contact} />
              <ClientNotes data={contact} />
              <MyEventsList />
            </>
          )
      }

    </Container>
  )
}

const sxBack: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}
