import React, { useEffect, useState } from 'react'
import { Box, Button, IconButton, Tooltip, Typography, Popover, Checkbox, FormGroup, FormControlLabel } from '@mui/material'
import { observer } from 'mobx-react-lite'
import SearchEventTitle from './SearchEventTitle'
import SearchEventDates from './SearchEventDates'
import { useStore } from 'hooks/useStore'
import RefreshIcon from '@mui/icons-material/Refresh'
import { useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { getClientDetail } from 'service/clientApi'
import { gSx } from 'styles/Theme'
import { sleep } from 'utils'
import TuneIcon from '@mui/icons-material/Tune'

interface Props {
  setPage: (pageNum: number) => void
}

export default observer(SearchEventBar)
function SearchEventBar({ setPage }: Props) {
  const store = useStore()
  const queryClient = useQueryClient()
  const params = store.User.getEventSearchParams()
  const { id: contactId } = useParams() // client_id

  const [title, setTitle] = useState(params.title ?? '') // pre-debounce
  const [isShowDate, setIsShowDate] = useState(true)
  const [isRefreshBusy, setRefreshBusy] = useState(false)
  const noSummary = params.no_summary ?? false
  const [isSummaryBusy, setSummaryBusy] = useState(false)
  const noCrm = params.no_crm ?? false
  const [isCrmBusy, setCrmBusy] = useState(false)

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'filter-popover' : undefined

  useEffect(() => {
    // if search by title, hide dates
    if (!title || (title.length == 0))
      setIsShowDate(true) // for searching by dates
    else if (title.length == 1) {
      // using 1 letter as a transition between search by title and date
      setPage(1)
      setIsShowDate(false)
      store.User.setEventSearchParams({ ...params, earliest: undefined, latest: undefined })
    }
    // title search starts 2+ letters.

  }, [title])

  async function onSummaryFilter() {
    setSummaryBusy(true)
    store.User.setEventSearchParams({ ...params, no_summary: !noSummary })
    await sleep(1000)
    setSummaryBusy(false)
  }

  async function onCrmFilter() {
    setCrmBusy(true)
    store.User.setEventSearchParams({ ...params, no_crm: !noCrm })
    await sleep(1000)
    setCrmBusy(false)
  }

  async function onClearSearch() {
    setTitle('')
    setPage(1)

    if (contactId) {
      const contact = await getClientDetail(contactId)
      store.User.setEventSearchParams({ contact })
    } else {
      store.User.setEventSearchParams({})
    }
  }

  async function onRefresh() {
    setRefreshBusy(true)

    if (contactId) {
      const contact = await getClientDetail(contactId)
      store.User.setEventSearchParams({ contact })
    } else {
      store.User.setEventSearchParams({})
    }

    await queryClient.invalidateQueries({ queryKey: ['getEvents'] })
    setRefreshBusy(false)
  }

  return (
    <Box sx={gSx.RowBetween}>
      <Box sx={gSx.Row}>
        <Box>
          <SearchEventTitle title={title} setTitle={setTitle} />
          {title && title.length == 1 &&
            <Typography sx={{ color: 'tomato' }}>Please type one more letter to search</Typography>
          }
        </Box>

        <Box sx={{ paddingX: 0.5 }} />

        <SearchEventDates isShowDate={isShowDate} />
        <Tooltip title="Filters">
          <IconButton onClick={handleClick}>
            <TuneIcon color={params.no_crm || params.no_summary ? "secondary" : "primary"} />
          </IconButton>
        </Tooltip>

        {(params.earliest || params.latest || params.title || params.no_crm || params.no_summary) &&
          <Button
            onClick={onClearSearch} variant='outlined'
            sx={{ color: 'tomato', borderColor: 'tomato', marginX: 1 }}
          >
            Clear Filter
          </Button>
        }
      </Box>

      <Box sx={gSx.Row}>
        <Tooltip title={"Refresh Meetings List"}>
          <IconButton onClick={onRefresh} loading={isRefreshBusy}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <FormGroup sx={{ padding: 2 }}>
          <FormControlLabel control={<Checkbox checked={noSummary} onChange={onSummaryFilter} />} label="No Summary Processed" />
          <FormControlLabel control={<Checkbox checked={noCrm} onChange={onCrmFilter} />} label="Not Sent to CRM" />
        </FormGroup>
      </Popover>
    </Box >
  )
}
