import React, { useState } from 'react'
import {
  Box,
  SxProps,
  Typography,
  TextField,
  DialogActions,
  DialogContent,
  IconButton,
  DialogTitle,
  Button,
} from '@mui/material'
import { ClientDetail, EventBase } from '../../client'
import { useQueryClient } from '@tanstack/react-query'
import { logInfo } from '../../log'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { LoadingButton } from '@mui/lab'
import { gSx } from 'styles/Theme'
import { upsertClient } from 'service/clientApi'

interface Props {
  event?: EventBase

  setIsAddStep: (isAdd: boolean) => void
  onClose: () => void
  onSuccessSnack: () => void
}

export default function ClientForEventNew({ event, setIsAddStep, onClose, onSuccessSnack }: Props) {
  const eventId = event?.id ?? ''
  const queryClient = useQueryClient()
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [email, setEmail] = useState('')
  const [busy, setBusy] = useState(false)
  const [err, setErr] = useState<string | undefined>()

  async function onSave() {
    setBusy(true)
    setErr(undefined)
    logInfo('ClientForEventNew, Pressed Save', { eventId, firstname })

    const client: ClientDetail = {
      first_name: firstname,
      last_name: lastname,
      email: email
    }
    const res = await upsertClient(client, event?.id)
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      onSuccessSnack()
      await queryClient.invalidateQueries({ queryKey: ['getEvent', eventId] })
      onClose()
    }
    setBusy(false)
  }

  return (
    <>
      <Box sx={{ ...gSx.Row, marginLeft: 2 }}>
        <IconButton onClick={() => setIsAddStep(false)}>
          <ArrowBackIcon />
        </IconButton>
        <DialogTitle>Add a New Contact for this Meeting</DialogTitle>
      </Box>

      <DialogContent>
        <TextField
          autoFocus
          label='First Name'
          variant="outlined"
          value={firstname}
          onChange={(t) => setFirstname(t.target.value)}
          sx={sxText}
        />

        <Box sx={{ padding: '1vh' }} />

        <TextField
          label='Last Name'
          variant="outlined"
          value={lastname}
          onChange={(t) => setLastname(t.target.value)}
          sx={sxText}
        />

        <Box sx={{ padding: '1vh' }} />

        <TextField
          label='Email'
          variant="outlined"
          value={email}
          onChange={(t) => setEmail(t.target.value)}
          sx={sxText}
          required={true}
          helperText="required"
        />

        {err && <Typography color={'tomato'} sx={{ paddingTop: 1 }}>{err}</Typography>}
      </DialogContent>

      <DialogActions>
        <Button onClick={() => setIsAddStep(false)}>
          Cancel
        </Button>
        <LoadingButton
          variant='contained'
          onClick={onSave}
          disabled={busy}
          sx={{ width: 100 }}
          loading={busy}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </>
  )
}

const sxText: SxProps = {
  width: '45vh'
}

const sxClose: SxProps = {
  alignSelf: 'flex-start',
}
