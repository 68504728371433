import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Box } from '@mui/system'
import { Button, Card, CardHeader, Container, Divider, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Switch, Typography } from '@mui/material'
import { logInfo } from 'log'
import { gSx } from 'styles/Theme'
import { useStore } from 'hooks/useStore'
import { isSuperUser } from 'service/authApi'
import Usage from 'components/settings/Usage'
import ProfileSettingsCard from 'components/settings/ProfileSettingsCard'
import HelpIcon from '@mui/icons-material/Help'
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import EmailSettingsCard from 'components/settings/EmailSettingsCard'
import PremeetingSettingsCard from 'components/settings/PremeetingSettingsCard'
import Grid from '@mui/system/Grid'
import { useTranslation } from 'react-i18next'

export default observer(Settings)
function Settings() {
  const { t } = useTranslation()
  const store = useStore()
  const showAdmin = store.Security.getShowAdmin()

  useEffect(() => {
    logInfo('Settings Screen')
  }, [])

  async function onSwitch(switchElement: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    store.Security.setShowAdmin(checked)
  }

  return (
    <Container sx={{ paddingY: 3 }}>

      <Typography variant='h1' sx={{ paddingBottom: 3 }} >{t('General')}</Typography>

      <Grid container spacing={2}>
        <Grid size={{ xs: 12, lg: 6 }} sx={{ display: 'flex' }}>
          <ProfileSettingsCard />
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }} sx={{ display: 'flex' }}>
          <Usage />

        </Grid>
        <Grid size={{ xs: 12, lg: 6 }} sx={{ display: 'flex' }}>
          <EmailSettingsCard />
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }} sx={{ display: 'flex' }}>
          <PremeetingSettingsCard />
        </Grid>

        <Grid size={{ xs: 12, lg: 6 }} sx={{ display: 'flex' }}>
          <Card sx={{ flexGrow: 1, margin: 0 }}>
            <CardHeader title="Help" />

            <Link
              rel='noopener'
              underline="none"
              target='_blank'
              href={'https://docs.finmate.ai'}
            >

              <Typography >
                FinMate Help Docs
              </Typography>
            </Link>
          </Card >
        </Grid>

        <Grid size={{ xs: 12, lg: 6 }} sx={{ display: 'flex' }}>
          <Card sx={{ flexGrow: 1, margin: 0 }}>
            <CardHeader
              title="Refer and Earn"
            />

            <Link
              rel='noopener'
              underline="none"
              target='_blank'
              href={'mailto:sales@finmate.ai'}
            >

              <Typography>  Earn by Referring. Contact sales@finmate.ai</Typography>
            </Link>
          </Card >
        </Grid>

      </Grid>

      <Box sx={{ paddingY: '3vh' }} />

      {
        isSuperUser() && (
          <Box sx={gSx.Row}>
            <Typography>Show admin. Visible only to @trellish or @finmate.ai</Typography>
            <Switch
              checked={showAdmin}
              onChange={onSwitch}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Box>
        )
      }
    </Container >
  )
}
