import React, { useEffect, useState } from 'react'
import { Box, Button, TextField, Snackbar, Alert, Typography } from '@mui/material'
import { auth, config } from 'service/api'
import { ActionCodeSettings, sendSignInLinkToEmail } from 'firebase/auth'
import { gSx } from 'styles/Theme'
import { logInfo } from 'log'
import { checkDisposableEmail } from 'service/publicApi'
import { isEmailValid } from 'utils'

//  See web/README.md > Email Link Auth for Web

interface Props {
  isSignUp: boolean
}

export default function SignInEmailLink({ isSignUp }: Props) {
  const urlParams = new URLSearchParams(location.search) // may contain affiliate
  const region = urlParams.get('region')

  const [email, setEmail] = useState('')
  const [busy, setBusy] = useState(false)
  const [err, setErr] = useState<string | undefined>()
  const [openSuccessSnack, setOpenSuccessSnack] = useState(false)

  useEffect(() => {
    if (region) localStorage.setItem('region', region)
  }, [region])

  async function onSendEmailLinkSignIn() {
    if (!config.web)
      return

    setBusy(true)
    setErr(undefined)

    if (!isEmailValid(email)) {
      setErr('Invalid email format')
      setBusy(false)
      return
    }

    const isDisposable = await checkDisposableEmail(email)
    if (isDisposable) {
      setErr('Please use a work email')
      setBusy(false)
      return
    }

    const affiliateId = localStorage.getItem('affiliateId')

    // url cannot have other parameters
    const url = affiliateId
      ? config.web + `/hi/${affiliateId}`
      : config.web + "/signin?"

    const actionCodeSettings: ActionCodeSettings = {
      url, handleCodeInApp: true
    }
    await sendSignInLinkToEmail(auth, email, actionCodeSettings)
    logInfo('SignInEmailLink Sent', { url, email })
    window.localStorage.setItem('emailForSignIn', email)
    setOpenSuccessSnack(true)
    setBusy(false)
  }

  const onKeyPress = async (event: any) => {
    if (event.key === 'Enter')
      onSendEmailLinkSignIn()
  }

  return (
    <>
      <Box>
        <Box sx={{ backgroundColor: 'white', paddingY: 0.5 }}>
          <TextField
            autoFocus
            label={isSignUp ? 'Sign Up With Email Link' : 'Sign In With Email Link'}
            value={email}
            onChange={(t) => setEmail(t.target.value)}
            onKeyDown={onKeyPress}
            sx={{ width: 282 }}
            size='small'
            disabled={busy}
          />
        </Box>

        <Box sx={gSx.RowEnd}>
          <Button
            onClick={onSendEmailLinkSignIn}
            disabled={busy} color='info' variant='contained'
            sx={{ paddingX: 3 }}
          >
            Send
          </Button>
        </Box>

        {err && <Typography color='error'>{err}</Typography>}
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSuccessSnack}
        autoHideDuration={3000}
        onClose={() => setOpenSuccessSnack(false)}
      >
        <Alert
          variant="filled"
          severity="success"
        >
          {isSignUp ? 'Sign Up With Email Link Sent' : 'Sign In With Email Link Sent'}
        </Alert>

      </Snackbar>
    </>
  )
}
