import React, { useEffect, useState } from 'react'
import { Typography, Box, Card, SxProps, Link, Tooltip, Button, Skeleton, Divider, Select, Switch, CircularProgress, LinearProgress } from '@mui/material'
import 'firebase/auth'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { gSx } from '../../styles/Theme'
import { Media } from 'service/media'
import CancelIcon from '@mui/icons-material/Cancel'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import AddToZoomButton from 'components/settings/AddToZoomButton'
import ContainerSmall from 'components/ContainerSmall'
import { logInfo } from 'log'
import IntegrationsBackButton from 'components/IntegrationsBackButton'
import { LoadingButton } from '@mui/lab'
import { deauthorizeZoom, getZoomSettings, setZoomEnableRecording } from 'service/integrations/zoomApi'
import Grid from '@mui/material/Grid2'
import { config } from 'service/api'

export default function ZoomSettingsScreen() {
  const queryClient = useQueryClient()
  const [busy, setBusy] = useState(false)
  const [busyPhone, setBusyPhone] = useState(false)
  const [err, setErr] = useState<string | undefined>()
  const [savePhone, setSavePhone] = useState(false)

  const { data: zoom, isLoading, refetch } = useQuery({
    queryKey: ['getZoomSettings'], queryFn: getZoomSettings,
  })

  useEffect(() => {
    logInfo('Zoom Settings Screen')
  }, [])

  useEffect(() => {
    setSavePhone(!!(zoom?.isPhoneReady && zoom?.enable_phone_recording))
  }, [zoom])

  async function onSavePhone(checked: boolean) {
    setErr(undefined)
    setBusyPhone(true)
    const res = await setZoomEnableRecording(checked)
    if (res instanceof Error) {
      setErr('Toggle Zoom Phone Enable/Disable Failed')
    } else {
      await refetch()
    }
    setBusyPhone(false)
  }

  async function disconnect() {
    setErr(undefined)
    setBusy(true)
    const res = await deauthorizeZoom()
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      await refetch()
    }
    setBusy(false)
  }

  return (
    <ContainerSmall>
      <IntegrationsBackButton />
      <Typography variant='h3'>Zoom Integration</Typography>
      <Divider sx={{ marginY: '2vh ' }} />
      <Box sx={gSx.Row}>
        <Link href="https://zoom.us" rel='noopener' target='_blank'>
          <Box
            component="img"
            sx={icon}
            src={Media.zoomLogo}
            alt="Zoom"
          />
        </Link>
        <Box sx={{ padding: 1 }} />
        <Typography variant='h1'>Zoom</Typography>
      </Box>
      <Box sx={{ padding: '1vh ' }} />

      <Typography>Connect FinMate to Zoom, so that the FinMate Notetaker can be invited to record your meetings</Typography>

      <Box sx={{ padding: '1vh ' }} />

      <Card>
        {isLoading
          ? <Skeleton variant="rectangular" width={'100%'} height={100} />
          : zoom?.isVideoReady
            ? <>
              <Box sx={gSx.RowBetween}>
                <Typography>
                  {
                    zoom.profile?.email
                      ? `Signed in as ${zoom.profile?.email}`
                      : 'Connected'
                  }
                </Typography>

                <Tooltip title="Disconnect Zoom App">
                  <LoadingButton
                    aria-label='Disconnect Zoom App'
                    onClick={disconnect}
                    loading={busy}
                  >
                    <CancelIcon />
                  </LoadingButton>
                </Tooltip>
              </Box>

              <Divider sx={{ marginY: 2 }} />

              <Grid container rowSpacing={2} alignItems="center">
                <Grid size={1.5}>
                  <Box component="img" sx={sxLogoSmall} src={Media.zoomLogo} />
                </Grid>
                <Grid size={3.5}>
                  <Typography variant='h6'>Zoom Video</Typography>
                </Grid>
                <Grid size={7}>
                  <Typography>Ready</Typography>
                </Grid>
              </Grid>

              <Grid container rowSpacing={2} alignItems="center">
                <Grid size={1.5}>
                  <Box component="img" sx={sxLogoSmall} src={Media.zoomPhone} />
                </Grid>
                <Grid size={3.5}>
                  <Typography variant='h6'>Zoom Phone</Typography>
                </Grid>
                <Grid size={5}>
                  {zoom.hasApiAccess
                    ? zoom.isPhoneReady
                      ? <Typography>{savePhone ? 'Ready' : 'Disabled'}</Typography>
                      : <Typography color={'darkred'}>Requires Zoom Phone Plan</Typography>
                    : <Typography color={'darkred'}>Requires Re-SignIn</Typography>
                  }
                </Grid>
                <Grid size={1}>
                  <Tooltip title={savePhone ? "Zoom Phone Recording Enabled" : "Zoom Phone Recording Disabled"}>
                    <Switch
                      checked={savePhone}
                      onChange={(event, checked) => onSavePhone(checked)}
                      disabled={isLoading || busyPhone || !zoom.isPhoneReady}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </Tooltip>
                </Grid>
              </Grid>

              {busyPhone && <LinearProgress />}
            </>
            : <>
              <Typography variant='h5'>Add to Zoom to Get Started</Typography>

              <Typography sx={{ fontSize: 14, paddingY: '1vh' }}>
                {`When adding to zoom, please checkmark "Allow this app to use my shared access permissions."`}
              </Typography>

              <Box sx={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
                <AddToZoomButton />
              </Box>
            </>

        }

        {err && <Typography color={'tomato'} sx={{ padding: 2 }}>
          {err}
        </Typography>
        }

      </Card >

      <Button
        component="a"
        href="https://docs.finmate.ai/docs/zoom/setup"
        target="_blank"
        rel="noopener"
        startIcon={<TextSnippetIcon />}
      >
        Zoom Guide
      </Button>

    </ContainerSmall >
  )
}

const icon: SxProps = {
  width: 50,
  marginRight: 2
}

const sxLogoSmall: SxProps = {
  width: 38,
}
