import React, { useEffect, useState } from 'react'
import { Link, Box, SxProps, Typography, useMediaQuery, Snackbar, Alert, Button } from '@mui/material'
import { Media } from 'service/media'
import { Region } from 'client'
import { gSx, theme } from 'styles/Theme'
import ManualSignInDevOnly from 'components/auth/ManualSigninDevOnly'
import SignInCard from 'components/auth/SignInCard'

export default function SignInActionSection() {
  const urlParams = new URLSearchParams(location.search)
  const region = urlParams.get('region')
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))
  const [failedEmailLinkAuth, setFailedEmailLinkAuth] = useState(false)
  const [isSignUp, setIsSignUp] = useState(false)

  useEffect(() => {
    if (urlParams) {
      const emailLinkAuthStatus = urlParams.get('emailLinkAuthStatus')
      if (emailLinkAuthStatus && emailLinkAuthStatus == 'failed')
        setFailedEmailLinkAuth(true)
    }
  }, [urlParams])

  useEffect(() => {
    region && localStorage.setItem('region', region)
  }, [region])

  const sxPage: SxProps = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: isSmallScreen ? "auto" : '80vh',
    width: '100%',
  }

  return (
    <>
      <Box sx={sxPage}>
        <Box sx={{ padding: 2 }} />
        <Box sx={gSx.Row} onClick={() => window.open('https://finmate.ai', '_blank')}>
          <Box component='img' alt='Finmate AI logo' sx={sxIcon} src={Media.logo100} />
          <Typography variant='h1'>FinMate AI</Typography>
        </Box>
        <Typography variant='caption'>By Financial Advisors, For Financial Advisors</Typography>

        {region?.toLowerCase() == Region.CANADA.toLowerCase() &&
          <Box sx={gSx.Row}>
            <Box component="img" src={Media.canada_flag} sx={{ width: 30 }} />
            <Typography sx={{ paddingLeft: 2 }}>Canada</Typography>
          </Box>
        }

        <Box sx={{ padding: 3 }} />
        <Typography variant='h3'>Welcome!</Typography>
        <Box sx={{ padding: 1 }} />

        <SignInCard isSignUp={isSignUp} />

        <Button variant='outlined' onClick={() => setIsSignUp(!isSignUp)}>
          {isSignUp ? 'Sign In' : 'Sign Up'}
        </Button>

        <ManualSignInDevOnly />

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={failedEmailLinkAuth}
          autoHideDuration={3000}
          onClose={() => setFailedEmailLinkAuth(false)}
        >
          <Alert variant='filled' severity='error'>
            Email link authentication expired. Please try again.
          </Alert>
        </Snackbar>

      </Box >
      <Legal />
    </>
  )
}

function Legal() {
  return (
    <Box sx={gSx.CenterVertical}>
      <Box sx={{ maxWidth: 500, paddingBottom: 5 }}>
        <Typography variant='caption'>
          By Using FinMate AI, you agree to the{' '}
          <Link rel='noopener' target='_blank' href={'https://www.finmate.ai/terms'}>
            Terms of Service
          </Link>{', '}

          <Link rel='noopener' target='_blank' href='https://www.finmate.ai/privacy'>
            Privacy Policy
          </Link>
          {', and '}
          <Link rel='noopener' target='_blank' href="https://finmate.ai/google">AI Limited Use Requirements</Link>
          .
          {` FinMate AI's use and transfer to any other app of information received from Google APIs will adhere to `}
          <Link rel='noopener' target='_blank' href="https://developers.google.com/terms/api-services-user-data-policy">Google API Services User Data Policy</Link>
          {`, including the Limited Use requirements. `}
        </Typography>
      </Box>
    </Box>
  )
}

const sxIcon: SxProps = {
  width: 40,
  height: 50,
  marginRight: 2,
}
