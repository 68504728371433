import React, { useState } from 'react'
import { Typography, Tab, Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import 'firebase/auth'
import { ClientDetail, EventBase, WealthboxBase, WealthboxType } from 'client'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import WealthboxWorkflowsStart from './WealthboxWorkflowsStart'
import WealthboxWorkflowsCompleteStep from './WealthboxWorkflowsCompleteStep'
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined'
import { gColor, gSx } from 'styles/Theme'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

interface Props {
  event: EventBase
  contacts: ClientDetail[]
  wbConfig?: WealthboxBase
  disabled?: boolean
}

export default function WealthboxWorkflowsCard({ event, wbConfig, contacts, disabled }: Props) {
  const isSent = !!event.wealthbox_workflows_sent
  const [expanded, setExpanded] = useState(!isSent)
  const [tab, setTab] = useState("1")

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue)
  }

  return (
    <Accordion
      sx={gSx.Crm.Accordian}
      expanded={expanded}
      onChange={() => setExpanded(state => !state)}
      disableGutters
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={gSx.Crm.AccordianSummary}
      >
        <Box sx={gSx.Row}>
          {isSent
            ? <CheckCircleIcon sx={{ ...gSx.Crm.LeftIcon, color: gColor.green500 }} />
            : <AccountTreeOutlinedIcon sx={gSx.Crm.LeftIcon} />
          }
          <Typography variant='h5'>Workflows</Typography>
        </Box>
        {isSent &&
          <Typography color={gColor.green500} sx={{ paddingRight: 2 }}>Sent</Typography>
        }
      </AccordionSummary>

      <AccordionDetails>
        <TabContext value={tab}>

          <TabList onChange={handleChange} aria-label="templates tabs" sx={{ paddingLeft: "14px" }}>
            <Tab label="Start New" value="1" />
            <Tab label="Complete a Step" value="2" />
          </TabList>

          <TabPanel value={"1"} sx={{ p: 1 }} >
            <WealthboxWorkflowsStart
              event={event}
              contacts={contacts}
              wbConfig={wbConfig}
              setExpanded={setExpanded}
              disabled={disabled}
            />
          </TabPanel>

          <TabPanel value={"2"} sx={{ p: 1 }} >
            <WealthboxWorkflowsCompleteStep
              event={event}
              setExpanded={setExpanded}
            />
          </TabPanel>

        </TabContext>
      </AccordionDetails>
    </Accordion>

  )
}