/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_appendRedtailActivity } from '../models/Body_appendRedtailActivity';
import type { Body_auth } from '../models/Body_auth';
import type { Body_sendRedtailNotes } from '../models/Body_sendRedtailNotes';
import type { Body_sendRedtailTasks } from '../models/Body_sendRedtailTasks';
import type { CalendarDb } from '../models/CalendarDb';
import type { ClientDetail } from '../models/ClientDetail';
import type { RedtailActivity } from '../models/RedtailActivity';
import type { RedtailBase } from '../models/RedtailBase';
import type { RedtailCategory } from '../models/RedtailCategory';
import type { RedtailTeam } from '../models/RedtailTeam';
import type { RedtailUser } from '../models/RedtailUser';
import type { RedtailVisibility } from '../models/RedtailVisibility';
import type { RedtailWorkflow } from '../models/RedtailWorkflow';
import type { RedtailWorkflowTemplate } from '../models/RedtailWorkflowTemplate';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class RedtailService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Is Redtail Authed
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public isRedtailAuthed(): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/integrations/redtail/auth',
        });
    }

    /**
     * Authenticate
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public auth(
        requestBody: Body_auth,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/integrations/redtail/auth',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Redtail
     * @returns RedtailBase Successful Response
     * @throws ApiError
     */
    public getRedtailSettings(): CancelablePromise<RedtailBase> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/integrations/redtail/',
        });
    }

    /**
     * Disconnect
     * @returns any Successful Response
     * @throws ApiError
     */
    public disconnect(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/integrations/redtail/',
        });
    }

    /**
     * Get Redtail Calendar Db
     * @returns CalendarDb Successful Response
     * @throws ApiError
     */
    public getRedtailCalendarDb(): CancelablePromise<CalendarDb> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/integrations/redtail/calendar',
        });
    }

    /**
     * Sync Redtail Calendar
     * @returns any Successful Response
     * @throws ApiError
     */
    public syncRedtailCalendar(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/integrations/redtail/calendar/sync',
        });
    }

    /**
     * Import Contacts
     * @returns number Successful Response
     * @throws ApiError
     */
    public importRedtailContacts(): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/integrations/redtail/contacts/import',
        });
    }

    /**
     * Add Redtail Ids
     * @param eventId
     * @returns ClientDetail Successful Response
     * @throws ApiError
     */
    public addRedtailIds(
        eventId: string,
    ): CancelablePromise<Array<ClientDetail>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/integrations/redtail/contacts/add-redtail-ids',
            query: {
                'event_id': eventId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Redtail Events
     * @param startDate
     * @param endDate
     * @returns RedtailActivity Successful Response
     * @throws ApiError
     */
    public getRedtailEvents(
        startDate?: string,
        endDate?: string,
    ): CancelablePromise<Array<RedtailActivity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/integrations/redtailevents',
            query: {
                'start_date': startDate,
                'end_date': endDate,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Append Event
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public appendRedtailActivity(
        requestBody: Body_appendRedtailActivity,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/integrations/redtail/activities',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Send Notes
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public sendRedtailNotes(
        requestBody: Body_sendRedtailNotes,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/integrations/redtail/notes',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Send Tasks
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public sendRedtailTasks(
        requestBody: Body_sendRedtailTasks,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/integrations/redtail/tasks',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Users
     * @returns RedtailUser Successful Response
     * @throws ApiError
     */
    public getRedtailUsers(): CancelablePromise<Array<RedtailUser>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/integrations/redtail/users',
        });
    }

    /**
     * Get Teams
     * @returns RedtailTeam Successful Response
     * @throws ApiError
     */
    public getRedtailTeams(): CancelablePromise<Array<RedtailTeam>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/integrations/redtail/teams',
        });
    }

    /**
     * Set User Notify
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public setUserNotify(
        requestBody: RedtailUser,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/integrations/redtail/users/notify',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Default Team Notify
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public setTeamNotify(
        requestBody: RedtailTeam,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/integrations/redtail/teams/notify',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Visibility
     * @param visibility
     * @returns any Successful Response
     * @throws ApiError
     */
    public setRedtailVisibility(
        visibility: RedtailVisibility,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/integrations/redtail/visibility',
            query: {
                'visibility': visibility,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Users
     * @returns RedtailCategory Successful Response
     * @throws ApiError
     */
    public getRedtailCategories(): CancelablePromise<Array<RedtailCategory>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/integrations/redtail/categories',
        });
    }

    /**
     * Get Workflow Templates
     * @returns RedtailWorkflowTemplate Successful Response
     * @throws ApiError
     */
    public getWorkflowTemplates(): CancelablePromise<Array<RedtailWorkflowTemplate>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/integrations/redtail/workflow/templates',
        });
    }

    /**
     * Create Workflow
     * @param eventId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public createWorkflow(
        eventId: string,
        requestBody: RedtailWorkflow,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/integrations/redtail/workflow/create',
            query: {
                'event_id': eventId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Workflows
     * @returns RedtailWorkflow Successful Response
     * @throws ApiError
     */
    public getWorkflows(): CancelablePromise<Array<RedtailWorkflow>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/integrations/redtail/workflows',
        });
    }

}
