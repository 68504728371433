import React, { useEffect, useState } from 'react'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Alert, Button, Dialog, DialogActions, DialogTitle, IconButton, Menu, MenuItem, Snackbar, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { ClientDetail } from 'client'
import { useNavigate } from 'react-router-dom'
import { Path } from 'routes'
import { deleteClient } from 'service/clientApi'
import { useQueryClient } from '@tanstack/react-query'

interface Props {
  data?: ClientDetail
  disabled?: boolean
}

export default function ClientDropdownMenu({ data, disabled }: Props) {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const queryClient = useQueryClient()

  const [openDialog, setOpenDialog] = useState(false)
  const [busy, setBusy] = useState(false)
  const [err, setErr] = useState<string | undefined>()
  const [openSuccessSnack, setOpenSuccessSnack] = useState(false)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  function onMenuDeleteOpen() {
    setOpenDialog(true)
    setAnchorEl(null)
  }

  async function onDeleteEvent() {
    setBusy(true)
    setErr(undefined)

    const res = await deleteClient(data?.id)
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      setOpenSuccessSnack(true)
      queryClient.invalidateQueries({ queryKey: ['getClientsIndex'] })
      setOpenDialog(false)
      navigate(Path.Clients)
    }
    setBusy(false)
  }

  return (
    <>
      <IconButton
        onClick={handleClick}
        disabled={disabled}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={onMenuDeleteOpen}>Delete Contact</MenuItem>
      </Menu>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      >
        <DialogTitle>
          Confirm Delete Contact
        </DialogTitle>

        <Typography sx={{ color: 'tomato', padding: 3 }}>
          Warning: This Action is Irreversible
        </Typography>

        <DialogActions>
          <Button
            onClick={() => setOpenDialog(false)}
            color="primary"
            aria-label='Keep'
            sx={{ margin: '10px' }}
          >
            Keep
          </Button>

          <LoadingButton
            sx={{ margin: '10px' }}
            onClick={onDeleteEvent}
            color="error"
            loading={busy}
            autoFocus
            aria-label='Delete'
            variant='contained'
          >
            Delete
          </LoadingButton>
        </DialogActions>

        {err && <Typography color={'tomato'} sx={{ padding: 2 }}>
          {err}
        </Typography>
        }
      </Dialog >

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSuccessSnack}
        autoHideDuration={3000}
        onClose={() => setOpenSuccessSnack(false)}
      >
        <Alert
          variant="filled"
          severity="success"
        >
          Contact has been deleted
        </Alert>
      </Snackbar>
    </>
  )
}
