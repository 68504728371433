import React, { ChangeEvent, useState } from 'react'
import {
  Typography,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
  SxProps,
  Box,
  Switch,
} from '@mui/material'
import { Access, SharedUser } from '../../../client'
import Grid from '@mui/material/Grid2'
import ProfilePhoto from 'components/settings/ProfilePhoto'
import { gSx } from 'styles/Theme'

interface Props {
  userConfig: SharedUser
  disabled?: boolean
  allShared: SharedUser[]
  onSave: (target: SharedUser[]) => Promise<void>
}

export default function UserAccessLineItemSettings({ userConfig, disabled, allShared, onSave }: Props) {
  const access = userConfig.access

  const onAccess = async (event: SelectChangeEvent) => {
    const updated: SharedUser = {
      ...userConfig,
      access: event.target.value as Access
    }
    const updated_all = []
    for (const s of allShared) {
      (s.uid == userConfig.uid)
        ? updated_all.push(updated)
        : updated_all.push(s)
    }
    await onSave(updated_all)
  }

  async function onEmail(event: ChangeEvent<HTMLInputElement>, enabled: boolean) {
    const updated: SharedUser = {
      ...userConfig,
      email_transcript_done: enabled,
      email_summary_done: enabled
    }
    const updated_all = []
    for (const s of allShared) {
      (s.uid == userConfig.uid)
        ? updated_all.push(updated)
        : updated_all.push(s)
    }
    await onSave(updated_all)
  }

  return (
    <>
      <Grid size={5}>
        <Box sx={gSx.Row}>
          <ProfilePhoto photoUrl={userConfig.photo_url} width={40} />
          <Box sx={{ paddingLeft: 1 }}>
            <Typography>{userConfig?.name}</Typography>
            <Typography variant='caption'>{userConfig?.email}</Typography>
          </Box>
        </Box>
      </Grid>

      <Grid size={4}>
        <Box sx={{ maxWidth: 150 }}>
          <FormControl fullWidth>
            <Select
              value={access}
              onChange={onAccess}
              disabled={disabled}
            >
              <MenuItem value={Access.EDITOR}>Editor</MenuItem>
              <MenuItem value={Access.VIEWER}>Viewer</MenuItem>
              <MenuItem value={Access.REMOVE}>Remove</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Grid>

      <Grid size={3}>
        <Switch
          checked={userConfig.email_summary_done}
          onChange={onEmail}
          inputProps={{ 'aria-label': 'controlled' }}
          disabled={disabled}
        />
      </Grid>
    </>
  )
}
