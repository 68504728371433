import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Typography, Box, Card, IconButton, Snackbar, Alert, Link } from '@mui/material'
import 'firebase/auth'
import { ClientDetail, EventBase, FlowInputConfig, SalesforceApp, SalesforceFlow } from 'client'
import { MasterTask } from '../SelectTasks'
import Grid from '@mui/material/Grid2'
import { getSalesforceSettings, getSalesforceUsers, startSalesforceFlow } from 'service/integrations/salesforceApi'
import { LoadingButton } from '@mui/lab'
import useIsBlockedByPlan from 'hooks/useIsBlockedByPlan'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { gSx } from 'styles/Theme'
import CheckIcon from '@mui/icons-material/Check'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import SalesforceFlowUser from './SalesforceFlowUser'
import SalesforceFlowTasks from './SalesforceFlowTasks'
import SalesforceFlowText from './SalesforceFlowText'
import SalesforceFlowContactOpp from './SalesforceFlowContactOpp'
import SalesforceFlowTask from './SalesforceFlowTask'
import { logWarn } from 'log'
import { getEventTranscript } from 'service/eventApi'
import { retryDelayExpBackOffUptoSevenSecs, retryStopOnlyExpired } from 'hooks/hookHelpers'

interface QueryError {
  message: string;
  description: string;
  statusCode: string | number;
  status: string | number;
}

interface Props {
  event: EventBase
  app: SalesforceApp
  contacts: ClientDetail[]
  setActiveStep: Dispatch<SetStateAction<number>>
  flow?: SalesforceFlow
  tasks: MasterTask[]
  setExpanded: Dispatch<SetStateAction<boolean>>
  disabled: boolean
}

export default function SalesforceFlowMap({ app, event, contacts, setActiveStep, flow, tasks, setExpanded, disabled: disabledByParent }: Props) {
  const queryClient = useQueryClient()
  const hasContacts = contacts?.length > 0
  const [isValidated, setIsValidated] = useState(true)
  const [busy, setBusy] = useState(false)
  const [flowInputs, setFlowInputs] = useState<FlowInputConfig[]>([])
  const [err, setErr] = useState<string | undefined>()
  const disableSend = !hasContacts || busy || !isValidated || disabledByParent
  const [isSnackSuccess, setSnackSuccess] = useState(false)
  const apiName = flow?.name ?? ''
  const [isTranscriptValid, setIsTranscriptValid] = useState(true) // ie. no transcript

  const { data: sfSettings } = useQuery({
    queryKey: ['getSalesforceSettings'], queryFn: getSalesforceSettings,
    enabled: queryClient.getQueryData(['getSalesforceSettings']) === undefined,
  })
  const subDomain = sfSettings?.instance_url

  const { data: users } = useQuery({
    queryKey: ['getSalesforceUsers'], queryFn: getSalesforceUsers,
    enabled: queryClient.getQueryData(['getSalesforceUsers']) === undefined,
  })

  const { error: errorTranscript } = useQuery({
    queryKey: ['getEventTranscript', event.id],
    queryFn: async () => await getEventTranscript(event.id),
    retry: retryStopOnlyExpired,
    retryDelay: retryDelayExpBackOffUptoSevenSecs,
  })

  useEffect(() => {
    if (errorTranscript) {
      const queryError = errorTranscript as QueryError
      if (queryError.status === 410)
        setIsTranscriptValid(false)
    }
  }, [errorTranscript])

  useEffect(() => {
    setIsValidated(true)
    setErr(undefined)

    const sumErr = validateFlow(flow)
    if (sumErr.length > 0) {
      setIsValidated(false)
      setErr(`Flow Input Error: ${sumErr.join(', ')}.`)
    }
    if (validateSingleTask(flow, flowInputs))
      setIsValidated(false)

  }, [flow, flowInputs])

  async function onSend() {
    setBusy(true)
    const res = await startSalesforceFlow(event.id, app, apiName, flowInputs)
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      setErr(undefined)
      queryClient.invalidateQueries(['getEvent', event.id])
      setSnackSuccess(true)
      setExpanded(false)
    }
    setBusy(false)
  }

  if (!flow) return null

  return (
    <Box>
      <Card>
        <Box sx={gSx.Row}>
          <IconButton onClick={() => setActiveStep(s => s - 1)}>
            <ChevronLeftIcon />
          </IconButton>

          <Box sx={{ padding: 1 }} />
          <Box>
            <Typography>{flow.label}</Typography>
            <Typography variant='caption'>{flow.description}</Typography>
          </Box>
        </Box>
      </Card>

      <Typography>Define each {app} Flow Input to a FinMate Variable.</Typography>
      <Box sx={{ padding: 1 }} />

      <Grid container>
        <Grid size={4}>
          <Typography variant='h6'>{app} Flow Inputs</Typography>
        </Grid>
        <Grid size={8}>
          <Typography variant='h6'>FinMate Variables</Typography>
        </Grid>
      </Grid>
      {flow.inputs && flow.inputs.map(input => {
        const isArray = !!(input.maxOccurs && input.maxOccurs > 1)
        return (
          <Box key={input.name}>
            {input.sObjectType === "User" && users &&
              <SalesforceFlowUser
                input={input}
                setFlowInputs={setFlowInputs}
                allUsers={users}
              />
            }
            {(input.sObjectType === "Contact" || input.sObjectType === "Opportunity") &&
              <SalesforceFlowContactOpp
                input={input}
                setFlowInputs={setFlowInputs}
                contacts={contacts}
              />
            }
            {input.sObjectType === "Task" &&
              (
                isArray ?
                  <SalesforceFlowTasks
                    input={input}
                    setFlowInputs={setFlowInputs}
                    tasks={tasks}
                  /> :
                  <SalesforceFlowTask
                    input={input}
                    setFlowInputs={setFlowInputs}
                    tasks={tasks}
                  />
              )

            }
            {input.type === "STRING" &&
              <SalesforceFlowText
                eventId={event.id}
                input={input}
                setFlowInputs={setFlowInputs}
                isTranscriptValid={isTranscriptValid}
              />
            }
          </Box>
        )
      })}

      <Grid container>
        <Grid size={10}>
          {err &&
            <Box sx={{ paddingX: 2 }}>
              <Typography color={'tomato'} >
                {err}
              </Typography>
              <Typography color={'tomato'}>
                More error information may be in   <Link rel='noopener' target='_blank' href={`${subDomain}/lightning/setup/Pausedflows/home`}>Salesforce Flow Errors Page</Link>.
              </Typography>
            </Box>
          }

        </Grid>
        <Grid size={2}>
          <LoadingButton
            variant={event?.salesforce_flows_sent ? 'outlined' : 'contained'}
            onClick={onSend}
            disabled={disableSend}
            loading={busy}
          >
            Start Flow
          </LoadingButton>
        </Grid>
      </Grid>

      <Snackbar
        open={isSnackSuccess}
        autoHideDuration={3000}
        onClose={() => setSnackSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          variant="filled"
          icon={<CheckIcon fontSize="inherit" />}
          onClose={() => setSnackSuccess(false)}
          severity='success'
          sx={{ width: '100%' }}
        >
          {app} Flows Launched
        </Alert>
      </Snackbar>
    </Box >
  )
}

function validateFlow(flow?: SalesforceFlow) {

  const sumErr = []

  if (flow && flow.inputs) {
    let numUser = 0
    let numContact = 0
    let numOpportunity = 0
    const unrecognizedRecord = []

    for (const i of flow.inputs) {
      if (i.sObjectType == 'User') {
        numUser++
        if (i.maxOccurs && i.maxOccurs > 1)
          sumErr.push('User Record "Allow Multiple values" not allowed')
      }
      else if (i.sObjectType == 'Contact') {
        numContact++
        if (i.maxOccurs && i.maxOccurs > 1)
          sumErr.push('Contact Record "Allow Multiple values" not allowed')
      }

      else if (i.sObjectType == 'Opportunity') {
        numOpportunity++
        if (i.maxOccurs && i.maxOccurs > 1)
          sumErr.push('Opportunity Record "Allow Multiple values" not allowed')
      }

      else if (i.type == 'STRING') {
        if (i.maxOccurs && i.maxOccurs > 1)
          sumErr.push('String Type "Allow Multiple values" not allowed')
      }
      else if (i.sObjectType == 'Task') {
        // skip. do nothing
      }
      else {
        logWarn('Flow Input has unrecognized record', { 'flowInput': i })
        unrecognizedRecord.push(`${i.name} ${i.type} ${i.sObjectType}`)
      }
    }

    if (numUser > 1)
      sumErr.push('Max 1 User Record allowed')
    if (numContact > 1)
      sumErr.push('Max 1 Contact Record allowed')
    if (numOpportunity > 1)
      sumErr.push('Max 1 Opportunity Record allowed')
    if (unrecognizedRecord.length > 0)
      sumErr.push(`unrecognized records: ${unrecognizedRecord.join(', ')}`)
  }
  return sumErr
}

function validateSingleTask(flow?: SalesforceFlow, inputs?: FlowInputConfig[]) {
  let numSingleTask = 0
  let numSingleTaskInput = 0

  if (flow && flow.inputs) {
    for (const i of flow.inputs) {
      if (i.sObjectType == 'Task' && i.maxOccurs == 1) {
        numSingleTask++
      }
    }
  }

  if (inputs)
    for (const i of inputs) {
      if (i.input?.sObjectType == 'Task' && i.input.maxOccurs == 1) {
        numSingleTaskInput++
      }
    }

  return (numSingleTask != numSingleTaskInput)
}
