import React, { useEffect, useState } from 'react'
import { logInfo } from '../../log'
import { useAudioRecorder } from 'react-audio-voice-recorder'
import { Alert, Box, Button, Card, Dialog, DialogTitle, Divider, IconButton, LinearProgress, Snackbar, SxProps, TextField, Typography, useMediaQuery } from '@mui/material'
import { UploadFile } from 'service/eventApi'
import ReactPlayer from 'react-player'
import { Media } from 'service/media'
import { msToHhMmSs } from 'utils'
import PauseIcon from '@mui/icons-material/Pause'
import { gColor, gSx } from 'styles/Theme'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { AudioVisualizer } from 'react-audio-visualize'
import { LiveAudioVisualizer } from './LiveAudioVisualizer'
import { Origin } from 'client'
import CheckIcon from '@mui/icons-material/Check'
import { useWakeLock } from 'react-screen-wake-lock'
import { LoadingButton } from '@mui/lab'
import './styles.css'
import { isMobile } from 'react-device-detect'
import MobileWarnAndSuggest from './MobileWarnAndSuggest'
import WebRecorderSettings from 'components/settings/WebRecorderSettings'
import { getUserConfig } from 'service'

type State = 'New' | 'Recording' | 'Paused' | 'Recorded' | 'Uploaded' | 'Error'

// must do standalone pop. 
// do not do web recorder dialog, because dialog can randomly close while still recording.

export default function WebRecorderPopUpScreen() {
  const queryClient = useQueryClient()
  const isSmall = useMediaQuery('(max-width:600px)')
  const { isSupported, request: wakeOn, release: wakeOff } = useWakeLock()
  const [progress, setProgress] = useState(0)
  const [busy, setBusy] = useState(false)
  const [isSnackSuccess, setSnackSuccess] = useState(false)
  const [isDownloaded, setDownloaded] = useState(false)
  const [showMobileWarn, setShowMobileWarn] = useState(false)

  const [title, setTitle] = useState('From Web Recorder')
  const [state, setState] = useState<State>('New')
  const [audioUrl, setAudioUrl] = useState('')
  const [recordTime, setRecordTime] = useState('00:00')
  const [file, setFile] = useState<File>()
  const [err, setErr] = useState<string | undefined>()
  const [noAudioInput, setNoAudioInput] = useState(false)

  useEffect(() => {
    logInfo('Web Recorder Screen')
    reset()
  }, [])

  const { data: userConfig, isLoading } = useQuery({
    queryKey: ['getUserConfig'], queryFn: getUserConfig,
    enabled: queryClient.getQueryData(['getUserConfig']) === undefined,
  })

  useEffect(() => {
    if (isMobile)
      setShowMobileWarn(true)
  }, [isMobile])

  const {
    startRecording,
    stopRecording,
    togglePauseResume,
    recordingBlob,
    isRecording,
    isPaused,
    recordingTime,
    mediaRecorder
  } = useAudioRecorder()

  useEffect(() => {
    logInfo(`WebRecorderDialog ${state}`)
    state == 'Recording'
      ? document.title = "Recording"
      : document.title = "Web Recorder"
  }, [state])

  useEffect(() => {
    setRecordTime(msToHhMmSs(Math.floor(recordingTime)))
  }, [recordingTime])

  useEffect(() => {
    if (isRecording) {
      setState('Recording')
      isSupported && wakeOn()
    } else {
      isSupported && wakeOff()
    }
  }, [isRecording])

  useEffect(() => {
    if (recordingBlob) {
      const url = URL.createObjectURL(recordingBlob)
      setAudioUrl(url)

      const now = (new Date()).toLocaleString()
      const f = new File([recordingBlob], `finmate_webrecorder_${now}.webm`, {
        type: recordingBlob.type,
        lastModified: Date.now()
      })
      setFile(f)
    }
  }, [recordingBlob])

  useEffect(() => {
    if (userConfig?.web_recorder_auto_download)
      onDownload()
  }, [file])

  useEffect(() => {
    const hasRecordingNotUploaded = (state == 'Paused' || state == 'Recording' || state == 'Recorded') && open
    const beforeUnloadHandler = (ev: any) => {
      logInfo('Web Recorder Close Browser Confirm Request')

      if (hasRecordingNotUploaded) {
        ev.preventDefault()
        ev.returnValue = 'Are you sure you want to close?'
      }
    }
    if (hasRecordingNotUploaded)
      window.addEventListener('beforeunload', beforeUnloadHandler)

    return () => { window.removeEventListener('beforeunload', beforeUnloadHandler) }
  }, [state, open])

  useEffect(() => {
    if (noAudioInput) {
      console.warn("No audio input detected. Please check your microphone settings.")
      document.title = "No Audio Input!"
    } else {
      state == 'Recording'
        ? document.title = "Recording"
        : document.title = "Web Recorder"
    }
  }, [noAudioInput])

  function onPause() {
    togglePauseResume()
    setState('Paused')
  }
  function onResume() {
    togglePauseResume()
    setState('Recording')
  }
  async function onStop() {
    stopRecording()
    setState('Recorded')
    // wait for useEffect recordingBlob to appear
  }

  async function onUpload() {
    setBusy(true)
    setErr(undefined)
    const now = (new Date()).toLocaleString()
    let newTitle = title
    if (newTitle.length == 0) {
      newTitle = `Web Recorded ${now}`
    }
    if (!file)
      return
    const track = { title, "filename": file.name, "filetype": file.type, "filesize": file.size }
    logInfo('WebRecorderDialog start', track)
    const res = await UploadFile(file, newTitle, Origin.WEB_RECORDER, setProgress)
    if (res instanceof Error) {
      setState('Error')
      setErr(res.message)
    } else {
      setState('Uploaded')
      await queryClient.invalidateQueries({ queryKey: ['getEvents'] })
      setSnackSuccess(true)
    }
    setBusy(false)
  }

  function reset() {
    stopRecording()
    setTitle('')
    setAudioUrl('')
    setRecordTime('00:00')
    setProgress(0)
    setState('New')
    setDownloaded(false)
  }

  const onDownload = async () => {
    if (!file)
      return
    logInfo('WebRecorderDialog Download file start')
    const url = window.URL.createObjectURL(file)
    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = url
    a.download = file.name
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(url)
    setDownloaded(true)
    logInfo('WebRecorderDialog Download file done')
  }

  const sxContainer: SxProps = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingX: isSmall ? 0 : 5, // max card width for small screens
    paddingBottom: 5,
    maxWidth: 550,
  }

  const sxWarnings: SxProps = {
    padding: '5px',
    color: 'DarkRed',
    fontSize: isSmall ? 12 : 14
  }

  const sxBlackNotes: SxProps = {
    padding: '5px',
    fontSize: isSmall ? 12 : 14
  }

  return (
    <Box sx={{...gSx.CenterVertical, marginLeft: '32px'}}> {/* marginLeft to center */}

      <Box sx={sxContainer}>

        <Box sx={sxTitle}>
          <Box component='img' alt='Finmate AI logo' sx={sxIcon} src={Media.logo100} />
          <Typography variant='h3' sx={{ color: gColor.green500 }}>Web Recorder</Typography>
        </Box>

        <Card sx={sxCard}>
          {
            (state == 'New' || state == 'Paused' || state == 'Recording') &&
            <div style={{ position: 'relative', height: '50px' }} >
              {mediaRecorder && state == 'Recording' &&
                <LiveAudioVisualizer mediaRecorder={mediaRecorder} setNoAudioInput={setNoAudioInput} height={'50px'} width={'50%'} barColor={'lightblue'} />}
              <Typography variant='h1' sx={{ fontWeight: 'bold' }} textAlign={'center'} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>{recordTime}</Typography>
            </div>
          }

          {state === 'New' && (
            <>
              <Box sx={{ padding: 3 }} />
              <Button
                sx={sxButtonMain}
                variant='contained'
                onClick={startRecording}>{`start notetaking`}
              </Button>
            </>
          )}
          {state === 'Recording' && (
            <>
              <Box sx={gSx.Row}>
                <div className="red-pulse" />
                <Typography color='tomato'>Recording</Typography>
                <Box sx={{ paddingX: 2 }} />
                <Button
                  startIcon={<PauseIcon />}
                  variant='outlined'
                  onClick={onPause}
                >
                  <Typography>pause</Typography>
                </Button>
              </Box>

              <Box sx={{ padding: 1 }}></Box>
              <Button
                sx={sxButtonMain}
                variant='contained'
                onClick={onStop}
              >
                Finish Notetaking
              </Button>
            </>
          )}

          {state === 'Paused' && (
            <>
              <Box sx={{ padding: 3.2 }}></Box>
              <Button
                variant="contained"
                sx={sxButtonMain}
                onClick={onResume}
              >
                Resume Notetaking
              </Button>
            </>
          )}

          {(state === 'Recorded' || state == 'Uploaded' || state == 'Error') && (
            <>
              <Typography>Review your recording, and Upload.</Typography>
              <Box sx={{ padding: 1 }}></Box>
              {recordingBlob && (
                <AudioVisualizer
                  blob={recordingBlob}
                  width={isMobile ? 300 : 400}
                  height={50}
                  barWidth={1}
                  gap={1}
                  barColor={'lightblue'}
                />
              )}
              <ReactPlayer
                style={{ height: 1 }}
                url={audioUrl}
                controls
                height='50px'
                width={isMobile ? '100%' : 400}
                preload="auto"
              />
              <Box sx={{ padding: 1 }}></Box>
              <TextField
                label='Meeting Title'
                variant="outlined"
                value={title}
                onChange={(t) => setTitle(t.target.value)}
                sx={sxTextField}
                disabled={state == 'Uploaded'}
              />
            </>
          )}
          <Box sx={{ padding: 1 }}></Box>

          {(state == 'Recorded' || state == 'Uploaded' || state == 'Error') &&
            <LoadingButton
              startIcon={<CloudUploadIcon />}
              onClick={onUpload}
              sx={sxButtonMain}
              variant='contained'
              disabled={(state !== 'Recorded' && state !== 'Error') || busy}
              loading={busy}
            >
              {
                state == 'Uploaded' ? 'Upload Complete' : 'Upload'
              }
            </LoadingButton>
          }

          {busy &&
            <Box sx={{ width: '100%', paddingTop: 1 }}>
              <LinearProgress variant="determinate" value={progress} />
            </Box>
          }

          {err && <Typography color={'tomato'}>{err}</Typography>}

          <Box sx={{ alignSelf: 'center', paddingBottom: 1 }} >
            {state == 'Uploaded' && !busy &&
              <Box sx={{ alignSelf: 'center', marginTop: 2 }}>
                <Button onClick={reset} variant='outlined'>
                  Start a New Recording
                </Button>
              </Box>
            }
          </Box>

        </Card>

        <WebRecorderSettings />
        {(state == 'Recorded' || state == 'Uploaded' || state == 'Error') &&
          <Box sx={{ alignSelf: 'center', marginTop: 2 }}>
            <Button variant='outlined' onClick={onDownload}>
              Download to your computer
            </Button>
          </Box>
        }

        <Divider sx={{ marginY: 3 }} />

        {!isDownloaded && state != 'Uploaded' &&
          <Typography sx={sxWarnings}>
            Do not refresh the web browser, you will lose current recording.
          </Typography >
        }

        <MobileWarnAndSuggest />
        {
          (state == 'New' || state == 'Recording') &&
          <Typography sx={sxBlackNotes}>
            Certain states, recording conversations may require consent from all parties involved.
          </Typography>
        }

        <Snackbar
          open={noAudioInput}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert severity="warning" variant="filled" sx={{ width: '100%' }}>
            No audio input detected. Please check your microphone settings.
          </Alert>
        </Snackbar>
      </Box >

      <Dialog open={showMobileWarn} onClose={() => setShowMobileWarn(false)}>
        <Box sx={{ padding: 2 }}>
          <DialogTitle>Mobile Usage of Web Recorder</DialogTitle>

          <MobileWarnAndSuggest />
          <Box sx={{ padding: 2 }} />
          <Button
            variant='contained'
            onClick={() => setShowMobileWarn(false)}
            sx={{ width: '100%' }}
          >
            Acknowledge
          </Button>
        </Box>
      </Dialog>

      <Snackbar
        open={isSnackSuccess}
        autoHideDuration={3000}
        onClose={() => setSnackSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          variant="filled"
          icon={<CheckIcon fontSize="inherit" />}
          onClose={() => setSnackSuccess(false)}
          severity='success'
          sx={{ width: '100%' }}
        >
          Recording Successfully Uploaded
        </Alert>
      </Snackbar>
    </Box >
  )
}

const sxTitle: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  paddingBottom: 1,
}

const sxCard: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}

const sxIcon: SxProps = {
  width: 26,
  height: 35,
  marginRight: 2,
}
const sxTextField: SxProps = {
  width: '100%',
  marginY: '10px'
}

const sxButtonMain: SxProps = { // main action buttons
  paddingY: 1.5,
  paddingX: 6
}
