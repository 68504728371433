import { Box, Button, Card, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Skeleton, TextField, Tooltip, Typography } from "@mui/material"
import Grid from "@mui/material/Grid2"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { getEnterprise, getEnterpriseSubscription, numStandardAllocatedToOrgs, numStarterAllocatedToOrgs } from "service/enterpriseApi"
import EditIcon from '@mui/icons-material/Edit'
import { useEffect, useState } from "react"
import { logInfo } from "log"
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import { gSx } from "styles/Theme"

export default function EnterprisePlanCard() {
  const queryClient = useQueryClient()
  const [showDialog, setDialog] = useState(false)
  const [busy, setBusy] = useState(false)
  const [err, setErr] = useState<string | undefined>()
  const [isSnackSuccess, setSnackSuccess] = useState(false)
  const [numStandard, setNumStandard] = useState(0)
  const [numStarter, setNumStarter] = useState(0)

  const { data: ent } = useQuery({
    queryKey: ['getEnterprise'], queryFn: getEnterprise,
  })

  const { data: sub, isLoading, refetch } = useQuery({
    queryKey: ['getEnterpriseSubscription'], queryFn: getEnterpriseSubscription,
  })
  const isSubscribed = !!sub?.subscription
  const standardPaidByEnt = sub?.standard?.quantity ?? 0
  const starterPaidByEnt = sub?.starter?.quantity ?? 0
  const standardAllocatedToOrgs = numStandardAllocatedToOrgs(ent)
  const starterAllocatedToOrgs = numStarterAllocatedToOrgs(ent)
  const standardAvailableByEnt = standardPaidByEnt - standardAllocatedToOrgs
  const starterAvailableByEnt = starterPaidByEnt - starterAllocatedToOrgs

  useEffect(() => {
    setNumStandard(sub?.standard?.quantity ?? 0)
    setNumStarter(sub?.starter?.quantity ?? 0)
  }, [sub])

  useEffect(() => {
    if (numStandard < 0)
      setNumStandard(0)
  }, [numStandard])

  useEffect(() => {
    if (numStarter < 0)
      setNumStarter(0)
  }, [numStarter])

  // async function onSave() {
  //   logInfo('Checkout Dialog, Pressed Subscribed', { numStandard, numStarter })
  //   setBusy(true)
  //   setErr(undefined)

  //   if (isSubscribed) {
  //     const res = await updateEnterpriseSubscription(numStandard, numStarter, ent?.id)
  //     if (res instanceof Error) {
  //       setErr(res.message)
  //     } else {
  //       setSnackSuccess(true)
  //       await refetch()
  //       setDialog(false)
  //     }
  //   } else {
  //     const res = await createEnterpriseSubscription(numStandard, numStarter, ent?.id)
  //     if (res instanceof Error) {
  //       setErr(res.message)
  //     } else {
  //       window.location.href = res
  //       setDialog(false)
  //     }
  //   }
  //   setBusy(false)
  // }

  return (
    <Card sx={{ flexGrow: 1, margin: 0 }}>
      <CardHeader title='Enterprise Plan'
        action={
          // <IconButton onClick={() => setDialog(true)}>
          //   <EditIcon fontSize='small' />
          // </IconButton>

          <Tooltip title="Contact us to modify your Enterprise Plan">
            <HelpOutlineOutlinedIcon fontSize='small' />
          </Tooltip>
        }
      />

      {isLoading
        ? <Skeleton variant="rectangular" width={'100%'} height={50} />
        : <>
          <Typography variant='h6'>Paid</Typography>
          <Grid container>
            <Grid size={9}>
              <Typography>Standard Seats</Typography>
              <Typography>Starter Seats</Typography>
            </Grid>
            <Grid size={3} textAlign={'right'}>
              <Typography>{standardPaidByEnt}</Typography>
              <Typography>{starterPaidByEnt}</Typography>
            </Grid>
          </Grid>
          <Box sx={{ padding: 1 }} />
          <Typography variant='h6'>Unallocated</Typography>
          <Grid container>
            <Grid size={9}>
              <Typography>Standard Seats</Typography>
              <Typography>Starter Seats</Typography>
            </Grid>
            <Grid size={3} textAlign={'right'}>
              <Typography>{standardAvailableByEnt}</Typography>
              <Typography>{starterAvailableByEnt}</Typography>
            </Grid>
          </Grid>

        </>
      }

      {/* <Dialog open={showDialog} onClose={() => setDialog(false)}>
        <DialogTitle>
          Edit Enterprise Plan
        </DialogTitle>

        <DialogContent>

          <Grid container>
            <Grid size={6}>
              <Typography>Standard Seats</Typography>
            </Grid>
            <Grid size={6} textAlign={'right'}>
              <TextField
                id='standard'
                aria-label='standard'
                label='Standard Seats'
                variant='outlined'
                sx={{ width: 120, marginY: 1 }}
                disabled={busy}
                value={numStandard}
                type="number"
                onChange={(t) => setNumStandard(Number(t.target.value))}
                size="small"

              />
            </Grid>
            <Grid size={6}>
              <Typography>Starter Seats</Typography>
            </Grid>
            <Grid size={6} textAlign={'right'}>
              <TextField
                sx={{ width: 120, marginY: 1 }}
                id='starter'
                aria-label='starter'
                label='Starter Seats'
                variant='outlined'
                disabled={busy}
                value={numStarter}
                type="number"
                onChange={(t) => setNumStarter(Number(t.target.value))}
                size="small"
              />
            </Grid>
          </Grid>

          {err && <Typography color='error'>{err}</Typography>}
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setDialog(false)}>Cancel</Button>
          <Button onClick={onSave}>Save</Button>
        </DialogActions>

      </Dialog> */}
    </Card>
  )
}
