import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import DashboardIcon from '@mui/icons-material/Dashboard'
import SettingsIcon from '@mui/icons-material/Settings'
import { Box, SxProps, List, Typography, ButtonBase, Divider, Grid2 } from '@mui/material'
import { Path } from 'routes'
import { Media } from 'service/media'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import SideNavItem from './SideNavItem'
import SideNavUpdate from './SideNavUpdate'
import NavBottomAccount from './NavBottomAccount'
import PeopleIcon from '@mui/icons-material/People'
import HoursRemaining from 'components/help/HoursRemaining'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import VideocamIcon from '@mui/icons-material/Videocam'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import CloudSyncIcon from '@mui/icons-material/CloudSync'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getOrg } from 'service/orgsApi'
import { auth } from 'service/api'
import { OrgUserRole, SalesforceApp } from 'client'
import { SideNavSalesforce, SideNavRedtail, SideNavWealthbox, SideNavRingCentral } from './SideNavItems'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import RegionBadge from 'components/auth/RegionBadge'
import { gSx } from 'styles/Theme'
import { getSalesforceSettings } from 'service/integrations/salesforceApi'
import { getRedtailSettings } from 'service/integrations/redtailApi'
import { getWealthboxSettings } from 'service/integrations/wealthboxApi'
import { getRingCentralProfile } from 'service/integrations/ringCentralApi'
import WhatsNew from './WhatsNew'
import { useTranslation } from 'react-i18next'
import GuideButton from './GuideButton'
import GroupWorkIcon from '@mui/icons-material/GroupWork'
import { getEnterprise, isEnterpriseViewer } from 'service/enterpriseApi'
import UpdatePopup from './UpdatePopup'

interface Props {
  drawerWidth: number
  setOpen: (open: boolean) => void
}

export default function NavContents({ drawerWidth, setOpen }: Props) {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const fbUser = auth.currentUser
  const [showSettings, setShowSettings] = useState(false)

  useEffect(() => {
    const p = window.location.href
    const show = p.includes(Path.Settings)
      || p.includes(Path.RecorderSettings)
      || p.includes(Path.Enterprise)
      || p.includes(Path.Organization)
      || p.includes(Path.Billing)
      || p.includes(Path.ZoomSettings)
      || p.includes(Path.RedtailSettings)
      || p.includes(Path.WealthboxSettings)
      || p.includes(Path.Integrations)

    setShowSettings(show)
  }, [window.location.href])

  const { data: org } = useQuery({
    queryKey: ['getOrg'], queryFn: getOrg,
    enabled: queryClient.getQueryData(['getOrg']) === undefined,
  })

  const { data: ent } = useQuery({
    queryKey: ['getEnterprise'], queryFn: getEnterprise,
    enabled: queryClient.getQueryData(['getEnterprise']) === undefined,
  })
  const isEntViewer = isEnterpriseViewer(ent)

  const { data: salesforce } = useQuery({
    queryKey: ['getSalesforceSettings'], queryFn: getSalesforceSettings,
    enabled: queryClient.getQueryData(['getSalesforceSettings']) === undefined,
  })
  const isSalesforce = !!salesforce?.sf_uid
  const salesforceApp = salesforce?.app ?? SalesforceApp.SALESFORCE

  const { data: redtail } = useQuery({
    queryKey: ['getRedtailSettings'], queryFn: getRedtailSettings,
    enabled: queryClient.getQueryData(['getRedtailSettings']) === undefined,
  })
  const isRedtail = !!redtail?.user_id

  const { data: wb } = useQuery({
    queryKey: ['getWealthboxSettings'], queryFn: getWealthboxSettings,
    enabled: queryClient.getQueryData(['getWealthboxSettings']) === undefined,
  })
  const isWealthbox = !!wb?.user_id

  const isAnyCrmsConnected = isSalesforce || isRedtail || isWealthbox

  const { data: ringcentral } = useQuery({
    queryKey: ['getRingCentralProfile'], queryFn: getRingCentralProfile,
    enabled: queryClient.getQueryData(['getRingCentralProfile']) === undefined,
  })
  const isRingcentral = ringcentral?.id

  useEffect(() => {
    const isInactive = org?.org_user_list
      .some(u => u.uid == fbUser?.uid && u.org_user_role == OrgUserRole.INACTIVE)

    if (isInactive)
      navigate(Path.BlockInactive)
  })

  const sxContainer: SxProps = {
    overflowX: 'hidden',
    width: `${drawerWidth}px`,
    paddingTop: '28px',
    height: '100vh',
  }

  const sxFooter: SxProps = {
    width: `${drawerWidth}px`,
  }

  return (
    <Grid2 container direction="column" wrap='nowrap' sx={sxContainer}>

      <Grid2 size='auto'>
        <Box sx={gSx.CenterVertical}>
          <ButtonBase
            aria-label='finmate AI home'
            onClick={() => navigate(Path.Home)}
          >
            <Box
              component='img'
              aria-label='Finmate AI logo'
              alt='Finmate AI logo'
              sx={sxLogo}
              src={Media.logo100}
            />
            <Typography variant='h4' sx={sxTitle}>
              FinMate AI
            </Typography>
          </ButtonBase>
        </Box>

        <Box sx={gSx.CenterVertical}>
          <RegionBadge />
        </Box>
      </Grid2 >

      <Grid2 size="grow" sx={{ overflow: 'auto' }}>
        <List
          sx={{ width: '100%', maxWidth: 360 }}
          component='nav'
          aria-labelledby='nested-list-subheader'
        >
          {
            !showSettings ? (
              <>
                {isEntViewer &&
                  <SideNavItem
                    icon={<GroupWorkIcon />}
                    path={Path.Enterprise}
                    title={'Enterprise'}
                    setOpen={setOpen}
                  />
                }

                <SideNavItem
                  icon={<DashboardIcon />}
                  path={Path.Home}
                  title={t('Dashboard')}
                  setOpen={setOpen}
                />

                <SideNavItem
                  icon={<CalendarMonthIcon />}
                  path={Path.Calendar}
                  title={t('Calendar')}
                  setOpen={setOpen}
                />

                <SideNavItem
                  icon={<PeopleIcon />}
                  path={Path.Clients}
                  title={t('Contacts')}
                  setOpen={setOpen}
                />

                <SideNavItem
                  icon={<SettingsIcon />}
                  path={Path.Settings}
                  title={t('Settings')}
                  setOpen={setOpen}
                />

                <SideNavItem
                  icon={<CloudSyncIcon />}
                  path={Path.Integrations}
                  title={t('Integrations')}
                  setOpen={setOpen}
                />

                <SideNavItem
                  icon={<AutoAwesomeIcon />}
                  path={Path.Templates}
                  title={t('Templates')}
                  setOpen={setOpen}
                />

                <Divider sx={{ margin: 2 }} />

                <SideNavUpdate />

                <WhatsNew />

                <GuideButton />
              </>
            ) : (
              <>
                <ButtonBase
                  onClick={() => {
                    setShowSettings(false)
                    navigate(Path.Home)
                  }}
                  sx={{ padding: 1.6 }}
                >
                  <ArrowBackIcon sx={{ width: 18 }} />
                  <Box sx={{ paddingX: 1 }} />
                  <Typography variant='h5'>
                    {t('Settings')}
                  </Typography>
                </ButtonBase>

                <Divider sx={{ marginX: 2, marginY: 1.5 }} />

                <SideNavItem
                  icon={<PersonOutlineIcon />}
                  path={Path.Settings}
                  title={t('General')}
                  setOpen={setOpen}
                />

                <SideNavItem
                  icon={<VideocamIcon />}
                  path={Path.RecorderSettings}
                  title={t('Recorder')}
                  setOpen={setOpen}
                />

                {isEntViewer &&
                  <SideNavItem
                    icon={<GroupWorkIcon />}
                    path={Path.Enterprise}
                    title={'Enterprise'}
                    setOpen={setOpen}
                  />
                }

                <SideNavItem
                  icon={<CorporateFareIcon />}
                  path={Path.Organization}
                  title={t('Organization')}
                  setOpen={setOpen}
                />

                <SideNavItem
                  icon={<AccountBalanceIcon />}
                  path={Path.Billing}
                  title={t('Billing')}
                  setOpen={setOpen}
                />

                <SideNavUpdate />

                <Divider sx={{ marginX: 2, marginY: 1.5 }} />
                <Typography sx={{ paddingTop: 2, paddingX: 2, paddingBottom: 1, fontWeight: 'normal' }}>{t('Integrations')}</Typography>

                <SideNavItem
                  icon={<CloudSyncIcon />}
                  path={Path.Integrations}
                  title={t('Overview')}
                  setOpen={setOpen}
                />

                {!isAnyCrmsConnected &&
                  <>
                    <SideNavRedtail setOpen={setOpen} />
                    <SideNavWealthbox setOpen={setOpen} />
                    <SideNavSalesforce setOpen={setOpen} />
                  </>
                }
                {isRedtail && <SideNavRedtail setOpen={setOpen} />}
                {isWealthbox && <SideNavWealthbox setOpen={setOpen} />}
                {isSalesforce &&
                  <SideNavSalesforce setOpen={setOpen} app={salesforceApp} />
                }

                {isRingcentral && <SideNavRingCentral setOpen={setOpen} />}

                <SideNavItem
                  icon={<Box
                    component="img"
                    sx={sxIcon}
                    src={Media.zoomLogo}
                    alt="Zoom"
                  />}
                  path={Path.ZoomSettings}
                  title={'Zoom'}
                  setOpen={setOpen}
                />

                {/* Scrolling past bottom contents */}
                <Box sx={{ paddingY: '160px' }} />
              </>
            )

          }

        </List>
      </Grid2>
      <Grid2 size='auto'>
        <Box sx={sxFooter}>
          <HoursRemaining />
          {/* <SideNavUpdate /> */}
          <Divider sx={{ marginX: 2 }} />
          <NavBottomAccount drawerWidth={drawerWidth} />
        </Box>
      </Grid2>
    </Grid2 >
  )
}

const sxLogo: SxProps = {
  height: 30,
  width: 22,
  resizeMode: 'contain',
  marginRight: '8px',
}

const sxTitle: SxProps = {
  cursor: 'pointer',
  textDecoration: 'none',
  color: 'primary.main'
}

const sxIcon: SxProps = {
  width: 25,
  height: 25,
}