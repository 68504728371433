import { StripeSession, ProductName, StripePrice, FinmateLineItem, OrgUser, StripeDiscount } from "client"
import { Api, auth, config } from "./api"
import { logErr, logInfo } from "log"
import { loadStripe } from '@stripe/stripe-js'
import { StripeSubscription } from "client/models/StripeSubscription"
import { FinmateSubscriptionPlans } from "client/models/FinmateSubscriptionPlans"
import { StripeProduct } from "client/models/StripeProduct"
import { FinmateSubscription } from "client/models/FinmateSubscription"
import { getErrorMessage } from "utils"
import { getOrg, standardAvailableForOrg, starterAvailableForOrg, updateOrgUser } from "./orgsApi"

export const stripePromise = loadStripe(config.stripePublishableKey)

export async function createCheckoutSession(numStandard: number, numStarter: number, numLite: number, targetOrgUser?: OrgUser): Promise<string | Error> {

  try {
    logInfo('Create Checkout Session', { numStandard, numStarter, numLite })
    const api = await Api()
    return await api.pay.createCheckoutSession(numStandard, numStarter, numLite, targetOrgUser)
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('createCheckoutSession', { err })
    return err
  }
}

export async function getSession(sessionId: string): Promise<StripeSession | undefined> {
  try {
    const api = await Api()
    return await api.pay.getSession(sessionId)
  } catch (e) {
    logErr('getSession', { e })
  }
}

export async function getCustomer() {
  try {
    const api = await Api()
    return await api.pay.getCustomer()
  } catch (e) {
    logErr('getCustomer', { e })
  }
}

export async function updateCustomer(email: string) {
  try {
    logInfo('Update Customer', { email })
    const api = await Api()
    return await api.pay.updateCustomer(email)
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('updateCustomer', { err })
    return err
  }
}

export async function getSubscriptionPlans(): Promise<FinmateSubscriptionPlans | undefined> {
  try {
    const api = await Api()
    return await api.pay.getSubscriptionPlans()
  } catch (e) {
    logErr('getSubscriptionPlans', { e })
  }
}

export async function getSubscription(): Promise<FinmateSubscription | undefined> {
  try {
    const api = await Api()
    return await api.pay.getSubscription()
  } catch (e) {
    logErr('getSubscription', { e })
  }
}

export async function getOrgSubscriptionAsEnterpriseAdmin(orgId: string): Promise<FinmateSubscription | undefined> {
  try {
    const api = await Api()
    return await api.pay.getOrgSubscriptionAsEnterpriseAdmin(orgId)
  } catch (e) {
    logErr('getOrgSubscriptionAsEnterpriseAdmin', { e })
  }
}

export async function cancelSubscription(): Promise<StripePrice | undefined> {
  try {
    logInfo('Cancel Subscription')
    const api = await Api()
    return await api.pay.cancelSubscription()
  } catch (e) {
    logErr('cancelSubscription', { e })
  }
}

export async function modifyPayment(): Promise<string | undefined> {
  try {
    logInfo('Modify Payment')
    const api = await Api()
    return await api.pay.modifyPayment()
  } catch (e) {
    logErr('modifyPayment', { e })
  }
}

export async function addToSubscription(standard: number, starter: number, lite: number, targetUser?: OrgUser): Promise<StripeSubscription | Error> {
  try {
    logInfo('Add to Subscription', { standard, starter, lite })
    const api = await Api()
    return await api.pay.addToSubscription(standard, starter, lite, targetUser)
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('addToSubscription', { err })
    return err
  }
}

export async function swapSubscription(target?: OrgUser): Promise<StripeSubscription | Error> {
  if (!target)
    return new Error('missing input')
  try {
    logInfo('Swap Subscription', { target })
    const api = await Api()
    return await api.pay.swapSubscription(target)
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('swapSubscription', { err })
    return err
  }
}

export function getNumPayPlan(targetPlan: ProductName, sub?: FinmateSubscription) {
  if (!sub)
    return 0
  if (targetPlan == ProductName.STANDARD)
    return sub.standard?.quantity ?? 0
  if (targetPlan == ProductName.STARTER)
    return sub.starter?.quantity ?? 0
  if (targetPlan == ProductName.LITE)
    return sub.starter?.quantity ?? 0
  return 0
}

export function calculateDiscountAmount(subtotal: number, discount?: StripeDiscount) {
  if (!discount)
    return 0

  if (discount.coupon?.amount_off)
    return discount.coupon?.amount_off / 100

  if (discount.coupon?.percent_off)
    return discount.coupon?.percent_off * subtotal / 100

  return 0
}

export function getOrgUserPrice(sub?: FinmateSubscription, orgUser?: OrgUser) {
  if (!orgUser || !sub)
    return 'unknown'
  if (orgUser?.org_user_plan == ProductName.STANDARD) {
    return (sub?.standard?.price?.unit_amount ?? 0) / 100
  }
  if (orgUser?.org_user_plan == ProductName.STARTER) {
    return (sub?.starter?.price?.unit_amount ?? 0) / 100
  }
  return 'unknown'
}

export async function singleUserPaidAutoApplyLicense() {
  // at this point, user just paid. if 1 user in org, and available seat is standard or starter, auto apply seat.

  const org = await getOrg()
  if (!org)
    return false
  const numUsers = org.org_user_list.length
  if (numUsers > 1)
    return false

  const sub = await getSubscription()
  const standardRemaining = standardAvailableForOrg(org, sub)
  const starterRemaining = starterAvailableForOrg(org, sub)
  if (numUsers == 1 && standardRemaining == 1 && starterRemaining == 0) {
    logInfo('singleUserPaidAutoApplyLicense', { "plan": ProductName.STANDARD })

    const targetUser = org.org_user_list[0]
    targetUser.org_user_plan = ProductName.STANDARD
    const res = updateOrgUser(targetUser)
    if (!(res instanceof Error))
      return true
  }
  else if (numUsers == 1 && starterRemaining == 1 && standardRemaining == 0) {
    logInfo('singleUserPaidAutoApplyLicense', { "plan": ProductName.STARTER })
    const targetUser = org.org_user_list[0]
    targetUser.org_user_plan = ProductName.STARTER
    const res = updateOrgUser(targetUser)
    if (!(res instanceof Error))
      return true
  }
  return false
}