import React, { useState, useEffect, useMemo, useRef } from 'react'
import {
  SxProps, Card, Box, Snackbar, Alert, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, List, ListItem, ListItemText
} from '@mui/material'
import { ClientData } from '../../client'
import ChildrenTable from 'components/finances/ChildrenTable'
import PersonalPropertyTable from 'components/finances/PersonalPropertyTable'
import BankAccountsTable from 'components/finances/BankAccountsTable'
import InvestmentAccountsTable from 'components/finances/InvestmentAccountsTable'
import CertificatesOfDepositTable from 'components/finances/CertificatesOfDepositTable'
import CollageFundTable from 'components/finances/CollageFundTable'
import RetirementPlansEmployerTable from 'components/finances/RetirementPlansEmployerTable'
import CreditCardsTable from './CreditCardsTable'
import MortgagesTable from './MortgagesTable'
import LoansTable from './LoansTable'
import RetirementGoalTable from './RetirementGoalTable'
import TravelGoalTable from './TravelGoalTable'
import WeddingGoalTable from './WeddingGoalTable'
import CelebrationGoalTable from './CelebrationGoalTable'
import CollegeGoalTable from './CollegeGoalTable'
import PrivateSchoolGoalTable from './PrivateSchoolGoalTable'
import NewBusinessGoalTable from './NewBusinessGoalTable'
import NewHomeGoalTable from './NewHomeGoalTable'
import HomeImprovementGoalTable from './HomeImprovementGoalTable'
import MajorPurchaseGoalTable from './MajorPurchaseGoalTable'
import ProvideCareGoalTable from './ProvideCareGoalTable'
import GiftOrDonationGoalTable from './GiftOrDonationGoalTable'
import OtherGoalTable from './OtherGoalTable'
import AnnuitiesTable from './AnnuitiesTable'
import LeaveBequestGoalTable from './LeaveBequestTable'
import RetirementPlansIndividualTable from 'components/finances/RetirementPlansIndividualTable'
import PensionTable from 'components/finances/PensionTable'
import OtherAssetTable from 'components/finances/OtherAssetTable'
import BusinessAssetTable from 'components/finances/BusinessAssetTable'
import ClientList from 'components/finances/ClientList'
import { hideTitle, UpdateClientDataInterface, isMemberIdInData, deleteClientInData } from './financesUtils'

interface Props {
  eventId: string
  clientDataInput?: ClientData
  updatePfClientData?: (eventId: string, updatedClientData: ClientData) => (void)
  datasetByTitle?: any
  dynamicValues?: any
  readOnly: boolean
  foldEmptyTables: boolean
  hideEmptyTables: boolean
}

export default function FinancesTables({ eventId, clientDataInput, updatePfClientData, datasetByTitle, dynamicValues, readOnly, hideEmptyTables, foldEmptyTables }: Props) {

  const [openErrorSnack, setOpenErrorSnack] = useState<string | undefined>()
  const [openSuccessSnack, setOpenSuccessSnack] = useState<string | undefined>()

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [confirmDialogLoading, setConfirmDialogLoading] = useState(false)
  const [cannotDeleteDialogOpen, setCannotDeleteDialogOpen] = useState(false)
  const [selectedMemberId, setSelectedMemberId] = useState('')
  const [groupsContainsMemberId, setGroupsContainsMemberId] = useState<string[]>([])
  const [clientData, setClientData] = useState<ClientData>()

  useEffect(() => {
    if (clientDataInput) {
      setClientData(clientDataInput)
    }
  }, [clientDataInput])

  async function onUpdatedData<
    P extends keyof ClientData,
    S extends keyof NonNullable<ClientData[P]>,
  >(
    type: P,
    subType: S,
    updated: NonNullable<ClientData[P]>[S],
  ) {
    if (eventId) {
      setClientData(prevClientData => {
        const updatedClientData = JSON.parse(JSON.stringify(prevClientData))
        if (!updatedClientData[type]) {
          updatedClientData[type] = {} as NonNullable<ClientData[P]>
        }
        (updatedClientData[type] as NonNullable<ClientData[P]>)[subType] = updated

        if (updatePfClientData) {
          updatePfClientData(eventId, updatedClientData)
        }
        return updatedClientData
      })
    }
  }

  function onDeleteClient(memberId: string) {
    if (clientData) {
      const groups = isMemberIdInData(clientData, memberId)
      if (groups.length == 0) {
        // show confirm delete client dialog
        setSelectedMemberId(memberId)
        setConfirmDialogOpen(true)
      } else {
        // show cannot delete dialog due to existing data associated with this memeber
        setGroupsContainsMemberId(groups)
        setCannotDeleteDialogOpen(true)
      }
    }
  }

  async function handleConfirmDelete() {
    if (clientData) {
      setConfirmDialogLoading(true)
      setClientData(prevClientData => {
        if (!prevClientData) return prevClientData
        const updatedClientData = deleteClientInData(prevClientData, selectedMemberId)
        if (updatePfClientData) {
          updatePfClientData(eventId, updatedClientData)
        }
        return updatedClientData
      })
      setConfirmDialogLoading(false)
      setConfirmDialogOpen(false)
    }
  }

  return (
    <>

      <ClientList eventId={eventId} clientData={clientData} onUpdate={onUpdatedData} onDeleteClient={onDeleteClient} datasetByTitle={datasetByTitle} dynamicValues={dynamicValues} readOnly={readOnly} />

      {
        !hideTitle(hideEmptyTables, clientData?.['Family Members']) &&
        <Box sx={stickyHeading}>
          <Typography variant="h5">Family Members</Typography>
          <Typography variant="subtitle1" color="textSecondary">
            Children, other family members
          </Typography>
        </Box>
      }

      <ChildrenTable title="Children" type={'Family Members'} subType={'Children'} eventId={eventId} dataInput={clientData?.['Family Members']?.['Children']} onUpdate={onUpdatedData<'Family Members', 'Children'>} onDeleteClient={onDeleteClient} datasetGroup={datasetByTitle?.['Family Members']?.['groups']?.['Children']} dynamicValues={dynamicValues} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      {
        !hideTitle(hideEmptyTables, clientData?.Assets) &&
        <Box sx={stickyHeading}>
          <Typography variant="h4">Assets</Typography>
          <Typography variant="subtitle1" color="textSecondary">
            Bank Accounts, Certificates Of Deposit, Investment Accounts, Collage Fund, Personal Properties
          </Typography>
        </Box>
      }

      <BankAccountsTable title="Bank Accounts" type={'Assets'} subType={'Bank Accounts'} eventId={eventId} dataInput={clientData?.['Assets']?.['Bank Accounts']} onUpdate={onUpdatedData<'Assets', 'Bank Accounts'>} datasetGroup={datasetByTitle?.['Assets']?.['groups']?.['Bank Accounts']} dynamicValues={dynamicValues} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <CertificatesOfDepositTable title="Certificates Of Deposit" type={'Assets'} subType={'Certificates of Deposit'} eventId={eventId} dataInput={clientData?.['Assets']?.['Certificates of Deposit']} onUpdate={onUpdatedData<'Assets', 'Certificates of Deposit'>} datasetGroup={datasetByTitle?.['Assets']?.['groups']?.['Certificates of Deposit']} dynamicValues={dynamicValues} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <InvestmentAccountsTable title="Taxable Investment Accounts" type={'Assets'} subType={'Taxable Investment Accounts'} eventId={eventId} dataInput={clientData?.['Assets']?.['Taxable Investment Accounts']} onUpdate={onUpdatedData<'Assets', 'Taxable Investment Accounts'>} datasetGroup={datasetByTitle?.['Assets']?.['groups']?.['Taxable Investment Accounts']} dynamicValues={dynamicValues} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <CollageFundTable title="College Funds" type={'Assets'} subType={'College Funds'} eventId={eventId} dataInput={clientData?.['Assets']?.['College Funds']} onUpdate={onUpdatedData<'Assets', 'College Funds'>} datasetGroup={datasetByTitle?.['Assets']?.['groups']?.['College Funds']} dynamicValues={dynamicValues} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <AnnuitiesTable title="Annuities" type={'Assets'} subType={'Annuities'} eventId={eventId} dataInput={clientData?.['Assets']?.['Annuities']} onUpdate={onUpdatedData<'Assets', 'Annuities'>} datasetGroup={datasetByTitle?.['Assets']?.['groups']?.['Annuities']} dynamicValues={dynamicValues} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <PersonalPropertyTable title="Personal Properties" type={'Assets'} subType={'Personal Property'} eventId={eventId} dataInput={clientData?.['Assets']?.['Personal Property']} onUpdate={onUpdatedData<'Assets', 'Personal Property'>} datasetGroup={datasetByTitle?.['Assets']?.['groups']?.['Personal Property']} dynamicValues={dynamicValues} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <BusinessAssetTable title="Business Assets" type={'Assets'} subType={'Business Assets'} eventId={eventId} dataInput={clientData?.['Assets']?.['Business Assets']} onUpdate={onUpdatedData<'Assets', 'Business Assets'>} datasetGroup={datasetByTitle?.['Assets']?.['groups']?.['Business Assets']} dynamicValues={dynamicValues} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <OtherAssetTable title="Other Assets" type={'Assets'} subType={'Other Assets'} eventId={eventId} dataInput={clientData?.['Assets']?.['Other Assets']} onUpdate={onUpdatedData<'Assets', 'Other Assets'>} datasetGroup={datasetByTitle?.['Assets']?.['groups']?.['Other Assets']} dynamicValues={dynamicValues} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      {
        !hideTitle(hideEmptyTables, clientData?.['Retirement Plans']) &&
        <Box sx={stickyHeading}>
          <Typography variant="h4">Retirement Plans</Typography>
          <Typography variant="subtitle1" color="textSecondary">
            401(k), Roth 401(k), 403(b), 456(b), Simple IRA, SEP IRA, IRA, Roth IRA, Pensions
          </Typography>
        </Box>
      }

      <RetirementPlansEmployerTable title="401(k)" type={'Retirement Plans'} subType={'401(k)'} eventId={eventId} dataInput={clientData?.['Retirement Plans']?.['401(k)']} onUpdate={onUpdatedData<'Retirement Plans', '401(k)'>} datasetGroup={datasetByTitle?.['Retirement Plans']?.['groups']?.['401(k)']} dynamicValues={dynamicValues} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <RetirementPlansEmployerTable title="Roth 401(k)" type={'Retirement Plans'} subType={'Roth 401(k)'} eventId={eventId} dataInput={clientData?.['Retirement Plans']?.['Roth 401(k)']} onUpdate={onUpdatedData<'Retirement Plans', 'Roth 401(k)'>} datasetGroup={datasetByTitle?.['Retirement Plans']?.['groups']?.['Roth 401(k)']} dynamicValues={dynamicValues} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <RetirementPlansEmployerTable title="403(b)" type={'Retirement Plans'} subType={'403(b)'} eventId={eventId} dataInput={clientData?.['Retirement Plans']?.['403(b)']} onUpdate={onUpdatedData<'Retirement Plans', '403(b)'>} datasetGroup={datasetByTitle?.['Retirement Plans']?.['groups']?.['403(b)']} dynamicValues={dynamicValues} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <RetirementPlansEmployerTable title="457(b)" type={'Retirement Plans'} subType={'457(b)'} eventId={eventId} dataInput={clientData?.['Retirement Plans']?.['457(b)']} onUpdate={onUpdatedData<'Retirement Plans', '457(b)'>} datasetGroup={datasetByTitle?.['Retirement Plans']?.['groups']?.['457(b)']} dynamicValues={dynamicValues} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <RetirementPlansEmployerTable title="Simple IRA" type={'Retirement Plans'} subType={'Simple IRA'} eventId={eventId} dataInput={clientData?.['Retirement Plans']?.['Simple IRA']} onUpdate={onUpdatedData<'Retirement Plans', 'Simple IRA'>} datasetGroup={datasetByTitle?.['Retirement Plans']?.['groups']?.['Simple IRA']} dynamicValues={dynamicValues} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <RetirementPlansEmployerTable title="SEP IRA" type={'Retirement Plans'} subType={'SEP IRA'} eventId={eventId} dataInput={clientData?.['Retirement Plans']?.['SEP IRA']} onUpdate={onUpdatedData<'Retirement Plans', 'SEP IRA'>} datasetGroup={datasetByTitle?.['Retirement Plans']?.['groups']?.['SEP IRA']} dynamicValues={dynamicValues} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <RetirementPlansIndividualTable title="IRA" type={'Retirement Plans'} subType={'IRA'} eventId={eventId} dataInput={clientData?.['Retirement Plans']?.IRA} onUpdate={onUpdatedData<'Retirement Plans', 'IRA'>} datasetGroup={datasetByTitle?.['Retirement Plans']?.['groups']?.['IRA']} dynamicValues={dynamicValues} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <RetirementPlansIndividualTable title="Roth IRA" type={'Retirement Plans'} subType={'Roth IRA'} eventId={eventId} dataInput={clientData?.['Retirement Plans']?.['Roth IRA']} onUpdate={onUpdatedData<'Retirement Plans', 'Roth IRA'>} datasetGroup={datasetByTitle?.['Retirement Plans']?.['groups']?.['Roth IRA']} dynamicValues={dynamicValues} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <PensionTable title="Pensions" type={'Retirement Plans'} subType={'Pensions'} eventId={eventId} dataInput={clientData?.['Retirement Plans']?.['Pensions']} onUpdate={onUpdatedData<'Retirement Plans', 'Pensions'>} datasetGroup={datasetByTitle?.['Retirement Plans']?.['groups']?.['Pensions']} dynamicValues={dynamicValues} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      {
        !hideTitle(hideEmptyTables, clientData?.Liabilities) &&
        <Box sx={stickyHeading}>
          <Typography variant="h4">Liabilities</Typography>
          <Typography variant="subtitle1" color="textSecondary">
            Credit Cards, Mortgages, Loans
          </Typography>
        </Box>
      }

      <CreditCardsTable title="Credit Cards" type={'Liabilities'} subType={'Credit Cards'} eventId={eventId} dataInput={clientData?.Liabilities?.['Credit Cards']} onUpdate={onUpdatedData<'Liabilities', 'Credit Cards'>} datasetGroup={datasetByTitle?.['Liabilities']?.['groups']?.['Credit Cards']} dynamicValues={dynamicValues} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <MortgagesTable title="Mortgages" type={'Liabilities'} subType={'Mortgages'} eventId={eventId} dataInput={clientData?.Liabilities?.['Mortgages']} onUpdate={onUpdatedData<'Liabilities', 'Mortgages'>} datasetGroup={datasetByTitle?.['Liabilities']?.['groups']?.['Mortgages']} dynamicValues={dynamicValues} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <LoansTable title="Loans" type={'Liabilities'} subType={'Loans'} eventId={eventId} dataInput={clientData?.Liabilities?.['Loans']} onUpdate={onUpdatedData<'Liabilities', 'Loans'>} datasetGroup={datasetByTitle?.['Liabilities']?.['groups']?.['Loans']} dynamicValues={dynamicValues} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      {
        !hideTitle(hideEmptyTables, clientData?.Liabilities) &&
        <Box sx={stickyHeading}>
          <Typography variant="h4">Goals</Typography>
          <Typography variant="subtitle1" color="textSecondary">
            Retirement, Travel, Wedding, Celebration, College, Private School, New Business, New Home, Home Improvement, Major Purchase, Provide Care, Gift or Donation, Leave Bequest, Other Goal
          </Typography>
        </Box>
      }

      <RetirementGoalTable title="Retirement Goals" type={'Goals'} subType={'Retirement'} eventId={eventId} dataInput={clientData?.Goals?.['Retirement']} onUpdate={onUpdatedData<'Goals', 'Retirement'>} datasetGroup={datasetByTitle?.['Goals']?.['groups']?.['Retirement']} dynamicValues={dynamicValues} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <TravelGoalTable title="Travel Goals" type={'Goals'} subType={'Travel'} eventId={eventId} dataInput={clientData?.Goals?.['Travel']} onUpdate={onUpdatedData<'Goals', 'Travel'>} datasetGroup={datasetByTitle?.['Goals']?.['groups']?.['Travel']} dynamicValues={dynamicValues} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <WeddingGoalTable title="Wedding Goals" type={'Goals'} subType={'Wedding'} eventId={eventId} dataInput={clientData?.Goals?.['Wedding']} onUpdate={onUpdatedData<'Goals', 'Wedding'>} datasetGroup={datasetByTitle?.['Goals']?.['groups']?.['Wedding']} dynamicValues={dynamicValues} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <CelebrationGoalTable title="Celebration Goals" type={'Goals'} subType={'Celebration'} eventId={eventId} dataInput={clientData?.Goals?.['Celebration']} onUpdate={onUpdatedData<'Goals', 'Celebration'>} datasetGroup={datasetByTitle?.['Goals']?.['groups']?.['Celebration']} dynamicValues={dynamicValues} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <CollegeGoalTable title="College Goals" type={'Goals'} subType={'College'} eventId={eventId} dataInput={clientData?.Goals?.['College']} onUpdate={onUpdatedData<'Goals', 'College'>} datasetGroup={datasetByTitle?.['Goals']?.['groups']?.['College']} dynamicValues={dynamicValues} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <PrivateSchoolGoalTable title="Private School Goals" type={'Goals'} subType={'Private School'} eventId={eventId} dataInput={clientData?.Goals?.['Private School']} onUpdate={onUpdatedData<'Goals', 'Private School'>} datasetGroup={datasetByTitle?.['Goals']?.['groups']?.['Private School']} dynamicValues={dynamicValues} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <NewBusinessGoalTable title="New Business Goals" type={'Goals'} subType={'New Business'} eventId={eventId} dataInput={clientData?.Goals?.['New Business']} onUpdate={onUpdatedData<'Goals', 'New Business'>} datasetGroup={datasetByTitle?.['Goals']?.['groups']?.['New Business']} dynamicValues={dynamicValues} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <NewHomeGoalTable title="New Home Goals" type={'Goals'} subType={'New Home'} eventId={eventId} dataInput={clientData?.Goals?.['New Home']} onUpdate={onUpdatedData<'Goals', 'New Home'>} datasetGroup={datasetByTitle?.['Goals']?.['groups']?.['New Home']} dynamicValues={dynamicValues} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <HomeImprovementGoalTable title="Home Improvement Goals" type={'Goals'} subType={'Home Improvement'} eventId={eventId} dataInput={clientData?.Goals?.['Home Improvement']} onUpdate={onUpdatedData<'Goals', 'Home Improvement'>} datasetGroup={datasetByTitle?.['Goals']?.['groups']?.['Home Improvement']} dynamicValues={dynamicValues} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <MajorPurchaseGoalTable title="Major Purchase Goals" type={'Goals'} subType={'Major Purchase'} eventId={eventId} dataInput={clientData?.Goals?.['Major Purchase']} onUpdate={onUpdatedData<'Goals', 'Major Purchase'>} datasetGroup={datasetByTitle?.['Goals']?.['groups']?.['Major Purchase']} dynamicValues={dynamicValues} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <ProvideCareGoalTable title="Provide Care Goals" type={'Goals'} subType={'Provide Care'} eventId={eventId} dataInput={clientData?.Goals?.['Provide Care']} onUpdate={onUpdatedData<'Goals', 'Provide Care'>} datasetGroup={datasetByTitle?.['Goals']?.['groups']?.['Provide Care']} dynamicValues={dynamicValues} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <GiftOrDonationGoalTable title="Gift or Donation Goals" type={'Goals'} subType={'Gift or Donation'} eventId={eventId} dataInput={clientData?.Goals?.['Gift or Donation']} onUpdate={onUpdatedData<'Goals', 'Gift or Donation'>} datasetGroup={datasetByTitle?.['Goals']?.['groups']?.['Gift or Donation']} dynamicValues={dynamicValues} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <LeaveBequestGoalTable title="Leave Bequest Goals" type={'Goals'} subType={'Leave Bequest'} eventId={eventId} dataInput={clientData?.Goals?.['Leave Bequest']} onUpdate={onUpdatedData<'Goals', 'Leave Bequest'>} datasetGroup={datasetByTitle?.['Goals']?.['groups']?.['Leave Bequest']} dynamicValues={dynamicValues} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <OtherGoalTable title="Other Goals" type={'Goals'} subType={'Other Goal'} eventId={eventId} dataInput={clientData?.Goals?.['Other Goal']} onUpdate={onUpdatedData<'Goals', 'Other Goal'>} datasetGroup={datasetByTitle?.['Goals']?.['groups']?.['Other Goal']} dynamicValues={dynamicValues} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!openErrorSnack}
        autoHideDuration={6000}
        onClose={() => setOpenErrorSnack(undefined)}
      >
        <Alert variant='filled' severity='error'>
          {openErrorSnack}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!openSuccessSnack}
        autoHideDuration={5000}
        onClose={() => setOpenSuccessSnack(undefined)}
      >
        <Alert variant='filled' severity='success'>
          {openSuccessSnack}
        </Alert>
      </Snackbar>

      <ConfirmDeleteDialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)} onConfirm={handleConfirmDelete} loading={confirmDialogLoading} />
      <CannotDeleteDialog open={cannotDeleteDialogOpen} onClose={() => setCannotDeleteDialogOpen(false)} associatedGroups={groupsContainsMemberId} />
    </>
  )
}

const stickyHeading: SxProps = {
  position: 'sticky',
  top: -25,
  backgroundColor: 'white',
  zIndex: 1,
  paddingTop: '20px',
  paddingBottom: "5px",
  paddingLeft: "0px",
  width: '100%',
  overflow: 'hidden',
}

interface DeleteDialogProps {
  open: boolean
  onClose: () => void
  onConfirm: () => void
  loading: boolean
  associatedGroups: string[]
}

export const ConfirmDeleteDialog = ({ open, onClose, onConfirm, loading }: Omit<DeleteDialogProps, 'associatedGroups'>) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>Confirm Deletion</DialogTitle>
      <DialogContent>
        <DialogContentText id="delete-dialog-description">
          Are you sure you want to delete this client?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onConfirm} loading={loading} color="error" autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export const CannotDeleteDialog = ({ open, onClose, associatedGroups }: Omit<DeleteDialogProps, 'onConfirm' | 'loading'>) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>Cannot Delete Client</DialogTitle>
      <DialogContent>
        <DialogContentText >
          This client cannot be deleted because it is associated with the following data:
        </DialogContentText>
        <List sx={{ pt: 1, pb: 1 }}>
          {associatedGroups.map((group, index) => (
            <ListItem key={index} sx={{ py: 0.5 }}>
              <ListItemText primary={group} />
            </ListItem>
          ))}
        </List>
        <Typography variant="body2" color="text.secondary">
          You must first remove this client from these groups before you can delete it.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}