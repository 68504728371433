import React from 'react'
import { Alert, Box, ClickAwayListener, IconButton, Popper, SxProps, Typography } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

export default function DefinitionsPlanIconDialog() {

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popper' : undefined

  return (
    <>
      <IconButton onClick={handleClick}>
        <InfoOutlinedIcon fontSize='small' />
      </IconButton>

      <Popper id={id} open={open} anchorEl={anchorEl} sx={sxPopper}>
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
          <Box sx={{ border: 1, p: 3, bgcolor: 'background.paper' }} onClick={handleClick}>
            <Typography variant='h5'>Plan</Typography>

            <Typography><b>Standard: </b> Includes meeting note-taking; Unlimited hours/month.</Typography>
            <Typography><b>Starter: </b> Includes meeting note-taking; 20 hours/month.</Typography>
            <Typography><b>Lite: </b> Access to shared events. Viewer and Editor Permissions. </Typography>
            <Typography><b>Expired: </b> Features disabled. Viewer only Permissions.</Typography>
          </Box>
        </ClickAwayListener>
      </Popper>
    </>
  )
}

const sxPopper: SxProps = {
  zIndex: 1500,
}