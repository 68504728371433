import React from 'react'
import { Box, Link, SxProps, Typography } from '@mui/material'
import GoogleCalendarSignInButton from '../auth/GoogleCalendarSignInButton'
import MicrosoftCalendarButton from 'components/auth/MicrosoftCalendarButton'
import { hasAuthedEmailLink, hasAuthedGoogle, hasAuthedMicrosoft } from 'utils'
import { gSx } from 'styles/Theme'

export default function SignInCalendarScreen() {
  const urlParams = new URLSearchParams(location.search)
  const platform = urlParams.get('platform')
  const showOnlyGoogle = platform == 'google'
  const showOnlyMicrosoft = platform == 'microsoft'

  const msAuth = hasAuthedMicrosoft()
  const gAuth = hasAuthedGoogle()
  const emailAuth = hasAuthedEmailLink()

  if (showOnlyGoogle)
    return (
      <Box sx={gSx.CenterVertical}>
        <GoogleCalendarSignInButton />
        <GoogleLegal />
      </Box>
    )

  if (showOnlyMicrosoft)
    return (
      <Box sx={gSx.CenterVertical}>
        <MicrosoftCalendarButton />
        <MsLegal />
      </Box>
    )

  return (
    <Box sx={gSx.CenterVertical}>
      {(msAuth || emailAuth) &&
        <Box sx={{ paddingY: 1 }}>
          <MicrosoftCalendarButton />
        </Box>
      }

      {(gAuth || emailAuth) &&
        <Box sx={{ paddingY: 1 }}>
          <GoogleCalendarSignInButton />
        </Box>
      }

      {(msAuth || emailAuth) && <MsLegal />}

      {(gAuth || emailAuth) && <GoogleLegal />}

    </Box>
  )
}

function GoogleLegal() {
  return (
    <>
      <Box sx={{ padding: 1 }} />
      <Typography variant='caption'>
        {`FinMate AI's use and transfer to any other app of information received from Google APIs will adhere to `}
        <Link href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes">Google API Services User Data Policy</Link>
        {`, including the Limited Use requirements.`}
      </Typography>
    </>
  )
}

function MsLegal() {
  return (
    <>
      <Box sx={{ padding: 1 }} />
      <Typography variant='caption'>
        {`FinMate AI's use and transfer to any other app of information received from Microsoft APIs will adhere to `}
        <Link href="https://learn.microsoft.com/en-us/legal/microsoft-apis/terms-of-use">Microsoft API Services User Data Policy</Link>
        {`, including the Limited Use requirements.`}
      </Typography>
    </>
  )
}

const sxContainer: SxProps = {
  display: 'flex',
  flexDirection: 'column',

}