import React from 'react'
import { Typography, Skeleton, CardHeader, Card } from '@mui/material'
import { getOrg, numActiveOrgUsersByPlan } from '../../service/orgsApi'
import { ProductName } from 'client'
import Grid from '@mui/material/Grid2'
import { useQuery } from '@tanstack/react-query'

export default function ActiveOrgSeats() {

  const { data: org, isLoading } = useQuery({
    queryKey: ['getOrg'], queryFn: getOrg,
  })

  return (
    <Card sx={{ margin: 0, flexGrow: 1 }}>
      <CardHeader title={`Active Seats`} />
      <Grid container>

        <Grid size={11}>
          <Typography>Standard Seats</Typography>
          <Typography>Starter Seats</Typography>
          <Typography>Lite Seats</Typography>
        </Grid>
        <Grid size={1}>
          {isLoading
            ? <Skeleton variant="rectangular" width={'100%'} height={40} />
            : (
              <>
                <Typography>
                  {numActiveOrgUsersByPlan(ProductName.STANDARD, org?.org_user_list)}
                </Typography>

                <Typography>
                  {numActiveOrgUsersByPlan(ProductName.STARTER, org?.org_user_list)}
                </Typography>

                <Typography>
                  {numActiveOrgUsersByPlan(ProductName.LITE, org?.org_user_list)}
                </Typography>
              </>
            )
          }
        </Grid>
      </Grid>
    </Card>
  )
}
