import React, { useState } from 'react'
import { Tab, SxProps } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import MyEventsList from './MyEventsList'
import SharedEventsList from './SharedEventsList'
import { useStore } from 'hooks/useStore'
import { logInfo } from 'log'
import { observer } from 'mobx-react-lite'

export default observer(EventsList)
function EventsList() {
  const [tabValue, setTabValue] = useState("1")

  const store = useStore()
  const p = store.User.getEventSearchParams()

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue)
    if (p.contact && newValue == '2') {
      logInfo('Event list per contact and shared', { "contact": p.contact })
    }
  }

  return (
    <TabContext value={tabValue}>
      <TabList sx={sxTabList} onChange={handleChange}>
        <Tab
          sx={sxTabList}
          label='My Meetings'
          value='1'
        />
        <Tab
          sx={sxTabList}
          label='Shared Meetings'
          value='2'
        />

      </TabList>
      <TabPanel sx={sxTabPanel} value='1'>
        <MyEventsList />
      </TabPanel>
      <TabPanel sx={sxTabPanel} value='2'>
        <SharedEventsList />
      </TabPanel>
    </TabContext>
  )
}

const sxTabList: SxProps = {
  marginLeft: 0.5,
  marginTop: 0.5,
  minHeight: '30px',
}

const sxTabPanel: SxProps = {
  padding: '0',
}