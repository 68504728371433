import React, { useEffect, useState } from 'react'

import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'

import { getOrg } from 'service/orgsApi'
import SubjectIcon from '@mui/icons-material/Subject'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, IconButton, Radio, RadioGroup, Stack, ToggleButton, ToggleButtonGroup, Tooltip, Typography, SxProps, Chip } from '@mui/material'
import { logInfo } from 'log'
import { Api, auth } from '../../service/api'
import { SampleOutputDialog, SampleOutputHighlightOptions } from './SampleOutputDialog'
import { CustomizerLoading } from '../Skeletons'
import { ModuleIdentifier, SubPromptOptionFormatType, SubPromptOptionPointOfView, ModuleSubPrompt, SubPromptTemplate, ModuleOptionLevelOfDetailType } from '../../client'
import MeetingTypeSectionsDialog from './MeetingTypeSectionsDialog'
import ModuleExamples from '../event/ModuleExamples'
import TemplateOwner from './TemplateOwner'
import CustomizerCardHeader from './CustomizerCardHeader'

interface Props {
  open: boolean
  targetModuleId: string
  onClose: () => void
}

export function MeetingTypeCustomizer({ open, targetModuleId, onClose }: Props) {
  const queryClient = useQueryClient()
  const [editedCustomModule, setEditedCustomModule] = useState<ModuleIdentifier | null>(null)
  const [moduleId, setModuleId] = useState<string>("")
  const [sectionsDialogOpen, setSectionsDialogOpen] = useState(false)

  const [sectionsMap, setSectionsMap] = useState<Map<string, SubPromptTemplate>>(new Map())

  const [expandedSection, setExpandedSection] = useState<string | null>(null)

  const [openSampleOutputDialog, setOpenSampleOutputDialog] = useState(false)
  const [sampleHighlightOptions, SetSampleHighlightOptions] = useState<SampleOutputHighlightOptions>({})
  const [subpromptTemplateId, setSubpromptTemplateId] = useState("")
  const [sectionTitle, setSectionTitle] = useState("")

  const [openAddCondition, setOpenAddCondition] = useState(false)

  const fbUser = auth.currentUser

  const { data: module, isLoading } = useQuery({
    queryKey: ['getMeetingTypesModule', moduleId],
    queryFn: runQuery,
  })

  async function runQuery() {
    if (moduleId) {
      const api = await Api()
      return await api.customizations.getMeetingTypesModule(moduleId)
    }
    return null
  }

  const { data: org, isLoading: isLoadingOrg } = useQuery({
    queryKey: ['getOrg'],
    queryFn: async () => await getOrg(),
  })

  const isCustomizable = module?.subprompt_list && module?.subprompt_list?.length > 0

  const { data: sections } = useQuery({
    queryKey: ['getAvailableMeetingTypeSections'],
    queryFn: getAvailableMeetingTypeSections,
  })

  async function getAvailableMeetingTypeSections() {
    const api = await Api()
    return await api.customizations.getAvailableMeetingTypeSections()
  }
  useEffect(() => {
    if (open) {
      // reset all
      setSectionsDialogOpen(false)
      logInfo("Template Meeting Type Customizer", { targetModuleId })
    }
  }, [open])

  useEffect(() => {
    if (sections) {
      const map = new Map(sections.map((item) => [String(item.id), item]))
      setSectionsMap(map)
    }
  }, [sections])

  useEffect(() => {
    if (targetModuleId) {
      setModuleId(targetModuleId)
    }
  }, [targetModuleId])

  useEffect(() => {
    if (module) {
      setEditedCustomModule(module)
    }
  }, [module])

  function isModuleEditable() {
    if (module && !isLoadingOrg) {
      if (module.ownership?.owner_id == fbUser?.uid)
        return true
      if (module.ownership?.org_id == org?.org_id) {
        if (module.edit_access?.editable_by_all_members) {
          return true
        }
        // TODO: implement per org user permission 
        // if(module.edit_access?.member_uid_list && module.edit_access?.member_uid_list.includes( firebaseId ) ){
        // }
      }
    }
    return false
  }

  function buildModuleSampleOutput() {
    // FE only - BE will overwrite the sample output on update to prevent http injection attack
    if (editedCustomModule && editedCustomModule.subprompt_list && editedCustomModule.subprompt_list.length > 0 && sections) {
      let newSampleOutput = ""
      editedCustomModule.subprompt_list.forEach(function (subprompt) {
        let matchingSample = "<h3>" + (subprompt.heading ?? subprompt.template_ui_title) + "</h3><p style=\"color:LightSteelBlue;\">Sample Output Coming Soon</p>"
        const matchingSection = sections.find(section => section.id === subprompt.template_id)
        if (matchingSection && matchingSection.sample_output_list && matchingSection.sample_output_list.length > 0) {
          const matchingSampleObj = matchingSection.sample_output_list.find(sample => sample.options?.format_type == subprompt.options?.format_type)
          matchingSample = matchingSampleObj?.text ?? matchingSample
        }
        newSampleOutput += matchingSample
      })
      return newSampleOutput
    }
  }

  useEffect(() => {
    if (editedCustomModule && editedCustomModule.subprompt_list && editedCustomModule.subprompt_list.length > 0 && sections) {
      const newSampleOutput = buildModuleSampleOutput()
      if (newSampleOutput != editedCustomModule?.sample_output) {
        setEditedCustomModule((prev) => ({ ...prev, sample_output: newSampleOutput }))
      }
    }
  }, [editedCustomModule, sections])

  const isEditable = isModuleEditable()

  const handlePointOfView = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isEditable) return
    if (editedCustomModule?.subprompt_affix_list && editedCustomModule?.subprompt_affix_list.length > 0) {
      setEditedCustomModule((prev) => ({
        ...prev,
        subprompt_affix_list: prev?.subprompt_affix_list?.map((subPrompt) => {
          if (subPrompt.template_ui_title === "summarize_system_prefix") {
            return {
              ...subPrompt,
              options: {
                ...subPrompt?.options,
                point_of_view: event.target.value as SubPromptOptionPointOfView
              }
            }
          }
          return subPrompt
        })
      }))
    }
  }

  const handleLevelOfDetail = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isEditable) return
    if (editedCustomModule?.subprompt_affix_list && editedCustomModule?.subprompt_affix_list.length > 0) {
      setEditedCustomModule((prev) => ({
        ...prev,
        options: {
          ...prev?.options,
          level_of_detail: event.target.value as ModuleOptionLevelOfDetailType
        }
      }))
    }
  }

  const handleFormatChange = (value: string | null, template_id: string) => {
    if (editedCustomModule) {
      setEditedCustomModule((prev) => ({
        ...prev,
        subprompt_list: prev?.subprompt_list?.map((subPrompt) => {
          if (!subPrompt.block_option_change && template_id === subPrompt.template_id) {
            let limit = 1
            if (value == SubPromptOptionFormatType.LIST) {
              limit = 5 // backend may update the limit based on other config
            }
            return {
              ...subPrompt,
              options: {
                ...subPrompt?.options,
                format_type: value as SubPromptOptionFormatType,
                format_limit: limit
              }
            }
          }
          return subPrompt
        })
      }))
    }
  }

  const handleDeleteSubPrompt = (template_id: string) => {
    if (editedCustomModule) {
      setEditedCustomModule((prev) => ({
        ...prev,
        subprompt_list: prev?.subprompt_list?.filter(item => item.template_id !== template_id)
      }))
    }
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return
    if (editedCustomModule && editedCustomModule?.subprompt_list) {
      const newSubPromptitems = Object.assign([], editedCustomModule?.subprompt_list)
      const [reorderedItem] = newSubPromptitems.splice(result.source.index, 1)
      newSubPromptitems.splice(result.destination.index, 0, reorderedItem)
      setEditedCustomModule((prev) => ({
        ...prev,
        subprompt_list: newSubPromptitems
      }))
    }
  }

  const openSampleOutput = (submodule_template_id: string, sectionTitle: string, highlightOptions: SampleOutputHighlightOptions) => {
    setSubpromptTemplateId(submodule_template_id)
    setSectionTitle(sectionTitle)
    SetSampleHighlightOptions(highlightOptions)
    setOpenSampleOutputDialog(true)
  }

  async function handleDuplicateModule() {
    if (moduleId) {
      const api = await Api()
      const res = await api.customizations.duplicateMeetingTypesModule(moduleId)
      if (res && res.module_id) {
        setModuleId(res.module_id)
        queryClient.invalidateQueries(["getAvailableModules"])
        queryClient.invalidateQueries(["customizationsConfigs"])
      }
    }
  }

  async function handleDeleteModule() {
    if (moduleId) {
      const api = await Api()
      const res = await api.customizations.deleteMeetingTypesModule(moduleId)
      queryClient.invalidateQueries(["getAvailableModules"])
      logInfo("Module deleted", { module_id: res.module_id })
      onClose()
    }
  }

  async function handleUpdateModule() {
    if (editedCustomModule) {
      if (editedCustomModule.subprompt_list && editedCustomModule.subprompt_list.length < 1) {
        setOpenAddCondition(true)
        return
      }
      const api = await Api()
      const res = await api.customizations.updateMeetingTypesModule(editedCustomModule)
      logInfo("Module updated", { module_id: res.module_id })
      queryClient.invalidateQueries(["getAvailableModules"])
      onClose()
    }
  }

  function openSectionsDialog() {
    setSectionsDialogOpen(true)
  }

  function closeSectionsDialog() {
    setSectionsDialogOpen(false)
  }

  function closeSectionsDialogWithUpdate(updatedSections: ModuleSubPrompt[]) {
    setEditedCustomModule((prev) => ({
      ...prev,
      subprompt_list: updatedSections
    }))
    setSectionsDialogOpen(false)
  }

  const closeSampleOutput = () => {
    setOpenSampleOutputDialog(false)
  }

  function handleAccordionExpanded(template_id: string, expanded: boolean) {
    if (expanded)
      setExpandedSection(template_id)
    else
      setExpandedSection(null)
  }

  function handleAddConditionClose() {
    setOpenAddCondition(false)
  }

  function handleTitleChange(updatedTitle: string) {
    setEditedCustomModule((prev) => ({
      ...prev, module_ui_title: updatedTitle
    }))
  }

  function handleIconChange(updatedIcon: string) {
    setEditedCustomModule((prev) => ({
      ...prev, module_ui_icon: updatedIcon
    }))
  }

  function handleDescriptionChange(updatedDescription: string) {
    setEditedCustomModule((prev) => ({
      ...prev, module_ui_description: updatedDescription
    }))
  }

  if (!open) {
    return null
  }

  if (isLoading || !editedCustomModule || !module) {
    return (
      <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth='lg'>
        <CustomizerLoading />
      </Dialog>
    )
  }

  if (!isCustomizable) {
    return (
      <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth='lg'>
        <DialogContent>
          <Card >
            <CustomizerCardHeader
              module_id={editedCustomModule.module_id}
              isCustomizable={isCustomizable}
              isEditable={isEditable}
              module_ui_title={editedCustomModule.module_ui_title ?? ""}
              module_ui_icon={editedCustomModule.module_ui_icon ?? ""}
              module_ui_description={editedCustomModule.module_ui_description ?? ""}
            />
            <CardContent>
              <Box display="flex" alignItems="center" justifyContent="space-between" >
                <TemplateOwner ownership={editedCustomModule.ownership} />
                <ModuleExamples item={editedCustomModule} />
              </Box>
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth='lg'>
      <DialogContent>

        <CustomizerCardHeader
          module_id={editedCustomModule.module_id}
          isCustomizable={isCustomizable}
          isEditable={isEditable}
          module_ui_title={editedCustomModule.module_ui_title ?? ""}
          module_ui_icon={editedCustomModule.module_ui_icon ?? ""}
          module_ui_description={editedCustomModule.module_ui_description ?? ""}
          onTitleChange={handleTitleChange}
          onIconChange={handleIconChange}
          onDescriptionChange={handleDescriptionChange}
          onDuplicateModule={handleDuplicateModule}
          onDeleteModule={handleDeleteModule}
        />
        <CardContent>
          <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ paddingRight: '12px' }}>
            <Box display="flex" alignItems="center">
              <TemplateOwner ownership={editedCustomModule.ownership} />
              {!isEditable && (
                <Box display="flex" alignItems="center">
                  <Box sx={{ paddingX: 2 }} />
                  <Box display="flex" alignItems="center">
                    <Button variant="outlined" onClick={handleDuplicateModule}>
                      Duplicate to Edit
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
            <ModuleExamples item={editedCustomModule} />
          </Box>
        </CardContent>

        {editedCustomModule.subprompt_affix_list && editedCustomModule.subprompt_affix_list.length > 0 &&
          <>
            <Card elevation={1} sx={{ padding: 0, marginBottom: 1 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Level of Detail
                </Typography>
                <Box sx={{ paddingLeft: 1, paddingRight: 1.5, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <FormControl component="fieldset" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexGrow: 1 }}>
                    <RadioGroup
                      row
                      value={editedCustomModule.options?.level_of_detail ?? ModuleOptionLevelOfDetailType.DETAILED}
                      onChange={handleLevelOfDetail}
                    >
                      {Object.values(ModuleOptionLevelOfDetailType).map((type) => (
                        <FormControlLabel disabled={!isEditable} value={type} control={<Radio />} label={type} key={type} />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </Box>
              </CardContent>
            </Card>
            <Card elevation={1} sx={{ padding: 0, marginBottom: 1 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Point of View
                </Typography>
                <Box sx={{ paddingLeft: 1, paddingRight: 1.5, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <FormControl component="fieldset" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexGrow: 1 }}>
                    <RadioGroup
                      row
                      value={editedCustomModule.subprompt_affix_list ? editedCustomModule.subprompt_affix_list[0].options?.point_of_view ?? SubPromptOptionPointOfView.THIRD_PERSON : SubPromptOptionPointOfView.THIRD_PERSON}
                      onChange={handlePointOfView}
                    >
                      {Object.values(SubPromptOptionPointOfView).map((type) => (
                        <FormControlLabel disabled={!isEditable} value={type} control={<Radio />} label={type} key={type} />
                      ))}
                    </RadioGroup>
                    <Tooltip title={`View Sample Results`} sx={{ marginLeft: 'auto' }}>
                      <IconButton aria-label='info'
                        onClick={() => {
                          if (editedCustomModule && editedCustomModule.subprompt_affix_list && editedCustomModule.subprompt_affix_list[0] && editedCustomModule.subprompt_affix_list[0].template_id) {
                            openSampleOutput(editedCustomModule.subprompt_affix_list[0].template_id, "Point of View", { PointOfView: true })
                          }
                        }
                        }>
                        <InfoOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  </FormControl>
                </Box>
              </CardContent>
            </Card>
          </>
        }

        <Card elevation={1} sx={{ padding: 0, marginBottom: 1 }}>
          <CardContent>
            <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ padding: 0, marginBottom: 1 }}>
              <Typography variant="h5" gutterBottom>
                Sections
              </Typography>
              {isEditable && (
                <Button
                  aria-label="add-sections"
                  onClick={openSectionsDialog}
                  variant="outlined"
                  startIcon={<AddCircleIcon />}
                >
                  Add
                </Button>
              )}
            </Box>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="sections">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {editedCustomModule?.subprompt_list?.map((section, index) => (
                      <Draggable key={section.template_id} draggableId={section.template_id ?? String(index)} index={index} isDragDisabled={!isEditable}>
                        {(provided) => (
                          <Accordion
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            sx={{ marginBottom: 1 }}
                            expanded={section.template_id == expandedSection}

                            onChange={(e, expanded) => handleAccordionExpanded(section.template_id, expanded)}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              {...provided.dragHandleProps}
                              sx={{ display: 'flex', alignItems: 'center' }}
                            >
                              <Tooltip title="Drag to Reorder">
                                <IconButton {...provided.dragHandleProps}>
                                  <DragIndicatorIcon />
                                </IconButton>
                              </Tooltip>

                              <Box sx={{ width: '33%', display: 'flex', alignItems: 'center', marginLeft: 1 }}>
                                <Typography sx={{ fontWeight: 'bold' }}>
                                  {sectionsMap.get(section.template_id)?.heading ?? section.template_ui_title}
                                </Typography>
                              </Box>

                              <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                                {section.template_id != expandedSection && (section.options?.format_type === SubPromptOptionFormatType.LIST || !section.options?.format_type) && (
                                  <ToggleButton value={SubPromptOptionFormatType.LIST} aria-label={SubPromptOptionFormatType.LIST}>
                                    <FormatListBulletedIcon />{SubPromptOptionFormatType.LIST}
                                  </ToggleButton>
                                )}
                                {section.template_id != expandedSection && section.options?.format_type === SubPromptOptionFormatType.PARAGRAPH && (
                                  <ToggleButton value={SubPromptOptionFormatType.PARAGRAPH} aria-label={SubPromptOptionFormatType.PARAGRAPH}>
                                    <SubjectIcon />{SubPromptOptionFormatType.PARAGRAPH}
                                  </ToggleButton>
                                )}
                                {
                                  isEditable && section.template_id == expandedSection &&
                                  <Tooltip title={`Delete`}>
                                    <IconButton aria-label='delete'
                                      onClick={() => {
                                        if (section && section.template_id) {
                                          handleDeleteSubPrompt(section.template_id)
                                        }
                                      }
                                      }>
                                      <DeleteForeverIcon />
                                    </IconButton>
                                  </Tooltip>
                                }
                              </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Card>
                                <Box sx={{
                                  padding: 1,
                                  width: '100%', // Ensure the box takes full width
                                  boxSizing: 'border-box', // Include padding in width calculation  
                                }}
                                >
                                  <Typography variant={"h6"} >
                                    {sectionsMap.get(section.template_id)?.ui_title}
                                  </Typography>
                                  {
                                    sectionsMap.get(section.template_id)?.heading &&
                                    <Typography variant="subtitle1" sx={{ marginY: 1, fontSize: '0.875rem', color: 'text.secondary' }}>
                                      Heading in Summary: <b>{sectionsMap.get(section.template_id)?.heading}</b>
                                    </Typography>
                                  }

                                  <Typography variant="body1" sx={{ marginY: 1 }}>
                                    {sectionsMap.get(section.template_id)?.ui_description}
                                  </Typography>
                                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: 1 }}>
                                    {sectionsMap.get(section.template_id)?.tags?.map((tag) => (
                                      <Chip key={tag} label={tag} size="small" />
                                    ))}
                                  </Box>
                                </Box>
                              </Card>
                              <Card>
                                <Stack direction="row" spacing={2} sx={{ width: '100%', alignItems: 'center' }}>
                                  <Typography sx={sxText}>Format </Typography>

                                  <ToggleButtonGroup value={section.options?.format_type ?? SubPromptOptionFormatType.LIST} exclusive onChange={(e, v) => {
                                    handleFormatChange(v, section.template_id ?? "")
                                  }} disabled={!isEditable || section.block_option_change}>
                                    <ToggleButton value={SubPromptOptionFormatType.LIST} aria-label={SubPromptOptionFormatType.LIST}>
                                      <FormatListBulletedIcon />{SubPromptOptionFormatType.LIST}
                                    </ToggleButton>
                                    <ToggleButton value={SubPromptOptionFormatType.PARAGRAPH} aria-label={SubPromptOptionFormatType.PARAGRAPH}>
                                      <SubjectIcon />{SubPromptOptionFormatType.PARAGRAPH}
                                    </ToggleButton>
                                  </ToggleButtonGroup>

                                  {section.block_option_change &&
                                    <Typography variant="caption">Option disabled for this section</Typography>
                                  }

                                  <Box sx={{ flexGrow: 1 }} />

                                  <Tooltip title={`View Sample Results`}>
                                    <IconButton aria-label='info'
                                      onClick={() => {
                                        if (section && section.template_id && section.template_ui_title) {
                                          openSampleOutput(section.template_id, "Section: " + section.template_ui_title, { Format: true })
                                        }
                                      }
                                      }>
                                      <InfoOutlinedIcon />
                                    </IconButton>
                                  </Tooltip>
                                </Stack>
                              </Card>
                            </AccordionDetails>
                          </Accordion>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </CardContent>
        </Card>
        <MeetingTypeSectionsDialog open={sectionsDialogOpen} onClose={closeSectionsDialog} onUpdateClose={closeSectionsDialogWithUpdate} currentSections={editedCustomModule?.subprompt_list ?? []} />
        {
          subpromptTemplateId && subpromptTemplateId.length > 0 && (
            <SampleOutputDialog sectionTitle={sectionTitle} open={openSampleOutputDialog} onClose={closeSampleOutput} subpromptTemplateId={subpromptTemplateId} highlightOptions={sampleHighlightOptions} />
          )
        }

      </DialogContent >
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        {
          isEditable &&
          <Button onClick={handleUpdateModule}>Save</Button>
        }
      </DialogActions>
      <Dialog
        open={openAddCondition}
        keepMounted
        onClose={handleAddConditionClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Section Selection Required"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Please select at least one section for your meeting template.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddConditionClose}>OK</Button>
        </DialogActions>
      </Dialog>
    </Dialog >
  )
}

const sxText: SxProps = {
  fontFamily: 'Roboto, sans-serif', // Use your desired font family
  fontWeight: 'bold', // You can set the font weight
  fontSize: '16px', // Set the font size
  color: '#777' // Set the text color
}