import React, { useEffect, useState } from 'react'
import { Typography, Box, Card, SxProps, Link, Tooltip, Button, Skeleton, Divider, CardHeader, CardActions } from '@mui/material'
import 'firebase/auth'
import RedtailSignIn from '../../components/integrations/redtail/RedtailSignIn'
import { Media } from '../../service/media'
import { disconnect, getRedtailSettings } from '../../service/integrations/redtailApi'
import { useQuery } from '@tanstack/react-query'
import CloseIcon from '@mui/icons-material/Close'
import { gSx } from '../../styles/Theme'
import { logInfo } from 'log'
import IntegrationsBackButton from 'components/IntegrationsBackButton'
import { LoadingButton } from '@mui/lab'
import RedtailImportContacts from 'components/integrations/redtail/RedtailImportContacts'
import RedtailVisibilitySetting from 'components/integrations/redtail/RedtailVisibilitySetting'
import ContainerSmall from 'components/ContainerSmall'
import { useNavigate } from 'react-router-dom'
import { Path } from 'routes'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import useRedtailValidateAuth from 'hooks/useRedtailValidateAuth'

export default function RedtailSettingsScreen() {
  const navigate = useNavigate()
  useRedtailValidateAuth()
  const [busy, setBusy] = useState(false)
  const [err, setErr] = useState<undefined | string>()

  useEffect(() => {
    logInfo('Redtail Settings Screen')
  }, [])

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['getRedtailSettings'], queryFn: getRedtailSettings,
  })
  const isSignedIn = !!data?.user_id

  async function signout() {
    setErr(undefined)
    setBusy(true)
    const res = await disconnect()
    if (res instanceof Error)
      setErr(res.message)
    else
      await refetch()
    setBusy(false)
  }

  return (
    <ContainerSmall>
      <IntegrationsBackButton />
      <Typography variant='h3'>Redtail Integration</Typography>
      <Divider sx={{ marginY: '2vh ' }} />
      <Link href="https://redtailtechnology.com" rel='noopener' target='_blank'>
        <Box
          component="img"
          sx={sxLogo}
          src={Media.redtail}
        />
      </Link>
      <Box sx={{ padding: 1 }} />

      <Typography>Connect FinMate AI to Redtail CRM, to easily keep your records up to date. </Typography>
      <Box sx={{ padding: 1 }} />
      <Typography>Transfer your AI generated meeting notes, tasks, and contacts to Redtail.</Typography>
      <Box sx={{ padding: 1 }} />
      <Typography>{`By connecting Redtail, Redtail Activities will show up in FinMate AI's Calendar.`}</Typography>

      <Box sx={{ padding: 1 }} />
      <Card>

        {isLoading ? (
          <Skeleton variant="rectangular" width={'100%'} height={100} />
        ) : (
          isSignedIn ? (
            <Box sx={gSx.RowBetween}>
              <Typography>{`Signed in as `}<b>{`${data.username}`}</b></Typography>
              <Tooltip title="Disconnect Redtail">
                <LoadingButton
                  loading={busy}
                  disabled={busy}
                  aria-label='Disconnect Redtail'
                  onClick={signout}
                >
                  <CloseIcon />
                </LoadingButton>
              </Tooltip>
            </Box>

          ) : <RedtailSignIn />
        )
        }

        {err && <Typography color={'tomato'}>
          {err}
        </Typography>
        }
      </Card >

      {isSignedIn && (
        <>
          <Card>
            <CardHeader title='Calendar Auto-Record Meetings' />

            <Typography>FinMate syncs regularly with Redtail Calendar up to 2 weeks ahead or the next 15 activities of type not equal to tasks.</Typography>
            <Box sx={{ padding: 1 }} />
            <Typography>Redtail Calendar Activity must not be of type Task, to show up as a Redtail Calender Event. It can be of type: Appointment, Phone Call, or Unassigned.</Typography>
            <Box sx={{ padding: 1 }} />
            <Typography>{"To have Finmate automatically join your Redtail calendar meetings, sync your FinMate Calendar with Redtail Calendar by visiting FinMate's "}
              <Link sx={{ cursor: 'pointer' }} onClick={() => navigate(Path.Home)}>Home Screen</Link>{" or "}
              <Link sx={{ cursor: 'pointer' }} onClick={() => navigate(Path.Calendar)}>Calendar Screen</Link>
              .</Typography>
            <Box sx={{ padding: 1 }} />

            <CardActions>
              <Button variant='outlined' onClick={() => navigate(Path.RecorderSettings)}>
                Auto Record Settings
              </Button>
            </CardActions>
          </Card>

          <Card>
            <CardHeader title='Configurations' />

            {/* <Box sx={{ padding: 2 }} />
              <RedtailNotifyUserSetting />   TODO figure out how this works in Redtail
              <Box sx={{ padding: 2 }} />
              <RedtailNotifyTeamSetting /> */}
            <RedtailVisibilitySetting />
          </Card>

          <Card>
            <CardHeader title='Import Contacts' />
            <Box sx={{ ...gSx.RowEnd, paddingTop: 2 }}>
              <RedtailImportContacts />
            </Box>
          </Card>
        </>
      )}

      <Button target="_blank" rel="noopener"
        href="https://docs.finmate.ai/docs/category/redtail"
        startIcon={<TextSnippetIcon />}
      >
        Redtail Docs
      </Button>
    </ContainerSmall>
  )
}

const sxLogo: SxProps = {
  width: 200,
}