import React, { useEffect, useState } from 'react'
import { Box } from '@mui/system'
import { SxProps, Card, CardHeader, Container, Tab, Typography } from '@mui/material'
import OrgName from 'components/settings/OrgName'
import OrgUsers from 'components/settings/OrgUsers'
import OrgDataRetention from 'components/settings/OrgDataRetention'
import StripeCustomerInfo from 'components/pay/StripeCustomerInfo'
import { useQuery } from '@tanstack/react-query'
import { getOrg } from 'service/orgsApi'
import { logInfo } from 'log'
import { gSx } from 'styles/Theme'
import OrgInviteCreate from 'components/settings/OrgInviteCreate'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import InviteStatus from 'components/settings/InviteStatus'
import StripeSubscriptionRemaining from 'components/pay/PlansAvailable'
import { getSubscription } from 'service/payApi'
import Grid from "@mui/material/Grid2/Grid2"
import { useTranslation } from 'react-i18next'

export default function OrgScreen() {
  const { t, i18n } = useTranslation()
  const [tabValue, setTabValue] = useState("1")

  useEffect(() => {
    logInfo('Organization Screen')
  }, [])

  const onTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue)
  }

  const { data: org, isLoading: isLoadingOrg } = useQuery({
    queryKey: ['getOrg'], queryFn: getOrg,
  })

  const { data: sub, isLoading: isLoadingSub } = useQuery({
    queryKey: ['getSubscription'], queryFn: getSubscription,
  })
  const isLoading = isLoadingOrg || isLoadingSub

  return (
    <Container sx={{ paddingY: 3 }}>
      <Typography variant='h1' sx={{ paddingBottom: 2 }} >Organization</Typography>

      <Grid container alignItems="stretch" spacing={2}>
        <Grid size={{ xs: 12, md: 6 }} sx={{ display: 'flex' }}>
          <OrgName />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }} sx={{ display: 'flex' }}>
          <StripeSubscriptionRemaining org={org} sub={sub} isLoading={isLoading} />
        </Grid>
      </Grid>

      <Card>
        <CardHeader title='Organization Users'
          action={org && <OrgInviteCreate org={org} />}
        />
        <TabContext value={tabValue}>
          <TabList sx={sxTabList} onChange={onTabChange}>
            <Tab
              sx={sxTabList}
              label='Members'
              value='1'
            />
            <Tab
              sx={sxTabList}
              label='Invites'
              value='2'
            />
          </TabList>
          <TabPanel sx={sxTabPanel} value='1'>
            <OrgUsers />
          </TabPanel>
          <TabPanel sx={sxTabPanel} value='2'>
            <InviteStatus />
          </TabPanel>
        </TabContext>
      </Card>

      <Card sx={{ margin: 0 }}>
        <CardHeader title={'Data Retention'} />

        <Typography variant='body2'>The data retention policy impacts all organization members and can be adjusted by the admin.</Typography><Typography variant='body2'>Please note: selecting &apos;No Retention&apos; for transcript will disable Analytics and speaker name updates, which could affect summarization results.</Typography>

        <Box sx={{ padding: 1 }} />
        <OrgDataRetention />

      </Card>
    </Container >
  )
}

const sxTabList: SxProps = {
  minHeight: '30px',
}

const sxTabPanel: SxProps = {
  padding: '0',
}
