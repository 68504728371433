import React from 'react'
import { Card, Box, SxProps, Typography, Divider, Button, Skeleton, Tooltip, ButtonBase } from '@mui/material'
import SignInCalendarButton from '../../components/calendar/SignInCalendarButton'
import ContainerSmall from 'components/ContainerSmall'
import IntegrationsBackButton from 'components/IntegrationsBackButton'
import { QueryClient, useQuery } from '@tanstack/react-query'
import { GetCalendarUser, deleteCalendarUser } from 'service/calendarApi'
import CancelIcon from '@mui/icons-material/Cancel'
import { useNavigate } from 'react-router-dom'
import { Path } from 'routes'
import { gColor, gSx } from 'styles/Theme'
import { Media } from 'service/media'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import VideocamIcon from '@mui/icons-material/Videocam'
import { isGoogleCalConnected, isMicrosoftCalConnected } from 'utils'

export default function CalendarAuthScreen() {
  const navigate = useNavigate()
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['GetCalendarUser'], queryFn: GetCalendarUser,
  })
  const isGoogle = isGoogleCalConnected(data)
  const isMicrosoft = isMicrosoftCalConnected(data)
  const gEmail = data?.connections?.filter(conn => conn.platform === 'google')[0].email
  const msEmail = data?.connections?.filter(conn => conn.platform === 'microsoft')[0].email

  async function onDisconnect() {
    await deleteCalendarUser()
    await refetch()
  }

  function MicrosoftUI() {
    return (
      <>
        <Box sx={gSx.Row}>
          <Box component="img" sx={icon} src={Media.microsoft100} alt="Microsoft Logo" />
          <Typography>Microsoft Outlook Calendar</Typography>
        </Box>
        <Message />
        <Box sx={{ padding: 3 }} />

        <Card>
          <Box sx={gSx.RowBetween}>
            <Typography variant='h5'>Connected </Typography>
            {msEmail && <Typography>{msEmail}</Typography>}
            <Tooltip title='Disconnect Calendar'>
              <ButtonBase onClick={onDisconnect} >
                <CancelIcon color='disabled' />
              </ButtonBase>
            </Tooltip>
          </Box>
        </Card>
      </>
    )
  }

  function GoogleUI() {
    return (
      <>
        <Box sx={gSx.Row}>
          <Box component="img" sx={icon} src={Media.googleCalendar} alt="Google Logo" />
          <Typography variant='h1'>Google Calendar</Typography>
        </Box>
        <Message />
        <Box sx={{ padding: 3 }} />

        <Card>
          <Box sx={gSx.RowBetween}>
            <Typography variant='h5'>Connected</Typography>
            {gEmail && <Typography>{gEmail}</Typography>}
            <Tooltip title='Disconnect Calendar'>
              <ButtonBase onClick={onDisconnect} >
                <CancelIcon color='disabled' />
              </ButtonBase>
            </Tooltip>
          </Box>
        </Card>
      </>
    )
  }

  return (
    <ContainerSmall>
      <IntegrationsBackButton />
      <Typography variant='h3' sx={{ paddingTop: 2 }}>Calendar Integration</Typography>
      <Divider sx={{ marginY: '2vh ' }} />

      {isLoading
        ? <Skeleton variant="rectangular" width={'100%'} height={50} />
        : (
          <>
            {isMicrosoft && <MicrosoftUI />}
            {isGoogle && <GoogleUI />}
            {!isGoogle && !isMicrosoft &&
              <>
                <Message />
                <Box sx={{ padding: 2 }} />
                <Card sx={sxCard}>
                  <Box sx={sxCenter}>
                    <SignInCalendarButton />
                  </Box>
                </Card>
              </>
            }
          </>
        )
      }

      {(isGoogle || isMicrosoft) &&
        <Box sx={bottom}>
          <Box sx={{ padding: 2 }} />
          <Button
            aria-label='Calendar Events'
            onClick={() => navigate(Path.Calendar)}
            startIcon={<CalendarMonthIcon />}
            variant='contained'
          >
            Calendar Events
          </Button>
          <Box sx={{ padding: 1 }} />
          <Button
            aria-label='Recorder Settings'
            onClick={() => navigate(Path.RecorderSettings)}
            startIcon={<VideocamIcon />}
            variant='contained'
          >
            Recorder Settings
          </Button>
        </Box>
      }

    </ContainerSmall >
  )
}

function Message() {
  return (
    <>
      <Box sx={{ padding: 2 }} />
      <Typography variant='body2'>
        By Connecting your calendar, FinMate AI can automatically record your upcoming calls.
      </Typography>
      <Typography variant='body2'>
        Finmate AI requests read-only  access to your calendar.
      </Typography>
    </>
  )
}

const bottom: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}

const icon: SxProps = {
  width: 40,
  marginRight: 2
}

const sxCard: SxProps = {
  padding: '2vh'
}

const sxCenter: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  margin: '3vh'
}
