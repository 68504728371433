import React, { useEffect, useState } from 'react'
import { Typography, SxProps, Box, Card, Button, TextField, IconButton, Snackbar, Alert, DialogActions, Skeleton } from '@mui/material'
import { gSx } from '../../styles/Theme'
import { ClientDetail } from '../../client'
import EditIcon from '@mui/icons-material/Edit'
import PersonIcon from '@mui/icons-material/Person'
import EmailIcon from '@mui/icons-material/Email'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import { logErr, logInfo } from '../../log'
import { getContactName, isContactEntity, upsertClient } from 'service/clientApi'
import { LoadingButton } from '@mui/lab'
import { CrmClientBadge } from './CrmClientBadge'
import { useQueryClient } from '@tanstack/react-query'
import { ContactChip } from './ContactChip'

interface Props {
  contact: ClientDetail
}

export default function ContactEdit({ contact }: Props) {

  const queryClient = useQueryClient()
  const [isEdit, setEdit] = useState(false)

  const [firstname, setFirstname] = useState(contact?.first_name ?? '')
  const [lastname, setLastname] = useState(contact?.last_name ?? '')
  const [fullName, setFullName] = useState(contact?.full_name ?? '')
  const [phone, setPhone] = useState(contact?.phone ?? '')
  const [email, setEmail] = useState(contact?.email ?? '')

  const isEntity = isContactEntity(contact)

  const [busy, setBusy] = useState(false)
  const [err, setErr] = useState<string | undefined>()
  const [successSnack, setSuccessSnack] = useState(false)

  async function onSave() {

    setBusy(true)
    setErr(undefined)
    if (!contact) {
      setErr('missing contact data')
      setBusy(false)
      return
    }
    const updated = contact
    if (isEntity) {
      updated.full_name = fullName
    } else {
      updated.first_name = firstname.trim()
      updated.last_name = lastname.trim()
    }
    updated.phone = phone.trim()
    updated.email = email.trim().toLowerCase()

    logInfo('Edit Client Pressed Save', { updated })
    const res = await upsertClient(updated)
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      await queryClient.invalidateQueries({ queryKey: ['getClientDetail', updated.id] })
      setSuccessSnack(true)
      setEdit(false)
      setErr(undefined)
    }
    setBusy(false)
  }

  async function onCancel() {
    setFirstname(contact?.first_name ?? '')
    setLastname(contact?.last_name ?? '')
    setFullName(contact?.full_name ?? '')
    setEmail(contact?.email ?? '')
    setPhone(contact?.phone ?? '')
    setEdit(false)
  }

  return (
    <Card>
      <Box sx={gSx.RowBetween}>

        <Box sx={gSx.Row}>
          <Typography variant='h4'>Contact</Typography>
          <Box sx={{ paddingX: 1 }} />
          <ContactChip contact={contact} />
        </Box>

        {!isEdit &&
          <IconButton disabled={!contact}
            onClick={() => setEdit(true)}
          >
            <EditIcon fontSize='small' />
          </IconButton>
        }
      </Box>

      <Box sx={{ padding: 1 }} />

      {
        !contact
          ? <Skeleton variant="rectangular" width={'100%'} height={110} />
          : (
            isEdit ? (
              <Box sx={sxEditBox}>

                <Box sx={{ padding: '1vh' }} />

                {
                  isEntity
                    ? <Box sx={gSx.Row}>
                      <PersonIcon sx={{ mr: 1 }} />
                      <TextField
                        autoFocus
                        label='Name'
                        variant="outlined"
                        value={fullName}
                        onChange={(t) => setFullName(t.target.value)}
                        sx={sxTextField}
                        disabled={busy || !isEntity}
                      />
                    </Box>
                    : (
                      <>
                        <Box sx={gSx.Row}>
                          <PersonIcon sx={{ mr: 1 }} />
                          <TextField
                            autoFocus
                            label='First Name'
                            variant="outlined"
                            value={firstname}
                            onChange={(t) => setFirstname(t.target.value)}
                            sx={sxTextField}
                            disabled={busy || isEntity}
                          />
                        </Box>

                        <Box sx={gSx.Row}>
                          <Box sx={{ mr: 4 }} />
                          <TextField
                            label='Last Name'
                            variant="outlined"
                            value={lastname}
                            onChange={(t) => setLastname(t.target.value)}
                            sx={sxTextField}
                            disabled={busy || isEntity}
                          />
                        </Box>
                      </>
                    )
                }

                <Box sx={gSx.Row}>
                  <EmailIcon sx={{ mr: 1 }} />
                  <TextField
                    label='Email'
                    variant="outlined"
                    value={email}
                    sx={sxTextField}
                    disabled
                  />
                </Box>

                <Box sx={gSx.Row}>
                  <PhoneIphoneIcon sx={{ mr: 1, mb: 3 }} />
                  <TextField
                    label='Phone'
                    variant="outlined"
                    value={phone}
                    onChange={(t) => setPhone(t.target.value)}
                    sx={sxTextField}
                    helperText="ie. 123-456-7890"
                    disabled={busy}
                  />
                </Box>

                <DialogActions>
                  <Button
                    onClick={onCancel}
                    sx={sxButton}
                    disabled={busy}
                  >
                    Cancel
                  </Button>

                  <LoadingButton
                    variant='contained'
                    onClick={onSave}
                    sx={sxButton}
                    disabled={busy}
                    loading={busy}
                  >
                    Save
                  </LoadingButton>
                </DialogActions>

                {err && <Typography color='error.main'>{err}</Typography>}

              </Box>
            ) : (
              <Box sx={gSx.RowBetween}>
                <Box>
                  <Box sx={gSx.Row}>
                    <PersonIcon sx={{ mr: 1, marginY: 1 }} />
                    <Typography variant='h5'>{getContactName(contact, true)}</Typography>
                  </Box>

                  <Box sx={gSx.Row}>
                    <EmailIcon sx={{ mr: 1, marginY: 1 }} />
                    <Typography>{email}</Typography>
                  </Box>

                  <Box sx={gSx.Row}>
                    <PhoneIphoneIcon sx={{ mr: 1, marginY: 1 }} />
                    <Typography>{phone}</Typography>
                  </Box>

                </Box>
                <CrmClientBadge client={contact} />
              </Box>
            )

          )
      }

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={successSnack}
        autoHideDuration={3000}
        onClose={() => setSuccessSnack(false)}
      >
        <Alert
          variant="filled"
          severity="success"
        >
          Contact Details Updated
        </Alert>

      </Snackbar>
    </Card>
  )
}

const sxEditBox: SxProps = {
  display: 'flex',
  flexDirection: 'column',
}

const sxTextField: SxProps = {
  width: '48vh',
  marginY: '1vh'
}

const sxButton: SxProps = {
  marginY: 2,
  width: '120px'
}
