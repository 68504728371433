import React, { useState } from 'react'
import {
  Box,
  SxProps,
  Typography,
  IconButton,
  TextField,
  Dialog,
  Snackbar,
  Alert,
  LinearProgress,
} from '@mui/material'
import { EmailBase, EventBase } from '../../client'
import { gSx } from '../../styles/Theme'
import CloseIcon from '@mui/icons-material/Close'
import SendIcon from '@mui/icons-material/Send'
import { isEmailValid } from '../../utils'
import { getContactName } from 'service/clientApi'
import { emailMeetingDetails } from 'service/communicationsApi'

interface Props {
  open: boolean
  onClose: () => void
  event: EventBase
  eventSummaryHtml?: string
}

export default function ShareDetailsDialog({ open, onClose, event, eventSummaryHtml }: Props) {
  const [busy, setBusy] = useState(false)
  const [err, setErr] = useState<string | undefined>()
  const [email, setEmail] = useState('')
  const [success, setSuccess] = useState(false)

  const contactName = (event.contacts && event.contacts.length > 0) ? getContactName(event.contacts[0]) : ''
  const contactEmail = (event.contacts && event.contacts.length > 0) ? event.contacts[0].email : ''

  async function send() {
    setErr(undefined)

    if (!isEmailValid(email)) {
      setErr('Invalid Email')
      return
    }
    setBusy(true)

    const data: EmailBase = {
      to_emails: [email],
      client_fullname: contactName,
      client_email: contactEmail,
      body_html: `<p>${eventSummaryHtml}</p>`,
      event_title: event.title ?? '',
      event_date: new Date(event.start_time ?? '').toLocaleString(),
    }

    const res = await emailMeetingDetails(data)
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      setSuccess(true)
    }
    setBusy(false)
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <IconButton sx={sxClose} aria-label='close' onClick={onClose}>
        <CloseIcon />
      </IconButton>

      <Box sx={sxBox}>
        <Typography variant='h3'>Email the Meeting Details</Typography>

        <Box sx={{ padding: '1vh' }}></Box>

        <Box sx={gSx.Row}>
          <TextField
            id='outlined-basic'
            aria-label='Share Summary via Email'
            label='Share Summary via Email'
            variant='outlined'
            sx={sxEmail}
            disabled={busy}
            value={email}
            onChange={(t) => setEmail(t.target.value)}
          />

          <IconButton aria-label='Send Summary via Email'
            onClick={() => send()}
            disabled={busy}
          >
            <SendIcon sx={sxSendIcon} />
          </IconButton>
        </Box>

        <Box sx={{ padding: '1vh' }}></Box>
        {busy && (
          <>
            <Typography>Sending</Typography>
            <Box sx={gSx.Row}>
              <LinearProgress sx={{ width: '100%' }} />
            </Box>
          </>
        )}

        {err && <Typography sx={{ color: 'red' }}>{err}</Typography>}

      </Box>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={success}
        autoHideDuration={3000}
        onClose={() => setSuccess(false)}
      >
        <Alert
          variant="filled"
          severity="success"
        >
          Email Sent to {email}
        </Alert>

      </Snackbar>

    </Dialog >
  )
}
const sxBox: SxProps = {
  paddingX: '3vh',
  paddingBottom: '3vh',
}

const sxClose: SxProps = {
  alignSelf: 'flex-end',
}

const sxSendIcon: SxProps = {
  paddingLeft: '1vh',
  color: 'primary.main',
}

const sxEmail: SxProps = {
  width: '400px',
}
