import _ from 'lodash'
import { Api, auth } from './api'
import { logErr, logInfo } from '../log'
import { OrgConfigs, OrgUser, OrgUserRole, OrgUserStatus, OrgBase, OrgInvite, ProductName, FinmateSubscription, EnterpriseDb, EnterpriseParent } from '../client'
import { getErrorMessage } from 'utils'
import { errorMonitor } from 'events'
import { numStandardToOrgFromEnterprise, numStarterToOrgFromEnterprise } from './enterpriseApi'

export async function getOrg(): Promise<OrgBase | undefined> {
  if (!auth.currentUser)
    return
  try {
    const api = await Api()
    return await api.orgs.getOrg()
  } catch (e) {
    logErr('getOrg', { e })
  }
}

export async function getOrgById(orgId?: string): Promise<OrgBase | undefined> {
  if (!orgId)
    return
  try {
    const api = await Api()
    return await api.enterprise.getOrgById(orgId)
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('getOrgById', { err })
  }
}

export function isAdminRole(org?: OrgBase): boolean {
  if (!org)
    return false
  for (const user of org.org_user_list)
    if (user.uid === auth.currentUser?.uid &&
      user.org_user_role === OrgUserRole.ADMIN)
      return true
  return false
}

export function getOrgUser(org?: OrgBase, uid?: string) {
  if (!org || !uid)
    return undefined

  for (const orgUser of org.org_user_list) {
    if (orgUser.uid == uid)
      return orgUser
  }
}

export function numActivePaidPlansOrgUsers(orgUsers?: OrgUser[]): number {
  if (!orgUsers)
    return 0
  return _.filter(orgUsers, (user =>
    user.org_user_role != OrgUserRole.INACTIVE &&
    (user.org_user_plan == ProductName.STANDARD ||
      user.org_user_plan == ProductName.STARTER)
  )).length
}

export function numActiveOrgUsersByPlan(plan: ProductName, orgUsers?: OrgUser[]): number {
  if (!orgUsers)
    return 0
  return _.filter(orgUsers, (user =>
    user.org_user_plan === plan && user.org_user_role != OrgUserRole.INACTIVE
  )).length
}

export async function updateOrgUser(orgUser: OrgUser): Promise<OrgUser[] | Error> {
  try {
    logInfo('Update Org User', { orgUser })
    const api = await Api()
    return await api.orgs.updateOrgUser(orgUser)
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('updateOrgUser', { err })
    return err
  }
}

export async function getOrgConfigs(): Promise<any> {
  try {
    const api = await Api()
    const res = await api.orgs.getOrgConfigs()
    if (res)
      return res
    return []
  } catch (e) {
    logErr('getOrgConfigs', { e })
  }
  return []
}

export async function updateOrgConfigs(
  org_configs: OrgConfigs): Promise<any> {
  try {
    logInfo('Update Org Configs', { org_configs })
    const api = await Api()
    const res = await api.orgs.updateOrgConfigs(org_configs)
    if (res)
      return res
    return {}
  } catch (e) {
    logErr('updateOrgConfigs', { e })
  }
  return []
}

export async function setOrgName(orgName: string): Promise<any> {
  try {
    logInfo('Set Org Name', { orgName })
    const api = await Api()
    const res = await api.orgs.setOrgName(orgName)
    if (res)
      return res
    return
  } catch (e) {
    logErr('setOrgName', { e })
  }
  return
}

export async function createOrgInvites(role: OrgUserRole, emails: string[]): Promise<OrgInvite[] | undefined> {
  try {
    logInfo('createOrgInvites', { emails })
    const api = await Api()
    return await api.orgs.createOrgInvites(role, emails)
  } catch (e) {
    logErr('createOrgInvites', { e })
  }
  return
}

export async function getOrgInvitesAsRecipient(): Promise<OrgInvite[] | undefined> {
  try {
    logInfo('getOrgInvitesAsRecipient')
    const api = await Api()
    return await api.orgs.getOrgInvitesAsRecipient()
  } catch (e) {
    logErr('getOrgInvitesAsRecipient', { e })
  }
  return
}

export async function getOrgInvites(): Promise<OrgInvite[] | undefined> {
  try {
    logInfo('getOrgPendingDeclinedInvites')
    const api = await Api()
    return await api.orgs.getOrgInvites()
  } catch (e) {
    logErr('getOrgInvites', { e })
  }
  return
}

export async function answerOrgInvite(inviteId: string, newStatus: OrgUserStatus): Promise<OrgInvite | undefined> {
  if (inviteId.length == 0)
    return
  try {
    logInfo('answerOrgInvite', { inviteId, newStatus })
    const api = await Api()
    return await api.orgs.answerOrgInvite(inviteId, newStatus)
  } catch (e) {
    logErr('answerOrgInvite', { e })
  }
  return
}

export function standardAvailableForOrg(org?: OrgBase, sub?: FinmateSubscription, ent?: EnterpriseDb) {
  if (!org) return 0
  const ent_paid = numStandardToOrgFromEnterprise(ent, org)
  const org_paid = sub?.standard?.quantity ?? 0
  const num_org = numActiveOrgUsersByPlan(ProductName.STANDARD, org.org_user_list)
  return ent_paid + org_paid - num_org
}

export function starterAvailableForOrg(org?: OrgBase, sub?: FinmateSubscription, ent?: EnterpriseDb) {
  if (!org) return 0
  const ent_paid = numStarterToOrgFromEnterprise(ent, org)
  const org_paid = sub?.starter?.quantity ?? 0
  const num_org = numActiveOrgUsersByPlan(ProductName.STARTER, org?.org_user_list)
  return ent_paid + org_paid - num_org
}

export function liteAvailableForOrg(org?: OrgBase, sub?: FinmateSubscription) {
  if (!org) return 0
  const org_paid = sub?.lite?.quantity ?? 0
  const num_org = numActiveOrgUsersByPlan(ProductName.LITE, org?.org_user_list)
  return org_paid - num_org
}

export function checkSwapPayingSeat(user: OrgUser, updatedUser: OrgUser, org?: OrgBase, sub?: FinmateSubscription, ent?: EnterpriseDb) {

  const updatedPlan = updatedUser.org_user_plan
  if ((user?.org_user_plan == ProductName.STARTER && updatedPlan == ProductName.STANDARD) ||
    (user?.org_user_plan == ProductName.STANDARD && updatedPlan == ProductName.STARTER)
  ) {
    // is swapping.

    const standardAvail = standardAvailableForOrg(org, sub, ent)
    const starterAvail = starterAvailableForOrg(org, sub, ent)

    if (updatedPlan == ProductName.STANDARD && standardAvail >= 1)
      return false // is swapping, and enough seats
    if (updatedPlan == ProductName.STARTER && starterAvail >= 1)
      return false // is swapping, and enough seats

    // is swapping, and not enough seats
    return true
  }
  return false
}

export function checkAddPayingSeat(user: OrgUser, updatedUser: OrgUser, org?: OrgBase, sub?: FinmateSubscription, ent?: EnterpriseDb) {
  const updatedPlan = updatedUser.org_user_plan
  const updatedRole = updatedUser.org_user_role

  // from non paying FREE TRIAL EXPIRED LITE to paying STANDARD STARTER   
  // used to trigger purchase
  const standardAvail = standardAvailableForOrg(org, sub, ent)
  const starterAvail = starterAvailableForOrg(org, sub, ent)

  if (updatedRole == OrgUserRole.INACTIVE)
    return false

  if (user?.org_user_plan == ProductName.STANDARD || user?.org_user_plan == ProductName.STARTER)
    return false // has existing license. will not add. either swap or remove.

  if (updatedPlan == ProductName.EXPIRED || updatedPlan == ProductName.FREE_TRIAL || updatedPlan == ProductName.LITE)
    return false

  if (updatedPlan == ProductName.STANDARD && standardAvail > 0)
    return false

  if (updatedPlan == ProductName.STARTER && starterAvail > 0)
    return false

  return true
}

export function getIsLiteMaxedOut(user: OrgUser, updatedUser: OrgUser, org?: OrgBase, sub?: FinmateSubscription) {
  const updatedPlan = updatedUser.org_user_plan
  const liteAvail = liteAvailableForOrg(org, sub)

  if (user?.org_user_plan == ProductName.LITE || updatedPlan != ProductName.LITE)
    return false

  if (liteAvail == 0)
    return true
  return false
}

export function getIsYearlySubMaxedOut(user: OrgUser, updatedUser: OrgUser, org?: OrgBase, sub?: FinmateSubscription, ent?: EnterpriseDb) {
  const isYearSub = sub?.interval == "year"
  if (!isYearSub) // yearly sub only 
    return false
  const updatedPlan = updatedUser.org_user_plan

  if (user?.org_user_plan == updatedPlan) // no update
    return false

  if (updatedPlan != ProductName.STANDARD && updatedPlan != ProductName.STARTER)
    return false

  const standardAvail = standardAvailableForOrg(org, sub, ent)
  const starterAvail = starterAvailableForOrg(org, sub, ent)

  if (updatedPlan == ProductName.STANDARD && standardAvail <= 0)
    return true

  if (updatedPlan == ProductName.STARTER && starterAvail <= 0)
    return true

  return false
}

export function isAtLeastOneActiveAdmin(targetUser: OrgUser, org?: OrgBase) {
  if (!org)
    return false

  let activeAdmin = 0
  for (const u of org.org_user_list) {
    if (u.uid == targetUser.uid) {
      if (targetUser.org_user_role == OrgUserRole.ADMIN)
        activeAdmin++
    } else {
      if (u.org_user_role == OrgUserRole.ADMIN)
        activeAdmin++
    }
    if (activeAdmin >= 1)
      return true
  }
  return false
}

export function validateOrgUserEdit(user: OrgUser, updatedUser: OrgUser, org?: OrgBase, sub?: FinmateSubscription, customer_email?: string, ent?: EnterpriseDb) {
  const targetInactive = updatedUser.org_user_role == OrgUserRole.INACTIVE
  const targetPaidPlan = updatedUser.org_user_plan == ProductName.STANDARD || updatedUser.org_user_plan == ProductName.STARTER || updatedUser.org_user_plan == ProductName.LITE
  const res: string[] = []

  if (targetInactive && updatedUser.email == customer_email)
    res.push('User is Billing Admin. User cannot be deactivated.')

  if (!isAtLeastOneActiveAdmin(updatedUser, org))
    res.push('Require at least 1 active administrator')

  if (targetInactive && targetPaidPlan)
    res.push('Inactive User cannot be on a Standard, Starter or Lite Plan')

  if (getIsLiteMaxedOut(user, updatedUser, org, sub))
    res.push('No more Lite plan seats available')

  if (getIsYearlySubMaxedOut(user, updatedUser, org, sub, ent))
    res.push('No more seats available. Contact Support to manage seats for your annual subscription.')

  if (res.length > 0)
    logInfo('OrgUserEditDialog validationErrors', res)
  return res
}

// for orgs that are part of an enterprise, get the enterprise info, limited view.
export async function getEnterpriseParent(): Promise<EnterpriseParent | undefined> {
  try {
    const api = await Api()
    return await api.orgs.getEnterpriseParent()
  } catch (e) {
    logErr('getEnterpriseParent', { e })
  }
}