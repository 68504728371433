/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FinmateSubscription } from '../models/FinmateSubscription';
import type { FinmateSubscriptionPlans } from '../models/FinmateSubscriptionPlans';
import type { OrgUser } from '../models/OrgUser';
import type { StripeCustomer } from '../models/StripeCustomer';
import type { StripeSession } from '../models/StripeSession';
import type { StripeSubscription } from '../models/StripeSubscription';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PayService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Session
     * @param sessionId
     * @returns StripeSession Successful Response
     * @throws ApiError
     */
    public getSession(
        sessionId: string,
    ): CancelablePromise<StripeSession> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/pay/session',
            query: {
                'session_id': sessionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Checkout Session
     * @param numStandard
     * @param numStarter
     * @param numLite
     * @param requestBody
     * @returns string Successful Response
     * @throws ApiError
     */
    public createCheckoutSession(
        numStandard: number,
        numStarter: number,
        numLite: number,
        requestBody?: OrgUser,
    ): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/pay/session',
            query: {
                'num_standard': numStandard,
                'num_starter': numStarter,
                'num_lite': numLite,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Customer
     * @returns StripeCustomer Successful Response
     * @throws ApiError
     */
    public getCustomer(): CancelablePromise<StripeCustomer> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/pay/customer',
        });
    }

    /**
     * Update Customer
     * @param email
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateCustomer(
        email: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/pay/customer',
            query: {
                'email': email,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Customer Subscription
     * @returns FinmateSubscription Successful Response
     * @throws ApiError
     */
    public getSubscription(): CancelablePromise<FinmateSubscription> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/pay/customer/subscriptions',
        });
    }

    /**
     * Cancel Subscription
     * @returns StripeSubscription Successful Response
     * @throws ApiError
     */
    public cancelSubscription(): CancelablePromise<StripeSubscription> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/pay/customer/subscriptions',
        });
    }

    /**
     * Get Customer Subscription As Ent Admin
     * @param orgId
     * @returns FinmateSubscription Successful Response
     * @throws ApiError
     */
    public getOrgSubscriptionAsEnterpriseAdmin(
        orgId: string,
    ): CancelablePromise<FinmateSubscription> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/pay/customer/subscriptions/{org_id}',
            path: {
                'org_id': orgId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add To Subscription
     * @param standardAdd
     * @param starterAdd
     * @param liteAdd
     * @param requestBody
     * @returns StripeSubscription Successful Response
     * @throws ApiError
     */
    public addToSubscription(
        standardAdd: number,
        starterAdd: number,
        liteAdd: number,
        requestBody?: OrgUser,
    ): CancelablePromise<StripeSubscription> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/pay/customer/subscriptions/add',
            query: {
                'standard_add': standardAdd,
                'starter_add': starterAdd,
                'lite_add': liteAdd,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Swap Subscription
     * @param requestBody
     * @returns StripeSubscription Successful Response
     * @throws ApiError
     */
    public swapSubscription(
        requestBody: OrgUser,
    ): CancelablePromise<StripeSubscription> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/pay/customer/subscriptions/swap',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Subscription
     * @param standard
     * @param starter
     * @param lite
     * @param requestBody
     * @returns StripeSubscription Successful Response
     * @throws ApiError
     */
    public updateSubscription(
        standard: number,
        starter: number,
        lite: number,
        requestBody?: OrgUser,
    ): CancelablePromise<StripeSubscription> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/pay/customer/subscriptions/update',
            query: {
                'standard': standard,
                'starter': starter,
                'lite': lite,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Subscriptions Plans
     * @returns FinmateSubscriptionPlans Successful Response
     * @throws ApiError
     */
    public getSubscriptionPlans(): CancelablePromise<FinmateSubscriptionPlans> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/pay/subscriptions/plans',
        });
    }

    /**
     * Modify Payment
     * @returns string Successful Response
     * @throws ApiError
     */
    public modifyPayment(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/pay/modify-payment',
        });
    }

}
