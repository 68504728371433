import React, { ReactNode, useMemo, useState } from 'react'
import { Typography, Box, Container } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { gSx } from '../../styles/Theme'
import { getClientsIndex, getContactName } from 'service/clientApi'
import { ClientIndex } from 'client'
import { SkeletonRows } from 'components/Skeletons'
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_Row,
} from 'material-react-table'
import { useNavigate } from 'react-router-dom'
import { Path } from 'routes'
import AddClientDialog from './AddClientDialog'
import ImportContactsListButtons from 'components/integrations/ImportContactsListButtons'
import { formatDate } from 'utils'
import { getSessionStorage, SessionStorageEnum, setSessionStorage } from 'sessionStorage'
import { CrmClientBadge } from 'components/client/CrmClientBadge'

interface CellProps {
  renderedCellValue: ReactNode
  row: MRT_Row<ClientIndex>
}

export default function ClientsScreen() {
  const navigate = useNavigate()
  const [pagination, setPagination] = useState(getSessionStorage(SessionStorageEnum.pagination) ?? { pageIndex: 0, pageSize: 10 })

  const { data, isLoading } = useQuery({
    queryKey: ['getClientsIndex'], queryFn: getClientsIndex
  }) // enabled=true. add/delete requires this.

  const columns = useMemo<MRT_ColumnDef<ClientIndex>[]>(
    () => [
      { //  This must be filled out for search to work. its how material-react-table works.
        header: 'Name',
        accessorKey: 'full_name',
        size: 200,
        Cell: CellName,
        filterFn: (row, columnId, filterValue) => {
          const fullName = String(row.getValue('full_name') || '').toLowerCase()
          const firstName = String(row.getValue('first_name') || '').toLowerCase()
          const lastName = String(row.getValue('last_name') || '').toLowerCase()

          const searchTerm = filterValue.toLowerCase()
          return fullName.includes(searchTerm) || firstName.includes(searchTerm) || lastName.includes(searchTerm)
        },
      },
      {
        accessorKey: 'email',
        header: 'Email',
        size: 180,
      },
      {
        accessorKey: 'last_event_datetime',
        header: 'Last Meeting',
        size: 100,
        Cell: CellLastEventDateTime,
        filterVariant: 'date',
        filterFn: 'lessThan',
        sortingFn: 'text'
      },
      {
        id: 'crm',
        accessorFn: (row) => `${row.redtail_id} ${row.wealthbox_id} ${row.salesforce_id}`,
        header: '',
        size: 100,
        Cell: (params) => <CrmClientBadge client={params.row.original} />
      },
    ],
    [],
  )

  const table = useMaterialReactTable({
    columns,
    data: data ?? [],
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => navigate(`${Path.ClientDetail}/${row.original.id}`),
      sx: { cursor: 'pointer' },
    }),
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    positionGlobalFilter: 'left',
    state: {
      pagination: pagination,
      showGlobalFilter: true, // Always show the global filter
    },
    enableHiding: false,
    muiTableBodyCellProps: {
      sx: {
        backgroundColor: '#fdfdfd', // Set background color for the entire table
      },
    },
    initialState: {
      sorting: [{ id: 'last_event_datetime', desc: true }],
      pagination: {
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize
      }
    },
    onPaginationChange: (updater) => {
      if (typeof updater !== "function") return
      const newPageInfo = updater(table.getState().pagination)
      setSessionStorage(SessionStorageEnum.pagination, newPageInfo)
      setPagination(newPageInfo)
    }
  })

  return (
    <Container sx={{ paddingY: 3 }}>
      <Box sx={gSx.RowBetween}>
        <Typography variant='h1' sx={{ paddingY: '2vh' }}>Contacts</Typography>

        <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: 1 }}>
          <AddClientDialog />
          <ImportContactsListButtons />
        </Box >
      </Box >

      {isLoading
        ? <SkeletonRows count={10} height={70} />
        : <MaterialReactTable table={table} />
      }

    </Container >
  )
}

function CellLastEventDateTime({ renderedCellValue }: CellProps) {
  if (!renderedCellValue)
    return null

  const date = new Date(String(renderedCellValue))
  const formatted = formatDate(date)

  return (
    <Typography>
      {formatted}
    </Typography>
  )
}

function CellName({ row }: CellProps) {
  const c = row.original

  return (
    <Typography>
      {getContactName(c, true)}
    </Typography>
  )
}
