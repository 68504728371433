import React, { useEffect, useState } from 'react'
import { Link, Card, Box, SxProps, Typography, LinearProgress, useMediaQuery } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { Path } from 'routes'
import Grid from '@mui/material/Grid2'
import { Media } from 'service/media'
import { logInfo } from 'log'
import { useQuery } from '@tanstack/react-query'
import { getAffiliateUrlShortener } from 'service/publicApi'
import { auth, config } from 'service/api'
import { gSx, theme } from 'styles/Theme'
import SignInActionSection from 'components/auth/SignInActionSection'
import useRedirectSubDomainRegion from 'hooks/useRedirectSubDomainRegion'

// do not use localStorage.clear(). tawkto uses this.
// do not remove localstorage item since email link callback to this screen, and reads in affiliate info.

export default function SignupAffiliation() {
  const navigate = useNavigate()
  const { id } = useParams()
  useRedirectSubDomainRegion(id)
  const affiliateId = id?.toLowerCase()
  const fbUser = auth.currentUser

  const [affiliateOfferParams, setAffiliateOfferParams] = useState<string | undefined>()

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    if (fbUser) navigate(Path.Loading)
  }, [fbUser])

  const { data, isLoading } = useQuery({
    queryKey: ['getAffiliateUrlShortener', affiliateId],
    queryFn: async () => await getAffiliateUrlShortener(affiliateId),
  })

  useEffect(() => {
    if (data?.offer_url) {
      logInfo(`Signup Affiliation Screen`, { data })
      const offer = data.offer_url.split('?')[1]
      setAffiliateOfferParams(offer)
    } else if (!isLoading && !data) {
      logInfo(`Signup Affiliation, no Affiliation. Redirect`, { affiliateId })
      navigate(Path.SignIn)
    }
  }, [data, isLoading])

  useEffect(() => {
    if (affiliateId) localStorage.setItem('affiliateId', affiliateId)
  }, [affiliateId])
  useEffect(() => {
    if (affiliateOfferParams) localStorage.setItem('affiliateOfferParams', affiliateOfferParams)
  }, [affiliateOfferParams])

  const sxSection: SxProps = {
    ...(isSmallScreen
      ? { ...gSx.CenterVertical, paddingY: 5 }
      : gSx.CenterPage),
    zIndex: 2,
    position: 'relative',
    color: 'white',
    padding: 2
  }

  if (isLoading) {
    return (
      <Box sx={sxPage}>
        <Box component='img' sx={sxLogo} src={Media.logo100} />
        <Box sx={{ width: '30%', paddingTop: 5 }}>
          <LinearProgress />
        </Box>
      </Box >
    )
  }

  return (
    <Grid container>
      <Grid size={{ xs: 12, md: 6 }} sx={{ backgroundColor: '#1C4662', position: 'relative', overflow: 'hidden' }}>
        <Box sx={sxSection}>

          <Box sx={gSx.CenterVertical}>
            {data?.target_company_logo_url &&
              <Box component='img' alt='Finmate AI logo' sx={sxCompanyLogo} src={data?.target_company_logo_url} />
            }
          </Box>
          <Box sx={{ padding: '2vh' }} />

          <Typography variant='h1' sx={{ alignSelf: 'center' }}>
            Welcome {data?.target_company}</Typography>
          <Box sx={{ padding: '2vh' }} />

          <Typography variant='h5'>
            {data?.message
              ? data.message
              : `Ready to never take notes again? If you have any questions, we're here to help.`
            }
          </Typography>
          <Box sx={{ padding: '1vh' }} />

          <Typography variant='h5'>-{data?.owner_display_name}</Typography>
          <Link href={`mailto:${data?.owner_email}`} color="inherit"><Typography variant='h5'>{data?.owner_email}</Typography></Link>

        </Box>
        <Box component="img" src={Media.mountainsBg} sx={sxBackground} />
        <BgAnimation />
      </Grid>

      <Grid size={{ xs: 12, md: 6 }}>
        <SignInActionSection />
      </Grid>
    </Grid >

  )
}

function BgAnimation() {
  return (
    <style>
      {`
    @keyframes moveBackground {
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
    }
  `}
    </style>
  )
}

const sxPage: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  width: '100%',
}

const sxLogo: SxProps = {
  height: 30,
  width: 22,
  resizeMode: 'contain',
  alignContent: 'center',
  paddingLeft: '5px',
  paddingRight: '10px',
}

const sxCompanyLogo: SxProps = {
  width: 150,
  alignSelf: 'center',
}

const sxBackground: SxProps = {
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '200%',
  height: 'auto',
  animation: 'moveBackground 100s linear infinite',
  zIndex: 1,
}