import React from 'react'
import { Box, SxProps, Typography, ButtonBase } from '@mui/material'
import { Media } from '../../service/media'

interface Props {
  text: string
  onClick: () => Promise<void>
  disable: boolean
}

export default function MicrosoftSignInButton({ text, disable, onClick }: Props) {

  const sxButton: SxProps = {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    minWidth: 250,
    border: 1,
    borderRadius: 100,
    padding: 1.5,
    borderColor: 'black',
    bgcolor: 'white',
    opacity: disable ? '25%' : '100%',
  }

  const sxText: SxProps = {
    color: disable ? 'lightgray' : 'black',
  }

  const icon: SxProps = {
    width: 20,
    height: 20,
    marginRight: 2,
    opacity: disable ? 0.2 : 1
  }

  return (
    <ButtonBase sx={sxButton} aria-label={text} onClick={onClick}>
      <Box
        component="img" sx={icon}
        src={Media.microsoft100} alt="Microsoft Logo"
      />
      <Typography sx={sxText}>{text}</Typography>
    </ButtonBase>
  )
}
