import React from 'react'
import { gColor } from "styles/Theme"
import { Typography } from '@mui/material'

interface Props {
  text: string
  onClick: () => void
}

export default function ClickableTypography({ text, onClick }: Props) {

  return (
    <Typography
      sx={{
        paddingLeft: '6px',
        cursor: 'pointer',
        '&:hover': {
          color: gColor.blue800,
          textDecoration: 'underline',
        }
      }}
      onClick={onClick}
    >
      {text}
    </Typography>
  )
}