import React, { useEffect, useState } from 'react'

import { logInfo, logErr } from 'log'
import { ModuleIdentifier } from 'client'

import { Media } from 'service/media'
import { LoadingButton } from '@mui/lab'
import { useQuery } from '@tanstack/react-query'
import PushPinIcon from '@mui/icons-material/PushPin'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { dom, IconName, library } from '@fortawesome/fontawesome-svg-core'
import { Box, Card, CardContent, CardHeader, Chip, IconButton, List, ListItem, SxProps, Tooltip, Typography } from '@mui/material'
import { Api } from '../../service/api'
import { getAvailableModules, getCustomizationsConfigs, updateCustomizationsConfigs } from '../../service/eventApi'
import { MeetingTypeCustomizer } from '../../components/templates/MeetingTypeCustomizer'
import { MeetingTypePinnedMsgAfter, MeetingTypePinnedMsgBefore } from '../../components/templates/CustomizerCardHeader'
import { TemplatePageLoading } from '../../components/Skeletons'
import TemplateDetailLevelChip from '../../components/templates/TemplateDetailLevelChip'

library.add(fas)
dom.watch()

export default function MeetingTypesPage() {
  const [open, setOpen] = useState(false)
  const [selectedModule, setSelectedModule] = useState<ModuleIdentifier | null>(null)
  const [pinnedMap, setPinnedMap] = useState(new Map())
  const [busy, setBusy] = useState(false)

  useEffect(() => {
    logInfo(`Template Meeting Types Screen`)
  }, [])

  const { data: meetingTypes, isLoading, refetch: refetchModules } = useQuery({ queryKey: ["getAvailableModules"], queryFn: getModules })

  async function getModules() {
    const modules = await getAvailableModules()
    return modules
  }

  const { data: configs, refetch: refetchConfigs } = useQuery({ queryKey: ['customizationsConfigs'], queryFn: getConfigs })

  async function getConfigs() {
    const configs = await getCustomizationsConfigs()
    return configs
  }

  const handleClickOpen = (module: ModuleIdentifier) => {
    setSelectedModule(module)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setSelectedModule(null)
  }

  const handleNewMeetingTemplate = async () => {
    setBusy(true)
    const api = await Api()
    const res = await api.customizations.newMeetingTypesModule()
    setSelectedModule(res)
    setOpen(true)
    refetchModules()
    setBusy(false)
  }

  useEffect(() => {
    if (configs && configs.summarize_module_id_list) {
      const map = new Map<string, boolean>()
      configs.summarize_module_id_list.forEach(item => map.set(item, true))
      setPinnedMap(map)
    }
  }, [configs])

  async function handlePinnedToggle(moduleId: string) {
    if (!configs) return
    if (moduleId == "allpurpose-module-general") return
    const favorited = pinnedMap.get(moduleId)
    const updatedPinnedMap = new Map(pinnedMap)
    updatedPinnedMap.set(moduleId, !favorited)

    setPinnedMap(updatedPinnedMap)
    const updatedConfig = { ...configs }
    if (favorited && configs.summarize_module_id_list) {
      updatedConfig.summarize_module_id_list = configs.summarize_module_id_list.filter(item => item != moduleId)
    } else {
      if (updatedConfig.summarize_module_id_list) {
        updatedConfig.summarize_module_id_list.push(moduleId)
      } else {
        updatedConfig.summarize_module_id_list = [moduleId]
      }
    }
    try {
      updatedConfig.summarize_module_id_list = Array.from(new Set(updatedConfig.summarize_module_id_list))
      await updateCustomizationsConfigs(updatedConfig)
      await refetchConfigs()
    } catch (error) {
      logErr("Failed to update configurations:", { error })
      // Revert the optimistic update if there was an error
      updatedPinnedMap.set(moduleId, favorited)
      setPinnedMap(updatedPinnedMap)
    }
  }

  if (isLoading || !meetingTypes) {
    return (
      <TemplatePageLoading />
    )
  }

  return (
    <>
      <Card>
        <CardHeader subheader={"Use Meeting Type Templates to tailor summaries! Create or duplicate templates, add or remove sections, and choose list or paragraph formats. Access templates from other members and pin your favorites for quick selection in meetings."} action={<LoadingButton onClick={handleNewMeetingTemplate} variant="outlined" sx={{ paddingLeft: 2 }} loading={busy}
          disabled={busy}>Build New Template</LoadingButton>} />

        <CardContent>
          <List>
            {meetingTypes && meetingTypes.length > 0 && meetingTypes.map((module, index) => (
              <ListItem
                key={index}
                sx={sxListItem}
                onClick={() => handleClickOpen(module)}
              >
                <CardContent sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                  <FontAwesomeIcon icon={['fas', module.module_ui_icon as IconName]} style={{ color: 'grey', marginRight: 16 }} fontSize="24px" />
                  <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="h5" component="div" sx={{ mr: 1 }}>
                        {module.module_ui_title}
                      </Typography>
                      <TemplateDetailLevelChip
                        module={module} />
                    </Box>
                    <Typography variant="body2" color="text.secondary">
                      {module.module_ui_description}
                    </Typography>
                    <Box sx={{ mt: 1 }}>
                      {module.ownership?.is_finmate_owned &&
                        <Chip key={"byfinmate"} label={"By FinMate"} icon={<Box component='img' alt='Finmate AI logo' sx={sxIcon} src={Media.logo100} />} />
                      }
                      {module.tags?.map((tag, index) => (
                        <Chip key={index} label={tag} sx={{ mr: 0.5, mt: 0.5 }} />
                      ))}
                    </Box>
                  </Box>
                </CardContent>
                <Tooltip title={pinnedMap.get(module.module_id ?? "") ? MeetingTypePinnedMsgAfter : MeetingTypePinnedMsgBefore}>
                  <IconButton onClick={(e) => {
                    e.stopPropagation()
                    handlePinnedToggle(module.module_id ?? "")
                  }}>
                    <PushPinIcon
                      sx={{ color: pinnedMap.get(module.module_id ?? "") ? '#FF9800' : 'grey' }}
                    />
                  </IconButton>
                </Tooltip>
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>
      {open && selectedModule && selectedModule.module_id && (<MeetingTypeCustomizer open={open} targetModuleId={selectedModule?.module_id} onClose={handleClose} />)}
    </>
  )
}

const sxIcon: SxProps = {
  width: 15,
  height: 20,
  paddingLeft: '5px',
}

const sxListItem: SxProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  border: '1px solid #e0e0e0',
  borderRadius: '8px',
  marginBottom: '8px',
  padding: '8px 16px', // Reduced padding
  backgroundColor: '#fff',
  cursor: 'pointer' // Make the item look clickable
}
