import React, { useState } from 'react'
import {
  Dialog,
  Snackbar,
  Alert,
} from '@mui/material'
import { EventBase } from '../../client'
import ClientForEventNew from './ClientForEventNew'
import ClientForEventExisting from './ClientForEventExisting'

interface Props {
  event?: EventBase
  open: boolean
  onClose: () => void
}

export default function ClientEventDialog({ event, open, onClose }: Props) {

  // const [input, setInput] = useState<OrgUser>({})
  const [isAddStep, setIsAddStep] = useState(false)
  const [openSuccessSnack, setOpenSuccessSnack] = useState(false)

  function handleClose() {
    setIsAddStep(false)
    onClose()
  }

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth>

        {
          isAddStep
            ? <ClientForEventNew
              event={event}

              setIsAddStep={setIsAddStep}
              onClose={handleClose}
              onSuccessSnack={() => setOpenSuccessSnack(true)}
            />
            : (
              <ClientForEventExisting
                event={event}
                setIsAddStep={setIsAddStep}
                onClose={handleClose}
                onSuccessSnack={() => setOpenSuccessSnack(true)}
              />
            )

        }
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSuccessSnack}
        autoHideDuration={3000}
        onClose={() => setOpenSuccessSnack(false)}
      >
        <Alert
          variant="filled"
          severity="success"
        >
          Contacts Updated for this Meeting
        </Alert>

      </Snackbar>
    </>
  )
}
