import React, { useState } from 'react'

import { Path } from 'routes'
import { useNavigate } from 'react-router-dom'

import { fas } from '@fortawesome/free-solid-svg-icons'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CenteredCircularProgress from 'components/CenteredCircularProgress'
import { dom, IconName, library } from '@fortawesome/fontawesome-svg-core'
import { Box, List, ListItemButton, ListItemIcon, ListItemText, SxProps, Typography } from '@mui/material'

import ModuleExamples from './ModuleExamples'
import { ModuleIdentifier } from '../../client'
import SpeakerNameConfirmDialog from './SpeakerNameConfirmDialog'
import { getEventTranscript, getFavoriteMeetingTypes, getUnchangedSpeakers, setEventModule } from '../../service/eventApi'
import { logInfo } from '../../log'
import TemplateDetailLevelChip from '../templates/TemplateDetailLevelChip'

library.add(fas)
dom.watch()

interface Props {
  eventId: string
  disabled?: boolean
  isRerun?: boolean
  onBuildModule?: () => void
}

export default function EventModuleSelect({ eventId, disabled, isRerun, onBuildModule }: Props) {
  const navigate = useNavigate()

  const { data, isLoading } = useQuery({ queryKey: ["modules"], queryFn: getModules })
  const queryClient = useQueryClient()
  const [selectedModule, setSelectedModule] = useState<ModuleIdentifier>()
  const [buildConfirmDialog, setBuildConfirmDialog] = useState(false)
  const [unchangedSpeakers, setUnchangedSpeakers] = useState("")
  const [busy, setBusy] = useState(false)

  const { data: transcript } = useQuery({
    queryKey: ['getEventTranscript', eventId],
    queryFn: async () => await getEventTranscript(eventId),
    enabled: queryClient.getQueryData(['getEventTranscript', eventId]) === undefined,
  })

  async function getModules() {
    const modules = await getFavoriteMeetingTypes()
    return modules
  }

  const handleClickModule = async (item: ModuleIdentifier) => {
    setBusy(true)
    setSelectedModule(item)

    const unchangedSpeakersList = getUnchangedSpeakers(transcript)

    if (unchangedSpeakersList.length == 0) {
      // all the speakers are updated
      await buildModule(item)
    } else {
      // show dialog
      const unchangedSpeakersString = unchangedSpeakersList.join(",  ")
      setUnchangedSpeakers(unchangedSpeakersString)
      setBuildConfirmDialog(true)
    }
    setBusy(false)
  }

  const handleCloseConfirmDialog = async (confirm: boolean) => {
    setBusy(true)
    if (confirm && selectedModule) {
      await buildModule(selectedModule)
    }
    setBuildConfirmDialog(false)
    setBusy(false)
  }

  const buildModule = async (item: ModuleIdentifier) => {
    if (item?.module_name) {
      const res = await setEventModule(eventId, item.module_name, isRerun)
      queryClient.invalidateQueries({ queryKey: ['getEvent', eventId] })
      queryClient.invalidateQueries({ queryKey: ['getEventSummary', eventId] })
      if (onBuildModule) {
        onBuildModule()
      }
      logInfo('Building Meeting Summary', { eventId, res })
    }
  }

  return (
    <Box sx={sxProcessing}>
      <CenteredCircularProgress loading={busy || isLoading}>
        <List sx={{ width: '100%' }}>
          {data?.map((item: ModuleIdentifier, index: number) => (
            <Box key={index} sx={{ display: 'flex', flexDirection: 'row' }}>
              <ListItemButton
                aria-label={item.module_ui_title}
                onClick={() => handleClickModule(item)}
                disabled={disabled || busy}
              >
                <ListItemIcon>
                  <FontAwesomeIcon icon={['fas', item.module_ui_icon as IconName]} style={{ color: 'grey', marginRight: 16 }} fontSize="24px" />
                </ListItemIcon>
                <ListItemText primary={<Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography component="span" sx={{ mr: 1 }}>{item.module_ui_title}</Typography>
                  <TemplateDetailLevelChip
                    module={item} />
                </Box>} secondary={item.module_ui_description} />
              </ListItemButton>
              <ModuleExamples item={item} />
            </Box>
          ))}

          <ListItemButton
            aria-label="navigate-template"
            onClick={() => navigate(Path.Templates)}
            disabled={disabled || busy}
            sx={{
              py: 2, // Increase vertical padding
              '&:hover': {
                backgroundColor: 'action.hover',
              },
            }}
          >
            <Typography sx={sxTemplateContent}>
              Want to customize meeting types? Try
              <Box
                component="span"
                sx={sxTemplateHighlight}
              >
                <AutoAwesomeIcon sx={{ fontSize: 'inherit', mr: 0.5 }} />
                Templates
              </Box>
            </Typography>
          </ListItemButton>
        </List>
      </CenteredCircularProgress>

      <SpeakerNameConfirmDialog
        openDialog={buildConfirmDialog}
        closeDialog={handleCloseConfirmDialog}
        busy={busy}
        unchangedSpeakers={unchangedSpeakers}
      />
    </Box >
  )
}

/* eslint-enable */

const sxProcessing: SxProps = {
  display: 'flex',
  flexDirection: 'column'
}

const sxTemplateHighlight: SxProps = {
  display: 'inline-flex',
  alignItems: 'center',
  ml: 1,
  color: 'primary.main',
}

const sxTemplateContent: SxProps = {
  display: 'flex',
  alignItems: 'center',
  color: 'text.secondary', // Softer text color
  fontWeight: 500, // Slightly bolder font
}