import React, { useState } from 'react'
import { Tabs, Tab, Typography, Box, SxProps, Container } from '@mui/material'
import MeetingTypesPage from './MeetingTypesPage'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'

const TemplatesScreen: React.FC = () => {
  const [value, setValue] = useState("1")

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  return (
    <Container sx={{ paddingY: 3 }}>
      <Box display="flex" gap={1}>
        <Typography variant="h1" component="h2" sx={{ lineHeight: 1 }}>
          Templates
        </Typography>
      </Box>
      <TabContext value={value}>
        <TabList onChange={handleChange} aria-label="templates tabs" sx={{ paddingLeft: "14px" }}>
          <Tab label="Meeting Types" value="1" />
          {/* <Tab label="Followup Emails" {...a11yProps(1)} /> */}
        </TabList>
        <TabPanel value={"1"} sx={{ p: 1 }} >
          <MeetingTypesPage />
        </TabPanel>
        {/* <TabPanel value={value} index={1}>
          Content for Followup Emails
        </TabPanel> */}
      </TabContext>
    </Container>
  )
}

export default TemplatesScreen
