import React, { HTMLAttributes, SyntheticEvent, useEffect, useRef, useState } from 'react'
import {
  Box,
  Typography,
  TextField,
  Button,
  Autocomplete,
  DialogActions,
  Divider,
  DialogContent,
  DialogTitle,
  Skeleton,
  ListItem,
  ListItemText,
} from '@mui/material'
import { ClientIndex, EventBase } from '../../client'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Path } from '../../routes'
import { useNavigate } from 'react-router-dom'
import { logInfo } from '../../log'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import { getClientsIndex, getRecentClients } from 'service/clientApi'
import { gSx } from 'styles/Theme'
import { LoadingButton } from '@mui/lab'
import ImportContactsListButtons from 'components/integrations/ImportContactsListButtons'
import { createFuzzySearch } from 'utils/fuzzySearch'
import { CrmClientBadge } from './CrmClientBadge'
import { addContactToEvent } from 'service/eventApi'

interface Props {
  event?: EventBase
  setIsAddStep: (isAdd: boolean) => void
  onClose: () => void
  onSuccessSnack: () => void
}

const LIMIT_RESULTS = 100
export default function ClientForEventExisting({ event, setIsAddStep, onClose, onSuccessSnack }: Props) {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const eventId = event?.id ?? ''
  const recentRef = useRef<ClientIndex[]>([])
  const searchRef = useRef<((query: string) => ClientIndex[]) | undefined>()
  const [dropdown, setDropdown] = useState<ClientIndex[]>([])

  const [inputValue, setInputValue] = useState('')
  const [selected, setSelected] = useState<ClientIndex | undefined>()
  const [busy, setBusy] = useState(false)
  const [expandSpace, setExpandSpace] = useState(false)
  const [err, setErr] = useState<string | undefined>()

  const { data, isLoading } = useQuery({
    queryKey: ['getClientsIndex'], queryFn: getClientsIndex,
    // always enable. when importing, auto refreshes.
  })

  useEffect(() => {
    if (data && data.length > 0) {
      recentRef.current = getRecentClients(LIMIT_RESULTS, data)
      setDropdown(recentRef.current)
      searchRef.current = createFuzzySearch(data)
    }
  }, [data])

  const onInputChange = (event: SyntheticEvent, value: string) => {
    setInputValue(value)
    if (searchRef.current && value.length > 0) {
      const searchResults = searchRef.current(value)
      setDropdown(searchResults.slice(0, LIMIT_RESULTS))
    } else {
      setDropdown(recentRef.current)
    }
  }

  async function onSave() {
    if (!selected)
      return
    setBusy(true)
    setErr(undefined)
    logInfo('Event, client details, set client', { eventId, clientId: selected?.id })

    const res = await addContactToEvent(eventId, selected?.id)

    if (res instanceof Error) {
      setErr(res.message)
    } else { // success
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: ['getEvent', eventId] }),
        queryClient.invalidateQueries({ queryKey: ['getSpeakersAndAssignees', eventId] }),
      ])
      onSuccessSnack()
      onClose()
    }
    setBusy(false)
  }

  return (
    <>
      <DialogTitle>Add a Contact for this Meeting</DialogTitle>

      <DialogContent sx={{ paddingY: 1, marginY: 0 }}>

        {isLoading
          ? <Skeleton variant="rectangular" width={'100%'} height={200} />
          : (
            <>
              <Box sx={{ padding: 1 }} />
              <Autocomplete
                disablePortal
                options={dropdown}
                inputValue={inputValue}
                onInputChange={onInputChange}
                getOptionLabel={optionLabel}
                renderOption={renderOption}
                renderInput={(params) => <TextField {...params} label='Existing Contacts' />}
                value={selected}
                onChange={(event: any, newValue) => {
                  // event.preventDefault()
                  setSelected(newValue ?? undefined)
                }}
                onOpen={() => setExpandSpace(true)}
                onClose={() => setExpandSpace(false)}
                disabled={!data || isLoading}
              />

              <Box sx={gSx.RowBetween}>
                <Button
                  onClick={() => navigate(`${Path.ClientDetail}/${selected?.id}`)}
                  disabled={isLoading}
                >
                  {selected
                    ? `Edit Contact`
                    : <Box sx={{ padding: 1.2 }} />
                  }
                </Button>
                <Typography variant='caption'>{`displays top ${LIMIT_RESULTS} results`}</Typography>
              </Box>
            </>
          )
        }

        {expandSpace && <Box sx={{ paddingY: 14 }} />}

        {err && <Typography color='error'>{err}</Typography>}

      </DialogContent>

      <DialogActions sx={{ paddingY: 0, marginY: 0 }}>
        <Button
          onClick={onClose}
        >
          Cancel
        </Button>

        <LoadingButton
          variant='contained'
          onClick={onSave}
          disabled={selected === null}
          sx={{ width: 100 }}
          loading={busy}
        >
          Save
        </LoadingButton>
      </DialogActions>

      <DialogContent >

        <Box sx={gSx.Row}>
          <Divider sx={{ width: '46%' }} />
          <Typography sx={{ width: '8%', textAlign: 'center' }}>OR</Typography>
          <Divider sx={{ width: '46%' }} />
        </Box>

        <Box sx={{ padding: '1vh' }} />

        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <Button
            variant='outlined'
            onClick={() => setIsAddStep(true)}
            startIcon={<PersonAddIcon />}
          >
            Add a New Contact
          </Button>

          <ImportContactsListButtons />
        </Box>
      </DialogContent>
    </>
  )
}

function optionLabel(c: ClientIndex): string {
  if (c.last_name && c.email)
    return `${c.first_name ?? ''} ${c.last_name ?? ''}, ${c.email ?? ''}`
  if (c.last_name)
    return `${c.first_name ?? ''} ${c.last_name ?? ''}`
  if (c.full_name)
    return c.full_name
  return `${c.email}`
}

function renderOption(props: HTMLAttributes<HTMLLIElement>, option: ClientIndex) {
  return (
    <ListItem {...props} key={option.id}>
      <ListItemText primary={optionLabel(option)} />
      <CrmClientBadge client={option} />
    </ListItem>
  )
}
