import { EventSearchParams, ListEvents, SummaryBase, ModuleIdentifier, SummaryProcessingStatus, Origin, SharedUser, ActionItemList, ActionItem, TranscriptBase, AssigneeInfo, UserConfigModuleBase, EventBase, Bot, Access, ClientDetail } from '../client'
import { logErr, logInfo, logWarn } from '../log'
import { Api, auth, config } from './api'
import { getErrorMessage, getFileExtension, htmlToPlainText } from '../utils'
import axios from 'axios'
import { isSuperUser } from './authApi'

export async function createBot(url: string) {
  url = extractUrl(url)
  try {
    logInfo('manual bot create', { url })
    const api = await Api()
    await api.events.createBot(url)
    return url
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('createBot', { err })
    return err
  }
}

function extractUrl(url: string) {
  // users sometimes paste in a paragraph. extract url from paragraph
  const urlRegex = /(https?:\/\/[^\s]+)/
  const match = url.match(urlRegex)
  if (match) {
    return match[0]
  }
  return url
}

/**
 * Existing Events
 * @returns 
 */
export async function listAdminEvents(): Promise<ListEvents | null> {
  if (config.env === 'prod')
    return null// This shows all events from all users.
  try {
    const api = await Api()
    return await api.events.finmateSuperUserEvents()
  } catch (e) {
    logErr('listAdminEvents', { e })
  }
  return null
}

export async function getEvents(p: EventSearchParams, page: number, existing?: ListEvents, isShared?: boolean): Promise<ListEvents | undefined> {

  const pages = existing?.pages
  if (page == 1)
    p.pages = []
  else if (existing?.pages) {
    p.pages = pages
  }

  logInfo('getEvents', { params: p, page })
  try {
    const api = await Api()
    const res = await api.events.getEventsV2(page, p, isShared)
    return res
  } catch (e) {
    logErr('getEvents', { e, params: p, page })
  }
}

export async function getEvent(id?: string): Promise<EventBase | undefined> {
  if (!id)
    return
  try {
    const api = await Api()
    return await api.events.getEvent(id)
  } catch (e: any) {
    logErr('getEvent', { id: id, err: e.message, e })
    throw e
  }
}

export async function getEventBot(id?: string): Promise<Bot | undefined> {
  if (!id)
    return
  try {
    const api = await Api()
    return await api.events.getEventBot(id)
  } catch (e: any) {
    logErr('getEventBot', { id: id, err: e.message, e })
    throw e
  }
}

export async function deleteEvent(id?: string): Promise<undefined | Error> {
  if (!id)
    return
  try {
    logInfo('deleteEvent', { id })
    const api = await Api()
    await api.events.deleteEvent(id)
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('deleteEvent', { id, err })
    return err
  }
}

export async function deleteEvents(eventIds: string[]): Promise<undefined | Error> {
  try {
    logInfo('deleteEvents', { eventIds })
    const api = await Api()
    await api.events.deleteEvents(eventIds)
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('deleteEvents', { eventIds, err })
    return err
  }
}

export async function getEventSummaryBase(id: string): Promise<SummaryBase | null> {
  try {
    const api = await Api()
    return await api.events.getEventSummaryBase(id)
  } catch (e: any) {
    logInfo('getEventSummary attempted', { id: id, err: e.message, e })
    throw e
  }
  return null
}

export async function getEventSummaryStatus(id: string): Promise<SummaryProcessingStatus | undefined> {
  try {
    const api = await Api()
    return await api.events.getEventSummaryStatus(id)
  } catch (e) {
    logErr('getEventSummaryStatus', { id })
    throw e
  }
}

export async function getEventTranscript(eventId: string) {
  if (!eventId)
    throw new Error('missing event id')
  try {
    const api = await Api()
    return await api.events.getEventTranscript(eventId)
  } catch (e) {
    const err = getErrorMessage(e)
    logWarn('getEventTranscript', { eventId, err })
    throw e
  }
}

export async function updateEventTranscriptSpeaker(eventId: string, speakerId: string | undefined, speakerRaw: AssigneeInfo | string | null) {
  if (!speakerId)
    return new Error('missing speaker id')

  let newSpeaker: AssigneeInfo = {}
  if (speakerRaw == null)
    return
  if (typeof speakerRaw == 'object') {
    newSpeaker = speakerRaw
  } else if (typeof speakerRaw == 'string') {
    newSpeaker.name = speakerRaw.trim()
  }

  newSpeaker.speaker_id = speakerId

  try {
    const api = await Api()
    return await api.events.updateEventTranscriptSpeaker(eventId, newSpeaker.speaker_id, newSpeaker.name ?? '')
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('updateEventTranscriptSpeaker', { eventId, err })
    return err
  }
}

export async function getAvailableModules(): Promise<Array<ModuleIdentifier> | undefined> {
  try {
    const api = await Api()
    // return await api.events.getAvailableModules()
    return await api.customizations.getAvailableMeetingTypes()
  } catch (e) {
    logErr('getAvailableModules', { e })
  }
}

export async function getFavoriteMeetingTypes(): Promise<Array<ModuleIdentifier> | undefined> {
  try {
    const api = await Api()
    return await api.customizations.getFavoriteMeetingTypes()
  } catch (e) {
    logErr('getFavoriteMeetingTypes', { e })
  }
}

export async function getCustomizationsConfigs(): Promise<UserConfigModuleBase | undefined> {
  try {
    const api = await Api()
    return await api.customizations.getConfigs()
  } catch (e) {
    logErr('getCustomizationsConfigs', { e })
  }
}

export async function updateCustomizationsConfigs(config: UserConfigModuleBase): Promise<UserConfigModuleBase | undefined> {
  try {
    const api = await Api()
    return await api.customizations.updateConfigs(config)
  } catch (e) {
    logErr('updateCustomizationsConfigs', { e })
  }
}

export async function setEventModule(id: string, moduleName: string, isRerun?: boolean): Promise<any | undefined> {
  try {
    const api = await Api()
    logInfo('Set Event Module', { id, moduleName })
    return await api.events.setEventModule(id, moduleName, isRerun)
  } catch (e) {
    logErr('setEventModule', { e })
  }
}

export async function UploadFile(file: File, title: string, origin: Origin, setProgress: (percent: number) => void) {
  try {
    logInfo('Upload File, Start', { title, origin })
    const event = await generateUrlForUpload(file, title, origin)
    if (!event?.id)
      throw new Error('missing event ID')

    const url = event?.video_bucket_info?.upload_signed_url
    if (!event || !url)
      throw new Error('failed generateUrlForUpload')

    logInfo('Upload File, Uploading', { title, origin, "event_id": event.id })
    try {
      await axios.put(url, file, {
        headers: {
          'Content-Type': file.type
        },
        onUploadProgress: (progressEvent: any) => {
          const p = Math.round((progressEvent.loaded / progressEvent.total) * 100)
          setProgress(p)
        },
      })
      logInfo('Upload File, Done', { title, origin, "eventId": event.id })
    } catch (e: any) {
      logErr('upload via signed url', { err: e.message })
      throw new Error(e)
    }

    const api = await Api()
    return await api.events.postUploadShowEvent(event.id)
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('UploadFile', { err })
    return err
  }
}

async function generateUrlForUpload(file: File, title: string, origin: Origin) {
  try {
    logInfo('Generate Url For Upload', { title, origin, "file.name": file.name })
    const ext = getFileExtension(file.name)
    const api = await Api()
    const event = await api.events.generateUrlForUpload(ext, title, origin)
    logInfo('Generate Url For Upload', { title, origin, "file.name": file.name, "event_id": event.id })
    return event
  } catch (e) {
    logErr('generateUrlForUpload', { e })
  }
}

export async function updateEventTitle(id: string, title: string): Promise<string | Error> {
  try {
    logInfo('Update Event Title', { title })
    const api = await Api()
    return await api.events.updateEventTitle(id, title)
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('updateEventTitle', { err })
    return err
  }

}

export async function runSentimentAnalysis(id: string) {
  try {
    logInfo('runSentimentAnalysis', { id })
    const api = await Api()
    return await api.events.runSentimentAnalysis(id)
  } catch (e) {
    logErr('runSentimentAnalysis', { e })
  }
}

export async function getSentimentAnalysis(id?: string) {
  if (!id)
    return
  try {
    const api = await Api()
    return await api.events.getSentimentAnalysis(id)
  } catch (e) {
    logErr('getSentimentAnalysis', { e })
  }
}

export async function addEventShare(sharedUser: SharedUser, notify: boolean, eventId?: string): Promise<boolean> {
  if (!eventId)
    return false
  try {
    const api = await Api()
    await api.events.addEventShare(eventId, notify, sharedUser)
    return true
  } catch (e) {
    logErr('updateEventShare', { e })
  }
  return false
}

export async function updateEventPermissions(allSharedUsers: SharedUser[], eventId?: string): Promise<undefined | Error> {
  if (!eventId)
    return new Error('missing Event Id')
  try {
    const api = await Api()
    await api.events.updateEventPermissions(eventId, allSharedUsers)
    return
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('updateEventPermissions', { err })
    return err
  }
}

export async function getEventTasks(eventId?: string): Promise<ActionItemList | undefined> {
  try {
    if (!eventId) {
      throw new Error("missing EventId")
    }
    const api = await Api()
    return await api.events.getEventTasks(eventId)
  } catch (e) {
    logErr('getEventTasks', { e })
    throw e
  }
}

export async function updateEventTasks(tasks: ActionItem[], eventId?: string): Promise<ActionItem[] | undefined | Error> {
  if (!eventId)
    return new Error('missing event Id')
  try {
    logInfo('updateEventTasks', { eventId, tasks })
    const api = await Api()
    return await api.events.updateEventTasks(eventId, tasks)
  } catch (e) {
    logErr('updateEventTasks', { e })
    const err = getErrorMessage(e)
    return err
  }
}

export function getUnchangedSpeakers(transcript?: TranscriptBase) {
  const regex = /^Speaker \d+$/
  const unchangedSpeakers = {} as { [key: string]: any }
  if (transcript?.entries) {
    for (const item of transcript.entries) {

      if (item.speaker && regex.test(item.speaker))
        unchangedSpeakers[item.speaker] = true
    }
  }
  return Object.keys(unchangedSpeakers)
}

export function getTaskAssignee(action?: ActionItem): AssigneeInfo {
  if (!action)
    return {}

  if (action.assignee_info)
    return action.assignee_info

  const a: AssigneeInfo = { name: action.assignee }
  return a
}

export function assigneeDisplay(a?: AssigneeInfo | string) {
  if (!a)
    return ''
  if (typeof a === 'string')
    return a.trim()

  return `${a.name ?? ''}`.trim()
}

export async function getSpeakersAndAssignees(eventId: string) {
  try {
    const api = await Api()
    const res = await api.events.getSpeakersAndAssignees(eventId)
    return res
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('getSpeakersAndAssignees', { eventId, err })
  }
  return
}

export function getAssigneeInfoListFromTasks(tasks: ActionItem[]) {
  const assigneeInfoList: AssigneeInfo[] = []
  for (const t of tasks) {
    (t.assignee_info)
      ? assigneeInfoList.push(t.assignee_info)
      : assigneeInfoList.push({ name: t.assignee })
  }
  return assigneeInfoList
}

interface Section {
  title: string
  description: string
}

export function getSummarySections(summary?: SummaryBase | null) {
  if (!summary || !summary.details_html_str)
    return []

  const sections: Section[] = []
  const regex = /<h3>(.*?)<\/h3>([\s\S]*?)(?=<h3>|$)/g
  let match

  while ((match = regex.exec(summary.details_html_str)) !== null) {
    const title = "Summary Section: " + match[1].replace(/:$/, '') // Remove trailing colon if it exists
    const descriptionHtml = match[2].trim() // Remove extra whitespace around the description
    const descriptionPlain = htmlToPlainText(descriptionHtml, false)
    sections.push({ title, description: descriptionPlain })
  }

  return sections
}

export async function addContactToEvent(eventId: string, contactId?: string) { // for wealthbox
  if (!contactId)
    throw new Error('missing contact Id')
  try {
    const api = await Api()
    return await api.events.addContactToEvent(eventId, contactId)
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('addContactToEvent', { err })
    return err
  }
}

export async function removeContactFromEvent(eventId: string, contactId?: string) {
  if (!contactId)
    throw new Error('missing contact Id')
  try {
    const api = await Api()
    return await api.events.removeContactFromEvent(eventId, contactId)
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('removeContactFromEvent', { err })
    return err
  }
}

export function checkEventEditor(event?: EventBase) {
  if (isSuperUser())
    return true

  if (!event)
    return false

  const uid = auth.currentUser?.uid
  if (!uid)
    return false
  if (event.owner_id == uid)
    return true

  const permissions = event?.permissions ?? []
  for (const p of permissions) {
    if (p.uid == uid)
      return p.access == Access.EDITOR
  }
  return false
}

export function isContactInList(contacts?: ClientDetail[], contact?: ClientDetail) {
  if (!contacts || !contact)
    return false

  for (const c of contacts) {
    if (c.id == contact.id)
      return true
  }
  return false
}