import React, { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Box, Card, Container, Typography, IconButton } from "@mui/material"
import { logInfo } from 'log'
import PricingPlansNew from '../../components/pay/PricingPlansNew'
import { getOrg, isAdminRole } from 'service/orgsApi'
import { getSubscription } from 'service/payApi'
import Grid from '@mui/material/Grid2'
import ActiveOrgSeats from 'components/pay/ActiveOrgSeats'
import SeatsPurchased from 'components/pay/SeatsPurchased'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'

import EditSubscriptionDialog from '../../components/pay/EditSubscriptionDialog'
import { gSx } from 'styles/Theme'
import BillingOrgCard from 'components/billing/BillingOrgCard'

export default function BillingScreen() {
  const queryClient = useQueryClient()
  const [showEditDialog, setShowEditDialog] = useState(false)

  useEffect(() => {
    logInfo('Billing Tab')
  }, [])

  const { data: org } = useQuery({
    queryKey: ['getOrg'], queryFn: getOrg,
    enabled: queryClient.getQueryData(['getOrg']) === undefined,
  })

  const { data: sub } = useQuery({
    queryKey: ['getSubscription'], queryFn: getSubscription,
    enabled: queryClient.getQueryData(['getSubscription']) === undefined,
  })

  const isAdmin = isAdminRole(org)
  const isOrgSubscribed = !!sub?.subscription
  const isYearSub = sub?.interval == "year"

  async function onEditClose(refresh?: boolean) {
    if (refresh) {
      // update all
      queryClient.invalidateQueries(['getOrg'])
      queryClient.invalidateQueries(['getSubscription'])
      queryClient.invalidateQueries(['getSubInfo'])
    }
    setShowEditDialog(false)
  }

  return (
    <Container sx={{ paddingY: 3 }}>
      <Box sx={gSx.RowBetween}>
        <Box>
          <Typography >Organization</Typography>
          <Typography variant='h1' sx={{ paddingBottom: 2 }}>Billing</Typography>
        </Box>

        {isOrgSubscribed && isAdmin && !isYearSub &&
          <IconButton onClick={() => setShowEditDialog(true)}>
            <MoreHorizIcon />
          </IconButton>
        }
      </Box>

      {isOrgSubscribed && isAdmin && !isYearSub &&
        <EditSubscriptionDialog
          open={showEditDialog}
          onClose={onEditClose}
        />
      }

      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 6, lg: 4 }} sx={{ display: 'flex' }}>
          <BillingOrgCard />
        </Grid>

        <Grid size={{ xs: 12, md: 6, lg: 4 }} sx={{ display: 'flex' }}>
          <SeatsPurchased />
        </Grid>

        <Grid size={{ xs: 12, md: 6, lg: 4 }} sx={{ display: 'flex' }}>
          <ActiveOrgSeats />
        </Grid>
      </Grid>

      <Box sx={{ padding: 1 }} />

      <PricingPlansNew isAdmin={isAdmin} />

    </Container>
  )
}
