import React from 'react'
import { Box, Tooltip, SxProps } from '@mui/material'
import { ClientDetail, ClientIndex, SalesforceApp } from 'client'
import { getSalesforceIcon, getSalesforceSettings } from 'service/integrations/salesforceApi'
import { Media } from 'service/media'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { gSx } from 'styles/Theme'

interface Props {
  client?: ClientIndex | ClientDetail
}

export function CrmClientBadge({ client }: Props) {
  const queryClient = useQueryClient()

  const { data: sf } = useQuery({
    queryKey: ['getSalesforceSettings'], queryFn: getSalesforceSettings,
    enabled: queryClient.getQueryData(['getSalesforceSettings']) === undefined,
  })
  const salesforceApp = sf?.app ?? SalesforceApp.SALESFORCE

  if (!client)
    return

  return (
    <Box sx={gSx.Row}>
      {client.redtail_id &&
        <Tooltip title={`Redtail Contact (ID: ${client.redtail_id})`}>
          <Box
            component="img"
            sx={sxIcon}
            src={Media.redtailIconWhite}
            alt="Redtail"
          />
        </Tooltip>
      }

      {client.wealthbox_id &&
        <Tooltip title={`Wealthbox Contact (ID: ${client.wealthbox_id})`}>
          <Box
            component="img"
            sx={sxIcon}
            src={Media.wealthboxIcon}
            alt="Wealthbox"
          />
        </Tooltip>
      }

      {client.salesforce_id &&
        <Tooltip title={`${salesforceApp} Contact (ID: ${client.salesforce_id})`}>
          <Box
            component="img"
            sx={sxIcon}
            src={getSalesforceIcon(salesforceApp)}
            alt="Salesforce"
          />
        </Tooltip>
      }

      {/* TODO: add precisefp */}
    </Box>
  )
}

const sxIcon: SxProps = {
  marginLeft: 1,
  width: 25,
  height: 25,
}